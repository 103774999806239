import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  Alert,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Snackbar,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import PackageDetails from "./PackageDetails";
import CustomerInfo from "./CustomerInfo";
import { useOrders } from "./OrdersContext";
import _ from "lodash";

const OrderEditPage = () => {
  const navigate = useNavigate();
  const { businessId, orderId } = useParams();
  const [editedOrder, setEditedOrder] = useState(null);
  const [originalOrder, setOriginalOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    orderDetails: null,
    sender: null,
    recipient: null,
    packages: null,
  });
  const [loadError, setLoadError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { fetchSingleOrder, updateOrder, saveCustomerInfo } = useOrders();
  const location = useLocation();
  const topRef = useRef(null);

  const validatePackages = (packages) => {
    const errors = [];

    if (!packages || !Array.isArray(packages)) {
      return ["Invalid package data"];
    }

    packages.forEach((pkg, pkgIndex) => {
      // Validate package items
      pkg.PackageItems?.forEach((item, itemIndex) => {
        if (!item.item_id && !item.upc) {
          if (!item.name || item.name.trim() === "") {
            errors.push(
              `Package ${pkgIndex + 1}, Item ${itemIndex + 1}: Name is required`
            );
          }
          if (!item.description || item.description.trim() === "") {
            errors.push(
              `Package ${pkgIndex + 1}, Item ${
                itemIndex + 1
              }: Description is required`
            );
          }
          if (!item.quantity || item.quantity < 1) {
            errors.push(
              `Package ${pkgIndex + 1}, Item ${
                itemIndex + 1
              }: Quantity must be at least 1`
            );
          }
        }
      });

      if (!pkg.weight || pkg.weight <= 0) {
        errors.push(`Package ${pkgIndex + 1}: Weight is required`);
      }
      if (!pkg.declared_value || pkg.declared_value <= 0) {
        errors.push(`Package ${pkgIndex + 1}: Declared value is required`);
      }
    });

    return errors;
  };

  const clearError = (section) => {
    setErrors((prev) => ({ ...prev, [section]: null }));
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const loadOrder = async () => {
      try {
        setLoading(true);
        const orderData = await fetchSingleOrder(orderId);
        setEditedOrder(orderData);
        setOriginalOrder(_.cloneDeep(orderData));
        setErrors({
          orderDetails: null,
          sender: null,
          recipient: null,
          packages: null,
        });
      } catch (err) {
        setLoadError("Failed to fetch order details: " + err.message);
        console.error("Error fetching order:", err);
      } finally {
        setLoading(false);
      }
    };

    loadOrder();
  }, [orderId, fetchSingleOrder]);

  useLayoutEffect(() => {
    console.log("useLayoutEffect triggered");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    console.log("Scroll position after force scroll:", window.pageYOffset);
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      [name]: value,
    }));
  };

  const handleCustomerChange = (type, field, value) => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      [type]: {
        ...prevOrder[type],
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      [name]: checked,
    }));
  };

  const isOrderDetailsChanged = () => {
    const relevantFields = [
      "order_status",
      "tracking_number",
      "signature_required",
      "saturday_delivery",
      "residential_delivery",
      "special_instructions",
    ];
    return relevantFields.some(
      (field) => !_.isEqual(editedOrder[field], originalOrder[field])
    );
  };

  // Update the change detection functions to use deep comparison
  const isSenderChanged = () => {
    return !_.isEqual(
      JSON.parse(JSON.stringify(editedOrder.sender)),
      JSON.parse(JSON.stringify(originalOrder.sender))
    );
  };

  const isRecipientChanged = () => {
    return !_.isEqual(
      JSON.parse(JSON.stringify(editedOrder.recipient)),
      JSON.parse(JSON.stringify(originalOrder.recipient))
    );
  };

  // Update isPackagesChanged to use deep comparison
  const isPackagesChanged = () => {
    return !_.isEqual(
      JSON.parse(JSON.stringify(editedOrder.Packages)),
      JSON.parse(JSON.stringify(originalOrder.Packages))
    );
  };

  const handleSaveOrderDetails = async () => {
    try {
      clearError("orderDetails");
      const orderData = {
        order_status: editedOrder.order_status,
        tracking_number: editedOrder.tracking_number,
        signature_required: editedOrder.signature_required,
        saturday_delivery: editedOrder.saturday_delivery,
        residential_delivery: editedOrder.residential_delivery,
        special_instructions: editedOrder.special_instructions,
      };
      const updatedOrder = await updateOrder(orderId, orderData, businessId);

      setOriginalOrder((prev) => ({
        ...prev,
        ...orderData,
      }));

      setSaveSuccess(true);
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        orderDetails: "Failed to update order details: " + err.message,
      }));
    }
  };

  const handleSaveSenderInfo = async () => {
    try {
      clearError("sender");
      const updatedSender = await saveCustomerInfo(
        editedOrder.sender.customer_id,
        editedOrder.sender,
        businessId
      );

      // Create deep copies to ensure proper state updates
      const updatedOriginalOrder = _.cloneDeep(originalOrder);
      updatedOriginalOrder.sender = _.cloneDeep(updatedSender);

      // Update both states to ensure they are in sync
      setOriginalOrder(updatedOriginalOrder);
      setEditedOrder((prev) => ({
        ...prev,
        sender: _.cloneDeep(updatedSender),
      }));

      setSaveSuccess(true);
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        sender: "Failed to update sender information: " + err.message,
      }));
    }
  };

  const handleSaveRecipientInfo = async () => {
    try {
      clearError("recipient");
      const updatedRecipient = await saveCustomerInfo(
        editedOrder.recipient.customer_id,
        editedOrder.recipient,
        businessId
      );

      // Create deep copies to ensure proper state updates
      const updatedOriginalOrder = _.cloneDeep(originalOrder);
      updatedOriginalOrder.recipient = _.cloneDeep(updatedRecipient);

      // Update both states to ensure they are in sync
      setOriginalOrder(updatedOriginalOrder);
      setEditedOrder((prev) => ({
        ...prev,
        recipient: _.cloneDeep(updatedRecipient),
      }));

      setSaveSuccess(true);
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        recipient: "Failed to update recipient information: " + err.message,
      }));
    }
  };

  const handleSavePackages = async () => {
    try {
      clearError("packages");
      const validationErrors = validatePackages(editedOrder.Packages);
      if (validationErrors.length > 0) {
        setErrors((prev) => ({
          ...prev,
          packages: validationErrors.join("\n"),
        }));
        return;
      }

      const orderDataToUpdate = {
        Packages: editedOrder.Packages.map((pkg) => ({
          package_id: pkg.package_id,
          weight: pkg.weight,
          dimensions: pkg.dimensions,
          declared_value: pkg.declared_value,
          fragile: pkg.fragile,
          hazardous: pkg.hazardous,
          temperature_control: pkg.temperature_control,
          min_temperature: pkg.min_temperature,
          max_temperature: pkg.max_temperature,
          PackageItems: pkg.PackageItems.map((item) => {
            const baseItem = {
              package_item_id: item.package_item_id,
              quantity: item.quantity,
            };

            if (item.item_id || item.upc) {
              return {
                ...baseItem,
                item_id: item.item_id,
                upc: item.upc,
                name: item.name,
                description: item.description,
                weight: item.specs?.weight || item.weight,
                value: item.value,
                brand: item.brand,
                category: item.category,
                specs: item.specs || {},
                image_data: item.image_data,
              };
            }

            return {
              ...baseItem,
              name: item.name,
              description: item.description,
              weight: item.weight,
              value: item.value,
            };
          }),
        })),
      };

      const updatedOrder = await updateOrder(
        orderId,
        orderDataToUpdate,
        businessId
      );

      if (updatedOrder) {
        // Create a deep copy of the current edited order
        const updatedOriginalOrder = _.cloneDeep(originalOrder);
        // Update only the Packages portion with a deep copy of the saved data
        updatedOriginalOrder.Packages = _.cloneDeep(updatedOrder.Packages);

        // Update the original order state with the new reference
        setOriginalOrder(updatedOriginalOrder);

        // Update the edited order to match the saved state
        setEditedOrder((prev) => ({
          ...prev,
          Packages: _.cloneDeep(updatedOrder.Packages),
        }));

        setSaveSuccess(true);
      }
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        packages: "Failed to update packages: " + err.message,
      }));
    }
  };

  const handlePackageDetailsChange = (updatedPackages) => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      Packages: updatedPackages,
    }));
  };

  const handleResetOrderDetails = () => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      order_status: originalOrder.order_status,
      tracking_number: originalOrder.tracking_number,
      signature_required: originalOrder.signature_required,
      saturday_delivery: originalOrder.saturday_delivery,
      residential_delivery: originalOrder.residential_delivery,
      special_instructions: originalOrder.special_instructions,
    }));
  };

  const handleResetSender = () => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      sender: _.cloneDeep(originalOrder.sender),
    }));
  };

  const handleResetRecipient = () => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      recipient: _.cloneDeep(originalOrder.recipient),
    }));
  };

  const handleResetPackages = () => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      Packages: _.cloneDeep(originalOrder.Packages),
    }));
  };

  const renderOrderForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="order_status"
                  value={editedOrder.order_status}
                  onChange={handleChange}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="out_for_delivery">Out for Delivery</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tracking Number"
                name="tracking_number"
                value={editedOrder.tracking_number || ""}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Signature Required</InputLabel>
                <Select
                  name="signature_required"
                  value={editedOrder.signature_required}
                  onChange={handleChange}
                >
                  <MenuItem value="no_signature">No Signature</MenuItem>
                  <MenuItem value="signature_required">
                    Signature Required
                  </MenuItem>
                  <MenuItem value="adult_signature">Adult Signature</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedOrder.saturday_delivery}
                    onChange={handleCheckboxChange}
                    name="saturday_delivery"
                  />
                }
                label="Saturday Delivery"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedOrder.residential_delivery}
                    onChange={handleCheckboxChange}
                    name="residential_delivery"
                  />
                }
                label="Residential Delivery"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Special Instructions"
                name="special_instructions"
                value={editedOrder.special_instructions || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {errors.orderDetails && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("orderDetails")}
            >
              {errors.orderDetails}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveOrderDetails}
              disabled={!isOrderDetailsChanged()}
            >
              Save Order Details
            </Button>
            <Button
              variant="outlined"
              startIcon={<UndoIcon />}
              onClick={handleResetOrderDetails}
              disabled={!isOrderDetailsChanged()}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <CustomerInfo
            customer={editedOrder.sender}
            onChange={(field, value) =>
              handleCustomerChange("sender", field, value)
            }
            title="Sender Information"
          />
          {errors.sender && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("sender")}
            >
              {errors.sender}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveSenderInfo}
              disabled={!isSenderChanged()}
            >
              Save Sender Info
            </Button>
            <Button
              variant="outlined"
              startIcon={<UndoIcon />}
              onClick={handleResetSender}
              disabled={!isSenderChanged()}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <CustomerInfo
            customer={editedOrder.recipient}
            onChange={(field, value) =>
              handleCustomerChange("recipient", field, value)
            }
            title="Recipient Information"
          />
          {errors.recipient && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("recipient")}
            >
              {errors.recipient}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveRecipientInfo}
              disabled={!isRecipientChanged()}
            >
              Save Recipient Info
            </Button>
            <Button
              variant="outlined"
              startIcon={<UndoIcon />}
              onClick={handleResetRecipient}
              disabled={!isRecipientChanged()}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Packages
          </Typography>
          <PackageDetails
            packages={editedOrder.Packages}
            onPackageDetailsChange={handlePackageDetailsChange}
          />
          {errors.packages && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("packages")}
            >
              <div style={{ whiteSpace: "pre-line" }}>{errors.packages}</div>
            </Alert>
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSavePackages}
              disabled={!isPackagesChanged()}
            >
              Save Packages
            </Button>
            <Button
              variant="outlined"
              startIcon={<UndoIcon />}
              onClick={handleResetPackages}
              disabled={!isPackagesChanged()}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderSkeletonLoading = () => (
    <Box sx={{ p: 3 }}>
      <Skeleton variant="text" width="50%" height={40} />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {[...Array(6)].map((_, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={120} height={36} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div
      ref={topRef}
      className="business-dashboard-page"
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div className="dashboard-main-content" style={{ flex: 1 }}>
        <div className="dashboard-container">
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" component="h1" sx={{ ml: 2 }}>
              {loading
                ? "Loading..."
                : `Edit Order: ${editedOrder?.shipping_order_id}`}
            </Typography>
          </Box>
          <Paper elevation={3} sx={{ p: 3 }}>
            {loading ? (
              renderSkeletonLoading()
            ) : (
              <>
                {loadError && <Alert severity="error">{loadError}</Alert>}
                <Divider sx={{ mb: 2 }} />
                {editedOrder ? (
                  renderOrderForm()
                ) : (
                  <Alert severity="error">Order not found</Alert>
                )}
              </>
            )}
          </Paper>
        </div>
      </div>
      <Snackbar
        open={saveSuccess}
        autoHideDuration={2000}
        onClose={() => setSaveSuccess(false)}
        message="Changes saved successfully"
      />
    </div>
  );
};

export default OrderEditPage;
