import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Alert,
  Skeleton,
  Box,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { AuthContext } from "../Auth/AuthContext";

const MetricCard = ({ loading, title, value }) => (
  <Card>
    <CardHeader title={loading ? <Skeleton width="60%" /> : title} />
    <CardContent>
      <Typography variant="h4">
        {loading ? <Skeleton width="80%" /> : value}
      </Typography>
    </CardContent>
  </Card>
);

const ChartSkeleton = () => (
  <Box sx={{ width: "100%", height: 300 }}>
    <Skeleton variant="rectangular" width="100%" height="100%" />
  </Box>
);

const BusinessDashboardOverview = ({ business }) => {
  const { api } = useContext(AuthContext);
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/business-actions/${business?.business_id}/overview`
        );

        if (response.data.success) {
          setOverviewData(response.data.data);
        } else {
          throw new Error("Failed to fetch overview data");
        }
      } catch (err) {
        console.error("Error fetching overview data:", err);
        setError(
          err.response?.data?.message || "Failed to fetch overview data"
        );
      } finally {
        setLoading(false);
      }
    };

    if (business?.business_id) {
      fetchOverviewData();
    }
  }, [business?.business_id]);

  if (error) {
    return (
      <div className="dashboard-overview">
        <Typography variant="h4" gutterBottom>
          Welcome to {business?.business_name || "Your"} Dashboard
        </Typography>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  if (!business) {
    return (
      <div className="dashboard-overview">
        <Typography variant="h4" gutterBottom>
          Loading business information...
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </div>
    );
  }

  return (
    <div className="dashboard-overview">
      <Typography variant="h4" gutterBottom>
        Welcome to {business.business_name} Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            loading={loading}
            title="Total Revenue"
            value={overviewData ? `$${overviewData.totalRevenue}` : "$0"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            loading={loading}
            title="New Orders (24h)"
            value={overviewData?.newOrders || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            loading={loading}
            title="Customer Satisfaction"
            value={overviewData?.customerSatisfaction || "N/A"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            loading={loading}
            title="Completion Rate"
            value={overviewData?.completionRate || "N/A"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Sales Overview (Last 30 Days)" />
            <CardContent>
              {loading ? (
                <ChartSkeleton />
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={overviewData?.salesData || []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      angle={-45}
                      textAnchor="end"
                      height={70}
                    />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="sales"
                      name="Sales ($)"
                      stroke="#8884d8"
                      dot={false}
                    />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="orders"
                      name="Orders"
                      stroke="#82ca9d"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Grid>

        {overviewData?.orderMetrics && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Order Metrics" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Total Orders</Typography>
                    <Typography variant="h6">
                      {overviewData.orderMetrics.total}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Pending</Typography>
                    <Typography variant="h6">
                      {overviewData.orderMetrics.pending}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Processing</Typography>
                    <Typography variant="h6">
                      {overviewData.orderMetrics.processing}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Delivered</Typography>
                    <Typography variant="h6">
                      {overviewData.orderMetrics.delivered}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BusinessDashboardOverview;
