import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Box,
  Tabs,
  Tab,
  Divider,
  Alert,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import {
  CompareArrows as CompareArrowsIcon,
  Check as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  Business as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import CollaborationActions from "./CollaborationActions";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import CollaborationComponent from "./CollaborationComponent";

const CollaborationDetailsDialog = ({
  open,
  onClose,
  collaborations,
  businessId,
  orderStatus,
  onActionComplete,
  onStartAgain,
}) => {
  const { api } = useContext(AuthContext);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCollaborationOpen, setIsCollaborationOpen] = useState(false);
  const [selectedCollaborationForNew, setSelectedCollaborationForNew] =
    useState(null);

  const handleStartAgain = (collaboration) => {
    setSelectedCollaborationForNew(collaboration);
    setIsCollaborationOpen(true);
  };

  const handleCollaborationClose = () => {
    setIsCollaborationOpen(false);
    setSelectedCollaborationForNew(null);
  };

  const handleCollaborationComplete = async () => {
    if (onActionComplete) {
      await onActionComplete();
    }
    handleCollaborationClose();
  };

  // debug use effect
  useEffect(() => {
    console.log("Collaborations are: ", collaborations);
  }, []);

  // Sort collaborations by date (newest first) when the component mounts or collaborations change
  useEffect(() => {
    console.log("Collaborations changed:", collaborations);
    if (collaborations?.length > 0) {
      const sortedCollaborations = [...collaborations].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setSelectedTab(0); // Reset to first tab when collaborations change
      console.log("Sorted collaborations:", sortedCollaborations);
      console.log("Selected collaboration:", sortedCollaborations[0]);

      // Fetch business details for the latest collaboration
      if (sortedCollaborations[0]?.collaborating_business_id) {
        fetchBusinessDetails(sortedCollaborations[0].collaborating_business_id);
      }
    }
  }, [collaborations]);

  const fetchBusinessDetails = async (collaboratingBusinessId) => {
    if (!collaboratingBusinessId) return;

    setLoading(true);
    try {
      const response = await api.get("/shipping-collaboration/businesses", {
        params: { businessIds: [collaboratingBusinessId] },
      });
      setBusinessDetails(response.data[0]);
    } catch (error) {
      console.error("Error fetching business details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (collaborations[newValue]?.collaborating_business_id) {
      fetchBusinessDetails(collaborations[newValue].collaborating_business_id);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "info";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "cancelled":
        return "error";
      case "completed":
        return "success";
      default:
        return "default";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "proposed":
        return <CompareArrowsIcon />;
      case "counter_offered":
        return <EditIcon />;
      case "accepted":
        return <CheckCircleIcon />;
      case "rejected":
        return <CancelIcon />;
      case "cancelled":
        return <CancelIcon />;
      case "completed":
        return <CheckCircleIcon />;
      default:
        return <HistoryIcon />;
    }
  };

  const renderCollaborationTimeline = (collaborationHistory) => {
    if (!collaborationHistory?.length) return null;

    const formatCurrency = (value) => {
      if (typeof value === "string" && value.startsWith("$")) return value;
      return `$${parseFloat(value).toFixed(2)}`;
    };

    const renderShippingTier = (tier) => {
      if (typeof tier === "string") {
        tier = JSON.parse(tier);
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Base Price: {tier.base_price}</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2">
              Weight-based: $
              {parseFloat(tier.pricing_details.base_price_weight).toFixed(2)}
            </Typography>
            <Typography variant="body2">
              Dimensions-based: $
              {parseFloat(tier.pricing_details.base_price_dimensions).toFixed(
                2
              )}
            </Typography>
            <Typography variant="body2">
              Value-based: $
              {parseFloat(tier.pricing_details.base_price_value).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      );
    };

    const renderOrderDetails = (details) => {
      if (typeof details === "string") {
        details = JSON.parse(details);
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Order ID: {details.order_id}</Typography>
          <Typography variant="body2">
            Package Count: {details.package_count}
          </Typography>
          <Typography variant="body2">
            Total Weight: {details.total_weight} lb
          </Typography>
        </Box>
      );
    };

    const renderFinancialDetails = (details) => {
      if (typeof details === "string") {
        details = JSON.parse(details);
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">
            Calculated Price: {details.calculated_price}
          </Typography>
          <Typography variant="body2">
            Agreed Compensation: {details.agreed_compensation}
          </Typography>
          <Typography variant="body2">
            Total Cost: {details.total_cost}
          </Typography>
          {details.additional_charges?.length > 0 && (
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2">Additional Charges:</Typography>
              {details.additional_charges.map((charge, idx) => (
                <Typography key={idx} variant="body2">
                  - {charge.description}: {formatCurrency(charge.amount)}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      );
    };

    const renderCollaborationSpecifics = (specifics) => {
      if (typeof specifics === "string") {
        specifics = JSON.parse(specifics);
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Type: {specifics.type}</Typography>
          {specifics.details.notes && (
            <Typography variant="body2">
              Notes: {specifics.details.notes}
            </Typography>
          )}
          <Typography variant="body2">
            Special Instructions:{" "}
            {specifics.special_instructions || "None provided"}
          </Typography>
        </Box>
      );
    };

    const renderChanges = (changes) => {
      return Object.entries(changes).map(([key, value]) => {
        const formattedKey = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase());

        // Handle additional charges changes
        if (key === "additional_charges") {
          const oldTotal =
            typeof value.old === "string"
              ? JSON.parse(value.old).total
              : value.old.total;
          const newTotal =
            typeof value.new === "string"
              ? JSON.parse(value.new).total
              : value.new.total;
          return (
            <Typography key={key} variant="body2" sx={{ ml: 2 }}>
              Additional Charges: {oldTotal} → {newTotal}
            </Typography>
          );
        }

        return (
          <Typography key={key} variant="body2" sx={{ ml: 2 }}>
            {formattedKey}: {value.old} → {value.new}
          </Typography>
        );
      });
    };

    return (
      <Timeline>
        {collaborationHistory.map((entry, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="text.secondary">
              {formatDate(entry.timestamp)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={getStatusColor(entry.action)}>
                {getStatusIcon(entry.action)}
              </TimelineDot>
              {index < collaborationHistory.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  {entry.action.charAt(0).toUpperCase() +
                    entry.action.slice(1).toLowerCase().replace("_", " ")}{" "}
                  by{" "}
                  {entry.actor_type === "originator"
                    ? "Originator"
                    : "Collaborator"}
                </Typography>

                {entry.details && (
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Additional Details:
                    </Typography>
                    {entry.details.shipping_tier && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Shipping Tier:
                        </Typography>
                        {renderShippingTier(entry.details.shipping_tier)}
                      </>
                    )}
                    {entry.details.order_details && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Order Details:
                        </Typography>
                        {renderOrderDetails(entry.details.order_details)}
                      </>
                    )}
                    {entry.details.financial_details && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Financial Details:
                        </Typography>
                        {renderFinancialDetails(
                          entry.details.financial_details
                        )}
                      </>
                    )}
                    {entry.details.collaboration_specifics && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Collaboration Specifics:
                        </Typography>
                        {renderCollaborationSpecifics(
                          entry.details.collaboration_specifics
                        )}
                      </>
                    )}
                  </Box>
                )}

                {entry.changes && Object.keys(entry.changes).length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Changes:
                    </Typography>
                    {renderChanges(entry.changes)}
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  const renderBusinessDetails = () => {
    if (loading) {
      return (
        <Box>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="text" width="80%" sx={{ mt: 1 }} />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="60%" />
        </Box>
      );
    }

    if (!businessDetails) {
      return (
        <Alert severity="error" sx={{ mt: 1 }}>
          Failed to load business details
        </Alert>
      );
    }

    return (
      <Card variant="outlined" sx={{ mt: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            {businessDetails.business_profile_picture ? (
              <Avatar
                src={businessDetails.business_profile_picture}
                alt={businessDetails.business_name}
                sx={{ width: 56, height: 56, mr: 2 }}
              />
            ) : (
              <Avatar sx={{ width: 56, height: 56, mr: 2 }}>
                <BusinessIcon />
              </Avatar>
            )}
            <Box>
              <Typography variant="h6" component="div">
                {businessDetails.business_name}
              </Typography>
              <Chip
                size="small"
                label="Collaborator"
                color="primary"
                sx={{ mt: 0.5 }}
              />
            </Box>
          </Box>

          <List dense>
            <ListItem>
              <EmailIcon sx={{ mr: 2, color: "action.active" }} />
              <ListItemText
                primary="Email"
                secondary={businessDetails.business_email}
              />
            </ListItem>

            <ListItem>
              <PhoneIcon sx={{ mr: 2, color: "action.active" }} />
              <ListItemText
                primary="Phone"
                secondary={businessDetails.business_phone}
              />
            </ListItem>

            {businessDetails.BusinessAddresses?.[0] && (
              <ListItem>
                <LocationIcon sx={{ mr: 2, color: "action.active" }} />
                <ListItemText
                  primary="Location"
                  secondary={`${businessDetails.BusinessAddresses[0].business_address_city}, ${businessDetails.BusinessAddresses[0].business_address_country}`}
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    );
  };

  const handleActionComplete = async (updatedCollaboration) => {
    try {
      // Fetch the updated order to get latest collaboration status
      const response = await api.get(
        `/shipping-orders/${updatedCollaboration.shipping_order_id}`
      );

      // Dispatch the ordersUpdated event with the updated order
      window.dispatchEvent(
        new CustomEvent("ordersUpdated", {
          detail: [response.data],
        })
      );

      // Call original onActionComplete if provided
      if (onActionComplete) {
        onActionComplete();
      }

      // Close the dialog
      onClose();
    } catch (error) {
      console.error("Error fetching updated order:", error);
    }
  };

  const renderCollaborationDetails = (selectedCollaboration) => {
    if (!selectedCollaboration) {
      return (
        <Box sx={{ p: 2 }}>
          <Alert severity="info">No collaboration details available</Alert>
        </Box>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Collaboration ID: {selectedCollaboration.collaboration_id}
          </Typography>
          <Chip
            label={selectedCollaboration.status}
            color={getStatusColor(selectedCollaboration.status)}
            style={{ marginTop: "10px" }}
          />
        </Grid>

        {/* Business Details */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Collaborating Business</Typography>
          {renderBusinessDetails()}
        </Grid>

        {/* Financial Details */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Financial Details</Typography>
          <Card variant="outlined" sx={{ mt: 1 }}>
            <CardContent>
              <List dense>
                {selectedCollaboration.agreed_compensation && (
                  <ListItem>
                    <ListItemText
                      primary="Agreed Compensation"
                      secondary={`$${parseFloat(
                        selectedCollaboration.agreed_compensation
                      ).toFixed(2)}`}
                    />
                  </ListItem>
                )}
                {selectedCollaboration.calculated_price && (
                  <ListItem>
                    <ListItemText
                      primary="Calculated Price"
                      secondary={`$${parseFloat(
                        selectedCollaboration.calculated_price
                      ).toFixed(2)}`}
                    />
                  </ListItem>
                )}
                {selectedCollaboration.total_cost && (
                  <ListItem>
                    <ListItemText
                      primary="Total Cost"
                      secondary={`$${parseFloat(
                        selectedCollaboration.total_cost
                      ).toFixed(2)}`}
                    />
                  </ListItem>
                )}
                {selectedCollaboration.additional_charges?.length > 0 && (
                  <ListItem>
                    <ListItemText
                      primary="Additional Charges"
                      secondary={
                        <Box component="div" sx={{ mt: 1 }}>
                          {selectedCollaboration.additional_charges.map(
                            (charge, index) => (
                              <Typography key={index} variant="body2">
                                {charge.description}: $
                                {parseFloat(charge.amount).toFixed(2)}
                              </Typography>
                            )
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Additional Information */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Additional Information</Typography>
          <Card variant="outlined" sx={{ mt: 1 }}>
            <CardContent>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Collaboration Details"
                    secondary={
                      selectedCollaboration.collaboration_details?.notes ||
                      "No notes provided"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Special Instructions"
                    secondary={
                      selectedCollaboration.special_instructions ||
                      "No special instructions"
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* History Timeline */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Collaboration History</Typography>
          <Card variant="outlined" sx={{ mt: 1, mb: 2 }}>
            <CardContent>
              {renderCollaborationTimeline(
                selectedCollaboration.collaboration_history
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Actions */}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CollaborationActions
              collaboration={selectedCollaboration}
              businessId={businessId}
              orderStatus={orderStatus}
              onActionComplete={handleActionComplete}
              onStartAgain={handleStartAgain}
              allCollaborations={collaborations}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const sortedCollaborations =
    collaborations?.length > 0
      ? [...collaborations].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : [];

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        TransitionProps={{
          onExited: () => {
            setSelectedTab(0);
            setBusinessDetails(null);
          },
        }}
      >
        <DialogTitle>Collaboration Details</DialogTitle>
        <DialogContent>
          {sortedCollaborations.length > 0 ? (
            <>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{ mb: 2 }}
              >
                {sortedCollaborations.map((collab, index) => (
                  <Tab
                    key={collab.collaboration_id}
                    label={`Attempt ${sortedCollaborations.length - index}`}
                    icon={getStatusIcon(collab.status)}
                    iconPosition="start"
                  />
                ))}
              </Tabs>
              <Divider sx={{ mb: 2 }} />
              {renderCollaborationDetails(sortedCollaborations[selectedTab])}
            </>
          ) : (
            <Box sx={{ p: 2 }}>
              <Alert severity="info">No collaboration details available</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CollaborationComponent
        open={isCollaborationOpen}
        onClose={handleCollaborationClose}
        selectedOrders={
          selectedCollaborationForNew
            ? [selectedCollaborationForNew.shipping_order_id]
            : []
        }
        businessId={businessId}
        onCollaborationUpdate={handleCollaborationComplete}
      />
    </>
  );
};

export default CollaborationDetailsDialog;
