import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const EmployeeContext = createContext();

export const EmployeeProvider = ({ children }) => {
  const { api } = useContext(AuthContext);
  const { businessId, employeeId } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEmployeeData = useCallback(async () => {
    if (!employeeId || !businessId) {
      setError("No employee ID or business ID available");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      const response = await api.get(`/employees/${businessId}/${employeeId}`);
      setEmployeeData(response.data.employee);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching employee data:", err);
      setError("Failed to fetch employee data");
      setLoading(false);
    }
  }, [employeeId, businessId]);

  useEffect(() => {
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  const updateEmployeeInfo = useCallback(
    async (updates) => {
      if (!employeeId || !businessId) {
        throw new Error("No employee ID or business ID available");
      }

      try {
        const response = await api.put(
          `/employees/${businessId}/${employeeId}`,
          updates
        );
        setEmployeeData(response.data);
        return response.data;
      } catch (err) {
        console.error("Error updating employee info:", err);
        throw err;
      }
    },
    [employeeId, businessId]
  );

  const fetchEmployeeSchedule = useCallback(async () => {
    if (!employeeId || !businessId) {
      throw new Error("No employee ID or business ID available");
    }

    try {
      const response = await api.get(
        `/employees/${businessId}/${employeeId}/schedule`
      );
      return response.data;
    } catch (err) {
      console.error("Error fetching employee schedule:", err);
      throw err;
    }
  }, [employeeId, businessId]);

  const fetchEmployeeTasks = useCallback(async () => {
    if (!employeeId || !businessId) {
      throw new Error("No employee ID or business ID available");
    }

    try {
      const response = await api.get(
        `/employees/${businessId}/${employeeId}/tasks`
      );
      return response.data;
    } catch (err) {
      console.error("Error fetching employee tasks:", err);
      throw err;
    }
  }, [employeeId, businessId]);

  return (
    <EmployeeContext.Provider
      value={{
        employeeData,
        loading,
        error,
        employeeId,
        businessId,
        fetchEmployeeData,
        updateEmployeeInfo,
        fetchEmployeeSchedule,
        fetchEmployeeTasks,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployee = () => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error("useEmployee must be used within an EmployeeProvider");
  }
  return context;
};

export default EmployeeContext;
