import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Divider,
  IconButton,
  Collapse,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  LocalShipping,
  Schedule,
  AttachMoney,
  Scale,
  Public,
  Architecture,
  Security,
  Eco,
  ExpandMore,
  ExpandLess,
  Assessment,
  Inventory,
  AddBox,
  SafetyCheck,
  RecyclingOutlined,
  VerifiedUser,
  LocalShippingOutlined,
  InventoryOutlined,
} from "@mui/icons-material";

const ShippingServiceCard = ({ service }) => {
  const [expanded, setExpanded] = React.useState(false);

  const tier = service.shippingTier;
  const provider = service.shippingServiceProvider;

  const formatPrice = (price) => {
    return typeof price === "number"
      ? `$${price.toFixed(2)}`
      : price
      ? `$${parseFloat(price).toFixed(2)}`
      : "N/A";
  };

  const formatPercentage = (value) => {
    return value ? `${parseFloat(value).toFixed(1)}%` : "N/A";
  };

  // Helper function to render performance metrics
  const PerformanceMetric = ({ label, value, color = "primary" }) => (
    <Box sx={{ mb: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
        <Typography variant="body2">{label}</Typography>
        <Typography variant="body2" color={`${color}.main`}>
          {formatPercentage(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value || 0}
        color={color}
        sx={{ height: 6, borderRadius: 1 }}
      />
    </Box>
  );

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: 6,
        },
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        {/* Header Section */}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography variant="h6" component="h3" gutterBottom>
              {service.service_name}
            </Typography>
            {/* subheader business business name */}
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {service.business.business_name}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              <Chip
                icon={<LocalShippingOutlined />}
                label={provider?.provider_type || "N/A"}
                size="small"
                color="primary"
                sx={{ mr: 1, mb: 1 }}
              />
              <Chip
                icon={<Assessment />}
                label={tier?.service_level || "Standard"}
                size="small"
                sx={{ backgroundColor: "#e3f2fd", color: "#1976d2", mb: 1 }}
              />
            </Box>
          </Box>
          <Typography variant="h6" sx={{ color: "#2196f3" }}>
            {formatPrice(tier?.base_price_flat_rate)}
          </Typography>
        </Box>

        {/* Basic Info Section */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            {/* Delivery Time */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <Schedule fontSize="small" color="action" />
              <Typography variant="body2">
                Delivery: {tier?.estimated_delivery_time?.min}-
                {tier?.estimated_delivery_time?.max}{" "}
                {tier?.estimated_delivery_time?.unit}
              </Typography>
            </Box>
            {/* Weight Range */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Scale fontSize="small" color="action" />
              <Typography variant="body2">
                Weight: {tier?.min_weight} - {tier?.max_weight} lbs
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Key Features */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {provider?.customs_clearance_capability && (
              <Tooltip title="Customs Clearance Available">
                <Chip
                  icon={<Public fontSize="small" />}
                  label="Customs"
                  size="small"
                />
              </Tooltip>
            )}
            {provider?.return_handling_capability && (
              <Tooltip title="Return Handling Available">
                <Chip
                  icon={<LocalShipping fontSize="small" />}
                  label="Returns"
                  size="small"
                />
              </Tooltip>
            )}
            {tier?.temperature_controlled && (
              <Tooltip title="Temperature Controlled">
                <Chip
                  icon={<SafetyCheck fontSize="small" />}
                  label="Temp Control"
                  size="small"
                />
              </Tooltip>
            )}
            {provider?.eco_friendly_initiatives?.length > 0 && (
              <Tooltip title="Eco-Friendly Options">
                <Chip
                  icon={<RecyclingOutlined fontSize="small" />}
                  label="Eco"
                  size="small"
                />
              </Tooltip>
            )}
          </Box>
        </Box>

        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{ ml: "auto", p: 1 }}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>

        <Collapse in={expanded}>
          <Divider sx={{ my: 2 }} />

          {/* Provider Performance Metrics */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Provider Performance
            </Typography>
            <PerformanceMetric
              label="Delivery Success Rate"
              value={provider?.delivery_success_rate}
              color="success"
            />
            <PerformanceMetric
              label="On-Time Delivery Rate"
              value={provider?.on_time_delivery_rate}
              color="primary"
            />
            <PerformanceMetric
              label="Damage Rate"
              value={provider?.damage_rate}
              color="error"
            />
          </Box>

          {/* Service Coverage */}
          {provider?.service_coverage?.countries?.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Service Coverage
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {provider.service_coverage.countries.map((country) => (
                  <Chip
                    key={country}
                    icon={<Public fontSize="small" />}
                    label={country}
                    size="small"
                    sx={{ backgroundColor: "#fff3e0" }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {/* Value Added Services */}
          {provider?.value_added_services?.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Value Added Services
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {provider.value_added_services.map((service) => (
                  <Chip
                    key={service}
                    icon={<AddBox fontSize="small" />}
                    label={service}
                    size="small"
                    sx={{ backgroundColor: "#e8f5e9" }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {/* Packaging Options */}
          {provider?.packaging_options?.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Packaging Options
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {provider.packaging_options.map((option) => (
                  <Chip
                    key={option}
                    icon={<Inventory fontSize="small" />}
                    label={option}
                    size="small"
                    sx={{ backgroundColor: "#f3e5f5" }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {/* Insurance Options */}
          {provider?.insurance_options &&
            Object.keys(provider.insurance_options).length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Insurance Options
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    icon={<VerifiedUser fontSize="small" />}
                    label={`Coverage up to ${formatPrice(
                      tier?.max_insurance_coverage
                    )}`}
                    size="small"
                    sx={{ backgroundColor: "#e8eaf6" }}
                  />
                </Box>
              </Box>
            )}

          {/* Restrictions */}
          {provider?.restricted_items?.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Restricted Items
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {provider.restricted_items.map((item) => (
                  <Chip
                    key={item}
                    icon={<InventoryOutlined fontSize="small" />}
                    label={item}
                    size="small"
                    sx={{ backgroundColor: "#ffebee" }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {/* SLA Information */}
          {provider?.service_level_agreements &&
            Object.keys(provider.service_level_agreements).length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Service Level Agreements
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {JSON.stringify(provider.service_level_agreements, null, 2)}
                </Typography>
              </Box>
            )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default ShippingServiceCard;
