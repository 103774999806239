import React, { useState, useContext, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  Box,
  TextField,
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Alert,
  CircularProgress,
  RadioGroup,
  Radio,
  IconButton,
  FormLabel,
  Card,
  CardContent,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemSecondaryAction,
  Chip,
} from "@mui/material";
import {
  LocalShipping,
  Warehouse,
  ShoppingCart,
  DirectionsCar,
  Build,
  Category,
  Store,
} from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useNavigate } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  AddAPhoto,
  CloudUpload as UploadIcon,
  Info as InfoIcon,
  CreditCard as CreditCardIcon,
} from "@mui/icons-material";

import { CheckCircle } from "@mui/icons-material";
import CelebrationIcon from "@mui/icons-material/Celebration";

import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaPaypal,
  FaCreditCard,
  FaCcJcb,
  FaCcDinersClub,
} from "react-icons/fa";

import AddCardIcon from "@mui/icons-material/AddCard";

import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import styles from "./BusinessRegistrationPage.module.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";

// const stripePromise = loadStripe(
//   "pk_live_51NnQZ6DZzcpBER6kAXXlwm7DPy4YfJPr4dq7L2O4bQU9Wma39a35H3KJApSF0Uode4ocVmkpgrL3WPNvbDE4cXWo00NcSCvsxN"
// );

const stripePromise = loadStripe(
  "pk_test_51NnQZ6DZzcpBER6ktmP8yKhVYERtedK5fz9qcW6gKYINs4vsBB28fbMEXQYFL2RusrRc8swykWeZuvbzD0HPjqle00zVLnN96d"
);

const libraries = ["places"];

function BusinessRegistrationPage() {
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    profile_completion: {},
    basic_info: {
      has_physical_address: "no",
      addresses: [],
    },
    business_category: {
      business_category_id: "",
      business_category_name: "",
      business_category_attributes: {},
    },
    operating_countries: [],
    licenses_and_insurance: {},
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [businessId, setBusinessId] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false);
  const [availabilityErrors, setAvailabilityErrors] = useState({});
  const [isLicensesAndInsuranceValid, setIsLicensesAndInsuranceValid] =
    useState(false);
  const [isPaymentMethodValid, setIsPaymentMethodValid] = useState(false);
  const [showPaymentMethodError, setShowPaymentMethodError] = useState(false);
  const navigate = useNavigate();
  const { api } = useContext(AuthContext);

  const steps = [
    "Complete Profile",
    "Basic Information",
    "Business Category",
    "Operating Countries",
    "Licenses & Insurance",
    "Payment Method",
    "Review & Submit",
  ];

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        console.log("user", user);
        setFormData((prevData) => ({
          ...prevData,
          profile_completion: {
            user_first_name: user.user_first_name,
            user_last_name: user.user_last_name,
            user_phone: user.user_phone,
            user_email: user.user_email,
          },
        }));
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to fetch user profile. Please try again.",
        });
      }
    };
    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      setIsLoadingClientSecret(true);
      try {
        const response = await api.post("/payments/create-setup-intent", {});
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to initialize payment form. Please try again.",
        });
      } finally {
        setIsLoadingClientSecret(false);
      }
    };

    if (activeStep === 5) {
      fetchClientSecret();
    }

    // if active step changes remove the alert message
    setAlertMessage(null);
  }, [activeStep]);

  const handleAddPaymentMethod = async (paymentMethod) => {
    setIsLoading(true);
    setAlertMessage(null);

    try {
      const response = await api.post("/payments/payment-methods", {
        paymentMethodId: paymentMethod.id,
      });
      setFormData((prevData) => ({
        ...prevData,
        payment_method: paymentMethod.id,
      }));
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
      setIsPaymentMethodValid(true);
    } catch (error) {
      console.error("Error adding payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
      setIsPaymentMethodValid(false);
    }

    setIsLoading(false);
  };

  const validateStep = (step) => {
    let stepErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (step) {
      case 0:
        if (!formData.profile_completion.user_first_name)
          stepErrors.user_first_name = "First Name is required";
        if (!formData.profile_completion.user_last_name)
          stepErrors.user_last_name = "Last Name is required";
        if (!formData.profile_completion.user_phone)
          stepErrors.user_phone = "Phone Number is required";
        else if (!matchIsValidTel(formData.profile_completion.user_phone))
          stepErrors.user_phone = "Invalid phone number format";
        break;
      case 1:
        if (!formData.basic_info.business_name)
          stepErrors.business_name = "Business Name is required";
        if (!formData.basic_info.business_email)
          stepErrors.business_email = "Business Email is required";
        else if (!emailRegex.test(formData.basic_info.business_email))
          stepErrors.business_email = "Invalid email format";
        if (!formData.basic_info.business_phone)
          stepErrors.business_phone = "Business Phone is required";
        else if (!matchIsValidTel(formData.basic_info.business_phone))
          stepErrors.business_phone = "Invalid phone number format";
        if (!formData.basic_info.business_description)
          stepErrors.business_description = "Business Description is required";

        if (formData.basic_info.has_physical_address === "yes") {
          if (formData.basic_info.addresses.length === 0) {
            stepErrors.addresses = "At least one address is required";
          } else {
            const addressErrors = formData.basic_info.addresses.map(
              (address) => {
                let errors = {};
                if (!address.address_line1)
                  errors.address_line1 = "Address Line 1 is required";
                if (!address.city) errors.city = "City is required";
                if (!address.state) errors.state = "State is required";
                if (!address.postal_code)
                  errors.postal_code = "Postal Code is required";
                if (!address.country) errors.country = "Country is required";
                if (!address.phone) errors.phone = "Phone number is required";
                else if (!matchIsValidTel(address.phone))
                  errors.phone = "Invalid phone number format";
                return errors;
              }
            );

            if (
              addressErrors.some((errors) => Object.keys(errors).length > 0)
            ) {
              stepErrors.addresses = addressErrors;
            }
          }
        }
        break;
      case 2:
        if (!formData.business_category.business_category_id)
          stepErrors.business_category = "Business Category is required";
        break;
      case 3:
        if (formData.operating_countries.length === 0)
          stepErrors.operating_countries =
            "At least one country must be selected";
        break;
      case 4:
        if (!isLicensesAndInsuranceValid) {
          stepErrors.licenses_and_insurance =
            "Please upload all required documents";
        }
        break;
      case 5:
        if (!isPaymentMethodValid) {
          stepErrors.payment_method =
            "Please select a valid payment method to continue";
        }
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const checkBusinessAvailability = async () => {
    setIsChecking(true);
    setAlertMessage(null);
    setAvailabilityErrors({});

    try {
      const response = await api.post("/business/check-business-availability", {
        business_name: formData.basic_info.business_name,
        business_email: formData.basic_info.business_email,
        business_phone: formData.basic_info.business_phone,
        addresses: formData.basic_info.addresses,
      });

      const { availability } = response.data;
      console.log("Business availability:", availability);
      let hasErrors = false;
      let newErrors = {};

      if (!availability.business_name.available) {
        newErrors.business_name = "Business name is already in use";
        hasErrors = true;
      }
      if (!availability.business_email.available) {
        newErrors.business_email = "Business email is already in use";
        hasErrors = true;
      }
      if (!availability.business_phone.available) {
        newErrors.business_phone = "Business phone is already in use";
        hasErrors = true;
      }

      availability.addresses.forEach((address, index) => {
        if (!address.available) {
          newErrors[`address_${index}`] = "This address is already registered";
          hasErrors = true;
        }
      });

      if (hasErrors) {
        setAvailabilityErrors(newErrors);
        setAlertMessage({
          type: "error",
          message:
            "Some business details are already in use. Please review and modify the highlighted fields.",
        });
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error checking business availability:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to check business information. Please try again.",
      });
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  const handleNext = async () => {
    if (validateStep(activeStep)) {
      if (activeStep === 1) {
        const isAvailable = await checkBusinessAvailability();
        if (isAvailable) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (activeStep === 4 && !isLicensesAndInsuranceValid) {
        setAlertMessage({
          type: "error",
          message: "Please upload all required documents before proceeding.",
        });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setAlertMessage({
        type: "error",
        message: "Please correct the errors before proceeding.",
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
    if (errors[Object.keys(data)[0]]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [Object.keys(data)[0]]: undefined,
      }));
    }
  };

  const handleSubmit = async () => {
    if (validateStep(activeStep)) {
      setIsLoading(true);
      try {
        console.log("formData", formData);
        const response = await api.post(
          "/business/register-business",
          formData
        );
        handleNext();
        setAlertMessage({
          type: "success",
          message: "Business registered successfully!",
        });
        console.log("Business registered:", response.data);
        setBusinessId(response.data.business.business_id);
      } catch (error) {
        console.error("Error registering business:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to register business. Please try again.",
        });
      }
      setIsLoading(false);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ProfileCompletionStep
            data={formData.profile_completion}
            onChange={(data) => handleFormChange("profile_completion", data)}
            errors={errors}
          />
        );
      case 1:
        return (
          <BasicInfoStep
            data={formData.basic_info}
            onChange={(data) => handleFormChange("basic_info", data)}
            errors={errors}
            setErrors={setErrors}
            isLoaded={isLoaded}
            availabilityErrors={availabilityErrors}
          />
        );
      case 2:
        return (
          <BusinessCategoryStep
            data={formData.business_category}
            onChange={(data) => handleFormChange("business_category", data)}
            error={errors.business_category}
          />
        );
      case 3:
        return (
          <OperatingCountriesStep
            data={formData.operating_countries}
            onChange={(data) => handleFormChange("operating_countries", data)}
            error={errors.operating_countries}
          />
        );
      case 4:
        return (
          <LicensesAndInsuranceStep
            data={formData.licenses_and_insurance}
            onChange={(data) =>
              handleFormChange("licenses_and_insurance", data)
            }
            businessCategory={formData.business_category.business_category_name}
            onValidationChange={setIsLicensesAndInsuranceValid}
          />
        );
      case 5:
        return (
          <PaymentMethodStep
            clientSecret={clientSecret}
            data={formData.payment_method}
            isLoadingClientSecret={isLoadingClientSecret}
            onSubmit={handleAddPaymentMethod}
            onValidationChange={setIsPaymentMethodValid}
            error={errors.payment_method}
          />
        );
      case 6:
        return <ReviewStep formData={formData} />;
      default:
        return "Unknown step";
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <div className={styles["registration-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <main
            className={`${styles["registration-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Register New Business</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            <Paper variant="outlined" className={styles.paper}>
              <Stepper activeStep={activeStep} className={styles.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Box sx={{ textAlign: "center", mt: 4, mb: 4 }}>
                    <CelebrationIcon
                      sx={{ fontSize: 60, color: "var(--soft-blue)" }}
                    />
                  </Box>
                  <Typography variant="h5" gutterBottom align="center">
                    Congratulations on Registering Your Business!
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    Your registration has been successfully submitted. We’re
                    excited to have you on board! A confirmation email will be
                    sent to you shortly with further instructions.
                  </Typography>
                  <Button
                    variant="outlined"
                    startIcon={<BusinessIcon fontSize="large" />}
                    size="large"
                    onClick={() =>
                      // navigate using the businessId
                      navigate("/business/" + businessId + "/dashboard")
                    }
                    sx={{
                      padding: "10px 20px",
                      alignContent: "center",
                      display: "block",
                      margin: "auto",
                      mt: 4,
                      mb: 4,
                      color: "midnightblue",
                      borderColor: "midnightblue",
                      "&:hover": {
                        color: "var(--soft-blue)",
                        borderColor: "var(--soft-blue)",
                      },
                      fontSize: "1.2rem",
                    }}
                    className={styles.button}
                  >
                    Go to Dashboard
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box
                    className={styles.buttons}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        variant="outlined"
                        sx={{
                          color: "midnightblue",
                          borderColor: "midnightblue",
                          "&:hover": {
                            color: "var(--soft-blue)",
                            borderColor: "var(--soft-blue)",
                          },
                        }}
                        className={styles.button}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<CheckIcon />}
                      onClick={
                        activeStep === steps.length - 1
                          ? handleSubmit
                          : handleNext
                      }
                      sx={{
                        color: "midnightblue",
                        borderColor: "midnightblue",
                        "&:hover": {
                          color: "var(--soft-blue)",
                          borderColor: "var(--soft-blue)",
                        },
                      }}
                      className={styles.button}
                      disabled={isLoading || isChecking}
                    >
                      {isChecking ? (
                        <CircularProgress size={24} />
                      ) : activeStep === steps.length - 1 ? (
                        "Submit"
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Paper>
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
}

function ProfileCompletionStep({ data, onChange, errors }) {
  const { api } = useContext(AuthContext);
  const { user, updateUserContext } = useContext(AuthContext);
  const [newPhoneNumber, setNewPhoneNumber] = useState(user.user_phone || "");
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);

  const isPendingVerification = () => {
    return (
      !!user.user_new_phone &&
      new Date(user.user_last_phone_verification_date_expired) > new Date()
    );
  };

  useEffect(() => {
    if (isPendingVerification()) {
      const expirationDate = new Date(
        user.user_last_phone_verification_date_expired
      );
      const now = new Date();
      setCountdown(Math.max(0, Math.floor((expirationDate - now) / 1000)));
    }
  }, [user]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handlePhoneChange = (value) => {
    setNewPhoneNumber(value);
    if (!matchIsValidTel(value)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleVerificationCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setVerificationCode(value);
  };

  const handlePhoneSubmit = async () => {
    if (!matchIsValidTel(newPhoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }
    setIsLoading(true);
    setAlertMessage(null);

    try {
      const response = await api.post("/auth/request-phone-verification", {
        phoneNumber: newPhoneNumber,
      });
      setCountdown(600);
      updateUserContext();
      setAlertMessage({
        type: "success",
        message: "Verification code sent to your phone number.",
      });
    } catch (error) {
      console.error("Error requesting phone number verification:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to send verification code. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    setAlertMessage(null);

    try {
      const response = await api.post("/auth/verify-phone", {
        verificationCode,
      });
      updateUserContext();
      setAlertMessage({
        type: "success",
        message: "Phone number verified successfully.",
      });
    } catch (error) {
      console.error("Error verifying phone number:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to verify phone number. Please try again.",
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...data, [name]: value });
  };

  const renderPhoneStatus = () => {
    if (user.user_is_phone_verified) {
      return (
        <Alert icon={<CheckIcon />} severity="success" sx={{ mt: 2, mb: 2 }}>
          Phone number verified
        </Alert>
      );
    } else {
      return (
        <Alert icon={<CancelIcon />} severity="error" sx={{ mt: 2, mb: 2 }}>
          Phone number not verified
        </Alert>
      );
    }
  };

  const renderPendingPhoneChange = () => {
    if (isPendingVerification()) {
      const minutesRemaining = Math.ceil(countdown / 60);
      return (
        <Alert icon={<WarningIcon />} severity="warning" sx={{ mt: 2, mb: 2 }}>
          <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>
            Phone number change pending: {user.user_new_phone}
          </p>
          <p style={{ margin: 0 }}>
            Verification code expires in: {minutesRemaining} minutes
          </p>
        </Alert>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Complete Your Profile
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please ensure your profile is complete before registering a business.
        </Typography>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => setAlertMessage(null)}
          >
            {alertMessage.message}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            required
            id="user_first_name"
            name="user_first_name"
            label="First Name"
            value={data.user_first_name || ""}
            onChange={handleChange}
            error={!!errors.user_first_name}
            helperText={errors.user_first_name}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            required
            id="user_last_name"
            name="user_last_name"
            label="Last Name"
            value={data.user_last_name || ""}
            onChange={handleChange}
            error={!!errors.user_last_name}
            helperText={errors.user_last_name}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <MuiTelInput
            id="user_phone"
            name="user_phone"
            label="Phone Number"
            value={newPhoneNumber}
            onChange={handlePhoneChange}
            error={!!phoneError}
            helperText={phoneError}
            fullWidth
          />
          {newPhoneNumber !== user.user_phone && (
            <Button
              variant="outlined"
              onClick={handlePhoneSubmit}
              disabled={
                isLoading || !!phoneError || !matchIsValidTel(newPhoneNumber)
              }
              sx={{
                marginTop: "10px",
                color: "midnightblue",
                borderColor: "midnightblue",
                "&:hover": {
                  color: "var(--soft-blue)",
                  borderColor: "var(--soft-blue)",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Send Verification Code"
              )}
            </Button>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {renderPhoneStatus()}
        {renderPendingPhoneChange()}
      </Grid>
      {isPendingVerification() && (
        <Grid item xs={12}>
          <form onSubmit={handleVerify}>
            <TextField
              name="verificationCode"
              type="text"
              variant="outlined"
              placeholder="Enter 6-digit code"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              inputProps={{
                maxLength: 6,
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
              fullWidth
              margin="normal"
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Button
                type="submit"
                variant="outlined"
                disabled={isVerifying || verificationCode.length !== 6}
                sx={{
                  marginTop: "10px",
                  color: "midnightblue",
                  borderColor: "midnightblue",
                  "&:hover": {
                    color: "var(--soft-blue)",
                    borderColor: "var(--soft-blue)",
                  },
                }}
              >
                {isVerifying ? (
                  <CircularProgress size={24} />
                ) : (
                  "Verify Phone Number"
                )}
              </Button>
              <p>
                Time remaining: {Math.floor(countdown / 60)}:
                {countdown % 60 < 10 ? "0" : ""}
                {countdown % 60}
              </p>
            </Box>
            {countdown === 0 && (
              <Button
                type="button"
                variant="outlined"
                onClick={handlePhoneSubmit}
                sx={{
                  marginTop: "10px",
                  color: "midnightblue",
                  borderColor: "midnightblue",
                  "&:hover": {
                    color: "var(--soft-blue)",
                    borderColor: "var(--soft-blue)",
                  },
                }}
              >
                Resend Code
              </Button>
            )}
          </form>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            id="user_email"
            name="user_email"
            label="Email"
            value={data.user_email || ""}
            onChange={handleChange}
            disabled
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

function AddressFields({
  address,
  onChange,
  onRemove,
  index,
  isLoaded,
  error,
  addressErrors,
}) {
  const [phoneError, setPhoneError] = useState("");

  const handleChange = (e) => {
    onChange(index, e.target.name, e.target.value);
  };

  const handlePhoneChange = (value) => {
    onChange(index, "phone", value);
    if (!matchIsValidTel(value)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleTimeChange = (day, timeType, value) => {
    const newHours = { ...address.hours };
    newHours[day] = {
      ...newHours[day],
      [timeType]: value,
    };
    onChange(index, "hours", newHours);
  };

  const handleDayToggle = (day, isOpen) => {
    const newHours = { ...address.hours };
    if (!isOpen) {
      delete newHours[day];
    } else {
      newHours[day] = {
        open: null,
        close: null,
      };
    }
    onChange(index, "hours", newHours);
  };

  const handleAddressSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      console.log("Address components:", addressComponents);

      const getAddressComponent = (type) => {
        const component = addressComponents.find((comp) =>
          comp.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const streetNumber = getAddressComponent("street_number");
      const street = getAddressComponent("route");
      const addressLine1 = `${streetNumber} ${street}`.trim();

      const addressData = {
        address_line1: addressLine1 || selectedAddress,
        address_line2: getAddressComponent("subpremise"),
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        postal_code: getAddressComponent("postal_code"),
        country: getAddressComponent("country"),
        latitude: latLng.lat,
        longitude: latLng.lng,
      };

      console.log("Selected address:", addressData);

      // Update all address fields at once
      onChange(index, addressData);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Card className={styles["address-card"]}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Address {index + 1}
        </Typography>
        <TextField
          name="name"
          label="Location Name"
          value={address.name || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          className={styles["form-field"]}
          error={!!addressErrors?.name}
          helperText={addressErrors?.name}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />

        <PlacesAutocomplete
          value={address.address_line1}
          onChange={(value) => onChange(index, "address_line1", value)}
          onSelect={handleAddressSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={styles["autocomplete-wrapper"]}>
              {error && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              <TextField
                {...getInputProps({
                  name: "address_line1",
                  label: "Address Line 1",
                  error: !!addressErrors?.address_line1,
                  helperText: addressErrors?.address_line1,
                  fullWidth: true,
                  margin: "dense",
                  required: true,
                  className: styles["form-field"],
                })}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              {suggestions.length > 0 && (
                <div className={styles["autocomplete-dropdown-container"]}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? styles["suggestion-item--active"]
                      : styles["suggestion-item"];
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                        key={index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>

        <TextField
          name="address_line2"
          label="Address Line 2"
          value={address.address_line2 || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          className={styles["form-field"]}
          error={!!addressErrors?.address_line2}
          helperText={addressErrors?.address_line2}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />
        <TextField
          name="city"
          label="City"
          value={address.city || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          required
          className={styles["form-field"]}
          error={!!addressErrors?.city}
          helperText={addressErrors?.city}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />
        <TextField
          name="state"
          label="State"
          value={address.state || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          required
          className={styles["form-field"]}
          error={!!addressErrors?.state}
          helperText={addressErrors?.state}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />
        <TextField
          name="postal_code"
          label="Postal Code"
          value={address.postal_code || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          required
          className={styles["form-field"]}
          error={!!addressErrors?.postal_code}
          helperText={addressErrors?.postal_code}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />
        <TextField
          name="country"
          label="Country"
          value={address.country || ""}
          onChange={handleChange}
          fullWidth
          margin="dense"
          required
          className={styles["form-field"]}
          error={!!addressErrors?.country}
          helperText={addressErrors?.country}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ marginTop: "20px" }}
        >
          Hours of Operation
        </Typography>
        <Grid container spacing={2}>
          {[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ].map((day) => (
            <Grid item xs={12} key={day}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!address.hours?.[day]}
                    onChange={(event) =>
                      handleDayToggle(day, event.target.checked)
                    }
                    name={day}
                  />
                }
                label={day}
              />
              {address.hours?.[day] && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DesktopTimePicker
                        label="Opening Time"
                        value={address.hours?.[day]?.open || null}
                        onChange={(value) =>
                          handleTimeChange(day, "open", value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DesktopTimePicker
                        label="Closing Time"
                        value={address.hours?.[day]?.close || null}
                        onChange={(value) =>
                          handleTimeChange(day, "close", value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              )}
            </Grid>
          ))}
        </Grid>

        <MuiTelInput
          name="phone"
          label="Phone Number"
          value={address.phone || ""}
          onChange={handlePhoneChange}
          fullWidth
          margin="dense"
          className={styles["form-field"]}
          error={!!addressErrors?.phone || !!phoneError}
          helperText={addressErrors?.phone || phoneError}
          sx={{
            "& .MuiInputBase-root": { fontSize: "0.8rem", height: "30px" },
            "& .MuiInputBase-input": { fontSize: "0.8rem", padding: "8px" },
            "& .MuiInputLabel-root": { fontSize: "0.75rem", top: "-6px" },
            "& .MuiInputLabel-shrink": { top: "0" },
            "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
          }}
        />

        <IconButton
          onClick={() => onRemove(index)}
          className={styles["remove-address-button"]}
        >
          <DeleteIcon />
        </IconButton>
        {error && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

function BasicInfoStep({
  data,
  onChange,
  errors,
  setErrors,
  isLoaded,
  availabilityErrors,
}) {
  const { api } = useContext(AuthContext);
  const [phoneError, setPhoneError] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...data, [name]: value });
  };

  useEffect(() => {
    console.log("Updated addresses:", data.addresses);
  }, [data.addresses]);

  const handlePhoneChange = (value) => {
    if (!matchIsValidTel(value)) {
      setPhoneError("Invalid phone number");
    } else {
      setPhoneError("");
    }
    onChange({ ...data, business_phone: value });
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: file.type }));
          }, file.type);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      // Check file type
      const acceptedImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!acceptedImageTypes.includes(file.type)) {
        setAlertMessage({
          type: "error",
          message: `Invalid file type. Please upload a JPEG, PNG, or GIF image for ${
            fieldName === "profile_photo" ? "profile photo" : "banner photo"
          }.`,
        });
        return;
      }

      // Resize image
      const maxWidth = 1200;
      const maxHeight = 1200;
      const resizedFile = await resizeImage(file, maxWidth, maxHeight);

      // Check file size after resizing (e.g., limit to 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (resizedFile.size > maxSize) {
        setAlertMessage({
          type: "error",
          message: `File is too large. Please upload an image smaller than 5MB for ${
            fieldName === "profile_photo" ? "profile photo" : "banner photo"
          }.`,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({ ...data, [fieldName]: reader.result });
        setAlertMessage(null);
      };
      reader.readAsDataURL(resizedFile);
    }
  };

  useEffect(() => {
    console.log("Addresses updated:", data.addresses);
  }, [data.addresses]);

  const validateAddresses = () => {
    if (data.has_physical_address === "yes" && data.addresses.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addresses: "At least one address is required",
      }));
      return false;
    }

    if (data.has_physical_address === "yes" && data.addresses.length > 0) {
      const newAddressErrors = data.addresses.map((address) => ({
        address_line1: !address.address_line1
          ? "Address Line 1 is required"
          : "",
        city: !address.city ? "City is required" : "",
        state: !address.state ? "State is required" : "",
        postal_code: !address.postal_code ? "Postal Code is required" : "",
        country: !address.country ? "Country is required" : "",
        phone: !address.phone ? "Phone number is required" : "",
      }));

      const hasErrors = newAddressErrors.some((addressError) =>
        Object.values(addressError).some((error) => error !== "")
      );

      if (hasErrors) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          addresses: newAddressErrors,
        }));
        return false;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      addresses: null,
    }));
    return true;
  };

  useEffect(() => {
    validateAddresses();
  }, [data.has_physical_address, data.addresses]);

  const handleAddressChange = (index, field, value) => {
    const newAddresses = [...data.addresses];
    if (typeof field === "object" && value === undefined) {
      newAddresses[index] = { ...newAddresses[index], ...field };
    } else {
      newAddresses[index] = { ...newAddresses[index], [field]: value };
    }
    onChange({ ...data, addresses: newAddresses });

    // Clear the error for the changed field
    setErrors((prevErrors) => {
      const newAddressErrors = [...(prevErrors.addresses || [])];
      if (newAddressErrors[index]) {
        newAddressErrors[index] = { ...newAddressErrors[index], [field]: "" };
      }
      return { ...prevErrors, addresses: newAddressErrors };
    });
  };

  const addAddress = () => {
    onChange({
      ...data,
      addresses: [
        ...data.addresses,
        {
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
          phone: "",
          latitude: null,
          longitude: null,
          name: "",
          hours: "",
        },
      ],
    });
  };

  const removeAddress = (index) => {
    const newAddresses = data.addresses.filter((_, i) => i !== index);
    onChange({ ...data, addresses: newAddresses });

    setErrors((prevErrors) => {
      const newAddressErrors = prevErrors.addresses?.filter(
        (_, i) => i !== index
      );
      return { ...prevErrors, addresses: newAddressErrors };
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Business Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please provide the following information about your business.
        </Typography>
        {alertMessage && (
          <Grid item xs={12}>
            <Alert
              severity={alertMessage.type}
              onClose={() => setAlertMessage(null)}
            >
              {alertMessage.message}
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="business_name"
          name="business_name"
          label="Business Name"
          fullWidth
          value={data.business_name || ""}
          onChange={handleChange}
          error={!!errors.business_name || !!availabilityErrors?.business_name}
          helperText={
            errors.business_name ||
            availabilityErrors?.business_name ||
            "Business Name is required"
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="business_email"
          name="business_email"
          label="Business Email"
          fullWidth
          value={data.business_email || ""}
          onChange={handleChange}
          error={
            !!errors.business_email || !!availabilityErrors?.business_email
          }
          helperText={
            errors.business_email ||
            availabilityErrors?.business_email ||
            "Business Email is required"
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiTelInput
          required
          id="business_phone"
          name="business_phone"
          label="Business Phone"
          fullWidth
          value={data.business_phone || ""}
          onChange={handlePhoneChange}
          error={
            !!phoneError ||
            !!errors.business_phone ||
            !!availabilityErrors?.business_phone
          }
          helperText={
            phoneError ||
            errors.business_phone ||
            availabilityErrors?.business_phone ||
            "Business Phone is required"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="business_description"
          name="business_description"
          label="Business Description"
          fullWidth
          multiline
          rows={4}
          value={data.business_description || ""}
          onChange={handleChange}
          error={!!errors.business_description}
          helperText={
            errors.business_description || "Business Description is required"
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="profile-photo-upload"
          type="file"
          onChange={(e) => handleFileChange(e, "profile_photo")}
        />
        <label htmlFor="profile-photo-upload">
          <Button variant="outlined" component="span" startIcon={<AddAPhoto />}>
            Upload Profile Photo
          </Button>
        </label>
        {data.profile_photo && (
          <img
            src={data.profile_photo}
            alt="Profile"
            style={{ maxWidth: "100%", maxHeight: "300px", marginTop: "10px" }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="banner-photo-upload"
          type="file"
          onChange={(e) => handleFileChange(e, "banner_photo")}
        />
        <label htmlFor="banner-photo-upload">
          <Button variant="outlined" component="span" startIcon={<AddAPhoto />}>
            Upload Banner Photo
          </Button>
        </label>
        {data.banner_photo && (
          <img
            src={data.banner_photo}
            alt="Banner"
            style={{ maxWidth: "100%", maxHeight: "300px", marginTop: "10px" }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Does the business have a physical address?
          </FormLabel>
          <RadioGroup
            aria-label="physical_address"
            name="has_physical_address"
            value={data.has_physical_address}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {data.has_physical_address === "yes" && (
        <Grid item xs={12}>
          {errors.addresses && typeof errors.addresses === "string" && (
            <Typography color="error">{errors.addresses}</Typography>
          )}
          {data.addresses.map((address, index) => (
            <AddressFields
              key={index}
              address={address}
              onChange={handleAddressChange}
              onRemove={removeAddress}
              index={index}
              isLoaded={isLoaded}
              error={availabilityErrors?.[`address_${index}`]}
              addressErrors={
                errors.addresses && Array.isArray(errors.addresses)
                  ? errors.addresses[index]
                  : {}
              }
            />
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addAddress}
            className={styles["add-address-button"]}
          >
            Add Another Address
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

const getCategoryIcon = (categoryName) => {
  switch (categoryName.toLowerCase()) {
    case "shipping":
      return <LocalShipping fontSize="inherit" />;
    case "product seller":
      return <Store fontSize="inherit" />;
    case "vehicle rental":
      return <DirectionsCar fontSize="inherit" />;
    case "warehousing service provider":
      return <Warehouse fontSize="inherit" />;
    default:
      return <Category fontSize="inherit" />;
  }
};

function BusinessCategoryStep({ data, onChange, error }) {
  const { api } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    data.parent_category_name || data.business_category_name || null
  );
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    data.business_category_name || null
  );
  const [openSubcategories, setOpenSubcategories] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/business/categories");
        setCategories(response.data.categories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching business categories:", error);
        setFetchError(
          "Failed to load business categories. Please try again later."
        );
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategorySelect = (category) => {
    if (!category.subcategories || category.subcategories.length === 0) {
      // If there are no subcategories, do nothing
      return;
    }

    setSelectedCategory(category.business_category_name);
    setSelectedSubcategory(null); // Reset subcategory when a new category is selected
    setOpenSubcategories(true);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory.business_category_name);
    onChange({
      business_category_id: subcategory.business_category_id,
      business_category_name: subcategory.business_category_name,
      parent_category_name: selectedCategory,
      business_category_attributes: subcategory.business_category_attributes,
    });
    setOpenSubcategories(false);
  };

  const getCategoryIcon = (categoryName) => {
    switch (categoryName.toLowerCase()) {
      case "shipping and logistics":
        return <LocalShipping fontSize="large" />;
      case "warehousing and storage":
        return <Warehouse fontSize="large" />;
      case "shopping":
        return <ShoppingCart fontSize="large" />;
      case "rentals":
        return <DirectionsCar fontSize="large" />;
      case "service business":
        return <Build fontSize="large" />;
      default:
        return <Category fontSize="large" />;
    }
  };

  if (loading) return <CircularProgress />;
  if (fetchError) return <Typography color="error">{fetchError}</Typography>;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Select a Business Category
      </Typography>
      <div className={styles["profile-grid"]}>
        {categories.map((category) => (
          <Card
            key={category.business_category_id}
            className={`${styles["profile-card"]} ${
              selectedCategory === category.business_category_name
                ? styles["selected-card"]
                : ""
            }`}
          >
            {selectedCategory === category.business_category_name && (
              <div className={styles["selected-overlay"]}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ color: "green", fontWeight: "bold" }}
                >
                  {selectedSubcategory
                    ? `${selectedCategory} - ${selectedSubcategory}`
                    : selectedCategory}
                </Typography>
              </div>
            )}
            <CardActionArea
              onClick={() => handleCategorySelect(category)}
              disabled={
                !category.subcategories || category.subcategories.length === 0
              }
              sx={{
                opacity:
                  !category.subcategories || category.subcategories.length === 0
                    ? 0.5
                    : 1,
                pointerEvents:
                  !category.subcategories || category.subcategories.length === 0
                    ? "none"
                    : "auto",
              }}
            >
              <CardContent>
                <div className={styles["category-icon"]}>
                  {getCategoryIcon(category.business_category_name)}
                </div>
                <Typography variant="h6" component="h3">
                  {category.business_category_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {category.business_category_description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>

      <Dialog
        open={openSubcategories}
        onClose={() => setOpenSubcategories(false)}
        aria-labelledby="subcategory-dialog-title"
        disableEnforceFocus
      >
        <DialogTitle id="subcategory-dialog-title">
          Select a Subcategory for Shipping and Logistics
        </DialogTitle>
        <DialogContent>
          <List>
            {selectedCategory &&
              categories
                .find((cat) => cat.business_category_name === selectedCategory)
                ?.subcategories?.map((subcategory) => (
                  <ListItem
                    button
                    key={subcategory.business_category_id}
                    onClick={() => handleSubcategorySelect(subcategory)}
                  >
                    <ListItemText
                      primary={subcategory.business_category_name}
                      secondary={subcategory.business_category_description}
                    />
                  </ListItem>
                ))}
          </List>
        </DialogContent>
      </Dialog>

      {error && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
    </div>
  );
}

function OperatingCountriesStep({ data, onChange, error }) {
  const { api } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.get("/business/countries");
        setCountries(response.data.countries);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setFetchError("Failed to load countries. Please try again later.");
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const handleToggle = (countryId) => {
    const currentIndex = data.indexOf(countryId);
    const newChecked = [...data];

    if (currentIndex === -1) {
      newChecked.push(countryId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };

  return (
    <FormControl fullWidth error={!!error}>
      <Typography variant="h6" gutterBottom>
        Operating Countries
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : fetchError ? (
        <Alert severity="error">{fetchError}</Alert>
      ) : (
        <List>
          {countries.map((country) => (
            <ListItem
              key={country.country_id}
              button
              onClick={() => handleToggle(country.country_id)}
            >
              <Checkbox
                checked={data.indexOf(country.country_id) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={country.country_name} />
            </ListItem>
          ))}
        </List>
      )}
      {error && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
    </FormControl>
  );
}

const LicensesAndInsuranceStep = ({
  data,
  onChange,
  businessCategory,
  onValidationChange,
}) => {
  const { api } = useContext(AuthContext);
  const [requirements, setRequirements] = useState([]);
  const [disclaimer, setDisclaimer] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const fetchRequirements = async () => {
      setLoading(true);
      try {
        const response = await api.get("/business/licenses-and-insurance", {
          params: { businessCategory },
        });
        setRequirements(response.data.requirements);
        setDisclaimer(response.data.disclaimer);
        setLoading(false);
      } catch (err) {
        console.error(
          "Error fetching license and insurance requirements:",
          err
        );
        setError("Failed to fetch requirements. Please try again.");
        setLoading(false);
      }
    };

    fetchRequirements();
  }, [businessCategory]);

  useEffect(() => {
    validateRequirements();
  }, [data, requirements]);

  const validateRequirements = () => {
    const newFieldErrors = {};
    const isValid = requirements.every((req) => {
      if (req.required || data[req.name]?.has) {
        if (!data[req.name]?.file) {
          newFieldErrors[req.name] = `Please upload ${req.label}`;
          return false;
        }
      }
      return true;
    });
    setFieldErrors(newFieldErrors);
    onValidationChange(isValid);
  };

  const handleOptionalChange = (event) => {
    const { name, checked } = event.target;
    onChange({ ...data, [name]: { ...data[name], has: checked } });
    if (!checked) {
      // If unchecking, remove any existing file
      onChange({ ...data, [name]: { has: false, file: null } });
      // Also remove any error for this field
      setFieldErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleFileUpload = async (event, name) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onChange({
          ...data,
          [name]: { ...data[name], file: base64String, has: true },
        });
        setFieldErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[name];
          return newErrors;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Licenses and Insurance
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please provide the required documents and indicate which optional
          licenses and insurance you have.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info" icon={<InfoIcon />}>
          {disclaimer}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <List>
            {requirements.map((req) => (
              <ListItem key={req.name} alignItems="flex-start">
                <Box sx={{ width: "100%" }}>
                  <Box display="flex" alignItems="center">
                    {!req.required && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data[req.name]?.has || false}
                            onChange={handleOptionalChange}
                            name={req.name}
                          />
                        }
                        label=""
                      />
                    )}
                    <ListItemText
                      primary={req.label}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            {req.description}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color={req.required ? "error" : "textSecondary"}
                            sx={{ display: "block" }}
                          >
                            {req.required ? "Required" : "Optional"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </Box>
                  {(req.required || data[req.name]?.has) && (
                    <Box mt={2}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <input
                            accept="application/pdf,image/*"
                            style={{ display: "none" }}
                            id={`upload-${req.name}`}
                            type="file"
                            onChange={(e) => handleFileUpload(e, req.name)}
                          />
                          <label htmlFor={`upload-${req.name}`}>
                            <Button
                              variant="outlined"
                              component="span"
                              startIcon={<UploadIcon />}
                            >
                              Upload {req.label}
                            </Button>
                          </label>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            color={data[req.name]?.file ? "primary" : "error"}
                          >
                            {data[req.name]?.file
                              ? "File uploaded"
                              : req.required
                              ? "Required file not uploaded"
                              : "No file uploaded"}
                          </Typography>
                        </Grid>
                      </Grid>
                      {fieldErrors[req.name] && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ mt: 1 }}
                        >
                          {fieldErrors[req.name]}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

function PaymentMethodStep({
  clientSecret,
  isLoadingClientSecret,
  data,
  onSubmit,
  onValidationChange,
  error,
}) {
  const { api } = useContext(AuthContext);
  const [existingPaymentMethods, setExistingPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isAddingNewMethod, setIsAddingNewMethod] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    fetchExistingPaymentMethods();
    if (data) {
      setSelectedPaymentMethod(data);
    }
  }, []);

  useEffect(() => {
    onValidationChange(!!selectedPaymentMethod);
  }, [selectedPaymentMethod, onValidationChange]);

  const fetchExistingPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/payments/payment-methods", {});
      setExistingPaymentMethods(response.data.paymentMethods);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching payment methods:", err);
      setFetchError(
        "Failed to load existing payment methods. Please try again."
      );
      setIsLoading(false);
    }
  };

  const handlePaymentMethodSelect = (paymentMethodId) => {
    setSelectedPaymentMethod(paymentMethodId);
    setIsAddingNewMethod(false);
    onSubmit({ id: paymentMethodId });
  };

  const handleAddNewMethod = () => {
    setSelectedPaymentMethod(null);
    setIsAddingNewMethod(true);
  };

  const handleCancelAddNewMethod = () => {
    setIsAddingNewMethod(false);
  };

  const handleNewPaymentMethodSubmit = async (paymentMethod) => {
    await onSubmit(paymentMethod);
    setIsAddingNewMethod(false);
    await fetchExistingPaymentMethods();
    setSelectedPaymentMethod(paymentMethod.id);
  };

  const CardIcon = ({ brand }) => {
    const iconProps = { className: styles.cardIcon, size: 24 };
    switch (brand.toLowerCase()) {
      case "visa":
        return <FaCcVisa {...iconProps} color="#1A1F71" />;
      case "mastercard":
        return <FaCcMastercard {...iconProps} color="#EB001B" />;
      case "amex":
        return <FaCcAmex {...iconProps} color="#2E77BC" />;
      case "discover":
        return <FaCcDiscover {...iconProps} color="#FF6000" />;
      default:
        return <FaCreditCard {...iconProps} color="#6C757D" />;
    }
  };

  if (isLoading || isLoadingClientSecret) {
    return (
      <Dialog open={true} disableEscapeKeyDown>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <CircularProgress style={{ marginBottom: "20px" }} />
            <Typography>Loading payment information...</Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (fetchError) {
    return <Typography color="error">{fetchError}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Payment Method
        </Typography>
        <Alert severity="info" sx={{ mb: 2 }}>
          <Typography variant="body2">
            A valid payment method is required to complete your business
            registration. This allows us to verify your account and process any
            applicable fees or subscriptions. Your card will not be charged at
            this time.
          </Typography>
        </Alert>
      </Grid>
      {error && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {existingPaymentMethods.length > 0 && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Select an existing payment method:
            </Typography>
            <List className={styles.paymentMethodsList}>
              {existingPaymentMethods.map((method) => (
                <ListItem
                  key={method.id}
                  button
                  selected={selectedPaymentMethod === method.id}
                  onClick={() => handlePaymentMethodSelect(method.id)}
                  className={styles.paymentMethodItem}
                >
                  <ListItemIcon>
                    <CardIcon brand={method.card.brand} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${method.card.brand} **** ${method.card.last4}`}
                    secondary={`Expires: ${method.card.exp_month}/${method.card.exp_year}`}
                  />
                  <ListItemSecondaryAction>
                    {selectedPaymentMethod === method.id && (
                      <Chip
                        icon={<CheckCircle />}
                        label="Selected"
                        color="primary"
                        size="small"
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {!isAddingNewMethod && (
          <Button
            variant="outlined"
            startIcon={<AddCardIcon />}
            onClick={handleAddNewMethod}
            sx={{ mt: 2, mb: 2 }}
          >
            Add New Payment Method
          </Button>
        )}
      </Grid>
      {isAddingNewMethod && (
        <Grid item xs={12}>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: { theme: "night", labels: "floating" },
              paymentMethodCreation: "manual",
            }}
          >
            <PaymentMethodForm
              onSubmit={handleNewPaymentMethodSubmit}
              onCancel={handleCancelAddNewMethod}
            />
          </Elements>
        </Grid>
      )}
    </Grid>
  );
}

function PaymentMethodForm({ onSubmit, onCancel }) {
  const { api } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    const paymentElement = elements.getElement(PaymentElement);

    if (!paymentElement) {
      setError("Payment element not found");
      setIsProcessing(false);
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      setIsProcessing(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      setError(error.message);
    } else if (paymentMethod) {
      onSubmit(paymentMethod);
    }
    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.paymentForm}>
      <div className={styles.cardElementWrapper}>
        <PaymentElement />
      </div>
      {error && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <div className={styles.formButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!stripe || isProcessing}
          startIcon={<AddCardIcon />}
          sx={{ mt: 2 }}
        >
          {isProcessing ? (
            <CircularProgress size={24} />
          ) : (
            "Add New Payment Method"
          )}
        </Button>
        <Button variant="outlined" onClick={onCancel} sx={{ mt: 2, ml: 2 }}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

function ReviewStep({ formData }) {
  const getCategoryInfo = () => {
    const category = formData.business_category;
    let categoryInfo = category.business_category_name || "Not specified";

    if (category.business_category_attributes) {
      if (typeof category.business_category_attributes === "string") {
        try {
          const parsed = JSON.parse(category.business_category_attributes);
          if (parsed.types && Array.isArray(parsed.types)) {
            categoryInfo += ` - ${parsed.types.join(", ")}`;
          }
        } catch (e) {
          console.error("Error parsing business_category_attributes:", e);
        }
      } else if (typeof category.business_category_attributes === "object") {
        if (
          category.business_category_attributes.types &&
          Array.isArray(category.business_category_attributes.types)
        ) {
          categoryInfo += ` - ${category.business_category_attributes.types.join(
            ", "
          )}`;
        }
      }
    }

    return categoryInfo;
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Review Your Business Information
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Owner Information</Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Name"
              secondary={`${formData.profile_completion.user_first_name} ${formData.profile_completion.user_last_name}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Phone Number"
              secondary={formData.profile_completion.user_phone}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Email Address"
              secondary={formData.profile_completion.user_email}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Business Information</Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Business Name"
              secondary={formData.basic_info.business_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Business Email"
              secondary={formData.basic_info.business_email}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Business Phone"
              secondary={formData.basic_info.business_phone}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Business Description"
              secondary={formData.basic_info.business_description}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2">Profile Photo</Typography>
        {formData.basic_info.profile_photo ? (
          <img
            src={formData.basic_info.profile_photo}
            alt="Profile"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        ) : (
          <Typography variant="body2">Not provided</Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2">Banner Photo</Typography>
        {formData.basic_info.banner_photo ? (
          <img
            src={formData.basic_info.banner_photo}
            alt="Banner"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        ) : (
          <Typography variant="body2">Not provided</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Physical Addresses</Typography>
        {formData.basic_info.has_physical_address === "yes" ? (
          formData.basic_info.addresses.map((addr, index) => (
            <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
              <Typography variant="subtitle2">Address {index + 1}</Typography>
              <Typography>{addr.address_line1}</Typography>
              {addr.address_line2 && (
                <Typography>{addr.address_line2}</Typography>
              )}
              <Typography>{`${addr.city}, ${addr.state} ${addr.postal_code}`}</Typography>
              <Typography>{addr.country}</Typography>
              <Typography>Phone: {addr.phone}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                Hours of Operation
              </Typography>
              {Object.entries(addr.hours || {}).map(([day, hours]) => (
                <Typography
                  key={day}
                >{`${day}: ${hours.open} - ${hours.close}`}</Typography>
              ))}
            </Paper>
          ))
        ) : (
          <Typography variant="body2">No physical addresses</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Business Category</Typography>
        <Typography>{getCategoryInfo()}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Operating Countries</Typography>
        <Typography>
          {Array.isArray(formData.operating_countries) &&
          formData.operating_countries.length > 0
            ? formData.operating_countries.join(", ")
            : "No countries specified"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Licenses and Insurance</Typography>
        <List>
          {Object.entries(formData.licenses_and_insurance || {})
            .filter(([, value]) => value && value.file)
            .map(([key, value]) => (
              <ListItem key={key}>
                <ListItemText
                  primary={key
                    .replace(/^has_/, "")
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  secondary="Uploaded"
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default BusinessRegistrationPage;
