import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import LoadingModal from "../Icon/LoadingModal";
import ParallaxBackground from "../Background/ParallaxBackground/ParallaxBackground";

const PublicRoute = ({ children }) => {
  const {
    isAuthenticated,
    loading,
    initializationComplete,
    user,
    isProfileComplete,
  } = useContext(AuthContext);
  const location = useLocation();
  const from = location.state?.from || "/dashboard";

  if (!initializationComplete || loading) {
    return (
      <>
        <ParallaxBackground />
        <LoadingModal open={true} />
      </>
    );
  }

  if (isAuthenticated) {
    // If profile is incomplete, redirect to complete profile
    if (!isProfileComplete(user)) {
      return <Navigate to="/complete-profile" replace />;
    }
    // Otherwise redirect to dashboard or original destination
    return <Navigate to={from} replace />;
  }

  return children;
};

export default PublicRoute;
