import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Chip,
  Card,
  CardContent,
  Typography,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./BusinessesPage.module.css";
import {
  CheckCircle,
  Block,
  Close,
  Help,
  HourglassEmpty,
} from "@mui/icons-material";
import EmployeeBusinessesList from "./EmployeeBusinessesList";

const defaultProfilePicture = "/images/background-image.jpg";
const defaultBannerPicture = "/images/background-image.jpg";

const BusinessesPage = () => {
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [businesses, setBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive]);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const fetchBusinesses = async () => {
    setIsLoading(true);
    try {
      console.log("Fetching businesses...");
      const response = await api.get("/business/get-all-businesses", {});

      console.log("Response data:", response.data);

      if (response.data && Array.isArray(response.data.businesses)) {
        setBusinesses(response.data.businesses);
      } else {
        console.error("Unexpected data structure:", response.data);
        setAlertMessage({
          type: "error",
          message: "Received unexpected data structure from server.",
        });
        setBusinesses([]);
      }
    } catch (error) {
      console.error("Error fetching businesses:", error);
      let errorMessage = "Failed to fetch businesses. Please try again.";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = "No response received from server. Please try again.";
      }
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
      setBusinesses([]);
    }
    setIsLoading(false);
  };

  const handleNavigateToRegister = () => {
    navigate("/register-business");
  };

  const renderBusinessCard = (business) => {
    // if business_banner_picture is not null, use it with  `data:image/jpeg;base64
    const profilePictureSrc = business.business_profile_picture
      ? `data:image/jpeg;base64,${business.business_profile_picture}`
      : defaultProfilePicture;
    const bannerPictureSrc = business.business_banner_picture
      ? `data:image/jpeg;base64,${business.business_banner_picture}`
      : defaultBannerPicture;

    return (
      <Card
        key={business.business_id}
        className={styles["business-card"]}
        onClick={() => navigate(`/business/${business.business_id}/dashboard`)}
      >
        <div className={styles.bannerContainer}>
          <img
            src={bannerPictureSrc}
            alt="Business Banner"
            className={styles.bannerImage}
          />
        </div>
        <CardContent className={styles.cardContent}>
          <div className={styles.profileImageContainer}>
            <img
              src={profilePictureSrc}
              alt="Business Profile"
              className={styles.profileImage}
            />
          </div>
          <Typography variant="h6" className={styles.businessName}>
            {business.business_name}
          </Typography>
          <Chip
            icon={
              business.business_status === "active" ? (
                <CheckCircle />
              ) : business.business_status === "suspended" ? (
                <Block />
              ) : business.business_status === "pending" ? (
                <HourglassEmpty />
              ) : business.business_status === "closed" ? (
                <Close />
              ) : (
                <Help />
              )
            }
            label={
              business.business_status.charAt(0).toUpperCase() +
              business.business_status.slice(1)
            }
            size="small"
            className={styles.statusChip}
            color={
              business.business_status === "active"
                ? "success"
                : business.business_status === "suspended"
                ? "error"
                : business.business_status === "pending"
                ? "warning"
                : business.business_status === "closed"
                ? "error"
                : "info"
            }
          />
          <Typography className={styles.businessInfo}>
            Email: {business.business_email}
          </Typography>
          <Typography className={styles.businessInfo}>
            Phone: {business.business_phone}
          </Typography>
          {business.addresses && business.addresses.length > 0 && (
            <div className={styles.addressInfo}>
              <Typography variant="subtitle2">Primary Address:</Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_street_address_1}
                {business.addresses[0].business_address_street_address_2 &&
                  `, ${business.addresses[0].business_address_street_address_2}`}
              </Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_city},{" "}
                {business.addresses[0].business_address_state_province}{" "}
                {business.addresses[0].business_address_postal_code}
              </Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_country}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={styles["businesses-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["businesses-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <EmployeeBusinessesList />
            <h1 className={styles.title}>Manage Businesses</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}

            {isLoading ? (
              <div className={styles["loading-container"]}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles["businesses-list"]}>
                <Card
                  className={`${styles["business-card"]} ${styles["add-card"]}`}
                  onClick={handleNavigateToRegister}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <AddBusinessIcon
                      style={{ fontSize: "4rem", marginBottom: "1rem" }}
                    />
                    <Typography variant="h6">Register New Business</Typography>
                  </CardContent>
                </Card>
                {businesses.length > 0 ? (
                  businesses.map((business) => renderBusinessCard(business))
                ) : (
                  // <Typography
                  //   variant="body1"
                  //   className={styles["no-businesses"]}
                  // >
                  //   {/* No businesses found. Register a new business to get started. */}
                  // </Typography>
                  <></>
                )}
              </div>
            )}
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default BusinessesPage;
