import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import {
  CircularProgress,
  TextField,
  Button,
  styled,
  Alert,
} from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import styles from "./ResetPassword.module.css";

const StyledLink = styled("a")({
  color: "#3498DB",
  textDecoration: "none",
  fontWeight: "bold",
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#E74C3C",
  },
});

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ResetPassword = () => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { api } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromURL = params.get("token");
    if (!tokenFromURL) {
      setAlertMessage({
        type: "error",
        message: "Reset token is missing",
      });
    }
    setIsLoading(false); // Set isLoading to false regardless
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      setAlertMessage({
        type: "error",
        message: "Passwords do not match",
      });
      setIsLoading(false);
      return;
    }

    const params = new URLSearchParams(location.search);
    const tokenFromURL = params.get("token");

    try {
      const response = await api.post("/auth/reset-password", {
        token: tokenFromURL,
        newPassword: newPassword,
      });
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
      setTimeout(() => {
        navigate("/auth");
      }, 3000);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.mainContent}>
      <Header />
      <ParallaxBackground />
      <main className={styles.container}>
        <div className={styles.card}>
          <h2>Reset Password</h2>
          {isLoading ? (
            <CircularProgress />
          ) : alertMessage ? (
            <Alert
              severity={alertMessage.type}
              variant="outlined"
              sx={{ mb: 2 }}
            >
              {alertMessage.message}
              {alertMessage.type === "success" && (
                <>
                  <br />
                  <StyledLink href="/auth">Redirecting to login...</StyledLink>
                </>
              )}
            </Alert>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                variant="outlined"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <CustomButton type="submit" variant="contained" fullWidth>
                Reset Password
              </CustomButton>
            </form>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ResetPassword;
