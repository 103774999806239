import React, { useState, useEffect, useRef, useContext } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  Autocomplete,
  Divider,
  InputLabel,
  Input,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  CardMedia,
  InputAdornment,
  Box,
  Alert,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Chip,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Save as SaveIcon,
  Restore as RestoreIcon,
  Business as BusinessIcon,
  Celebration as CelebrationIcon,
  Build as BuildIcon,
  Construction as BuildCircleIcon,
  Clear as ClearIcon,
  Report as ReportProblemIcon,
  Error as ErrorIcon,
  LocalShipping,
  AttachMoney,
  Schedule,
  Scale,
  Refresh,
  Straighten,
  Security,
  AcUnit,
  Gavel,
  EnergySavingsLeaf,
  Restore,
  FlightTakeoff,
  Weekend,
  Delete as DeleteIcon,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";
import { FaPallet as PalletIcon } from "react-icons/fa6";
import { GiWoodenCrate as CrateIcon } from "react-icons/gi";
import { FaBoxOpen as OtherIcon } from "react-icons/fa";
import { IoBag as BagIcon } from "react-icons/io5";
import { FaBox as BoxIcon } from "react-icons/fa";
import { FaEnvelope as EnvelopeIcon } from "react-icons/fa";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MuiTelInput } from "mui-tel-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import isEmail from "validator/lib/isEmail";
import PrintComponent from "./PrintComponent";
import ReactToPrint from "react-to-print";

// Styled Accordion component with left padding
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginLeft: theme.spacing(3), // Adds left padding
  marginBottom: theme.spacing(2), // Adds some space between accordions
  "&:before": {
    display: "none",
    marginLeft: theme.spacing(3),
  },
  "&.Mui-expanded": {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const QuickAddOrder = ({
  existingCustomers,
  onOrderCreate,
  initialOrder = null,
}) => {
  const { api } = useContext(AuthContext);
  const printComponentRef = useRef();
  const [tempFormId, setTempFormId] = useState(null);
  const [errors, setErrors] = useState({});
  const [hasFetchedShippingTiers, setHasFetchedShippingTiers] = useState(false);
  const [shippingTierErrors, setShippingTierErrors] = useState({});
  const [insuranceErrors, setInsuranceErrors] = useState({});
  const [customerErrors, setCustomerErrors] = useState({});
  const [upcErrors, setUpcErrors] = useState({});
  const { businessId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });
  const [submittedOrderId, setSubmittedOrderId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    sender: {
      customer_id: "",
      business_id: "", // You might want to set this from props or context
      user_id: null, // This might be set later if applicable
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      notes: "",
      customer_type: null,
      status: "active",
    },
    recipient: {
      customer_id: "",
      business_id: "", // You might want to set this from props or context
      user_id: null, // This might be set later if applicable
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      notes: "",
      customer_type: null,
      status: "active",
    },
    shipping_tier_id: "",
    specialInstructions: "",
    additionalCharges: [],
    insurance: false,
    insurance_id: "",
    saturdayDelivery: false,
    residentialDelivery: true,
    packages: [
      {
        // Initialize with one empty package
        items: [],
        dimensions: { length: "", width: "", height: "" },
        packaging_type: "",
        weight: "",
        declaredValue: "",
        temperatureControlled: false,
        minTemperature: "",
        maxTemperature: "",
        fragile: false,
        hazardous: false,
      },
    ],
    signature: "no_signature",
  });

  useEffect(() => {
    if (initialOrder) {
      setFormData(initialOrder);
      setActiveStep(0);
      setTempFormId(initialOrder.shipping_order_id);
    }
  }, [initialOrder]);

  const [tierPrices, setTierPrices] = useState({});
  const [shippingTiers, setShippingTiers] = useState([]);
  const [filteredShippingTiers, setFilteredShippingTiers] = useState([]);
  const [tierError, setTierError] = useState("");
  const [selectedTier, setSelectedTier] = useState(null);
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [filteredInsuranceOptions, setFilteredInsuranceOptions] = useState([]);
  const [insurancePremiums, setInsurancePremiums] = useState({});
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [error, setError] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [upcCode, setUpcCode] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const [reportItem, setReportItem] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const steps = [
    "Customer Info",
    "Package Details",
    "Shipping Tier",
    "Insurance",
    "Additional Info",
    "Review",
    "Order Complete",
  ];

  const packagingTypes = [
    {
      value: "Box",
      label: "Box",
      Icon: BoxIcon,
    },
    {
      value: "Envelope",
      label: "Envelope",
      Icon: EnvelopeIcon,
    },
    {
      value: "Pallet",
      label: "Pallet",
      Icon: PalletIcon,
    },
    {
      value: "Bag",
      label: "Bag",
      Icon: BagIcon,
    },
    {
      value: "Crate",
      label: "Crate",
      Icon: CrateIcon,
    },
    {
      value: "Other",
      label: "Other",
      Icon: OtherIcon,
    },
  ];

  const handleNavigation = () => {
    // Replace the last segment of the current path with "profile"
    const newPath = location.pathname.replace(/\/[^/]+$/, "/services");
    navigate(newPath);
  };

  useEffect(() => {
    fetchShippingTiers();
    fetchInsuranceOptions();
    // if shippingTierId is in the formData, and insuranceId is in the formData, then set the selectedTier and selectedInsurance
    if (formData.shipping_tier_id) {
      const tier = shippingTiers.find(
        (tier) => tier.tier_id === formData.shipping_tier_id
      );
      setSelectedTier(tier);
    }
    if (formData.insurance_id) {
      const insurance = insuranceOptions.find(
        (option) => option.insurance_id === formData.insurance_id
      );
      setSelectedInsurance(insurance);
    }
    console.log("Existing customers:", existingCustomers);
    console.log("Initial order:", initialOrder);
    console.log("Business ID:", businessId);
  }, []);

  useEffect(() => {
    if (
      initialOrder &&
      shippingTiers.length > 0 &&
      insuranceOptions.length > 0
    ) {
      // Set selected shipping tier
      const tier = shippingTiers.find(
        (tier) => tier.tier_id === initialOrder.shipping_tier_id
      );
      if (tier) {
        setSelectedTier(tier);
      }

      // Set selected insurance
      if (initialOrder.insurance_id) {
        const insurance = insuranceOptions.find(
          (option) => option.insurance_id === initialOrder.insurance_id
        );
        if (insurance) {
          setSelectedInsurance(insurance);
        }
      }
    }
  }, [initialOrder, shippingTiers, insuranceOptions]);

  const fetchShippingTiers = async () => {
    // setIsLoading(true);
    try {
      setHasFetchedShippingTiers(false);
      console.log("Fetching shipping tiers...");
      console.log("businessId", businessId);
      const response = await api.get(`/shipping-tiers`, {
        params: { business_id: businessId },
      });
      console.log("Response data:", response.data);
      setShippingTiers(response.data.tiers);
      setHasFetchedShippingTiers(true);
    } catch (error) {
      console.error("Error fetching shipping tiers:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch shipping tiers. Please try again.",
      });
    }
    // setIsLoading(false);
  };

  const calculateTierPrice = (tier, packages) => {
    let totalPrice = parseFloat(tier.base_price_flat_rate) || 0;

    const totalWeight = packages.reduce(
      (sum, pkg) => sum + parseFloat(pkg.weight || 0),
      0
    );
    const totalVolume = packages.reduce(
      (sum, pkg) =>
        sum +
        parseFloat(pkg.dimensions.length || 0) *
          parseFloat(pkg.dimensions.width || 0) *
          parseFloat(pkg.dimensions.height || 0),
      0
    );
    const totalValue = packages.reduce(
      (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
      0
    );

    if (tier.price_model.includes("WeightBased")) {
      totalPrice += parseFloat(tier.base_price_weight) * totalWeight;
    }

    if (tier.price_model.includes("DimensionBased")) {
      totalPrice += parseFloat(tier.base_price_dimensions) * totalVolume;
    }

    if (tier.price_model.includes("ValueBased")) {
      totalPrice += parseFloat(tier.base_price_value) * totalValue;
    }

    // Apply any additional price factors
    if (tier.price_factors) {
      // Example: Apply weight increment pricing
      if (
        tier.price_factors.weightIncrement &&
        tier.price_factors.weightPrice
      ) {
        const weightIncrements = Math.ceil(
          totalWeight / tier.price_factors.weightIncrement
        );
        totalPrice +=
          weightIncrements * parseFloat(tier.price_factors.weightPrice);
      }
      // Add more price factor calculations as needed
    }

    return parseFloat(totalPrice.toFixed(2));
  };

  const filterShippingTiers = () => {
    const filtered = shippingTiers.filter((tier) => {
      const totalWeight = formData.packages.reduce(
        (sum, pkg) => sum + parseFloat(pkg.weight || 0),
        0
      );
      const maxDimensions = formData.packages.reduce(
        (max, pkg) => ({
          length: Math.max(max.length, parseFloat(pkg.dimensions.length || 0)),
          width: Math.max(max.width, parseFloat(pkg.dimensions.width || 0)),
          height: Math.max(max.height, parseFloat(pkg.dimensions.height || 0)),
        }),
        { length: 0, width: 0, height: 0 }
      );
      const totalDeclaredValue = formData.packages.reduce(
        (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
        0
      );

      // Check for special conditions across all packages
      const hasHazardousMaterials = formData.packages.some(
        (pkg) => pkg.hazardous
      );
      const hasFragileItems = formData.packages.some((pkg) => pkg.fragile);
      const hasTemperatureControlled = formData.packages.some(
        (pkg) => pkg.temperatureControlled
      );

      const weightCheck =
        totalWeight >= parseFloat(tier.min_weight) &&
        totalWeight <= parseFloat(tier.max_weight);
      const dimensionsCheck =
        maxDimensions.length <= parseFloat(tier.max_dimensions.length) &&
        maxDimensions.width <= parseFloat(tier.max_dimensions.width) &&
        maxDimensions.height <= parseFloat(tier.max_dimensions.height);

      // Additional checks for special conditions
      const hazardousCheck =
        !hasHazardousMaterials || tier.hazardous_materials_handling;
      // const fragileCheck = !hasFragileItems || tier.white_glove_services; // Assuming white glove services handle fragile items
      const temperatureCheck =
        !hasTemperatureControlled || tier.temperature_controlled;

      // Temperature range check
      let temperatureRangeCheck = true;
      if (hasTemperatureControlled && tier.temperature_controlled) {
        const requiredTempRange = formData.packages.reduce(
          (range, pkg) => {
            if (pkg.temperatureControlled) {
              range.min = Math.min(range.min, pkg.minTemperature);
              range.max = Math.max(range.max, pkg.maxTemperature);
            }
            return range;
          },
          { min: Infinity, max: -Infinity }
        );
        temperatureRangeCheck =
          requiredTempRange.min >= tier.temperature_range.min &&
          requiredTempRange.max <= tier.temperature_range.max;
      }

      return (
        weightCheck &&
        dimensionsCheck &&
        hazardousCheck &&
        temperatureCheck &&
        temperatureRangeCheck
      );
    });

    const prices = {};
    filtered.forEach((tier) => {
      prices[tier.tier_id] = calculateTierPrice(tier, formData.packages);
    });

    setFilteredShippingTiers(filtered);
    setTierPrices(prices);
  };

  const filterInsuranceOptions = () => {
    console.log("Filtering insurance options...");
    if (!formData.packages.length) {
      console.log("No packages to calculate insurance for");
      setFilteredInsuranceOptions([]);
      return;
    }

    const totalDeclaredValue = formData.packages.reduce(
      (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
      0
    );

    console.log("Total declared value:", totalDeclaredValue);
    console.log("Insurance options:", insuranceOptions);
    const filtered = insuranceOptions.filter(
      (option) =>
        option.max_coverage_amount >= totalDeclaredValue && option.is_active
    );

    console.log("Filtered insurance options:", filtered);
    const premiums = {};
    filtered.forEach((option) => {
      premiums[option.insurance_id] = calculateInsurancePremium(
        option,
        totalDeclaredValue
      );
    });
    console.log("Insurance premiums:", premiums);
    setFilteredInsuranceOptions(filtered);
    setInsurancePremiums(premiums);
  };

  const calculateInsurancePremium = (option, declaredValue) => {
    return (option.premium_rate / 100) * declaredValue;
  };

  // Add this to your existing useEffect or create a new one
  useEffect(() => {
    // if step is less than the last step then filter the shipping tiers
    if (activeStep < steps.length - 1) {
      filterInsuranceOptions();
      filterShippingTiers();
    }
  }, [formData.packages, insuranceOptions, shippingTiers, activeStep]);

  // Update the handleRefresh function to include filterInsuranceOptions
  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      await fetchShippingTiers();
      await fetchInsuranceOptions();
      filterShippingTiers();
      filterInsuranceOptions();
    } catch (error) {
      console.error("Error refreshing data:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to refresh data. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInsuranceOptions = async () => {
    console.log("Fetching insurance options...");
    // setIsLoading(true);
    try {
      const response = await api.get(`/insurance?business_id=${businessId}`);
      setInsuranceOptions(response.data.insurances);
    } catch (err) {
      console.error("Error fetching insurance options:", err);
      setError("Failed to fetch insurance options. Please try again.");
    } finally {
      // setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Setting form data:", name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCustomerChange = (type, newValue) => {
    if (newValue && newValue.customer_id) {
      // Existing customer selected
      setFormData((prevData) => ({
        ...prevData,
        [type]: {
          ...newValue,
          customer_id: newValue.customer_id,
          first_name: newValue.first_name,
          last_name: newValue.last_name,
          email: newValue.email,
          phone: newValue.phone,
          address_line1: newValue.address_line1,
          address_line2: newValue.address_line2 || "",
          city: newValue.city,
          state: newValue.state,
          postal_code: newValue.postal_code,
          country: newValue.country,
        },
      }));

      // Clear all errors for this customer type
      setCustomerErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        Object.keys(updatedErrors).forEach((key) => {
          if (key.startsWith(type)) {
            delete updatedErrors[key];
          }
        });
        return updatedErrors;
      });
    } else {
      // New customer being entered or selection cleared
      setFormData((prevData) => ({
        ...prevData,
        [type]: {
          customer_id: "",
          business_id: prevData[type].business_id,
          user_id: null,
          first_name: newValue || "",
          last_name: "",
          email: "",
          phone: "",
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
          notes: "",
          customer_type: null,
          status: "active",
        },
      }));

      // Clear all errors for this customer type
      setCustomerErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        Object.keys(updatedErrors).forEach((key) => {
          if (key.startsWith(type)) {
            delete updatedErrors[key];
          }
        });
        return updatedErrors;
      });
    }

    // Check for same customer error
    if (type === "sender" || type === "recipient") {
      const otherType = type === "sender" ? "recipient" : "sender";
      if (
        newValue &&
        newValue.customer_id &&
        newValue.customer_id === formData[otherType].customer_id
      ) {
        setCustomerErrors((prevErrors) => ({
          ...prevErrors,
          sameCustomer: "Sender and recipient cannot be the same customer",
        }));
      } else {
        setCustomerErrors((prevErrors) => {
          const { sameCustomer, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const handleCustomerDetailChange = (type, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [field]: value,
      },
    }));

    // Clear the error for this specific field
    setCustomerErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[
        `${type}${field.charAt(0).toUpperCase() + field.slice(1)}`
      ];
      return updatedErrors;
    });

    // Special handling for email and phone
    if (field === "email" || field === "phone") {
      const otherType = type === "sender" ? "recipient" : "sender";
      if (value === formData[otherType][field]) {
        setCustomerErrors((prevErrors) => ({
          ...prevErrors,
          [`same${
            field.charAt(0).toUpperCase() + field.slice(1)
          }`]: `Sender and recipient cannot have the same ${field} unless they are the same customer`,
        }));
      } else {
        setCustomerErrors((prevErrors) => {
          const {
            [`same${field.charAt(0).toUpperCase() + field.slice(1)}`]: _,
            ...rest
          } = prevErrors;
          return rest;
        });
      }
    }
  };

  const addPackage = () => {
    setFormData((prevData) => ({
      ...prevData,
      packages: [
        ...prevData.packages,
        {
          items: [],
          dimensions: { length: "", width: "", height: "" },
          packaging_type: "",
          weight: "",
          declaredValue: "",
          temperatureControlled: false,
          minTemperature: "",
          maxTemperature: "",
          fragile: false,
          hazardous: false,
        },
      ],
    }));
  };

  const handlePackageChange = (pkgIndex, field, value) => {
    setFormData((prevData) => {
      const newPackages = [...prevData.packages];
      if (field === "dimensions") {
        newPackages[pkgIndex].dimensions = {
          ...newPackages[pkgIndex].dimensions,
          ...value,
        };
      } else {
        newPackages[pkgIndex] = { ...newPackages[pkgIndex], [field]: value };
      }
      return { ...prevData, packages: newPackages };
    });
  };

  const handleItemChange = (pkgIndex, itemIndex, field, value) => {
    validateField(value, field, pkgIndex, itemIndex);
    if (field === "weight") {
      // Round to two decimal places
      value = Number(parseFloat(value).toFixed(2));
    }
    setFormData((prevData) => {
      const newPackages = [...prevData.packages];
      newPackages[pkgIndex].items[itemIndex] = {
        ...newPackages[pkgIndex].items[itemIndex],
        [field]: value,
      };
      return { ...prevData, packages: newPackages };
    });
  };

  const addUPCItem = async (pkgIndex) => {
    const pkg = formData.packages[pkgIndex];
    if (pkg.upcCode) {
      try {
        const response = await api.get(`/upc/${pkg.upcCode}`);
        const productData = response.data.product;

        console.log("Product data:", productData);

        // Function to parse the specs array into an object
        const parseSpecs = (specsArray) => {
          return specsArray.reduce((acc, spec) => {
            let [key, value] = spec;
            key = key.toLowerCase().replace(/\s+/g, "_");
            acc[key] = value;
            return acc;
          }, {});
        };

        // Check if item already exists in the package
        const existingItemIndex = pkg.items.findIndex(
          (item) => item.upc === productData.upc
        );

        if (existingItemIndex > -1) {
          // If item exists, increase quantity
          setFormData((prevData) => {
            const newPackages = [...prevData.packages];
            newPackages[pkgIndex].items[existingItemIndex].quantity += 1;
            return { ...prevData, packages: newPackages };
          });
        } else {
          // If item doesn't exist, add new item
          setFormData((prevData) => {
            const newPackages = [...prevData.packages];
            newPackages[pkgIndex].items.push({
              item_id: productData.item_id,
              quantity: 1,
              name: productData.name,
              description: productData.description,
              // Parse the specs array into an object
              specs: productData.specs ? parseSpecs(productData.specs) : {},
              weight: parseFloat(parseSpecs(productData.specs).weight) || "",
              value: productData.value || "",
              image_data: productData.image_data, // Store the base64 image data
              upc: productData.upc,
              brand: productData.brand,
              category: productData.category,
            });
            return { ...prevData, packages: newPackages };
          });

          // Expand the newly added item
          setExpandedItems((prev) => [
            ...prev,
            `${pkgIndex}-${formData.packages[pkgIndex].items.length}`,
          ]);
        }

        // display the parsed specs in the console
        console.log("Parsed specs:", parseSpecs(productData.specs));

        // Clear UPC code input and any existing errors
        handlePackageChange(pkgIndex, "upcCode", "");
        setUpcErrors((prevErrors) => ({
          ...prevErrors,
          [pkgIndex]: null,
        }));
      } catch (err) {
        console.error("Error fetching UPC data:", err);
        setUpcErrors((prevErrors) => ({
          ...prevErrors,
          [pkgIndex]:
            err.response?.data?.error || "Failed to fetch product data.",
        }));
      }
    }
  };

  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [reportSubmitted, setReportSubmitted] = useState(false);

  const handleReportDialogOpen = (item) => {
    setReportItem(item);
    setOpenReportDialog(true);
  };

  const handleReportDialogClose = () => {
    setOpenReportDialog(false);
    setReportItem(null);
    setReportSubmitted(false);
  };

  const handleReportImage = async () => {
    try {
      console.log("Reporting image for item:", reportItem);
      await api.post(`/upc/${reportItem.item_id}/report-image`);
      setReportSubmitted(true);
    } catch (error) {
      console.error("Error reporting image:", error);
    }
    setOpenReportDialog(false);
    setReportItem(null);
  };

  const addManualItem = (pkgIndex) => {
    setFormData((prevData) => {
      const newPackages = [...prevData.packages];
      const newItemIndex = newPackages[pkgIndex].items.length;
      newPackages[pkgIndex].items.push({
        name: "",
        description: "",
        weight: "",
        quantity: 1,
      });
      // Expand the newly added item
      setExpandedItems((prev) => [...prev, `${pkgIndex}-${newItemIndex}`]);

      return { ...prevData, packages: newPackages };
    });
  };

  const handleAccordionChange = (index) => {
    setExpandedItems((prev) => {
      if (prev.includes(index)) {
        return prev.filter((item) => item !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleInsuranceSelection = (insurance) => {
    setSelectedInsurance(insurance);
    // update the form data
    setFormData((prevData) => ({
      ...prevData,
      insurance: true,
      insurance_id: insurance.insurance_id,
    }));
  };

  const removeItem = (pkgIndex, itemIndex) => {
    // clear any errors for the removed item
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[`${pkgIndex}-${itemIndex}-name`];
      delete newErrors[`${pkgIndex}-${itemIndex}-description`];
      delete newErrors[`${pkgIndex}-${itemIndex}-weight`];
      delete newErrors[`${pkgIndex}-${itemIndex}-quantity`];
      delete newErrors[`${pkgIndex}-${itemIndex}-value`];
      return newErrors;
    });
    setFormData((prevData) => {
      const newPackages = [...prevData.packages];
      newPackages[pkgIndex].items.splice(itemIndex, 1);
      return { ...prevData, packages: newPackages };
    });
  };

  const addAdditionalCharge = () => {
    setFormData((prevData) => ({
      ...prevData,
      additionalCharges: [
        ...prevData.additionalCharges,
        { description: "", amount: "" },
      ],
    }));
  };

  const handleAdditionalChargeChange = (index, field, value) => {
    const newCharges = [...formData.additionalCharges];
    newCharges[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      additionalCharges: newCharges,
    }));
  };

  const removeAdditionalCharge = (index) => {
    const newCharges = [...formData.additionalCharges];
    newCharges.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      additionalCharges: newCharges,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("No access token found");
      }

      const orderData = {
        business_id: businessId,
        sender: formData.sender,
        recipient: formData.recipient,
        shipping_tier_id: selectedTier.tier_id,
        insurance_id: selectedInsurance ? selectedInsurance.insurance_id : null,
        packages: formData.packages,
        signature: formData.signature,
        saturdayDelivery: formData.saturdayDelivery,
        residentialDelivery: formData.residentialDelivery,
        specialInstructions: formData.specialInstructions,
        additionalCharges: formData.additionalCharges,
      };

      const response = await api.post("/shipping-orders/create", orderData, {});

      // clear the saved incomplete orders for the order that was just submitted
      const savedOrders = JSON.parse(
        localStorage.getItem("incompleteOrders") || "[]"
      );
      const updatedOrders = savedOrders.filter(
        (order) => order.shipping_order_id !== tempFormId
      );
      localStorage.setItem("incompleteOrders", JSON.stringify(updatedOrders));
      const newOrderId = response.data.shipping_order_id;
      setSubmittedOrderId(newOrderId);
      // replace the formdata with the response data
      console.log("Order created successfully:", response);
      // update the form data with the response data
      setFormData(response.data);
      console.log("Form data after submission:", formData);
      setActiveStep(steps.length - 1); // Move to the last step (Order Complete)
      setAlertMessage({
        type: "success",
        message: "Order created successfully!",
      });
      onOrderCreate(response.data);
      // Call onOrderCreate to update state in parent component
    } catch (err) {
      setAlertMessage({
        type: "error",
        message: "Failed to create order. Please try again.",
      });
      console.error("Error creating order:", err);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleNewOrder = () => {
    clearFormAfterSubmission();
    setActiveStep(0);
    setSubmittedOrderId(null);
  };

  const handleViewOrderDetails = (orderId) => {
    // Get the current path
    const currentPath = location.pathname;

    // Remove trailing slash if it exists
    const basePath = currentPath.endsWith("/")
      ? currentPath.slice(0, -1)
      : currentPath;

    // Navigate to the order details page
    navigate(`${basePath}/${orderId}`);
  };

  const handleSendEmailReceipt = async (orderId) => {
    try {
      // get the email address of the sender
      const senderEmail = formData.sender.email;

      await api.post(`/shipping-orders/send-receipt/${orderId}`, {
        senderEmail,
      });
      setAlertMessage({
        type: "success",
        message: "Receipt sent successfully!",
      });
    } catch (error) {
      console.error("Error sending email receipt:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to send email receipt. Please try again.",
      });
    }
  };

  const renderOrderComplete = () => {
    console.log("Rendering order complete...");
    console.log("Submitted order ID:", submittedOrderId);
    console.log("Form data:", formData);
    return (
      <Grid
        container
        spacing={3}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Order Successfully Created!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Order ID: {submittedOrderId}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => handleViewOrderDetails(submittedOrderId)}
          >
            View Order Details
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ReactToPrint
            trigger={() => <Button variant="outlined">Print Receipt</Button>}
            content={() => printComponentRef.current}
          />
          <div style={{ display: "none" }}>
            <PrintComponent
              ref={printComponentRef}
              order={formData}
              printOptions={{
                orderLabel: { checked: false, size: "4x6" },
                trackingQR: { checked: false, size: "4x6" },
                packageLabels: { checked: false, size: "4x6" },
                packageQRs: { checked: false, size: "4x6" },
                itemQRs: { checked: false, size: "4x6" },
                receipt: { checked: true, size: "A4" },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => handleSendEmailReceipt(submittedOrderId)}
          >
            Send Email Receipt
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => handleNewOrder()}>
            Create New Order
          </Button>
        </Grid>
      </Grid>
    );
  };

  const saveIncompleteOrder = () => {
    const orderToSave = {
      ...formData,
      shipping_order_id: tempFormId || `draft-${Date.now()}`,
      order_status: "draft",
      updatedAt: new Date().toISOString(),
      activeStep,
      selectedTier,
      selectedInsurance,
    };

    // Retrieve existing incomplete orders from local storage
    const existingOrders = JSON.parse(
      localStorage.getItem("incompleteOrders") || "[]"
    );

    if (tempFormId) {
      // Update existing order
      const updatedOrders = existingOrders.map((order) =>
        order.shipping_order_id === tempFormId ? orderToSave : order
      );
      localStorage.setItem("incompleteOrders", JSON.stringify(updatedOrders));
    } else {
      // Add new order
      existingOrders.push(orderToSave);
      localStorage.setItem("incompleteOrders", JSON.stringify(existingOrders));
      setTempFormId(orderToSave.shipping_order_id);
    }

    // Call onOrderCreate to update state in parent component
    onOrderCreate(orderToSave);

    setAlertMessage({
      type: "success",
      message: tempFormId
        ? "Order updated successfully."
        : "Order saved successfully.",
    });
  };

  // reset form data
  const resetForm = () => {
    // clear the selection for sender and recipient
    setFormData({
      sender: {
        customer_id: "",
        business_id: "", // You might want to set this from props or context
        user_id: null, // This might be set later if applicable
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        notes: "",
        customer_type: null,
        status: "active",
      },
      recipient: {
        customer_id: "",
        business_id: "", // You might want to set this from props or context
        user_id: null, // This might be set later if applicable
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        notes: "",
        customer_type: null,
        status: "active",
      },
      shipping_tier_id: "",
      dimensions: { length: "", width: "", height: "" },
      weight: "",
      declaredValue: "",
      specialInstructions: "",
      additionalCharges: [],
      insurance: false,
      insurance_id: "",
      saturdayDelivery: false,
      residentialDelivery: true,
      packages: [
        {
          // Initialize with one empty package
          items: [],
          dimensions: { length: "", width: "", height: "" },
          weight: "",
          declaredValue: "",
          temperatureControlled: false,
          minTemperature: 0,
          maxTemperature: 0,
          fragile: false,
          hazardous: false,
        },
      ],
      signature: "no_signature",
    });
    setErrors({});
    setTotalPrice(0);
    setTierError("");
    setSelectedTier(null);
    setSelectedInsurance(null);
    setActiveStep(0);
    setTempFormId(null);
    setAlertMessage({
      type: "info",
      message: "Form has been reset.",
    });
  };

  // empty form but not active step
  const clearFormAfterSubmission = () => {
    setFormData({
      sender: {
        customer_id: "",
        business_id: "", // You might want to set this from props or context
        user_id: null, // This might be set later if applicable
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        notes: "",
        customer_type: null,
        status: "active",
      },
      recipient: {
        customer_id: "",
        business_id: "", // You might want to set this from props or context
        user_id: null, // This might be set later if applicable
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        notes: "",
        customer_type: null,
        status: "active",
      },
      shipping_tier_id: "",
      dimensions: { length: "", width: "", height: "" },
      weight: "",
      declaredValue: "",
      specialInstructions: "",
      additionalCharges: [],
      insurance: false,
      insurance_id: "",
      saturdayDelivery: false,
      residentialDelivery: true,
      packages: [
        {
          // Initialize with one empty package
          items: [],
          dimensions: { length: "", width: "", height: "" },
          weight: "",
          declaredValue: "",
          temperatureControlled: false,
          minTemperature: 0,
          maxTemperature: 0,
          fragile: false,
          hazardous: false,
        },
      ],
      signature: "no_signature",
    });
    setErrors({});
    setTotalPrice(0);
    setTierError("");
    setSelectedTier(null);
    setSelectedInsurance(null);
    setTempFormId(null);
  };

  const validateCustomerInfo = () => {
    const newErrors = {};
    ["sender", "recipient"].forEach((type) => {
      if (!formData[type].first_name)
        newErrors[`${type}FirstName`] = "First name is required";
      if (!formData[type].last_name)
        newErrors[`${type}LastName`] = "Last name is required";
      if (formData[type].email && !isEmail(formData[type].email)) {
        newErrors[`${type}Email`] = "Invalid email format";
      }
      if (!formData[type].phone) {
        newErrors[`${type}Phone`] = "Phone is required";
      } else if (!isValidPhoneNumber(formData[type].phone)) {
        newErrors[`${type}Phone`] = "Invalid phone number";
      }
      if (!formData[type].address_line1)
        newErrors[`${type}AddressLine1`] = "Street address is required";
      if (!formData[type].city) newErrors[`${type}City`] = "City is required";
      if (!formData[type].state)
        newErrors[`${type}State`] = "State is required";
      if (!formData[type].postal_code)
        newErrors[`${type}PostalCode`] = "Postal code is required";
      if (!formData[type].country)
        newErrors[`${type}Country`] = "Country is required";
    });

    // Check if sender and recipient have the same email
    if (
      formData.sender.email === formData.recipient.email &&
      formData.sender.email !== "" &&
      formData.sender.customer_id !== formData.recipient.customer_id
    ) {
      newErrors.sameEmail =
        "Sender and recipient cannot have the same email unless they are the same customer";
    }

    // Check if sender and recipient have the same phone number
    if (
      formData.sender.phone === formData.recipient.phone &&
      formData.sender.phone !== "" &&
      formData.sender.customer_id !== formData.recipient.customer_id
    ) {
      newErrors.samePhone =
        "Sender and recipient cannot have the same phone number unless they are the same customer";
    }

    setCustomerErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const removePackage = (pkgIndex) => {
    if (formData.packages.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        packages: prevData.packages.filter((_, index) => index !== pkgIndex),
      }));
    }
  };

  const handleTemperatureChange = (pkgIndex, field, value) => {
    const numValue = parseFloat(value);
    setFormData((prevData) => {
      const newPackages = [...prevData.packages];
      const pkg = newPackages[pkgIndex];

      if (field === "minTemperature") {
        pkg.minTemperature = numValue;
        if (pkg.maxTemperature < numValue) {
          pkg.maxTemperature = numValue;
        }
      } else if (field === "maxTemperature") {
        pkg.maxTemperature = numValue;
        if (pkg.minTemperature > numValue) {
          pkg.minTemperature = numValue;
        }
      }

      return { ...prevData, packages: newPackages };
    });
  };

  const validatePackageDetails = () => {
    let isValid = true;
    const newErrors = {};

    formData.packages.forEach((pkg, pkgIndex) => {
      // if (pkg.items.length === 0) {
      //   newErrors[`package${pkgIndex}Items`] = "At least one item is required";
      //   isValid = false;
      // }
      // ["length", "width", "height"].forEach((dim) => {
      //   if (!pkg.dimensions[dim]) {
      //     newErrors[
      //       `package${pkgIndex}${dim.charAt(0).toUpperCase() + dim.slice(1)}`
      //     ] = `${dim.charAt(0).toUpperCase() + dim.slice(1)} is required`;
      //     isValid = false;
      //   }
      // });

      if (!pkg.weight) {
        newErrors[`package${pkgIndex}Weight`] = "Weight is required";
        isValid = false;
      }

      if (!pkg.declaredValue) {
        newErrors[`package${pkgIndex}DeclaredValue`] =
          "Declared value is required";
        isValid = false;
      }

      pkg.items.forEach((item, itemIndex) => {
        ["name", "description", "quantity"].forEach((field) => {
          if (!item[field] && item[field] !== 0) {
            newErrors[`${pkgIndex}-${itemIndex}-${field}`] = `${
              field.charAt(0).toUpperCase() + field.slice(1)
            } is required`;
            isValid = false;
          }
        });
      });
      console.log("Package errors:", newErrors);
      console.log("Package isValid:", isValid);
    });

    setErrors((prev) => ({ ...prev, ...newErrors }));
    return isValid;
  };

  const validateField = (value, fieldName, pkgIndex, itemIndex = null) => {
    const errorKey =
      itemIndex !== null
        ? `${pkgIndex}-${itemIndex}-${fieldName}`
        : `package${pkgIndex}${fieldName}`;

    if (!value && value !== 0) {
      setErrors((prev) => ({
        ...prev,
        [errorKey]: `${
          fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
        } is required`,
      }));
      return false;
    }
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[errorKey];
      return newErrors;
    });
    return true;
  };

  const validateShippingTier = () => {
    if (!formData.shipping_tier_id) {
      setShippingTierErrors("Please select a shipping tier");
      return false;
    }
    setShippingTierErrors("");
    return true;
  };

  const validateInsurance = () => {
    // Insurance is optional, so we don't need to check if it's selected
    if (formData.insurance) {
      const selectedInsurance = insuranceOptions.find(
        (option) => option.insurance_id === formData.insurance_id
      );
      if (!selectedInsurance) {
        setInsuranceErrors("Invalid insurance selection");
        return false;
      }
      // Additional validation can be added here if needed
    }
    setInsuranceErrors("");
    return true;
  };

  const clearErrorsForStep = (step) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      Object.keys(newErrors).forEach((key) => {
        if (step === 1 && (key.startsWith("package") || key.includes("-"))) {
          delete newErrors[key];
        }
        // Add similar conditions for other steps if needed
      });
      return newErrors;
    });

    if (step === 0) {
      setCustomerErrors("");
    } else if (step === 1) {
      setErrors("");
    } else if (step === 2) {
      setShippingTierErrors("");
    } else if (step === 3) {
      setInsuranceErrors("");
    }
  };

  const validateAdditionalInfo = () => {
    const newErrors = {};
    // Add any additional validations for the "Additional Info" step
    return newErrors;
  };

  const handleNext = () => {
    let isValid = false;
    switch (activeStep) {
      case 0:
        isValid = validateCustomerInfo();
        break;
      case 1:
        isValid = validatePackageDetails();
        break;
      case 2:
        isValid = validateShippingTier();
        break;
      case 3:
        isValid = validateInsurance();
        break;
      case 4:
        isValid = validateAdditionalInfo();
        break;
      case 5: // Review step
        handleSubmit(new Event("submit"));
        return; // Don't proceed to next step automatically
      default:
        isValid = true;
        break;
    }

    if (isValid) {
      clearErrorsForStep(activeStep);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return renderCustomerInfo();
      case 1:
        return renderPackageDetails();
      case 2:
        return renderShippingTierSelection();
      case 3:
        return renderInsuranceSelection();
      case 4:
        return renderAdditionalInfo();
      case 5:
        return renderReview();
      case 6:
        return renderOrderComplete();
      default:
        return null;
    }
  };

  // Add these helper components for step information
  const StepInfo = ({ children, icon }) => (
    <Alert
      severity="info"
      icon={icon}
      sx={{
        marginBottom: 2,
        "& .MuiAlert-icon": {
          fontSize: "1.5rem",
        },
      }}
    >
      {children}
    </Alert>
  );

  // Update renderCustomerInfo
  const renderCustomerInfo = () => {
    const isEmailInUse = (email, type) => {
      return existingCustomers.some(
        (customer) =>
          customer.email === email &&
          customer.customer_id !== formData[type].customer_id &&
          (formData.sender.email !== email || type === "sender") &&
          (formData.recipient.email !== email || type === "recipient")
      );
    };

    const isPhoneInUse = (phone, type) => {
      return existingCustomers.some(
        (customer) =>
          customer.phone === phone &&
          customer.customer_id !== formData[type].customer_id &&
          (formData.sender.phone !== phone || type === "sender") &&
          (formData.recipient.phone !== phone || type === "recipient")
      );
    };

    const isSameCustomer = () => {
      return (
        formData.sender.customer_id &&
        formData.recipient.customer_id &&
        formData.sender.customer_id === formData.recipient.customer_id
      );
    };
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StepInfo icon={<BusinessIcon />}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Step 1: Enter Customer Information</strong>
            </Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              <li>
                Search for existing customers or enter new customer details
              </li>
              <li>Email is optional but helpful for order notifications</li>
              <li>Required fields are marked with an asterisk (*)</li>
              <li>Phone numbers are in international format</li>
            </ul>
          </StepInfo>
        </Grid>
        {["sender", "recipient"].map((type) => (
          <Grid item xs={12} md={6} key={type}>
            <Typography
              variant="h6"
              style={{ textTransform: "capitalize", marginBottom: "10px" }}
            >
              {type}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={existingCustomers}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  value={formData[type].customer_id ? formData[type] : null}
                  onChange={(event, newValue) =>
                    handleCustomerChange(type, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select or enter new ${type}`}
                      required
                      error={!!customerErrors[`${type}Select`]}
                      helperText={customerErrors[`${type}Select`]}
                    />
                  )}
                  freeSolo
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {formData[type].customer_id
                    ? "Existing Customer"
                    : "New Customer"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  value={formData[type].first_name}
                  onChange={(e) =>
                    handleCustomerDetailChange(
                      type,
                      "first_name",
                      e.target.value
                    )
                  }
                  error={!!customerErrors[`${type}FirstName`]}
                  helperText={customerErrors[`${type}FirstName`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  value={formData[type].last_name}
                  onChange={(e) =>
                    handleCustomerDetailChange(
                      type,
                      "last_name",
                      e.target.value
                    )
                  }
                  error={!!customerErrors[`${type}LastName`]}
                  helperText={customerErrors[`${type}LastName`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  type="email"
                  value={formData[type].email}
                  onChange={(e) =>
                    handleCustomerDetailChange(type, "email", e.target.value)
                  }
                  error={
                    !!customerErrors[`${type}Email`] ||
                    isEmailInUse(formData[type].email, type)
                  }
                  helperText={
                    customerErrors[`${type}Email`] ||
                    (isEmailInUse(formData[type].email, type) &&
                      "This email is already in use")
                  }
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiTelInput
                  fullWidth
                  required
                  label="Phone"
                  value={formData[type].phone}
                  onChange={(value) =>
                    handleCustomerDetailChange(type, "phone", value)
                  }
                  error={
                    !!customerErrors[`${type}Phone`] ||
                    isPhoneInUse(formData[type].phone, type)
                  }
                  helperText={
                    customerErrors[`${type}Phone`] ||
                    (isPhoneInUse(formData[type].phone, type) &&
                      "This phone number is already in use")
                  }
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Address Line 1"
                  value={formData[type].address_line1}
                  onChange={(e) =>
                    handleCustomerDetailChange(
                      type,
                      "address_line1",
                      e.target.value
                    )
                  }
                  error={!!customerErrors[`${type}AddressLine1`]}
                  helperText={customerErrors[`${type}AddressLine1`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 2"
                  value={formData[type].address_line2}
                  onChange={(e) =>
                    handleCustomerDetailChange(
                      type,
                      "address_line2",
                      e.target.value
                    )
                  }
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  value={formData[type].city}
                  onChange={(e) =>
                    handleCustomerDetailChange(type, "city", e.target.value)
                  }
                  error={!!customerErrors[`${type}City`]}
                  helperText={customerErrors[`${type}City`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="State/Province"
                  value={formData[type].state}
                  onChange={(e) =>
                    handleCustomerDetailChange(type, "state", e.target.value)
                  }
                  error={!!customerErrors[`${type}State`]}
                  helperText={customerErrors[`${type}State`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Postal Code"
                  value={formData[type].postal_code}
                  onChange={(e) =>
                    handleCustomerDetailChange(
                      type,
                      "postal_code",
                      e.target.value
                    )
                  }
                  error={!!customerErrors[`${type}PostalCode`]}
                  helperText={customerErrors[`${type}PostalCode`]}
                  disabled={!!formData[type].customer_id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel id={`${type}-country-label`}>Country</InputLabel>
                  <Select
                    labelId={`${type}-country-label`}
                    id={`${type}-country`}
                    value={formData[type].country || ""}
                    onChange={(e) =>
                      handleCustomerDetailChange(
                        type,
                        "country",
                        e.target.value
                      )
                    }
                    error={!!customerErrors[`${type}Country`]}
                    label="Country"
                    disabled={!!formData[type].customer_id}
                  >
                    <MenuItem value="">Select a country</MenuItem>
                    <MenuItem value="United States">United States</MenuItem>
                    <MenuItem value="Cuba">Cuba</MenuItem>
                  </Select>
                  {customerErrors[`${type}Country`] && (
                    <FormHelperText error>
                      {customerErrors[`${type}Country`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {isSameCustomer() && (
          <Grid item xs={12}>
            <Alert severity="error">
              Sender and recipient cannot be the same customer
            </Alert>
          </Grid>
        )}
        {customerErrors.sameEmail && (
          <Grid item xs={12}>
            <Alert severity="error">{customerErrors.sameEmail}</Alert>
          </Grid>
        )}
        {customerErrors.samePhone && (
          <Grid item xs={12}>
            <Alert severity="error">{customerErrors.samePhone}</Alert>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPackageDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StepInfo icon={<LocalShipping />}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Step 2: Package Details</strong>
            </Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              <li>Add items using UPC codes or manual entry</li>
              <li>Specify package dimensions and weight carefully</li>
              <li>Mark special handling requirements (fragile, hazardous)</li>
              <li>Set temperature requirements if needed</li>
              <li>Multiple packages can be added to a single order</li>
            </ul>
          </StepInfo>
        </Grid>
        {formData.packages.map((pkg, pkgIndex) => (
          <Grid item xs={12} key={pkgIndex}>
            <Typography variant="h6">Package {pkgIndex + 1}</Typography>
            {formData.packages.length > 1 && (
              <IconButton onClick={() => removePackage(pkgIndex)} color="error">
                <DeleteIcon />
              </IconButton>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Items</Typography>
              </Grid>
              <Grid item xs={12}>
                {pkg.items.map((item, itemIndex) => (
                  <StyledAccordion
                    key={itemIndex}
                    expanded={expandedItems.includes(
                      `${pkgIndex}-${itemIndex}`
                    )}
                    onChange={() =>
                      handleAccordionChange(`${pkgIndex}-${itemIndex}`)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${pkgIndex}-${itemIndex}-content`}
                      id={`panel${pkgIndex}-${itemIndex}-header`}
                    >
                      <Typography>
                        {item.name || `Item ${itemIndex + 1}`}
                      </Typography>
                      {(errors[`${pkgIndex}-${itemIndex}-name`] ||
                        errors[`${pkgIndex}-${itemIndex}-description`] ||
                        errors[`${pkgIndex}-${itemIndex}-quantity`]) && (
                        <ErrorIcon
                          color="error"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {item.image_data && (
                          <Grid item xs={12} sm={3}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={`data:image/jpeg;base64,${item.image_data}`}
                              alt={item.name}
                              sx={{ objectFit: "contain", mb: 2 }}
                            />
                            <IconButton
                              onClick={() => handleReportDialogOpen(item)}
                              color="warning"
                            >
                              <ReportProblemIcon />
                            </IconButton>
                            {reportSubmitted && (
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                Report submitted
                              </Typography>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            label="Name"
                            value={item.name}
                            onChange={(e) =>
                              handleItemChange(
                                pkgIndex,
                                itemIndex,
                                "name",
                                e.target.value
                              )
                            }
                            required
                            error={!!errors[`${pkgIndex}-${itemIndex}-name`]}
                            helperText={errors[`${pkgIndex}-${itemIndex}-name`]}
                            InputProps={{
                              readOnly: !!item.item_id,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            label="Description"
                            value={item.description}
                            onChange={(e) =>
                              handleItemChange(
                                pkgIndex,
                                itemIndex,
                                "description",
                                e.target.value
                              )
                            }
                            multiline
                            rows={2}
                            required
                            error={
                              !!errors[`${pkgIndex}-${itemIndex}-description`]
                            }
                            helperText={
                              errors[`${pkgIndex}-${itemIndex}-description`]
                            }
                            InputProps={{
                              readOnly: !!item.item_id,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            fullWidth
                            label="Quantity"
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              handleItemChange(
                                pkgIndex,
                                itemIndex,
                                "quantity",
                                e.target.value
                              )
                            }
                            required
                            error={
                              !!errors[`${pkgIndex}-${itemIndex}-quantity`]
                            }
                            helperText={
                              errors[`${pkgIndex}-${itemIndex}-quantity`]
                            }
                            inputProps={{ min: 1 }}
                            onKeyDown={(e) => {
                              if (["-", "+", "e"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          {/* <TextField
                            fullWidth
                            label="Value"
                            type="number"
                            value={item.value}
                            onChange={(e) =>
                              handleItemChange(
                                pkgIndex,
                                itemIndex,
                                "value",
                                e.target.value
                              )
                            }
                            required
                            error={
                              !!errors[
                                `package${pkgIndex}Item${itemIndex}Value`
                              ]
                            }
                            onKeyDown={(e) => {
                              if (["-", "+", "e"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            inputProps={{ min: 0, step: "0.01" }}
                            helperText={
                              errors[`package${pkgIndex}Item${itemIndex}Value`]
                            }
                          /> */}
                        </Grid>
                        {item.upc && (
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              label="UPC"
                              value={item.upc}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        )}
                        {item.brand && (
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              label="Brand"
                              value={item.brand}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        )}
                        {item.category && (
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              label="Category"
                              value={item.category}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={1}>
                          <IconButton
                            onClick={() => removeItem(pkgIndex, itemIndex)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                ))}
              </Grid>
              <Grid item xs={12}>
                {upcErrors[pkgIndex] && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setUpcErrors((prevErrors) => ({
                        ...prevErrors,
                        [pkgIndex]: null,
                      }));
                    }}
                  >
                    {upcErrors[pkgIndex]}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  label="UPC Code"
                  value={pkg.upcCode || ""}
                  helperText="Enter a UPC code to fetch product details"
                  onChange={(e) =>
                    handlePackageChange(pkgIndex, "upcCode", e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={() => addUPCItem(pkgIndex)}
                          variant="contained"
                          color="primary"
                          disabled={!pkg.upcCode}
                        >
                          Add UPC Item
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  onClick={() => addManualItem(pkgIndex)}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{ height: "70%" }}
                >
                  Add Manual Item
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "20px 0" }} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Package Details</Typography>
              </Grid>
              {/* Select from package types */}
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="package-type-label">Package Type</InputLabel>
                  <Select
                    sx={{ width: "50%" }}
                    labelId="package-type-label"
                    value={pkg.packaging_type}
                    onChange={(e) =>
                      handlePackageChange(
                        pkgIndex,
                        "packaging_type",
                        e.target.value
                      )
                    }
                    required
                  >
                    {packagingTypes.map((type) => {
                      const IconComponent = type.Icon;
                      return (
                        <MenuItem key={type.value} value={type.value}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <IconComponent size={20} />
                            {type.label}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Length (in)"
                  type="number"
                  value={pkg.dimensions.length}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                  onChange={(e) =>
                    handlePackageChange(pkgIndex, "dimensions", {
                      ...pkg.dimensions,
                      length: e.target.value,
                    })
                  }
                  required
                  error={!!errors[`package${pkgIndex}Length`]}
                  helperText={errors[`package${pkgIndex}Length`]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Width (in)"
                  type="number"
                  value={pkg.dimensions.width}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                  onChange={(e) =>
                    handlePackageChange(pkgIndex, "dimensions", {
                      ...pkg.dimensions,
                      width: e.target.value,
                    })
                  }
                  required
                  error={!!errors[`package${pkgIndex}Width`]}
                  helperText={errors[`package${pkgIndex}Width`]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Height (in)"
                  type="number"
                  value={pkg.dimensions.height}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                  onChange={(e) =>
                    handlePackageChange(pkgIndex, "dimensions", {
                      ...pkg.dimensions,
                      height: e.target.value,
                    })
                  }
                  required
                  error={!!errors[`package${pkgIndex}Height`]}
                  helperText={errors[`package${pkgIndex}Height`]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Total Weight (lbs)"
                  type="number"
                  // Handle both empty string and numeric values
                  value={pkg.weight}
                  onChange={(e) =>
                    handlePackageChange(pkgIndex, "weight", e.target.value)
                  }
                  required
                  error={!!errors[`package${pkgIndex}Weight`]}
                  helperText={errors[`package${pkgIndex}Weight`]}
                  inputProps={{
                    min: 0,
                    step: "0.01",
                  }}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Declared Value"
                  type="number"
                  value={pkg.declaredValue}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                  onChange={(e) =>
                    handlePackageChange(
                      pkgIndex,
                      "declaredValue",
                      e.target.value
                    )
                  }
                  required
                  error={!!errors[`package${pkgIndex}DeclaredValue`]}
                  helperText={errors[`package${pkgIndex}DeclaredValue`]}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Additional Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={pkg.fragile}
                      onChange={(e) =>
                        handlePackageChange(
                          pkgIndex,
                          "fragile",
                          e.target.checked
                        )
                      }
                      name="fragile"
                    />
                  }
                  label="Fragile"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={pkg.hazardous}
                      onChange={(e) =>
                        handlePackageChange(
                          pkgIndex,
                          "hazardous",
                          e.target.checked
                        )
                      }
                      name="hazardous"
                    />
                  }
                  label="Hazardous"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={pkg.temperatureControlled}
                      value={pkg.temperatureControlled}
                      onChange={(e) =>
                        handlePackageChange(
                          pkgIndex,
                          "temperatureControlled",
                          e.target.checked
                        )
                      }
                      name="temperatureControlled"
                    />
                  }
                  label="Temperature Controlled"
                />
              </Grid>
              {pkg.temperatureControlled && (
                <Grid item xs={12} sm={6} container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Min Temperature (°C)"
                      type="number"
                      value={pkg.minTemperature}
                      onChange={(e) =>
                        handleTemperatureChange(
                          pkgIndex,
                          "minTemperature",
                          e.target.value
                        )
                      }
                      InputProps={{ inputProps: { min: -273, step: 0.1 } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Max Temperature (°C)"
                      type="number"
                      value={pkg.maxTemperature}
                      onChange={(e) =>
                        handleTemperatureChange(
                          pkgIndex,
                          "maxTemperature",
                          e.target.value
                        )
                      }
                      InputProps={{ inputProps: { min: -273, step: 0.1 } }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button onClick={addPackage} variant="outlined">
            Add Another Package
          </Button>
        </Grid>
        {Object.keys(errors).length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              Please correct the errors in the package and item details before
              proceeding.
            </Alert>
          </Grid>
        )}
        <Dialog
          open={openReportDialog}
          onClose={() => handleReportDialogClose()}
        >
          <DialogTitle>Report Incorrect Image</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to report this image as incorrect or
              missing?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleReportDialogClose()} color="primary">
              Cancel
            </Button>
            <Button onClick={handleReportImage} color="primary">
              Submit Report
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  const renderShippingTierSelection = () => {
    const servicesPath = location.pathname.replace(/\/[^/]+$/, "/services");

    try {
      // Helper function to safely render values
      const safeRender = (value) => {
        if (value === null || value === undefined) return "";
        if (typeof value === "object") return JSON.stringify(value);
        return value;
      };

      // Helper function to check package compatibility
      const getPackageCompatibilityIssues = (tier) => {
        try {
          const issues = [];

          // Check weight constraints
          const totalWeight =
            formData?.packages?.reduce((sum, pkg) => {
              try {
                return sum + (parseFloat(pkg?.weight) || 0);
              } catch (error) {
                console.error("Error parsing package weight:", error);
                return sum;
              }
            }, 0) || 0;

          if (tier?.min_weight && totalWeight < tier.min_weight) {
            issues.push(
              `Total weight (${totalWeight.toFixed(
                2
              )}g) is below minimum requirement (${tier.min_weight.toFixed(
                2
              )}g)`
            );
          }
          if (tier?.max_weight && totalWeight > tier.max_weight) {
            issues.push(
              `Total weight (${totalWeight.toFixed(
                2
              )}g) exceeds maximum limit (${tier.max_weight.toFixed(2)}g)`
            );
          }

          // Check dimension constraints for each package
          formData?.packages?.forEach((pkg, index) => {
            try {
              if (tier?.max_dimensions) {
                const length = parseFloat(pkg?.dimensions?.length) || 0;
                const width = parseFloat(pkg?.dimensions?.width) || 0;
                const height = parseFloat(pkg?.dimensions?.height) || 0;

                if (length > tier.max_dimensions.length) {
                  issues.push(
                    `Package ${
                      index + 1
                    } length (${length}in) exceeds maximum (${
                      tier.max_dimensions.length
                    }in)`
                  );
                }
                if (width > tier.max_dimensions.width) {
                  issues.push(
                    `Package ${index + 1} width (${width}in) exceeds maximum (${
                      tier.max_dimensions.width
                    }in)`
                  );
                }
                if (height > tier.max_dimensions.height) {
                  issues.push(
                    `Package ${
                      index + 1
                    } height (${height}in) exceeds maximum (${
                      tier.max_dimensions.height
                    }in)`
                  );
                }
              }
            } catch (error) {
              console.error(
                `Error checking dimensions for package ${index + 1}:`,
                error
              );
            }
          });

          // Check temperature control requirements
          const needsTemperatureControl = formData?.packages?.some(
            (pkg) => pkg?.temperatureControlled
          );

          if (needsTemperatureControl && !tier?.temperature_controlled) {
            issues.push(
              "Temperature controlled shipping required but not available in this tier"
            );
          }

          if (needsTemperatureControl && tier?.temperature_controlled) {
            formData?.packages?.forEach((pkg, index) => {
              try {
                if (pkg?.temperatureControlled) {
                  if (pkg?.minTemperature < tier.temperature_range?.min) {
                    issues.push(
                      `Package ${index + 1} minimum temperature (${
                        pkg.minTemperature
                      }°C) is below supported range (${
                        tier.temperature_range.min
                      }°C)`
                    );
                  }
                  if (pkg?.maxTemperature > tier.temperature_range?.max) {
                    issues.push(
                      `Package ${index + 1} maximum temperature (${
                        pkg.maxTemperature
                      }°C) exceeds supported range (${
                        tier.temperature_range.max
                      }°C)`
                    );
                  }
                }
              } catch (error) {
                console.error(
                  `Error checking temperature for package ${index + 1}:`,
                  error
                );
              }
            });
          }

          // Check hazardous materials
          const hasHazardousMaterials = formData?.packages?.some(
            (pkg) => pkg?.hazardous
          );
          if (hasHazardousMaterials && !tier?.hazardous_materials_handling) {
            issues.push(
              "Hazardous materials present but not supported by this tier"
            );
          }

          // Check fragile items support
          const hasFragileItems = formData?.packages?.some(
            (pkg) => pkg?.fragile
          );
          if (hasFragileItems && !tier?.white_glove_services) {
            issues.push(
              "Fragile items present but special handling not available in this tier"
            );
          }

          return issues;
        } catch (error) {
          console.error("Error in getPackageCompatibilityIssues:", error);
          return ["Error checking package compatibility"];
        }
      };

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StepInfo icon={<FlightTakeoff />}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Step 3: Select Shipping Method</strong>
              </Typography>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                <li>Compare different shipping options and prices</li>
                <li>Check estimated delivery times for each option</li>
                <li>
                  Only compatible shipping methods for your package will be
                  shown
                </li>
                <li>
                  Prices are calculated based on weight, dimensions, and
                  distance
                </li>
              </ul>
            </StepInfo>

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <IconButton
                onClick={handleRefresh}
                color="primary"
                aria-label="refresh shipping tiers"
              >
                <Refresh />
              </IconButton>
              <Typography variant="body2" color="textSecondary">
                Refresh available shipping options
              </Typography>
            </Box>

            {shippingTierErrors && shippingTierErrors.length > 0 && (
              <Alert
                severity="error"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                {shippingTierErrors}
              </Alert>
            )}
          </Grid>

          {Array.isArray(filteredShippingTiers) &&
          filteredShippingTiers.length > 0 ? (
            filteredShippingTiers.map((tier) => {
              if (!tier) return null;

              try {
                const compatibilityIssues = getPackageCompatibilityIssues(tier);
                const isCompatible = compatibilityIssues.length === 0;

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={tier.tier_id || "unknown"}
                  >
                    <Card sx={{ opacity: isCompatible ? 1 : 0.7 }}>
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Typography variant="h6">
                            {safeRender(tier.tier_name) || "Unnamed Tier"}
                          </Typography>
                          <Box display="flex" gap={1}>
                            <Chip
                              icon={
                                isCompatible ? (
                                  <CheckCircleOutline />
                                ) : (
                                  <ErrorOutline />
                                )
                              }
                              label={
                                isCompatible ? "Compatible" : "Incompatible"
                              }
                              color={isCompatible ? "success" : "warning"}
                              size="small"
                            />
                            <Chip
                              label={tier.is_active ? "Active" : "Inactive"}
                              color={tier.is_active ? "success" : "default"}
                              size="small"
                            />
                          </Box>
                        </Box>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          paragraph
                        >
                          {safeRender(tier.description) ||
                            "No description available"}
                        </Typography>

                        {!isCompatible && (
                          <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Compatibility Issues:
                            </Typography>
                            <List dense>
                              {compatibilityIssues.map((issue, index) => (
                                <ListItem key={index} disableGutters>
                                  <ListItemIcon sx={{ minWidth: 30 }}>
                                    <ErrorOutline
                                      fontSize="small"
                                      color="warning"
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={safeRender(issue)} />
                                </ListItem>
                              ))}
                            </List>
                          </Alert>
                        )}

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center" mb={1}>
                              <LocalShipping fontSize="small" sx={{ mr: 1 }} />
                              <Typography variant="body2">
                                Modes:{" "}
                                {Array.isArray(tier.transportation_modes)
                                  ? tier.transportation_modes.join(", ")
                                  : "Not specified"}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb={1}>
                              <AttachMoney fontSize="small" sx={{ mr: 1 }} />
                              <Typography variant="body2">
                                Estimated Price: $
                                {(
                                  (tierPrices || {})[tier.tier_id] || 0
                                ).toFixed(2)}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb={1}>
                              <Schedule fontSize="small" sx={{ mr: 1 }} />
                              <Typography variant="body2">
                                Estimated Delivery:{" "}
                                {tier.estimated_delivery_time
                                  ? `${tier.estimated_delivery_time.min}-${tier.estimated_delivery_time.max} ${tier.estimated_delivery_time.unit}`
                                  : "Not specified"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2}>
                          <Button
                            variant={
                              formData?.shipping_tier_id === tier.tier_id
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                shipping_tier_id: tier.tier_id,
                              }));
                              setSelectedTier(tier);
                              setShippingTierErrors("");
                            }}
                            fullWidth
                            disabled={!isCompatible}
                          >
                            {isCompatible ? "Select" : "Incompatible"}
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              } catch (error) {
                console.error(
                  `Error rendering shipping tier ${tier?.tier_id}:`,
                  error
                );
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={tier?.tier_id || "error"}
                  >
                    <Alert severity="error">
                      Error displaying shipping tier information
                    </Alert>
                  </Grid>
                );
              }
            })
          ) : (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Typography variant="subtitle1" gutterBottom>
                  No compatible shipping tiers found
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Please try refreshing the shipping options or contact support
                  if the problem persists.
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      );
    } catch (error) {
      console.error("Error in renderShippingTierSelection:", error);
      return (
        <Alert severity="error">
          <Typography variant="subtitle1">
            An error occurred while loading shipping options
          </Typography>
          <Typography variant="body2">
            Please try refreshing the page or contact support if the problem
            persists.
          </Typography>
        </Alert>
      );
    }
  };

  const renderInsuranceSelection = () => {
    if (!Array.isArray(filteredInsuranceOptions)) {
      console.error(
        "filteredInsuranceOptions is not an array:",
        filteredInsuranceOptions
      );
      return null;
    }

    const formatCurrency = (value) => {
      const number = parseFloat(value);
      return isNaN(number) ? "0.00" : number.toFixed(2);
    };

    const formatPercentage = (value) => {
      const number = parseFloat(value);
      return isNaN(number) ? "0.00" : number.toFixed(2);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <StepInfo icon={<Security />}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Step 4: Insurance Options</strong>
              </Typography>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                <li>
                  Insurance is optional but recommended for valuable items
                </li>
                <li>Coverage amount is based on declared package value</li>
                <li>Premium rates vary based on coverage and package type</li>
                <li>Review deductibles and coverage terms before selecting</li>
              </ul>
            </StepInfo>
          </Grid>
          <IconButton
            onClick={handleRefresh}
            color="primary"
            aria-label="refresh shipping tiers"
          >
            <Refresh />
          </IconButton>
          {insuranceErrors && typeof insuranceErrors === "string" && (
            <Alert
              severity="error"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              {insuranceErrors}
            </Alert>
          )}
        </Grid>
        {filteredInsuranceOptions.length > 0 ? (
          filteredInsuranceOptions.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.insurance_id}>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="h6">
                      {option.name || "Unnamed Option"}
                    </Typography>
                    <Chip
                      label={option.coverage_type || "Unknown"}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {option.description || "No description available"}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2">
                    Max Coverage: ${formatCurrency(option.max_coverage_amount)}
                  </Typography>
                  <Typography variant="body2">
                    Premium Rate: {formatPercentage(option.premium_rate)}%
                  </Typography>
                  <Typography variant="body2">
                    Estimated Premium: $
                    {formatCurrency(insurancePremiums[option.insurance_id])}
                  </Typography>
                  {option.deductible && (
                    <Typography variant="body2">
                      Deductible: ${formatCurrency(option.deductible)}
                    </Typography>
                  )}
                  <Box mt={2}>
                    <Button
                      variant={
                        formData.insurance_id === option.insurance_id
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleInsuranceSelection(option)}
                      fullWidth
                    >
                      Select
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert severity="info">
              No insurance options are available for this shipment. You can
              proceed without insurance or adjust your package details.
            </Alert>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderAdditionalInfo = () => {
    const calculateTotalCost = () => {
      const shippingCost = calculateTierPrice(selectedTier, formData.packages);
      const totalDeclaredValue = formData.packages.reduce(
        (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
        0
      );
      const insuranceCost = formData.insurance
        ? calculateInsurancePremium(selectedInsurance, totalDeclaredValue)
        : 0;
      const additionalChargesTotal = formData.additionalCharges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      );
      return shippingCost + insuranceCost + additionalChargesTotal;
    };

    const handleSwitchChange = (event) => {
      const { name, checked } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepInfo icon={<Weekend />}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Step 5: Additional Services</strong>
            </Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              <li>Choose signature requirements for delivery</li>
              <li>Specify if Saturday delivery is needed</li>
              <li>Add any special handling instructions</li>
              <li>Include additional charges if applicable</li>
            </ul>
          </StepInfo>
        </Grid>

        {/* Cost Breakdown */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Cost Breakdown</Typography>
              <Typography>
                Shipping Cost: $
                {calculateTierPrice(selectedTier, formData.packages).toFixed(2)}
              </Typography>
              {formData.insurance && (
                <Typography>
                  Insurance Cost: $
                  {calculateInsurancePremium(
                    selectedInsurance,
                    formData.packages.reduce(
                      (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
                      0
                    )
                  ).toFixed(2)}
                </Typography>
              )}
              {formData.additionalCharges.map((charge, index) => (
                <Typography key={index}>
                  {charge.description}: $
                  {parseFloat(charge.amount || 0).toFixed(2)}
                </Typography>
              ))}
              <Divider style={{ margin: "10px 0" }} />
              <Typography variant="h6">
                Total Cost: ${calculateTotalCost().toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Existing fields */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Signature Required</FormLabel>
            <RadioGroup
              row
              name="signature"
              value={formData.signature}
              onChange={handleChange}
            >
              <FormControlLabel
                value="no_signature"
                control={<Radio />}
                label="No Signature"
              />
              <FormControlLabel
                value="signature_required"
                control={<Radio />}
                label="Signature Required"
              />
              <FormControlLabel
                value="adult_signature"
                control={<Radio />}
                label="Adult Signature Required"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* New fields */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.saturdayDelivery || false}
                onChange={handleSwitchChange}
                name="saturdayDelivery"
              />
            }
            label="Saturday Delivery"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.residentialDelivery || false}
                onChange={handleSwitchChange}
                name="residentialDelivery"
              />
            }
            label="Residential Delivery"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="specialInstructions"
            label="Special Instructions"
            multiline
            rows={4}
            value={formData.specialInstructions}
            onChange={handleChange}
          />
        </Grid>

        {/* Additional Charges */}
        <Grid item xs={12}>
          <Typography variant="h6">Additional Charges</Typography>
          {formData.additionalCharges.map((charge, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Description"
                  value={charge.description}
                  onChange={(e) =>
                    handleAdditionalChargeChange(
                      index,
                      "description",
                      e.target.value
                    )
                  }
                  sx={{ marginBottom: 1 }}
                  error={!!errors[`additionalCharge${index}Description`]}
                  helperText={errors[`additionalCharge${index}Description`]}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  value={charge.amount}
                  onChange={(e) =>
                    handleAdditionalChargeChange(
                      index,
                      "amount",
                      e.target.value
                    )
                  }
                  sx={{ marginBottom: 1 }}
                  error={!!errors[`additionalCharge${index}Amount`]}
                  helperText={errors[`additionalCharge${index}Amount`]}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => removeAdditionalCharge(index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button onClick={addAdditionalCharge} startIcon={<AddIcon />}>
            Add Additional Charge
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderReview = () => {
    const calculateTotalCost = () => {
      const shippingCost = calculateTierPrice(selectedTier, formData.packages);
      const totalDeclaredValue = formData.packages.reduce(
        (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
        0
      );
      const insuranceCost = formData.insurance
        ? calculateInsurancePremium(selectedInsurance, totalDeclaredValue)
        : 0;
      const additionalChargesTotal = formData.additionalCharges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      );
      return shippingCost + insuranceCost + additionalChargesTotal;
    };

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StepInfo icon={<Gavel />}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Step 6: Review Order Details</strong>
            </Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              <li>Review all shipping details for accuracy</li>
              <li>Verify costs and additional charges</li>
              <li>Check delivery preferences and special instructions</li>
              <li>Submit order when all details are confirmed</li>
            </ul>
          </StepInfo>
        </Grid>

        {/* Sender Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Sender</Typography>
          <Typography>
            {formData.sender.first_name} {formData.sender.last_name}
          </Typography>
          <Typography>{formData.sender.email}</Typography>
          <Typography>{formData.sender.phone}</Typography>
          <Typography>{formData.sender.address_line1}</Typography>
          {formData.sender.address_line2 && (
            <Typography>{formData.sender.address_line2}</Typography>
          )}
          <Typography>
            {formData.sender.city}, {formData.sender.state}{" "}
            {formData.sender.postal_code}
          </Typography>
          <Typography>{formData.sender.country}</Typography>
        </Grid>

        {/* Recipient Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Recipient</Typography>
          <Typography>
            {formData.recipient.first_name} {formData.recipient.last_name}
          </Typography>
          <Typography>{formData.recipient.email}</Typography>
          <Typography>{formData.recipient.phone}</Typography>
          <Typography>{formData.recipient.address_line1}</Typography>
          {formData.recipient.address_line2 && (
            <Typography>{formData.recipient.address_line2}</Typography>
          )}
          <Typography>
            {formData.recipient.city}, {formData.recipient.state}{" "}
            {formData.recipient.postal_code}
          </Typography>
          <Typography>{formData.recipient.country}</Typography>
        </Grid>

        {/* Package Details */}
        <Grid item xs={12}>
          <Typography variant="h6">Package Details</Typography>
          {formData.packages.map((pkg, index) => (
            <Box key={index} mb={2}>
              <Typography variant="subtitle1">Package {index + 1}</Typography>
              <Typography>
                Dimensions: {pkg.dimensions.length} x {pkg.dimensions.width} x{" "}
                {pkg.dimensions.height} in
              </Typography>
              <Typography>Weight: {pkg.weight} lbs</Typography>
              <Typography>
                Declared Value: ${parseFloat(pkg.declaredValue).toFixed(2)}
              </Typography>
              {pkg.temperatureControlled && (
                <Typography>
                  Temperature Range: {pkg.minTemperature}°C to{" "}
                  {pkg.maxTemperature}°C
                </Typography>
              )}
              {pkg.fragile && <Typography>Fragile</Typography>}
              {pkg.hazardous && <Typography>Hazardous</Typography>}
              <Typography variant="subtitle2">Items:</Typography>
              <List>
                {pkg.items.map((item, itemIndex) => (
                  <ListItem key={itemIndex}>
                    <ListItemText
                      primary={item.name}
                      secondary={`Quantity: ${item.quantity}, Description: ${item.description}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Grid>

        {/* Shipping Details */}
        <Grid item xs={12}>
          <Typography variant="h6">Shipping Details</Typography>
          <Typography>
            Selected Tier: {selectedTier?.tier_name || "Not selected"}
          </Typography>
          <Typography>
            Signature Required:{" "}
            {formData.signature === "no_signature"
              ? "No"
              : formData.signature === "adult_signature"
              ? "Adult Signature"
              : "Signature Required"}
          </Typography>
          <Typography>
            Saturday Delivery: {formData.saturdayDelivery ? "Yes" : "No"}
          </Typography>
          <Typography>
            Residential Delivery: {formData.residentialDelivery ? "Yes" : "No"}
          </Typography>
        </Grid>

        {/* Insurance */}
        <Grid item xs={12}>
          <Typography variant="h6">Insurance</Typography>
          {selectedInsurance ? (
            <>
              <Typography>
                Selected Insurance: {selectedInsurance.name}
              </Typography>
              <Typography>
                Insurance Amount: $
                {calculateInsurancePremium(
                  selectedInsurance,
                  formData.packages.reduce(
                    (sum, pkg) => sum + parseFloat(pkg.declaredValue || 0),
                    0
                  )
                ).toFixed(2)}
              </Typography>
            </>
          ) : (
            <Typography>No insurance selected</Typography>
          )}
        </Grid>

        {/* Additional Charges */}
        <Grid item xs={12}>
          <Typography variant="h6">Additional Charges</Typography>
          {formData.additionalCharges.length > 0 ? (
            formData.additionalCharges.map((charge, index) => (
              <Typography key={index}>
                {charge.description}: ${parseFloat(charge.amount).toFixed(2)}
              </Typography>
            ))
          ) : (
            <Typography>No additional charges</Typography>
          )}
        </Grid>

        {/* Special Instructions */}
        <Grid item xs={12}>
          <Typography variant="h6">Special Instructions</Typography>
          <Typography>{formData.specialInstructions || "None"}</Typography>
        </Grid>

        {/* Total Cost */}
        <Grid item xs={12}>
          <Typography variant="h5">
            Total Cost: ${calculateTotalCost().toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (shippingTiers.length === 0 && hasFetchedShippingTiers) {
    return (
      <Card>
        <CardContent>
          <Box sx={{ textAlign: "center", mt: 4, mb: 4 }}>
            <BuildCircleIcon sx={{ fontSize: 80, color: "black" }} />
          </Box>
          <Typography variant="h5" gutterBottom align="center">
            Set Up Your Shipment Options
          </Typography>
          <Typography variant="subtitle1" align="center">
            Before you can add orders, you need to set up your shipping tiers.
            Head over to the Business Profile page to add your services. If you
            believe this is an error, please try refreshing the page.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            onClick={handleNavigation}
            sx={{
              padding: "10px 20px",
              alignContent: "center",
              display: "block",
              margin: "auto",
              mt: 4,
              mb: 4,
              color: "midnightblue",
              borderColor: "midnightblue",
              "&:hover": {
                color: "var(--soft-blue)",
                borderColor: "var(--soft-blue)",
              },
              fontSize: "1.2rem",
            }}
          >
            Go to Business Profile
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h5" component="div">
              {initialOrder || tempFormId
                ? "Complete Order"
                : "Quick Add Order"}
            </Typography>
            {tempFormId && (
              <Typography variant="subtitle2" color="textSecondary" ml={2}>
                (Draft ID: {tempFormId.substring(6, 14)}...)
              </Typography>
            )}
          </Box>
        }
        action={
          <>
            {activeStep < steps.length - 1 && (
              <Button startIcon={<SaveIcon />} onClick={saveIncompleteOrder}>
                Save Progress
              </Button>
            )}
            {/* <Button
              startIcon={<RestoreIcon />}
              onClick={restoreIncompleteOrder}
            >
              Restore Last Saved
            </Button> */}
            {activeStep < steps.length - 1 && (
              <Button startIcon={<ClearIcon />} onClick={resetForm}>
                Clear Form
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 4 }}>
          {tierError && (
            <Alert
              severity="error"
              onClose={() => setTierError("")}
              sx={{ marginBottom: 2 }}
            >
              {tierError}
            </Alert>
          )}
          <form onSubmit={(e) => e.preventDefault()}>
            {renderStepContent(activeStep)}
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <Button
                  disabled={activeStep === 0 || activeStep === steps.length - 1}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Step {activeStep + 1} of {steps.length}
                </Typography>
              </Grid>
              <Grid item>
                {activeStep < steps.length - 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={
                      activeStep === 2 && filteredShippingTiers.length === 0
                    }
                  >
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={submitting}
                  >
                    {submitting ? "Submitting..." : "Submit Order"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuickAddOrder;
