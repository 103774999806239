import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FaTools, FaExclamationTriangle } from "react-icons/fa";
import logo from "../../assets/logo.svg"; // Make sure to update this path to your logo
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import styles from "./Custom404Page.module.css";
import { useNavigate } from "react-router-dom";

const Custom404Page = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageWrapper}>
      <ParallaxBackground />
      <div className={styles.custom404Page}>
        <div className={styles.contentContainer}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Company Logo" />
          </div>
          <Typography variant="h1" className={styles.errorCode}>
            404
          </Typography>
          <Typography variant="h2" className={styles.errorTitle}>
            Page Not Found
          </Typography>
          <div className={styles.iconContainer}>
            <FaTools size={24} />
            <FaExclamationTriangle size={24} />
          </div>
          <Typography variant="body1" className={styles.errorMessage}>
            This site is under active development. The page you're looking for
            is likely a work in progress!
          </Typography>
          <Link to="/" className={styles.homeButton}>
            Return to Home
          </Link>
          <Typography
            variant="body1"
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            Or
          </Typography>
          <button onClick={() => navigate(-1)} className={styles.homeButton}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Custom404Page;
