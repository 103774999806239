import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import CustomButton from "../../../components/Button/Button";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { Alert, CircularProgress } from "@mui/material";
import styles from "./BusinessPaymentsPage.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { Edit, Delete, CheckCircle } from "@mui/icons-material";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaPaypal,
  FaCreditCard,
  FaCcJcb,
  FaCcDinersClub,
} from "react-icons/fa";

import AddCardIcon from "@mui/icons-material/AddCard";
import { Dialog, DialogContent } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const stripePromise = loadStripe(
  "pk_test_51NnQZ6DZzcpBER6ktmP8yKhVYERtedK5fz9qcW6gKYINs4vsBB28fbMEXQYFL2RusrRc8swykWeZuvbzD0HPjqle00zVLnN96d"
);

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const PaymentMethodForm = ({ onSubmit, onCancel, editingPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("card");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (!paymentElement) {
      setError("Payment element not found");
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      return;
    }

    if (editingPaymentMethod) {
      // Handle update
      onSubmit({ id: editingPaymentMethod.id, update: true });
    } else {
      // Handle new payment method creation
      const { error, paymentMethod: stripePaymentMethod } =
        await stripe.createPaymentMethod({
          elements,
        });

      if (error) {
        setError(error.message);
      } else if (stripePaymentMethod) {
        onSubmit(stripePaymentMethod);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.paymentForm}>
      <h2>
        {editingPaymentMethod
          ? "Edit Payment Method"
          : "Add New Payment Method"}
      </h2>
      <div className={styles.cardElementWrapper}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: editingPaymentMethod ? "never" : "auto",
            },
            defaultValues: editingPaymentMethod
              ? {
                  billingDetails: {
                    name: editingPaymentMethod.billing_details.name,
                    email: editingPaymentMethod.billing_details.email,
                    phone: editingPaymentMethod.billing_details.phone,
                    address: editingPaymentMethod.billing_details.address,
                  },
                }
              : undefined,
          }}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles["form-buttons"]}>
        <CustomButton
          type="submit"
          text={
            editingPaymentMethod
              ? "Update Payment Method"
              : "Add Payment Method"
          }
          disabled={!stripe}
        />
        <CustomButton onClick={onCancel} text="Cancel" />
      </div>
    </form>
  );
};

const PaymentMethodsList = ({
  paymentMethods,
  defaultPaymentMethodId,
  onSetDefault,
  onEdit,
  onRemove,
}) => {
  if (!paymentMethods || paymentMethods.length === 0) {
    return <Typography>No payment methods found.</Typography>;
  }

  const CardIcon = ({ brand }) => {
    const iconProps = { className: styles.cardIcon, size: 32 };
    switch (brand.toLowerCase()) {
      case "visa":
        return <FaCcVisa {...iconProps} color="#1A1F71" />;
      case "mastercard":
        return <FaCcMastercard {...iconProps} color="#EB001B" />;
      case "amex":
        return <FaCcAmex {...iconProps} color="#2E77BC" />;
      case "discover":
        return <FaCcDiscover {...iconProps} color="#FF6000" />;
      case "jcb":
        return <FaCcJcb {...iconProps} color="#0E4C96" />;
      case "diners":
        return <FaCcDinersClub {...iconProps} color="#004A97" />;
      default:
        return <FaCreditCard {...iconProps} color="#6C757D" />;
    }
  };

  return (
    <List className={styles.paymentMethodsList}>
      {paymentMethods.map((method) => (
        <ListItem key={method.id} divider className={styles.paymentMethodItem}>
          <ListItemIcon>
            <CardIcon brand={method.card.brand} />
          </ListItemIcon>
          <ListItemText
            primary={`${method.card.brand} **** ${method.card.last4}`}
            secondary={`Expires: ${method.card.exp_month}/${method.card.exp_year}`}
          />
          <div className={styles.paymentMethodActions}>
            {method.id === defaultPaymentMethodId ? (
              <Chip
                icon={<CheckCircle style={{ color: "white" }} />}
                label="Default"
                size="small"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                className={styles.defaultChip}
              />
            ) : (
              <Button
                variant="filled"
                size="small"
                onClick={() => onSetDefault(method.id)}
                className={styles.setDefaultButton}
                startIcon={<CheckCircleOutlineIcon />}
                sx={{
                  color: "var(--midnight-blue)",
                  borderColor: "var(--midnight-blue)",
                  "&:hover": {
                    color: "var(--soft-blue)",
                    borderColor: "var(--soft-blue)",
                  },
                }}
              >
                Set as Default
              </Button>
            )}
            <IconButton
              aria-label="edit"
              onClick={() => onEdit(method)}
              className={styles.actionIcon}
              sx={{ color: "midnightblue" }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => onRemove(method.id)}
              className={styles.deleteIcon}
              sx={{ color: "midnightblue" }}
            >
              <Delete />
            </IconButton>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

const BusinessPaymentsPage = () => {
  const { api } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false);
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  // Use the custom hook for category management
  const businessCategory = useBusinessCategory(businessId);
  const [currentBusiness, setCurrentBusiness] = useState(() =>
    getBusinessById(businessId)
  );

  useEffect(() => {
    console.log("businessId", businessId);
    if (businessId) {
      console.log("Fetching business...");
      console.log("getBusinessById", getBusinessById);
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
    console.log("businessId", businessId);
    console.log("currentBusiness", currentBusiness);
  }, [businessId, getBusinessById]);

  const handleEditPaymentMethod = (method) => {
    setEditingPaymentMethod(method);
    setShowAddPaymentForm(true);
  };

  const handleRemovePaymentMethod = async (paymentMethodId) => {
    setIsLoading(true);
    setAlertMessage(null);
    try {
      if (paymentMethodId === defaultPaymentMethodId) {
        const confirmRemove = window.confirm(
          "This is your default payment method. Are you sure you want to remove it? If you have other payment methods, a new one will be set as default."
        );
        if (!confirmRemove) {
          setIsLoading(false);
          return;
        }
      }

      const response = await api.delete(
        `/payments/business-payment-methods/${paymentMethodId}`,
        {
          data: { businessId: currentBusiness.business_id },
        }
      );

      if (response.data.newDefaultPaymentMethodId) {
        setDefaultPaymentMethodId(response.data.newDefaultPaymentMethodId);
      }

      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Payment method removed successfully",
      });
    } catch (error) {
      console.error("Error removing payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    setIsLoading(true);
    setAlertMessage(null);
    try {
      await api.post("/payments/set-default-business-payment-method", {
        paymentMethodId,
        businessId: currentBusiness.business_id,
      });
      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Default payment method set successfully",
      });
    } catch (error) {
      console.log("Error setting default payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleUpdatePaymentMethod = async (paymentMethod) => {
    setIsLoading(true);
    setAlertMessage(null);

    try {
      const response = await api.put(
        `/payments/business-payment-methods/${paymentMethod.id}`,
        {
          businessId: currentBusiness.business_id,
        }
      );
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error updating payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive, reloadTrigger]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      setIsLoadingClientSecret(true);
      try {
        const response = await api.post(
          "/payments/create-business-setup-intent",
          { businessId: currentBusiness.business_id }
        );
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to initialize payment form. Please try again.",
        });
      } finally {
        setIsLoadingClientSecret(false);
      }
    };

    // onyl fetch pauyment methods if current business is set
    if (currentBusiness) {
      fetchClientSecret();
      fetchPaymentMethods();
    }
  }, [currentBusiness, reloadTrigger]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    try {
      console.log("Fetching payment methods...");
      const response = await api.get(
        `/payments/business-payment-methods?businessId=${currentBusiness.business_id}`
      );
      console.log("Fetched payment methods:", response.data);
      setPaymentMethods(response.data.paymentMethods);
      console.log("Payment methods from response:", response.data);
      setDefaultPaymentMethodId(response.data.defaultPaymentMethodId);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleAddPaymentMethod = async (paymentMethod) => {
    setIsLoading(true);
    setAlertMessage(null);

    const cardExists = paymentMethods.some(
      (existingMethod) =>
        existingMethod.card.last4 === paymentMethod.card.last4 &&
        existingMethod.card.brand === paymentMethod.card.brand &&
        existingMethod.card.exp_month === paymentMethod.card.exp_month &&
        existingMethod.card.exp_year === paymentMethod.card.exp_year
    );

    if (cardExists) {
      setAlertMessage({
        type: "error",
        message: "This card has already been added to your account.",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/payments/business-payment-methods", {
        paymentMethodId: paymentMethod.id,
        businessId: currentBusiness.business_id,
      });
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error adding payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  return (
    <div className={styles["payments-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessHeader />
        <div className={styles["main-content"]}>
          {renderSidebar()}
          <main
            className={`${styles["payments-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Business Payment Methods</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {alertMessage.message}
              </Alert>
            )}
            {paymentMethods ? (
              <div className={styles["payments-info"]}>
                <h2>Your Payment Methods</h2>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <PaymentMethodsList
                    paymentMethods={paymentMethods}
                    defaultPaymentMethodId={defaultPaymentMethodId}
                    onSetDefault={handleSetDefaultPaymentMethod}
                    onEdit={handleEditPaymentMethod}
                    onRemove={handleRemovePaymentMethod}
                  />
                )}
              </div>
            ) : (
              <CircularProgress />
            )}
            <div className={styles["edit-payments"]}>
              {!showAddPaymentForm ? (
                <Button
                  variant="outlined"
                  startIcon={<AddCardIcon />}
                  onClick={() => {
                    setEditingPaymentMethod(null);
                    setShowAddPaymentForm(true);
                  }}
                  className={styles.addPaymentMethodButton}
                  sx={{
                    color: "midnightblue",
                    borderColor: "midnightblue",
                    "&:hover": {
                      color: "var(--soft-blue)",
                      borderColor: "var(--soft-blue)",
                    },
                  }}
                >
                  Add New Payment Method
                </Button>
              ) : isLoadingClientSecret ? (
                <CircularProgress />
              ) : clientSecret ? (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    appearance: {
                      theme: "night",
                      labels: "floating",
                    },
                    paymentMethodCreation: "manual",
                  }}
                >
                  <PaymentMethodForm
                    onSubmit={
                      editingPaymentMethod
                        ? handleUpdatePaymentMethod
                        : handleAddPaymentMethod
                    }
                    onCancel={() => {
                      setShowAddPaymentForm(false);
                      setEditingPaymentMethod(null);
                    }}
                    editingPaymentMethod={editingPaymentMethod}
                  />
                </Elements>
              ) : (
                <Dialog
                  open={isLoadingClientSecret}
                  disableEscapeKeyDown
                  disableBackdropClick={false}
                >
                  <DialogContent>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <CircularProgress style={{ marginBottom: "20px" }} />
                      <Typography>Initializing payment form...</Typography>
                    </div>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default BusinessPaymentsPage;
