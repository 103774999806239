import React, { createContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const broadcastChannelRef = useRef(new BroadcastChannel("auth"));
  const refreshTokenPromise = useRef(null);
  const refreshTimeout = useRef(null);
  const refreshQueue = useRef([]);
  const isLoggingOut = useRef(false);

  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded.exp * 1000 > Date.now() + 10000; // 10 second buffer
    } catch (error) {
      console.error("Token validation error:", error);
      return false;
    }
  };

  const processQueue = (error, token = null) => {
    refreshQueue.current.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    refreshQueue.current = [];
  };

  const logout = async (skipApiCall = false) => {
    // Prevent multiple simultaneous logout attempts
    if (isLoggingOut.current) return;
    isLoggingOut.current = true;

    try {
      if (!skipApiCall) {
        await api.post("/auth/logout");
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      localStorage.removeItem("accessToken");
      Cookies.remove("refreshToken", { path: "/" });
      Cookies.remove("rememberMeToken", { path: "/" });
      Cookies.remove("securityAccessTimestamp", { path: "/" });
      setUser(null);

      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
      refreshTokenPromise.current = null;

      broadcastChannelRef.current.postMessage("logout");

      // Store the intended destination if not /auth
      if (location.pathname !== "/auth") {
        sessionStorage.setItem("redirectAfterLogin", location.pathname);
      }

      navigate("/auth", { replace: true });
      isLoggingOut.current = false;
    }
  };

  const refreshAccessToken = async () => {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.current.push({ resolve, reject });
      });
    }

    setIsRefreshing(true);

    try {
      const { data } = await api.post("/auth/refresh-token");
      const newToken = data.accessToken;

      if (!isTokenValid(newToken)) {
        throw new Error("Received invalid token during refresh");
      }

      localStorage.setItem("accessToken", newToken);

      // Schedule next refresh 4 minutes before expiration
      const decoded = JSON.parse(atob(newToken.split(".")[1]));
      const expiresIn = decoded.exp * 1000 - Date.now() - 4 * 60 * 1000;

      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }

      refreshTimeout.current = setTimeout(() => {
        refreshAccessToken();
      }, Math.max(expiresIn, 0));

      processQueue(null, newToken);
      return newToken;
    } catch (error) {
      processQueue(error, null);
      try {
        // Try remember me as fallback
        return await rememberMeLogin();
      } catch (rememberMeError) {
        // If both refresh and remember me fail, force logout
        await logout(true);
        throw new Error("Authentication failed");
      }
    } finally {
      setIsRefreshing(false);
    }
  };

  const rememberMeLogin = async () => {
    try {
      const { data } = await api.post("/auth/remember-me");
      if (!isTokenValid(data.accessToken)) {
        throw new Error("Received invalid token during remember me login");
      }
      localStorage.setItem("accessToken", data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error("Error with remember me login:", error);
      throw error;
    }
  };

  const getRefreshedToken = async () => {
    if (!refreshTokenPromise.current) {
      refreshTokenPromise.current = refreshAccessToken().finally(() => {
        refreshTokenPromise.current = null;
      });
    }
    return refreshTokenPromise.current;
  };

  // Axios interceptors
  useEffect(() => {
    let retryCount = 0;
    const MAX_RETRIES = 2;

    const requestInterceptor = api.interceptors.request.use(
      async (config) => {
        let token = localStorage.getItem("accessToken");

        if (token && !isTokenValid(token)) {
          try {
            token = await refreshAccessToken();
          } catch (error) {
            console.error("Token refresh failed during request:", error);
          }
        }

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = api.interceptors.response.use(
      (response) => {
        if (response.headers["x-should-refresh-token"]) {
          getRefreshedToken().catch(console.error);
        }
        retryCount = 0; // Reset retry count on successful response
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        // Handle 401 errors
        if (error.response?.status === 401) {
          if (retryCount >= MAX_RETRIES) {
            // Max retries reached, force logout
            await logout(true);
            return Promise.reject(new Error("Authentication failed"));
          }

          if (!originalRequest._retry) {
            originalRequest._retry = true;
            retryCount++;

            try {
              // Attempt to refresh the token
              const accessToken = await getRefreshedToken();
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${accessToken}`;
              return api(originalRequest);
            } catch (refreshError) {
              // If refresh fails, force logout
              await logout(true);
              return Promise.reject(new Error("Authentication failed"));
            }
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);

  api.interceptors.request.use((config) => {
    if (!config.baseURL) {
      console.error("API base URL is not configured correctly");
    }
    return config;
  });

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          if (!isTokenValid(accessToken)) {
            await getRefreshedToken();
          }
          const response = await api.get("/auth/verify-token");
          setUser(response.data.user);
          if (location.pathname === "/auth") {
            if (isProfileComplete(response.data.user)) {
              navigate("/dashboard");
            } else {
              navigate("/complete-profile");
            }
          }

          const decoded = JSON.parse(atob(accessToken.split(".")[1]));
          const expiresIn = decoded.exp * 1000 - Date.now() - 4 * 60 * 1000;

          if (expiresIn > 0) {
            refreshTimeout.current = setTimeout(() => {
              getRefreshedToken();
            }, expiresIn);
          } else {
            await getRefreshedToken();
          }
        } else {
          const refreshToken = Cookies.get("refreshToken");
          if (refreshToken) {
            try {
              await refreshAccessToken();
              const response = await api.get("/auth/verify-token");
              setUser(response.data.user);
              if (location.pathname === "/auth") {
                if (isProfileComplete(response.data.user)) {
                  navigate("/dashboard");
                } else {
                  navigate("/complete-profile");
                }
              }
            } catch (error) {
              console.error("Error refreshing token:", error);
              try {
                await rememberMeLogin();
                const response = await api.get("/auth/verify-token");
                setUser(response.data.user);
                if (location.pathname === "/auth") {
                  if (isProfileComplete(response.data.user)) {
                    navigate("/dashboard");
                  } else {
                    navigate("/complete-profile");
                  }
                }
              } catch (rememberMeError) {
                console.error("Remember me login failed:", rememberMeError);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error initializing auth:", error);
        await logout(true);
      } finally {
        setLoading(false);
        setInitializationComplete(true);
      }
    };

    initializeAuth();

    const handleBroadcastMessage = (event) => {
      if (event.data === "logout") {
        setUser(null);
        navigate("/auth");
      }
    };

    const broadcastChannel = broadcastChannelRef.current;
    broadcastChannel.addEventListener("message", handleBroadcastMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleBroadcastMessage);
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
      setLoading(false);
      setInitializationComplete(false);
    };
  }, [navigate, location]);

  const verifyPassword = async (password) => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    const response = await api.post("/auth/verify-password", { password });

    if (response.data.verified) {
      Cookies.set("securityAccessTimestamp", Date.now().toString(), {
        expires: 1 / 96, // 15 minutes
        path: "/",
        secure: process.env.NODE_ENV === "production",
        sameSite: "Strict",
      });
    }

    return response.data;
  };

  const login = async (email, password, rememberMe) => {
    try {
      const response = await api.post("/auth/login", {
        user_email: email,
        user_password: password,
        remember_me: rememberMe,
      });

      const newToken = response.data.accessToken;
      if (!isTokenValid(newToken)) {
        throw new Error("Received invalid token during login");
      }

      localStorage.setItem("accessToken", newToken);
      const userResponse = await api.get("/auth/verify-token");
      setUser(userResponse.data.user);

      // Set up refresh timer
      const decoded = JSON.parse(atob(newToken.split(".")[1]));
      const expiresIn = decoded.exp * 1000 - Date.now() - 4 * 60 * 1000;

      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }

      refreshTimeout.current = setTimeout(() => {
        getRefreshedToken();
      }, Math.max(expiresIn, 0));

      if (isProfileComplete(userResponse.data.user)) {
        navigate("/dashboard");
      } else {
        navigate("/complete-profile");
      }
    } catch (error) {
      console.error("There was an error logging in!", error);
      throw error;
    }
  };

  const updateUserContext = async () => {
    try {
      const response = await api.get("/auth/verify-token");
      setUser(response.data.user);
    } catch (error) {
      console.error("Error updating user context:", error);
      logout();
    }
  };

  const isProfileComplete = (user) => {
    return (
      user?.user_first_name && user?.user_last_name && user?.user_date_of_birth
    );
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout,
        refreshAccessToken,
        isProfileComplete,
        updateUserContext,
        verifyPassword,
        rememberMeLogin,
        api,
        initializationComplete,
        isAuthenticated: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
