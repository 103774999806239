import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  useParams,
  useLocation,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Paper,
  Alert,
  Button,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EmployeeHeader from "../../../components/Header/DashboardHeader";
import EmployeeSidebar from "../../../components/Sidebar/EmployeeSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import {
  OrdersProvider,
  useOrders,
} from "../../business/shipping/ShipmentsPage/OrdersContext";
import DashboardOverview from "../../business/shipping/ShipmentsPage/DashboardOverview";
import QuickAddOrder from "../../business/shipping/ShipmentsPage/QuickAddOrder";
import BulkImport from "../../business/shipping/ShipmentsPage/BulkImport";
import OrderListWithErrorBoundary from "../../business/shipping/ShipmentsPage/OrderList";
import OrderEditPage from "../../business/shipping/ShipmentsPage/OrderEditPage";
import { useEmployee } from "../../../components/Auth/EmployeeContext";
import "../../business/shipping/ShipmentsPage/ShipmentsPage.css";

const ShipmentsContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { businessId } = useParams();
  const { employeeId } = useParams();
  const { getBusinessById } = useBusiness();
  const {
    orders,
    groups,
    loading,
    error,
    customers,
    customersLoading,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    updateOrder,
    addOrder,
    deleteOrder,
    deleteOrderFromLocalStorage,
  } = useOrders();
  const [currentBusiness, setCurrentBusiness] = useState(() =>
    getBusinessById(businessId)
  );
  const [completingOrder, setCompletingOrder] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const location = useLocation();
  const prevLocationRef = useRef(null);
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    // restore scroll position when navigating back
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (businessId) {
      fetchGroups(businessId);
      console.log("Fetching groups for business:", businessId);
      console.log("Groups are: ", groups);
    }
  }, [businessId, fetchGroups]);

  useEffect(() => {
    if (groups) {
      setFilteredGroups(groups);
    }
  }, [groups]);

  const handleOrderClick = useCallback(
    (orderId) => {
      navigate(
        `/business/${businessId}/employee/${employeeId}/shipments/${orderId}`
      );
    },
    [businessId, employeeId, navigate]
  );

  // Memoize the orders data
  const memoizedOrders = useMemo(() => {
    if (prevLocationRef.current !== location.pathname) {
      fetchOrders(businessId);
    }
    prevLocationRef.current = location.pathname;
    return orders;
  }, [businessId, fetchOrders, location.pathname, orders]);

  // Fetch customers on mount
  useEffect(() => {
    fetchCustomers(businessId);
  }, [businessId, fetchCustomers]);

  useEffect(() => {
    console.log("Customers are: ", customers);
  }, [customers]);

  const handleCompleteOrder = useCallback((order) => {
    setCompletingOrder(order);
    setActiveTab(1);
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const handleRefresh = useCallback(() => {
    setRefreshCount((prevCount) => prevCount + 1);
    fetchOrders(businessId, true);
    fetchCustomers(businessId);
    fetchGroups(businessId);
  }, [businessId, fetchOrders, fetchCustomers, fetchGroups]);

  if (loading || customersLoading) {
    return <CircularProgress />;
  }

  if (error || customersError) {
    return <Alert severity="error">{error || customersError}</Alert>;
  }

  return (
    <Box ref={contentRef} style={{ height: "100%", overflowY: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Shipments Management
      </Typography>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
        sx={{ mb: 2 }}
      >
        Refresh Data
      </Button>
      <DashboardOverview orders={memoizedOrders} />
      <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Order List" />
          {/* <Tab label="Group Orders" /> */}
          <Tab label="Quick Add Order" />
          <Tab label="Bulk Import" />
        </Tabs>
        <div className="tab-content">
          {activeTab === 0 && (
            <OrderListWithErrorBoundary
              orders={memoizedOrders}
              onOrderUpdate={updateOrder}
              onCompleteOrder={handleCompleteOrder}
              onDeleteDraftOrder={deleteOrderFromLocalStorage}
              loading={loading}
              onOrderClick={handleOrderClick}
            />
          )}
          {/* {activeTab === 1 && (
            <GroupsList
              groups={filteredGroups}
              orders={memoizedOrders}
              customers={customers}
              onOrderCreate={addOrder}
              initialOrder={completingOrder}
            />
          )} */}
          {activeTab === 1 && (
            <QuickAddOrder
              existingCustomers={customers}
              onOrderCreate={addOrder}
              initialOrder={completingOrder}
            />
          )}
          {activeTab === 2 && (
            <BulkImport
              onOrdersImport={(importedOrders) =>
                importedOrders.forEach(addOrder)
              }
            />
          )}
        </div>
      </Paper>
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Skeleton variant="rectangular" width="100%" height={118} />
    <Skeleton variant="text" sx={{ marginTop: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </Box>
);

const ShipmentsPage = () => {
  const { employeeData, loading, error, businessId } = useEmployee();
  const [sidebarActive, setSidebarActive] = useState(() => {
    return JSON.parse(localStorage.getItem("businessSidebarActive") || "true");
  });

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  const { getBusinessById } = useBusiness();
  const [currentBusiness, setCurrentBusiness] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
  }, [businessId, getBusinessById]);

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (!employeeData || !currentBusiness) {
      return (
        <Alert severity="warning">
          No employee or business data available.
        </Alert>
      );
    }

    return (
      <OrdersProvider>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames="page"
            timeout={300}
          >
            <Routes>
              <Route path="/" element={<ShipmentsContent />} />
              <Route path="/:orderId" element={<OrderEditPage />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </OrdersProvider>
    );
  };

  const employee_role = employeeData?.employee_role || "Unknown";
  const business_type = currentBusiness?.business_category_name || "Unknown";

  return (
    <div className="business-dashboard-page">
      <ParallaxBackground />
      <EmployeeHeader />
      <div className="dashboard-main-content">
        <EmployeeSidebar
          onSidebarStateChange={handleSidebarStateChange}
          employeeRole={employee_role}
          businessType={business_type}
        />
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            {renderContent()}
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default ShipmentsPage;
