import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import CustomButton from "../../components/Button/Button";
import LoadingModal from "../../components/Icon/LoadingModal";
import { TextField, Alert, AlertTitle } from "@mui/material";
import styles from "./PasswordVerificationPage.module.css";

const PasswordVerificationPage = () => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { verifyPassword } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive]);

  const handleSidebarStateChange = (isActive) => {
    setSidebarActive(isActive);
  };

  const validateForm = () => {
    if (password.length < 5) {
      setErrorMessage("Password must be at least 5 characters long!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    try {
      const result = await verifyPassword(password);
      if (result.verified) {
        navigate(from);
      } else {
        setErrorMessage("Invalid password. Please try again.");
      }
    } catch (error) {
      console.error("There was an error verifying the password!", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["password-verification-page"]}>
      <LoadingModal open={isLoading} />
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["password-verification-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h2>Verify Your Password</h2>
            <p>Please enter your password to access sensitive settings.</p>
            <form onSubmit={handleSubmit} className={styles["password-form"]}>
              {errorMessage && (
                <Alert severity="error" className={styles["error-message"]}>
                  {errorMessage}
                </Alert>
              )}
              <TextField
                name="password"
                type="password"
                variant="outlined"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <div className={styles["submit-button"]}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={!password || isLoading}
                  text={isLoading ? "Verifying..." : "Verify"}
                />
              </div>
            </form>
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default PasswordVerificationPage;
