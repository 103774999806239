import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import {
  LocalShipping,
  Security,
  Speed,
  Language,
  Business,
  Assessment,
} from "@mui/icons-material";
import "./ServicesPage.css";

const ServicesPage = () => {
  const services = [
    {
      title: "Smart Package Tracking",
      icon: <Assessment />,
      description:
        "Advanced real-time tracking system with predictive analytics and comprehensive shipment monitoring. Stay informed with instant updates and detailed analytics.",
    },
    {
      title: "Fleet Management",
      icon: <LocalShipping />,
      description:
        "Comprehensive fleet management solution for optimizing delivery operations and vehicle maintenance. Maximize efficiency and reduce operational costs.",
    },
    {
      title: "Express Delivery",
      icon: <Speed />,
      description:
        "Expedited shipping solutions for time-sensitive deliveries with guaranteed delivery windows. When speed matters most, trust our express services.",
    },
  ];

  return (
    <div className="services-page" id="wrapper">
      <Header />
      <main className="services-content">
        <ParallaxBackground />
        <h1 className="services-title">Our Services</h1>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="services-card" key={index}>
              <div className="service-icon">{service.icon}</div>
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ServicesPage;
