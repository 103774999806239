import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
  Skeleton,
  IconButton,
  Drawer,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import {
  Map as MapIcon,
  ViewList as ListIcon,
  Refresh as RefreshIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  LocalShipping as ShippingIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { useLocationTracking } from "./LocationTrackingContext";
import MapControls from "./MapControls";
import { useParams } from "react-router-dom";

const LocationTrackingView = () => {
  const { user } = useContext(AuthContext);
  const {
    orders,
    couriers,
    loading,
    error,
    locationHistories,
    fetchLocationData,
    fetchLocationHistory,
    fetchLocationDataQuiet,
  } = useLocationTracking();

  const [showSidePanel, setShowSidePanel] = useState(true);
  const [selectedTab, setSelectedTab] = useState("orders");
  const [mapCenter, setMapCenter] = useState({ lat: 22.0964, lng: -79.9542 });
  const [mapZoom, setMapZoom] = useState(7);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [mapSettings, setMapSettings] = useState({
    showHistoryPaths: false,
    showOrders: true,
    showCouriers: true,
    showActiveOnly: false,
    pathOpacity: 0.7,
    mapType: "roadmap",
  });
  const { businessId } = useParams();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    fetchLocationData(businessId);
    // const interval = setInterval(() => {
    //   fetchLocationDataQuiet(businessId);
    // }, 30000);
    // return () => clearInterval(interval);
  }, [businessId, fetchLocationData, fetchLocationDataQuiet]);

  useEffect(() => {
    const activeOrders = orders.filter(
      (order) =>
        order.location &&
        (!mapSettings.showActiveOnly ||
          order.order?.order_status !== "delivered")
    );
    const activeCouriers = couriers.filter(
      (courier) =>
        courier.current_location &&
        (!mapSettings.showActiveOnly || courier.active)
    );

    const allLocations = [
      ...activeOrders.map((o) => ({
        lat: Number(o.location.latitude),
        lng: Number(o.location.longitude),
      })),
      ...activeCouriers.map((c) => ({
        lat: Number(c.current_location.latitude),
        lng: Number(c.current_location.longitude),
      })),
    ];

    if (allLocations.length > 0 && !selectedItem) {
      const center = allLocations.reduce(
        (acc, loc) => ({
          lat: acc.lat + loc.lat,
          lng: acc.lng + loc.lng,
        }),
        { lat: 0, lng: 0 }
      );

      setMapCenter({
        lat: center.lat / allLocations.length,
        lng: center.lng / allLocations.length,
      });
    }
  }, [orders, couriers, mapSettings.showActiveOnly, selectedItem]);

  const handleItemSelect = async (itemId, type) => {
    console.log("Selected item:", itemId, type);
    if (selectedItem?.id === itemId && selectedItem?.type === type) {
      setSelectedItem(null);
      return;
    }

    const item =
      type === "order"
        ? orders.find((o) => o.order_id === itemId)
        : couriers.find((c) => c.employee_id === itemId);

    if (item) {
      const location = type === "order" ? item.location : item.current_location;
      // check if location is available
      if (location) {
        setMapCenter({
          lat: Number(location.latitude),
          lng: Number(location.longitude),
        });
        setMapZoom(12);
      } else {
        setMapCenter({ lat: 22.0964, lng: -79.9542 });
        setMapZoom(7);
      }
    }

    setSelectedItem({ id: itemId, type });
    if (!locationHistories[itemId]) {
      await fetchLocationHistory(businessId, itemId, type);
    }
  };

  const renderHistoryPath = () => {
    if (
      !selectedItem ||
      !locationHistories[selectedItem.id] ||
      !mapSettings.showHistoryPaths
    ) {
      return null;
    }

    const history = locationHistories[selectedItem.id];
    const path = history.locations.map((loc) => ({
      lat: Number(loc.latitude),
      lng: Number(loc.longitude),
    }));

    return (
      <Polyline
        path={path}
        options={{
          strokeColor: selectedItem.type === "order" ? "#FF0000" : "#0000FF",
          strokeOpacity: mapSettings.pathOpacity,
          strokeWeight: 3,
        }}
      />
    );
  };

  const renderOrderMarkers = () => {
    if (!mapSettings.showOrders) return null;

    return orders
      .filter(
        (order) =>
          order.location &&
          (!mapSettings.showActiveOnly ||
            order.order?.order_status !== "delivered")
      )
      .map((order) => (
        <Marker
          key={order.order_id}
          position={{
            lat: Number(order.location.latitude),
            lng: Number(order.location.longitude),
          }}
          onClick={() => handleItemSelect(order.order_id, "order")}
        />
      ));
  };

  const renderCourierMarkers = () => {
    if (!mapSettings.showCouriers) return null;

    return couriers
      .filter(
        (courier) =>
          courier.current_location &&
          (!mapSettings.showActiveOnly || courier.active)
      )
      .map((courier) => (
        <Marker
          key={courier.employee_id}
          position={{
            lat: Number(courier.current_location.latitude),
            lng: Number(courier.current_location.longitude),
          }}
          onClick={() => handleItemSelect(courier.employee_id, "courier")}
        />
      ));
  };

  const courierColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        const firstName = params.row?.employee_first_name || "";
        const lastName = params.row?.employee_last_name || "";
        return `${firstName} ${lastName}`.trim() || "N/A";
      },
    },
    {
      field: "current_location",
      headerName: "Current Location",
      width: 200,
      renderCell: (params) => {
        const location = params.row?.current_location;
        if (!location) return "N/A";
        return `${Number(location.latitude).toFixed(4)}, ${Number(
          location.longitude
        ).toFixed(4)}`;
      },
    },
    {
      field: "last_update",
      headerName: "Last Update",
      width: 200,
      renderCell: (params) => {
        const timestamp = params.row?.current_location?.recorded_at;
        if (!timestamp) return "N/A";
        try {
          return new Date(timestamp).toLocaleString();
        } catch {
          return "Invalid Date";
        }
      },
    },
  ];

  const orderColumns = [
    {
      field: "tracking_number",
      headerName: "Tracking #",
      width: 150,
      renderCell: (params) => params.row?.order.tracking_number || "N/A",
    },
    {
      field: "order_status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => params.row?.order.order_status || "N/A",
    },
    {
      field: "location",
      headerName: "Current Location",
      width: 200,
      renderCell: (params) => {
        const location = params.row?.location;
        if (!location) return "N/A";
        return `${Number(location.latitude).toFixed(4)}, ${Number(
          location.longitude
        ).toFixed(4)}`;
      },
    },
    {
      field: "last_update",
      headerName: "Last Update",
      width: 200,
      renderCell: (params) => {
        const timestamp = params.row?.location?.recorded_at;
        if (!timestamp) return "N/A";
        try {
          return new Date(timestamp).toLocaleString();
        } catch {
          return "Invalid Date";
        }
      },
    },
  ];

  const getProcessedRows = () => {
    try {
      if (selectedTab === "orders") {
        return (orders || []).map((order) => ({
          id: order.shipping_order_id || order.order_id,
          tracking_number: order.tracking_number,
          order_status: order.order_status,
          current_location: order.current_location,
          ...order,
        }));
      }
      return (couriers || []).map((courier) => ({
        id: courier.employee_id,
        name: `${courier.employee_first_name || ""} ${
          courier.employee_last_name || ""
        }`.trim(),
        current_location: courier.current_location,
        ...courier,
      }));
    } catch (error) {
      console.error("Error processing rows:", error);
      return [];
    }
  };

  if (loading) return <Skeleton variant="rectangular" height={400} />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!isLoaded) return <Skeleton variant="rectangular" height={400} />;

  return (
    <Box sx={{ p: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ height: 600, position: "relative" }}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={mapCenter}
              zoom={mapZoom}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                mapTypeId: mapSettings.mapType,
              }}
            >
              {renderOrderMarkers()}
              {renderCourierMarkers()}
              {renderHistoryPath()}
            </GoogleMap>

            <MapControls
              {...mapSettings}
              setShowHistoryPaths={(value) =>
                setMapSettings((prev) => ({ ...prev, showHistoryPaths: value }))
              }
              setShowOrders={(value) =>
                setMapSettings((prev) => ({ ...prev, showOrders: value }))
              }
              setShowCouriers={(value) =>
                setMapSettings((prev) => ({ ...prev, showCouriers: value }))
              }
              setShowActiveOnly={(value) =>
                setMapSettings((prev) => ({ ...prev, showActiveOnly: value }))
              }
              setPathOpacity={(value) =>
                setMapSettings((prev) => ({ ...prev, pathOpacity: value }))
              }
              setMapType={(value) =>
                setMapSettings((prev) => ({ ...prev, mapType: value }))
              }
            />

            {/* Side Panel Toggle Button */}
            <Paper
              sx={{
                position: "absolute",
                left: showSidePanel ? "400px" : 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <IconButton onClick={() => setShowSidePanel(!showSidePanel)}>
                {showSidePanel ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              </IconButton>
            </Paper>

            {/* Side Panel with DataGrid */}
            <Paper
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: "400px",
                transform: `translateX(${showSidePanel ? "0" : "-100%"})`,
                transition: "transform 0.3s ease-in-out",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                backdropFilter: "blur(10px)",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                sx={{ px: 2, pt: 1 }}
              >
                <Tab label="Orders" value="orders" />
                <Tab label="Couriers" value="couriers" />
              </Tabs>

              <Box sx={{ flex: 1, overflow: "hidden" }}>
                <DataGrid
                  rows={getProcessedRows()}
                  columns={
                    selectedTab === "orders" ? orderColumns : courierColumns
                  }
                  pageSize={pageSize}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                  onRowClick={(params) =>
                    handleItemSelect(
                      params.row.id,
                      selectedTab === "orders" ? "order" : "courier"
                    )
                  }
                  loading={loading}
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          p: 2,
                        }}
                      >
                        No data available
                      </Box>
                    ),
                  }}
                  sx={{
                    height: "100%",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                      backgroundColor: "primary.light",
                    },
                  }}
                  selectionModel={selectedItem ? [selectedItem.id] : []}
                />
              </Box>
            </Paper>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocationTrackingView;
