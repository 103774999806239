import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LoginForm from "../../components/Form/LoginForm/LoginForm";
import SignUpForm from "../../components/Form/SignUpForm/SignUpForm";
import ResetForm from "../../components/Form/ResetForm/ResetForm";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import "./LoginPage.css";

const LoginPage = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("login");

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  return (
    <div className="login-page" id="wrapper">
      <Header />
      <main className="login-content">
        <ParallaxBackground />
        <div id="form-content" className="form-content">
          <div className="tabs">
            <button
              className={activeTab === "login" ? "active" : ""}
              onClick={() => setActiveTab("login")}
            >
              Sign In
            </button>
            <button
              className={activeTab === "signup" ? "active" : ""}
              onClick={() => setActiveTab("signup")}
            >
              Sign Up
            </button>
            <button
              className={activeTab === "reset" ? "active" : ""}
              onClick={() => setActiveTab("reset")}
            >
              Reset
            </button>
          </div>
          <div className="tab-descriptions">
            {activeTab === "login" && <p>Log in to your account.</p>}
            {activeTab === "signup" && <p>Create a new account.</p>}
            {activeTab === "reset" && <p>Reset your password.</p>}
          </div>
          <div className="form-container">
            {activeTab === "login" && <LoginForm />}
            {activeTab === "signup" && <SignUpForm />}
            {activeTab === "reset" && <ResetForm />}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LoginPage;
