import React, { useState, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
} from "@mui/material";
import styles from "./CompleteProfilePopup.module.css";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessage = styled("div")({
  color: "red",
  backgroundColor: "#ffe6e6",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid red",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
});

const CompleteProfilePopup = () => {
  const [profileData, setProfileData] = useState({
    user_first_name: "",
    user_last_name: "",
    user_date_of_birth: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { api } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    if (
      !profileData.user_first_name ||
      !profileData.user_last_name ||
      !profileData.user_date_of_birth
    ) {
      setErrorMessage("All fields are required!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await api.put("/users/complete-profile", profileData);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage("Error updating profile");
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["popup-overlay"]}>
      <ParallaxBackground />
      <Box
        component="form"
        className={styles["popup-content"]}
        onSubmit={handleSubmit}
      >
        <h2>Complete Your Profile</h2>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <TextField
          name="user_first_name"
          variant="outlined"
          type="text"
          placeholder="First Name"
          value={profileData.user_first_name}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          name="user_last_name"
          variant="outlined"
          type="text"
          placeholder="Last Name"
          value={profileData.user_last_name}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          name="user_date_of_birth"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          label="Date of Birth"
          value={profileData.user_date_of_birth}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <CustomButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Save"}
        </CustomButton>
      </Box>
    </div>
  );
};

export default CompleteProfilePopup;
