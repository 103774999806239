import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Box,
  Chip,
  Switch,
  FormControlLabel,
  Divider,
  InputAdornment,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  AddCircle as AddCircleIcon,
  Security,
} from "@mui/icons-material";
import styles from "./InsuranceManagement.module.css";

const InsuranceManagement = ({ businessId }) => {
  const { api } = useContext(AuthContext);
  const [insurances, setInsurances] = useState([]);
  const [newInsurance, setNewInsurance] = useState({
    name: "",
    coverage_type: "Basic",
    max_coverage_amount: "",
    premium_rate: "",
    description: "",
    is_active: true,
  });
  const [editingInsuranceId, setEditingInsuranceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchInsurances();
  }, [businessId]);

  const fetchInsurances = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/insurance?business_id=${businessId}`);
      setInsurances(response.data.insurances);
    } catch (error) {
      console.error("Error fetching insurances:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch insurance packages. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewInsurance((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = (insurance) => {
    const newErrors = {};
    if (!insurance.name.trim()) newErrors.name = "Name is required";
    if (!insurance.coverage_type)
      newErrors.coverage_type = "Coverage type is required";
    if (
      !insurance.max_coverage_amount ||
      isNaN(insurance.max_coverage_amount) ||
      insurance.max_coverage_amount <= 0
    )
      newErrors.max_coverage_amount =
        "Max coverage amount must be a positive number";
    if (
      !insurance.premium_rate ||
      isNaN(insurance.premium_rate) ||
      insurance.premium_rate < 0 ||
      insurance.premium_rate > 100
    )
      newErrors.premium_rate =
        "Premium rate must be a percentage between 0 and 100";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddNewInsurance = () => {
    setNewInsurance({
      name: "",
      coverage_type: "Basic",
      max_coverage_amount: "",
      premium_rate: "",
      description: "",
      is_active: true,
    });
    setEditingInsuranceId("new");
  };

  const handleSave = async (insuranceId) => {
    const insuranceToSave =
      insuranceId === "new"
        ? newInsurance
        : insurances.find((ins) => ins.insurance_id === insuranceId);

    if (!validateForm(insuranceToSave)) {
      setAlertMessage({
        type: "error",
        message: "Please fix the validation errors before submitting.",
      });
      return;
    }

    setIsLoading(true);
    try {
      let response;
      if (insuranceId === "new") {
        response = await api.post("/insurance/create", {
          ...insuranceToSave,
          business_id: businessId,
        });
        setInsurances([...insurances, response.data.insurance]);
      } else {
        response = await api.put(`/insurance/update`, {
          ...insuranceToSave,
          business_id: businessId,
          insurance_id: insuranceId,
        });
        setInsurances(
          insurances.map((ins) =>
            ins.insurance_id === insuranceId ? response.data.insurance : ins
          )
        );
      }
      setEditingInsuranceId(null);
      setNewInsurance({
        name: "",
        coverage_type: "Basic",
        max_coverage_amount: "",
        premium_rate: "",
        description: "",
        is_active: true,
      });
      setErrors({});
      setAlertMessage({
        type: "success",
        message: `Insurance package ${
          insuranceId === "new" ? "added" : "updated"
        } successfully!`,
      });
    } catch (error) {
      console.error("Error saving insurance:", error);
      setAlertMessage({
        type: "error",
        message: `Failed to ${
          insuranceId === "new" ? "add" : "update"
        } insurance package. Please try again.`,
      });
    }
    setIsLoading(false);
  };

  const handleDelete = async (insuranceId) => {
    setIsLoading(true);
    try {
      await api.delete(`/insurance/delete`, {
        data: { business_id: businessId, insurance_id: insuranceId },
      });
      setInsurances(
        insurances.filter((ins) => ins.insurance_id !== insuranceId)
      );
      setAlertMessage({
        type: "success",
        message: "Insurance package deleted successfully!",
      });
    } catch (error) {
      console.error("Error deleting insurance:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete insurance package. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleEdit = (insurance) => {
    setNewInsurance(insurance);
    setEditingInsuranceId(insurance.insurance_id);
  };

  const handleCancelEdit = (insuranceId) => {
    if (insuranceId === "new") {
      setNewInsurance({
        name: "",
        coverage_type: "Basic",
        max_coverage_amount: "",
        premium_rate: "",
        description: "",
        is_active: true,
      });
    }
    setEditingInsuranceId(null);
    setErrors({});
  };

  const renderInsuranceCard = (insurance) => {
    const isEditing = insurance.insurance_id === editingInsuranceId;

    return (
      <Card className={styles["insurance-card"]} key={insurance.insurance_id}>
        <CardContent className={styles["card-content"]}>
          {isEditing ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSave(insurance.insurance_id);
              }}
            >
              <TextField
                name="name"
                label="Insurance Name"
                value={newInsurance.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
                error={!!errors.name}
                helperText={errors.name}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.coverage_type}
              >
                <InputLabel>Coverage Type</InputLabel>
                <Select
                  name="coverage_type"
                  value={newInsurance.coverage_type}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="Standard">Standard</MenuItem>
                  <MenuItem value="Premium">Premium</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="max_coverage_amount"
                label="Max Coverage Amount"
                type="number"
                value={newInsurance.max_coverage_amount}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
                error={!!errors.max_coverage_amount}
                helperText={errors.max_coverage_amount}
                onKeyDown={(e) => {
                  if (["-", "+", "e"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
              <TextField
                name="premium_rate"
                label="Premium Rate"
                onKeyDown={(e) => {
                  if (["-", "+", "e"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type="number"
                value={newInsurance.premium_rate}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
                error={!!errors.premium_rate}
                helperText={errors.premium_rate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0, max: 100, step: 0.01 },
                }}
              />
              <TextField
                name="description"
                label="Description"
                value={newInsurance.description}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newInsurance.is_active}
                    onChange={handleInputChange}
                    name="is_active"
                  />
                }
                label="Is Active"
              />
              <Box className={styles["card-actions"]}>
                <IconButton type="submit" className={styles["icon-button"]}>
                  <SaveIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleCancelEdit(insurance.insurance_id)}
                  className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </form>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6" component="h3">
                  {insurance.name}
                </Typography>
                <Chip
                  label={insurance.is_active ? "Active" : "Inactive"}
                  color={insurance.is_active ? "success" : "default"}
                />
              </Box>

              <Typography variant="body2" color="textSecondary" paragraph>
                {insurance.description}
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Box display="flex" alignItems="center" mb={1}>
                <Security fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Coverage Type: {insurance.coverage_type}
                </Typography>
              </Box>
              <Typography variant="body2">
                Max Coverage: $
                {parseFloat(insurance.max_coverage_amount).toFixed(2)}
              </Typography>
              <Typography variant="body2">
                Premium Rate: {parseFloat(insurance.premium_rate).toFixed(2)}%
              </Typography>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <IconButton
                  onClick={() => handleEdit(insurance)}
                  className={`${styles["icon-button"]} ${styles["edit-button"]}`}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(insurance.insurance_id)}
                  className={`${styles["icon-button"]} ${styles["delete-button"]}`}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={styles["insurance-management"]}>
      <h1 className={styles.title}>Manage Insurance Options</h1>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage.message}
        </Alert>
      )}
      {isLoading ? (
        <div className={styles["loading-container"]}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles["insurance-grid"]}>
          <Card
            className={`${styles["insurance-card"]} ${styles["add-card"]}`}
            onClick={handleAddNewInsurance}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "2rem",
              textAlign: "center",

              "&:hover": {
                backgroundColor: "#e6f3ff",
                transform: "translateY(-5px)",
                boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <CardContent>
              <AddCircleIcon
                style={{ fontSize: "4rem", marginBottom: "1rem" }}
              />
              <Typography variant="h6" component="h3">
                Add New Insurance Package
              </Typography>
            </CardContent>
          </Card>
          {newInsurance &&
            editingInsuranceId === "new" &&
            renderInsuranceCard({ ...newInsurance, insurance_id: "new" })}
          {insurances.map((insurance) => renderInsuranceCard(insurance))}
        </div>
      )}
    </div>
  );
};

export default InsuranceManagement;
