import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Paper,
  Alert,
  Button,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useAssets } from "./AssetsContext";
import AssetsDashboardOverview from "./AssetsDashboardOverview";
import CourierList from "./CourierList";
import VehicleList from "./VehicleList";
import LocationList from "./LocationList";
import OrderAssignment from "./OrderAssignment";

const AssetsContent = () => {
  const [activeTab, setActiveTab] = useState(() => {
    // Initialize activeTab from localStorage, default to 0 if not found
    return parseInt(localStorage.getItem("assetsActiveTab") || "0", 10);
  });
  const { businessId } = useParams();
  const contentRef = useRef(null);
  const {
    couriers,
    vehicles,
    locations,
    orders,
    groups,
    loading,
    error,
    fetchCouriers,
    fetchVehicles,
    fetchLocations,
    fetchOrders,
    fetchGroups,
    addCourier,
    updateCourier,
    deleteCourier,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    unassignVehicle,
    createVehicleAssignment,
    addLocation,
    updateLocation,
    deleteLocation,
    assignOrder,
    assignOrderToCourier,
  } = useAssets();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    fetchCouriers(businessId);
    fetchVehicles(businessId);
    fetchLocations(businessId);
    fetchOrders(businessId);
    fetchGroups(businessId);
  }, [businessId, fetchCouriers, fetchVehicles, fetchLocations, fetchOrders]);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
    // Store the new active tab in localStorage
    localStorage.setItem("assetsActiveTab", newValue.toString());
  }, []);

  const LoadingSkeleton = () => (
    <Box sx={{ padding: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={118} />
      <Skeleton variant="text" sx={{ marginTop: 2 }} />
      <Skeleton variant="text" />
      <Skeleton variant="text" width="60%" />
    </Box>
  );

  const handleRefresh = useCallback(() => {
    fetchCouriers(businessId);
    fetchVehicles(businessId);
    fetchLocations(businessId);
    fetchOrders(businessId);
  }, [businessId, fetchCouriers, fetchVehicles, fetchLocations, fetchOrders]);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box ref={contentRef} style={{ height: "100%", overflowY: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Assets Management
      </Typography>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
        sx={{ mb: 2 }}
      >
        Refresh Data
      </Button>
      <AssetsDashboardOverview
        employeesCount={couriers.length}
        vehiclesCount={vehicles.length}
        locationsCount={locations.length}
        ordersCount={orders.length}
      />
      <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Couriers" />
          <Tab label="Vehicles" />
          <Tab label="Locations" />
          <Tab label="Order Assignment" />
        </Tabs>
        <div className="tab-content">
          {activeTab === 0 && (
            <CourierList
              couriers={couriers}
              onCourierAdded={(data) => addCourier(data, businessId)}
              onCourierUpdated={(id, data) =>
                updateCourier(id, data, businessId)
              }
              onCourierDeleted={(id) => deleteCourier(id, businessId)}
            />
          )}
          {activeTab === 1 && (
            <VehicleList
              vehicles={vehicles}
              couriers={couriers}
              setVehicles={fetchVehicles}
              onAddVehicle={(data) => addVehicle(data, businessId)}
              onUpdateVehicle={(id, data) =>
                updateVehicle(id, data, businessId)
              }
              onDeleteVehicle={(id) => deleteVehicle(id, businessId)}
              createVehicleAssignment={(assignmentData) =>
                createVehicleAssignment(businessId, assignmentData)
              }
              unassignVehicle={(id) => unassignVehicle(businessId, id)}
              businessId={businessId}
            />
          )}
          {activeTab === 2 && (
            <LocationList
              locations={locations}
              onAddLocation={(data) => addLocation(data, businessId)}
              onUpdateLocation={(id, data) =>
                updateLocation(id, data, businessId)
              }
              onDeleteLocation={(id) => deleteLocation(id, businessId)}
            />
          )}
          {activeTab === 3 && (
            <OrderAssignment
              orders={orders}
              groups={groups}
              couriers={couriers}
              vehicles={vehicles}
              locations={locations}
              onAssignOrder={(orderId, assignmentData) =>
                assignOrder(orderId, assignmentData, businessId)
              }
              onAssignOrderToCourier={(orderId, employeeId) =>
                assignOrderToCourier(orderId, employeeId, businessId)
              }
            />
          )}
        </div>
      </Paper>
    </Box>
  );
};

export default AssetsContent;
