import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  TextField,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Button,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  CheckCircle,
} from "@mui/icons-material";
import AddHomeIcon from "@mui/icons-material/AddHome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styles from "./AddressesPage.module.css";

const libraries = ["places"];

const AddressesPage = () => {
  const { api } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [defaultAddressId, setDefaultAddressId] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("sidebarActive", JSON.stringify(isActive));
  }, []);

  const fetchAddresses = useCallback(async () => {
    if (dataFetched) return;
    setIsLoading(true);
    try {
      const response = await api.get("/address/addresses", {});
      setAddresses(response.data);
      const defaultAddress = response.data.find((addr) => addr.is_default);
      if (defaultAddress) {
        setDefaultAddressId(defaultAddress.address_id);
      }
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch addresses. Please try again.",
      });
    }
    setIsLoading(false);
  }, [dataFetched]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const handleChange = (e, addressId) => {
    const { name, value } = e.target;
    if (addressId === "new") {
      setNewAddress((prev) => ({ ...prev, [name]: value }));
    } else {
      setAddresses(
        addresses.map((addr) =>
          addr.address_id === addressId ? { ...addr, [name]: value } : addr
        )
      );
    }
  };

  const handleAddressSelect = async (selectedAddress, addressId) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((comp) =>
          comp.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const newAddressData = {
        address_line1: selectedAddress,
        address_line2: getAddressComponent("subpremise"),
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        postal_code: getAddressComponent("postal_code"),
        country: getAddressComponent("country"),
        latitude: latLng.lat,
        longitude: latLng.lng,
      };

      if (addressId === "new") {
        setNewAddress(newAddressData);
      } else {
        setAddresses(
          addresses.map((addr) =>
            addr.address_id === addressId
              ? { ...addr, ...newAddressData }
              : addr
          )
        );
      }
    } catch (error) {
      console.error("Error selecting address:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to process the selected address. Please try again.",
      });
    }
  };

  const handleSave = async (addressId, updatedAddress) => {
    setIsLoading(true);
    try {
      let response;
      if (addressId === "new") {
        response = await api.post("/address/addresses", updatedAddress, {});
        setAddresses([...addresses, response.data]);
        setNewAddress(null);
      } else {
        response = await api.put(`/address/${addressId}`, updatedAddress);
        setAddresses(
          addresses.map((addr) =>
            addr.address_id === addressId ? response.data : addr
          )
        );
      }
      setEditingAddressId(null);
      setAlertMessage({
        type: "success",
        message: `Address ${
          addressId === "new" ? "added" : "updated"
        } successfully!`,
      });
      // check if the updated address is the default address
      if (response.data.is_default) {
        setDefaultAddressId(response.data.address_id);
      }
    } catch (error) {
      console.error("Error saving address:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message || error.response.data.msg
          ? error.response.data.msg
          : "";
      setAlertMessage({
        type: "error",
        message: `Failed to ${
          addressId === "new" ? "add" : "update"
        } address. ${errorMessage}`,
      });
    }
    setIsLoading(false);
  };

  const handleDelete = async (addressId) => {
    setIsLoading(true);
    try {
      await api.delete(`/address/${addressId}`, {});
      setAddresses(addresses.filter((addr) => addr.address_id !== addressId));
      if (addressId === defaultAddressId) {
        setDefaultAddressId(null);
      }
      setAlertMessage({
        type: "success",
        message: "Address deleted successfully!",
      });
    } catch (error) {
      console.error("Error deleting address:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete address. Please try again.",
      });
    }
    setEditingAddressId(null);
    setIsLoading(false);
  };

  const setDefaultAddress = async (addressId) => {
    setIsLoading(true);
    try {
      await api.put(`/address/set-default/${addressId}`, {});
      setDefaultAddressId(addressId);
      setAlertMessage({
        type: "success",
        message: "Default address updated successfully!",
      });
    } catch (error) {
      console.error("Error setting default address:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to set default address. Please try again.",
      });
    }
    setIsLoading(false);
    setEditingAddressId(null);
  };

  const [originalAddressData, setOriginalAddressData] = useState(null);

  const handleEdit = (address) => {
    setEditingAddressId(address.address_id);
    setOriginalAddressData({ ...address });
  };

  const handleCancelEdit = (addressId) => {
    if (addressId === "new") {
      setNewAddress(null);
    } else {
      setAddresses(
        addresses.map((addr) =>
          addr.address_id === addressId ? originalAddressData : addr
        )
      );
    }
    setEditingAddressId(null);
  };

  const renderAddressCard = (address) => {
    const handleFormSubmit = (e) => {
      e.preventDefault(); // Prevent the default form submission behavior

      // Collect the form data
      const formData = new FormData(e.target);
      const phone =
        formData.get("phone") ||
        address.phone ||
        (user.user_phone ? user.user_phone.toString() : "");
      // Ensure phone number is included even if not edited
      const updatedAddress = {
        address_line1: formData.get("address_line1"),
        address_line2: formData.get("address_line2"),
        city: formData.get("city"),
        state: formData.get("state"),
        postal_code: formData.get("postal_code"),
        country: formData.get("country"),
        phone: phone,
      };

      console.log(updatedAddress);

      handleSave(address.address_id, updatedAddress); // Pass form data to handleSave
    };

    const isEditing =
      address.address_id === editingAddressId || address.address_id === "new";
    const isDefault = address.address_id === defaultAddressId;

    return (
      <Card className={styles["address-card"]}>
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {isEditing ? (
            <form
              className={styles["address-form"]}
              onSubmit={handleFormSubmit}
            >
              <PlacesAutocomplete
                value={address.address_line1}
                onChange={(value) =>
                  handleChange(
                    { target: { name: "address_line1", value } },
                    address.address_id
                  )
                }
                onSelect={(selectedAddress) =>
                  handleAddressSelect(selectedAddress, address.address_id)
                }
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className={styles["autocomplete-wrapper"]}>
                    <TextField
                      {...getInputProps({
                        name: "address_line1",
                        label: "Address Line 1",
                        fullWidth: true,
                        margin: "none",
                        required: true,
                        className: styles["form-field"],
                      })}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "0.8rem",
                          height: "30px",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.8rem",
                          padding: "8px",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.75rem",
                          top: "-6px",
                        },
                        "& .MuiInputLabel-shrink": { top: "0" },
                        "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                      }}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className={styles["autocomplete-dropdown-container"]}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? styles["suggestion-item--active"]
                            : styles["suggestion-item"];
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                              key={index}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>

              <TextField
                name="address_line2"
                label="Address Line 2"
                type="text"
                value={address.address_line2}
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={false}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="city"
                label="City"
                type="text"
                value={address.city}
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={true}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="state"
                label="State"
                type="text"
                value={address.state}
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={true}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="postal_code"
                label="Postal Code"
                type="text"
                value={address.postal_code}
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={true}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="country"
                label="Country"
                type="text"
                value={address.country}
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={true}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="phone"
                label="Phone Number"
                type="tel"
                value={
                  address.phone
                    ? address.phone.toString()
                    : user.user_phone
                    ? user.user_phone.toString()
                    : ""
                }
                onChange={(e) => handleChange(e, address.address_id)}
                fullWidth
                margin="dense"
                required={false}
                className={styles["form-field"]}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <div className={styles["card-actions"]}>
                <IconButton
                  aria-label="save"
                  type="submit"
                  size="small"
                  variant="contained"
                  className={styles["icon-button"]}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  aria-label="cancel"
                  type="reset"
                  onClick={() => {
                    handleCancelEdit(address.address_id);
                  }}
                  margin="dense"
                  size="small"
                  className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </form>
          ) : (
            <div
              className={styles["address-details"]}
              onClick={() => handleEdit(address)}
            >
              <Typography variant="h6" component="h3">
                {address.address_line1}
              </Typography>
              <Typography color="textSecondary">
                {address.address_line2}
              </Typography>
              <Typography color="textSecondary">
                {`${address.city}, ${address.state} ${address.postal_code}`}
              </Typography>
              <Typography color="textSecondary">{address.country}</Typography>
              {address.phone && (
                <Typography color="textSecondary">{`Phone: ${address.phone}`}</Typography>
              )}
              {isDefault && (
                <Chip
                  icon={<CheckCircle style={{ color: "white" }} />}
                  label="Default"
                  size="small"
                  style={{ backgroundColor: "#4CAF50", color: "white" }}
                  className={styles.defaultChip}
                />
              )}
              <div className={styles["card-actions"]}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit(address)}
                  size="small"
                  color="midnightblue"
                  className={`${styles["icon-button"]} ${styles["edit-button"]}`}
                  sx={{
                    color: "var(--midnight-blue)",
                    borderColor: "var(--midnight-blue)",
                    "&:hover": {
                      color: "var(--soft-blue)",
                      borderColor: "var(--soft-blue)",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(address.address_id)}
                  size="small"
                  className={`${styles["icon-button"]} ${styles["delete-button"]}`}
                  sx={{
                    color: "var(--midnight-blue)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                {!isDefault && (
                  <Button
                    variant="filled"
                    size="small"
                    onClick={() => setDefaultAddress(address.address_id)}
                    startIcon={<CheckCircleOutlineIcon />}
                    sx={{
                      color: "var(--midnight-blue)",
                      borderColor: "var(--midnight-blue)",
                      "&:hover": {
                        color: "var(--soft-blue)",
                        borderColor: "var(--soft-blue)",
                      },
                    }}
                  >
                    Set as Default
                  </Button>
                )}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={styles["addresses-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["addresses-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Manage Addresses</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            {isLoading ? (
              <div className={styles["loading-container"]}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles["addresses-grid"]}>
                <Card
                  key="add-new-address"
                  className={`${styles["address-card"]} ${styles["add-card"]}`}
                  onClick={() =>
                    setNewAddress({
                      address_line1: "",
                      address_line2: "",
                      city: "",
                      state: "",
                      postal_code: "",
                      country: "",
                      phone: user.user_phone ? user.user_phone.toString() : "",
                    })
                  }
                >
                  <CardContent
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddHomeIcon
                      style={{ fontSize: "4rem", marginBottom: "1rem" }}
                    />
                    <Typography variant="h6" component="h3">
                      Add New Address
                    </Typography>
                  </CardContent>
                </Card>
                {newAddress && (
                  <div key="new-address">
                    {renderAddressCard({ ...newAddress, address_id: "new" })}
                  </div>
                )}
                {addresses.map((address) => (
                  <div key={address.address_id}>
                    {renderAddressCard(address)}
                  </div>
                ))}
              </div>
            )}
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default AddressesPage;
