import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Button,
  MenuItem,
  Box,
  Switch,
  Collapse,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  CircularProgress,
  Paper,
  Divider,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
  PlayArrow as StartIcon,
  Check as CompleteIcon,
  Cancel as CancelIcon,
  Visibility as ViewIcon,
  Receipt as InvoiceIcon,
  Undo as ReturnIcon,
  Refresh as ReactivateIcon,
  NoteAdd as AddNoteIcon,
  Search as SearchIcon,
  GetApp as ExportIcon,
  MoreVert as MoreVertIcon,
  Print as PrintIcon,
  Update as UpdateIcon,
  GroupWork as GroupIcon,
  ArrowDropDown as ArrowDropDownIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import { useOrders } from "./OrdersContext";
import {
  useOrderActions,
  OrderActionMenu,
} from "../../../../components/Actions/ShippingOrderActions";
import GroupActionMenu from "../../../../components/Actions/GroupActionMenu";
import MultiOrderPrintDialog from "./MultiOrderPrintDialog";
import CollaborationComponent from "./CollaborationComponent";
import CollaborationDetailsDialog from "./CollaborationDetailsDialog";
import { Handshake as CollaborationIcon } from "@mui/icons-material";

const OrderList = ({
  onOrderUpdate,
  onCompleteOrder,
  onDeleteDraftOrder,
  initialSearchQuery,
}) => {
  const {
    orders,
    groups,
    loading,
    error,
    updateOrderStatus,
    addOrderToGroup,
    removeOrderFromGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    setOrders,
    fetchOrders,
  } = useOrders();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    serviceLevel: "",
    groupId: "",
    orderCount: "",
  });
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isGroupedView, setIsGroupedView] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isGroupManagementOpen, setIsGroupManagementOpen] = useState(false);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
  const [activeOrderForStatusMenu, setActiveOrderForStatusMenu] =
    useState(null);
  const [groupMenuAnchorEl, setGroupMenuAnchorEl] = useState(null);
  const [activeOrderForGroupMenu, setActiveOrderForGroupMenu] = useState(null);
  const [loadingOrderId, setLoadingOrderId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { businessId } = useParams();
  const tableRef = useRef(null);
  const { handleAction } = useOrderActions(setOrders);
  const [isBulkStatusDialogOpen, setIsBulkStatusDialogOpen] = useState(false);
  const [isMultiPrintDialogOpen, setIsMultiPrintDialogOpen] = useState(false);
  const [groupManagementAnchorEl, setGroupManagementAnchorEl] = useState(null);
  const [ineligibleOrderWarning, setIneligibleOrderWarning] = useState("");
  const [isCollaborationDetailsOpen, setIsCollaborationDetailsOpen] =
    useState(false);
  const [selectedCollaborations, setSelectedCollaborations] = useState([]);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [isCollaborationComponentOpen, setIsCollaborationComponentOpen] =
    useState(false);
  const [currentOrderForCollaboration, setCurrentOrderForCollaboration] =
    useState(null);
  // New state for draft order menu
  const [draftMenuAnchorEl, setDraftMenuAnchorEl] = useState(null);
  const [activeDraftOrder, setActiveDraftOrder] = useState(null);

  const getLatestCollaboration = (collaborations) => {
    if (!collaborations || collaborations.length === 0) return null;
    return collaborations.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0];
  };

  const handleOpenCollaborationComponent = (orderId) => {
    const order = orders.find((o) => o.shipping_order_id === orderId);
    if (!order) return;

    if (order.ShippingCollaborations?.length > 0) {
      const latestCollaboration = getLatestCollaboration(
        order.ShippingCollaborations
      );
      const isActive = [
        "proposed",
        "accepted",
        "counter_offered",
        "completed",
      ].includes(latestCollaboration.status);

      if (isActive) {
        setIneligibleOrderWarning(
          "Cannot start new collaboration while there is an active one."
        );
        return;
      }
    }

    setSelectedOrders([orderId]);
    setCurrentOrderForCollaboration(order);
    setIsCollaborationComponentOpen(true);
  };

  // Update the refresh function to use fetchOrders
  const refreshOrderData = useCallback(async () => {
    try {
      await fetchOrders(businessId, true); // Pass true to force refresh
    } catch (error) {
      console.error("Failed to refresh orders:", error);
    }
  }, [businessId, fetchOrders]);

  const handleCloseCollaborationComponent = async (wasUpdated = false) => {
    setIsCollaborationComponentOpen(false);
    setCurrentOrderForCollaboration(null);
    if (wasUpdated) {
      console.log("Collaboration was updated, refreshing orders...");
      await refreshOrderData();
    } else {
      console.log("Collaboration was not updated, no need to refresh orders");
    }
  };

  // Function to handle opening the draft order menu
  const handleDraftMenuOpen = (event, order) => {
    setDraftMenuAnchorEl(event.currentTarget);
    setActiveDraftOrder(order);
  };

  // Function to handle closing the draft order menu
  const handleDraftMenuClose = () => {
    setDraftMenuAnchorEl(null);
    setActiveDraftOrder(null);
  };

  // Function to handle draft order actions
  const handleDraftAction = (action) => {
    if (activeDraftOrder) {
      if (action === "complete") {
        onCompleteOrder(activeDraftOrder);
      } else if (action === "delete") {
        onDeleteDraftOrder(activeDraftOrder.shipping_order_id);
      }
    }
    handleDraftMenuClose();
  };

  // Filter and search logic
  const filterAndSearchItems = useCallback(() => {
    let result = isGroupedView ? groups : orders;
    if (filters.status) {
      result = result.filter((item) =>
        isGroupedView
          ? item.status === filters.status
          : item.order_status === filters.status
      );
    }
    if (filters.serviceLevel && !isGroupedView) {
      result = result.filter(
        (order) => order.ShippingTier?.service_level === filters.serviceLevel
      );
    }
    if (filters.groupId && !isGroupedView) {
      const group = groups.find((g) => g.group_id === filters.groupId);
      result = result.filter((order) =>
        group?.ShippingOrders.some(
          (go) => go.shipping_order_id === order.shipping_order_id
        )
      );
    }
    if (isGroupedView && filters.orderCount) {
      result = result.filter((group) => {
        const orderCount = group.ShippingOrders.length;
        switch (filters.orderCount) {
          case "low":
            return orderCount < 5;
          case "medium":
            return orderCount >= 5 && orderCount < 10;
          case "high":
            return orderCount >= 10;
          default:
            return true;
        }
      });
    }
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter((item) =>
        isGroupedView
          ? item.name.toLowerCase().includes(query)
          : item.shipping_order_id.toLowerCase().includes(query) ||
            item.sender?.first_name.toLowerCase().includes(query) ||
            item.sender?.last_name.toLowerCase().includes(query) ||
            item.recipient?.first_name.toLowerCase().includes(query) ||
            item.recipient?.last_name.toLowerCase().includes(query)
      );
    }
    if (isGroupedView) {
      setFilteredGroups(result);
    } else {
      setFilteredOrders(result);
    }
  }, [orders, groups, filters, searchQuery, isGroupedView]);

  useEffect(() => {
    console.log("Orders or filters changed, filtering items...");
    filterAndSearchItems();
  }, [orders, filters, searchQuery, isGroupedView]);

  useEffect(() => {
    console.log("Orders are:", orders);
    if (initialSearchQuery) {
      console.log("Initial search query:", initialSearchQuery);
      setSearchQuery(initialSearchQuery);
      setCurrentPage(1);
    }
  }, [initialSearchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const toggleGroupView = () => {
    setIsGroupedView(!isGroupedView);
    setCurrentPage(1);
  };

  const handleExpandGroup = (groupId) => {
    setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
  };
  const handleOrderClick = useCallback(
    (orderId) => {
      const order = orders.find((o) => o.shipping_order_id === orderId);
      if (order && order.order_status === "draft") {
        onCompleteOrder(order);
      } else {
        const currentPath = location.pathname;
        navigate(`${currentPath}/${orderId}`);
      }
    },
    [orders, navigate, businessId, onCompleteOrder]
  );

  const handleCreateGroup = async () => {
    try {
      await createGroup({ name: "New Group" }, businessId);
    } catch (err) {
      console.error("Failed to create group:", err);
    }
  };

  // Status change handling
  const handleStatusMenuOpen = (event, orderId) => {
    setStatusMenuAnchorEl(event.currentTarget);
    setActiveOrderForStatusMenu(orderId);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
    setActiveOrderForStatusMenu(null);
  };

  // Individual order status update handler
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      setLoadingOrderId(orderId);
      handleStatusMenuClose();
      console.log("Updating order status...");
      console.log("Order ID: ", orderId);
      console.log("New Status: ", newStatus);

      // Wait for the updateOrderStatus to complete
      const updatedOrder = await updateOrderStatus(orderId, newStatus);

      // Update the local orders state to reflect the change
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipping_order_id === orderId
            ? { ...order, ...updatedOrder } // Use the complete updated order from backend
            : order
        )
      );

      // Trigger a re-filter of the orders
      filterAndSearchItems();
      console.log("Order status updated successfully");
    } catch (error) {
      console.error("Failed to update order status:", error);
    } finally {
      setLoadingOrderId(null);
      handleStatusMenuClose();
    }
  };

  // Group management
  const handleGroupMenuOpen = (event, orderId) => {
    setGroupMenuAnchorEl(event.currentTarget);
    setActiveOrderForGroupMenu(orderId);
  };

  const handleGroupMenuClose = () => {
    setGroupMenuAnchorEl(null);
    setActiveOrderForGroupMenu(null);
  };

  const handleAddOrderToGroup = async (groupId, orderId) => {
    try {
      // if order is in a group, remove it first
      const currentGroup = groups.find((group) =>
        group.ShippingOrders.some(
          (order) => order.shipping_order_id === orderId
        )
      );
      if (currentGroup) {
        await removeOrderFromGroup(currentGroup.group_id, orderId, businessId);
      }

      await addOrderToGroup(groupId, orderId, businessId);
    } catch (err) {
      console.error("Failed to add order to group:", err);
    }
  };

  const handleRemoveOrderFromGroup = async (groupId, orderId) => {
    try {
      await removeOrderFromGroup(groupId, orderId, businessId);
    } catch (err) {
      console.error("Failed to remove order from group:", err);
    }
  };

  const handleChangeOrderGroup = async (groupId) => {
    if (activeOrderForGroupMenu) {
      try {
        // if order is in a group, remove it first
        const currentGroup = groups.find((group) =>
          group.ShippingOrders.some(
            (order) => order.shipping_order_id === activeOrderForGroupMenu
          )
        );
        if (currentGroup) {
          await removeOrderFromGroup(
            currentGroup.group_id,
            activeOrderForGroupMenu,
            businessId
          );
        }
        if (groupId) {
          await addOrderToGroup(groupId, activeOrderForGroupMenu, businessId);
        } else {
          const currentGroup = groups.find((group) =>
            group.ShippingOrders.some(
              (order) => order.shipping_order_id === activeOrderForGroupMenu
            )
          );
          if (currentGroup) {
            await removeOrderFromGroup(
              currentGroup.group_id,
              activeOrderForGroupMenu,
              businessId
            );
          }
        }
      } catch (err) {
        console.error("Failed to change order group:", err);
      }
    }
    handleGroupMenuClose();
  };

  const handleBulkAction = (action, event) => {
    switch (action) {
      case "updateStatus":
        setIsBulkStatusDialogOpen(true);
        break;
      case "delete":
        console.log("Deleting orders:", selectedOrders);
        // Implement delete logic here
        break;
      case "print":
        setIsMultiPrintDialogOpen(true);
        break;
      case "manageGroups":
        setGroupManagementAnchorEl(event.currentTarget);
        break;
      case "removeFromGroups":
        handleRemoveFromAllGroups(selectedOrders);
        break;
      case "collaborate":
        if (isCollaborateDisabled) {
          setIneligibleOrderWarning(
            "Some selected orders are not pending or already have collaborators. Please deselect these orders to proceed with collaboration."
          );
          return;
        }
        setIneligibleOrderWarning(""); // Clear any existing warning
        setIsCollaborationComponentOpen(true);
        break;
      default:
        console.log(`Unhandled bulk action: ${action}`);
    }
  };

  const handleGroupManagementClose = () => {
    setGroupManagementAnchorEl(null);
  };

  const handleAddToExistingGroup = (groupId) => {
    selectedOrders.forEach((orderId) => {
      handleAddOrderToGroup(groupId, orderId);
    });
    handleGroupManagementClose();
  };

  const handleCreateNewGroupWithOrders = async () => {
    try {
      // make the group name the current group total + 1
      const groupCount = groups.length;
      const newGroupName = `Group ${groupCount + 1}`;
      const newGroup = await createGroup({ name: newGroupName }, businessId);
      selectedOrders.forEach((orderId) => {
        handleAddOrderToGroup(newGroup.group_id, orderId);
      });
      handleGroupManagementClose();
    } catch (err) {
      console.error("Failed to create new group and add orders:", err);
    }
  };

  const handleBulkStatusUpdate = async (newStatus) => {
    try {
      console.log("Starting bulk status update to:", newStatus);
      console.log("Selected orders:", selectedOrders);

      // Create an array to store all update promises
      const updatePromises = selectedOrders.map(async (orderId) => {
        try {
          return await updateOrderStatus(orderId, newStatus);
        } catch (err) {
          console.error(`Failed to update order ${orderId}:`, err);
          return null;
        }
      });

      // Wait for all updates to complete
      const updatedOrders = await Promise.all(updatePromises);

      // Update the orders state with all successful updates
      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          const updatedOrder = updatedOrders.find(
            (updated) => updated?.shipping_order_id === order.shipping_order_id
          );
          return updatedOrder ? { ...order, ...updatedOrder } : order;
        })
      );

      // Trigger a re-filter of the orders
      filterAndSearchItems();
      console.log("Bulk order status update completed");
    } catch (error) {
      console.error("Failed to update bulk order status:", error);
    } finally {
      setIsBulkStatusDialogOpen(false);
      setSelectedOrders([]); // Clear selection after bulk update
    }
  };

  const handleRemoveFromAllGroups = async (orderIds) => {
    try {
      for (const orderId of orderIds) {
        const groupsContainingOrder = groups.filter((group) =>
          group.ShippingOrders.some((o) => o.shipping_order_id === orderId)
        );
        for (const group of groupsContainingOrder) {
          await removeOrderFromGroup(group.group_id, orderId, businessId);
        }
      }
      // Refresh the orders and groups data after removing from groups
      // You might want to implement a function to refresh the data from your API
      // refreshOrdersAndGroups();
    } catch (err) {
      console.error("Failed to remove orders from groups:", err);
    }
  };

  // Function to handle group orders status update
  const handleUpdateGroupOrdersStatus = async (groupId, newStatus) => {
    try {
      const group = groups.find((g) => g.group_id === groupId);
      if (!group) return;

      const updatePromises = group.ShippingOrders.map(async (orderInfo) => {
        try {
          return await updateOrderStatus(
            orderInfo.shipping_order_id,
            newStatus
          );
        } catch (err) {
          console.error(
            `Failed to update order ${orderInfo.shipping_order_id}:`,
            err
          );
          return null;
        }
      });

      const updatedOrders = await Promise.all(updatePromises);

      // Update the orders state with all successful updates
      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          const updatedOrder = updatedOrders.find(
            (updated) => updated?.shipping_order_id === order.shipping_order_id
          );
          return updatedOrder ? { ...order, ...updatedOrder } : order;
        })
      );

      // Trigger a re-filter of the orders
      filterAndSearchItems();
      console.log("Group orders status update completed");
    } catch (err) {
      console.error("Failed to update group orders status:", err);
    }
  };

  const isCollaborateDisabled = useMemo(() => {
    return selectedOrders.some((orderId) => {
      const order = orders.find((o) => o.shipping_order_id === orderId);
      if (!order || order.order_status !== "pending") return true;

      if (order.ShippingCollaborations?.length > 0) {
        const latestCollaboration = getLatestCollaboration(
          order.ShippingCollaborations
        );
        const isActive = [
          "proposed",
          "accepted",
          "counter_offered",
          "completed",
        ].includes(latestCollaboration.status);
        return isActive;
      }

      return false;
    });
  }, [selectedOrders, orders]);

  const handleSelectOrder = (orderId) => {
    const order = orders.find((o) => o.shipping_order_id === orderId);
    if (order && order.order_status !== "draft") {
      setSelectedOrders((prev) =>
        prev.includes(orderId)
          ? prev.filter((id) => id !== orderId)
          : [...prev, orderId]
      );
    }
  };

  const handleSelectAllOrders = (event) => {
    if (event.target.checked) {
      const allOrderIds = paginatedItems
        .filter((item) => !isGroupedView && item.order_status !== "draft")
        .map((item) => item.shipping_order_id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };

  const handleDeleteDraftOrder = async (orderId) => {
    // Implement draft order deletion logic
    console.log(`Deleting draft order: ${orderId}`);

    onDeleteDraftOrder(orderId);
  };

  const renderStatusChip = (status, shipping_order_id, isGroup = false) => {
    const statusColors = {
      pending: "warning",
      processing: "primary",
      shipped: "info",
      out_for_delivery: "warning",
      delivered: "success",
      cancelled: "error",
      active: "success",
      inactive: "error",
      draft: "default",
    };

    const formatStatus = (s) => {
      if (typeof s !== "string") {
        return ""; // or return some default value, or 'Unknown Status'
      }
      return s
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    return loadingOrderId === shipping_order_id ? (
      <Skeleton variant="rect" width={100} height={24} />
    ) : (
      <Chip
        label={formatStatus(status)}
        color={statusColors[status] || "default"}
        size="small"
        onClick={
          isGroup || status === "draft"
            ? undefined
            : (event) => handleStatusMenuOpen(event, shipping_order_id)
        }
        onDelete={
          isGroup || status === "draft"
            ? undefined
            : (event) => handleStatusMenuOpen(event, shipping_order_id)
        }
        deleteIcon={
          isGroup || status === "draft" ? undefined : <ArrowDropDownIcon />
        }
      />
    );
  };

  const getCollaborationStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "primary";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "completed":
        return "info";
      default:
        return "default";
    }
  };

  // Update the renderCollaborationStatus function
  const handleViewCollaboration = (collaborationData) => {
    setSelectedCollaboration({
      allCollaborations: collaborationData.ShippingCollaborations || [],
    });
    setIsCollaborationDetailsOpen(true);
  };

  // Update the renderCollaborationStatus function
  const renderCollaborationStatus = (order) => {
    if (order.order_status === "draft") {
      return (
        <Tooltip title={`Order is a draft`}>
          <Chip icon={<CollaborationIcon />} label="None" color="default" />
        </Tooltip>
      );
    }

    if (order.ShippingCollaborations?.length > 0) {
      console.log("Getting latest collaboration for order: ", order);
      console.log("Collaborations: ", order.ShippingCollaborations);
      const latestCollaboration = getLatestCollaboration(
        order.ShippingCollaborations
      );
      const isActive = [
        "proposed",
        "accepted",
        "counter_offered",
        "completed",
      ].includes(latestCollaboration.status);

      return (
        <Tooltip
          title={`Latest Collaboration Status: ${latestCollaboration.status}${
            isActive ? " (Active)" : ""
          }`}
        >
          <Chip
            icon={<CollaborationIcon />}
            label={latestCollaboration.status}
            color={getCollaborationStatusColor(latestCollaboration.status)}
            onClick={() => {
              handleViewCollaboration({
                ...latestCollaboration,
                order_status: order.order_status,
                ShippingCollaborations: order.ShippingCollaborations, // Pass all collaborations for history
              });
            }}
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={`No Collaboration`}>
          <Chip
            icon={<CollaborationIcon />}
            onClick={() =>
              order.order_status === "pending"
                ? handleOpenCollaborationComponent(order.shipping_order_id)
                : null
            }
            label="None"
            color="default"
            sx={{
              cursor: order.order_status === "pending" ? "pointer" : "default",
            }}
          />
        </Tooltip>
      );
    }
  };

  // Add these new functions to handle draft orders
  const handleCompleteDraft = async (orderId) => {
    try {
      // Implement the logic to complete a draft order
      console.log(`Completing draft order: ${orderId}`);
      // After completing, you might want to refresh the order list or update the order status
    } catch (error) {
      console.error("Failed to complete draft order:", error);
    }
  };

  const renderOrderRow = (order) => (
    <TableRow key={order.shipping_order_id}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedOrders.includes(order.shipping_order_id)}
          onChange={() => handleSelectOrder(order.shipping_order_id)}
          disabled={order.order_status === "draft"}
        />
      </TableCell>
      <TableCell>
        <Button onClick={() => handleOrderClick(order.shipping_order_id)}>
          {order.shipping_order_id}
        </Button>
      </TableCell>
      <TableCell>{`${order.sender?.first_name} ${order.sender?.last_name}`}</TableCell>
      <TableCell>{order.sender?.city}</TableCell>
      <TableCell>{order.recipient?.city}</TableCell>
      <TableCell>
        {renderStatusChip(order.order_status, order.shipping_order_id)}
      </TableCell>
      <TableCell>{order.ShippingTier?.service_level}</TableCell>
      <TableCell>{renderCollaborationStatus(order)}</TableCell>
      <TableCell>
        <Chip
          label={
            groups?.find((g) =>
              g.ShippingOrders?.some(
                (o) => o.shipping_order_id === order.shipping_order_id
              )
            )?.name || "No Group"
          }
          variant={
            groups?.find((g) =>
              g.ShippingOrders?.some(
                (o) => o.shipping_order_id === order.shipping_order_id
              )
            )
              ? "filled"
              : "outlined"
          }
          onClick={
            order.order_status !== "draft"
              ? (event) => handleGroupMenuOpen(event, order.shipping_order_id)
              : undefined
          }
        />
      </TableCell>
      <TableCell>
        {order.order_status === "draft" ? (
          <IconButton onClick={(event) => handleDraftMenuOpen(event, order)}>
            <MoreVertIcon />
          </IconButton>
        ) : (
          <OrderActionMenu
            order={order}
            onAction={handleAction}
            isDraft={false}
          />
        )}
      </TableCell>
    </TableRow>
  );

  const renderGroupRow = (group) => {
    const isExpanded = expandedGroups[group.group_id];

    return (
      <React.Fragment key={group.group_id}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedOrders.includes(group.group_id)}
              onChange={() => handleSelectOrder(group.group_id)}
            />
          </TableCell>
          <TableCell>
            <IconButton
              size="small"
              onClick={() => handleExpandGroup(group.group_id)}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{group?.name}</TableCell>
          <TableCell>{group?.ShippingOrders.length}</TableCell>
          <TableCell>${parseFloat(group?.total_value).toFixed(2)}</TableCell>
          <TableCell>
            {new Date(group?.createdAt).toLocaleDateString()}
          </TableCell>
          <TableCell>{renderStatusChip(group?.status, true)}</TableCell>
          <TableCell>
            <GroupActionMenu
              group={group}
              onEdit={(groupId, newName) =>
                updateGroup(groupId, { name: newName }, businessId)
              }
              onDelete={(groupId) => deleteGroup(groupId, businessId)}
              onUpdateGroupStatus={(groupId, status) =>
                updateGroup(groupId, { status }, businessId)
              }
              onUpdateOrdersStatus={(groupId, status) =>
                handleUpdateGroupOrdersStatus(groupId, status)
              }
              onPrint={() => {}} // This is now handled within the component
              orders={orders} // Pass the orders to the GroupActionMenu
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Orders in {group?.name}
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Sender</TableCell>
                      <TableCell>Recipient</TableCell>
                      <TableCell>Origin</TableCell>
                      <TableCell>Destination</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Level</TableCell>
                      <TableCell>Collaboration</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group?.ShippingOrders.map((orderInfo) => {
                      const order = orders.find(
                        (o) =>
                          o.shipping_order_id === orderInfo.shipping_order_id
                      );
                      return order ? renderOrderRow(order) : null;
                    })}
                  </TableBody>
                </Table>
                <Autocomplete
                  options={orders.filter(
                    (o) =>
                      !group?.ShippingOrders.some(
                        (go) => go.shipping_order_id === o.shipping_order_id
                      )
                  )}
                  getOptionLabel={(option) =>
                    `${option.shipping_order_id} - ${option.sender?.first_name} ${option.sender?.last_name}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Order to Group"
                      margin="normal"
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleAddOrderToGroup(
                        group.group_id,
                        newValue.shipping_order_id
                      );
                    }
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const paginatedItems = useMemo(() => {
    const items = isGroupedView ? filteredGroups : filteredOrders;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  }, [
    isGroupedView,
    filteredGroups,
    filteredOrders,
    currentPage,
    itemsPerPage,
  ]);

  return (
    <>
      {ineligibleOrderWarning && (
        <Alert
          severity="warning"
          onClose={() => setIneligibleOrderWarning("")}
          sx={{ mb: 2 }}
        >
          {ineligibleOrderWarning}
        </Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      <Card>
        <CardHeader
          title={isGroupedView ? "Order Groups" : "Orders"}
          action={
            <Box display="flex" alignItems="center">
              {isGroupedView && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleCreateGroup}
                  sx={{ ml: 2 }}
                >
                  Create Group
                </Button>
              )}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Group View
              </Typography>
              <Switch checked={isGroupedView} onChange={toggleGroupView} />
            </Box>
          }
        />
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  label="Status"
                >
                  <MenuItem value="">All</MenuItem>
                  {isGroupedView ? (
                    <>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="processing">Processing</MenuItem>
                      <MenuItem value="shipped">Shipped</MenuItem>
                      <MenuItem value="delivered">Delivered</MenuItem>
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                      <MenuItem value="out_for_delivery">
                        Out for Delivery
                      </MenuItem>
                    </>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {isGroupedView ? (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Order Count</InputLabel>
                  <Select
                    name="orderCount"
                    value={filters.orderCount}
                    onChange={handleFilterChange}
                    label="Order Count"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="low">Low (0-4)</MenuItem>
                    <MenuItem value="medium">Medium (5-9)</MenuItem>
                    <MenuItem value="high">High (10+)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Service Level</InputLabel>
                  <Select
                    name="serviceLevel"
                    value={filters.serviceLevel}
                    onChange={handleFilterChange}
                    label="Service Level"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Express">Express</MenuItem>
                    <MenuItem value="Priority">Priority</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items per page"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={() => handleBulkAction("updateStatus")}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Update Status
            </Button>
            {/* <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteForeverIcon />}
              onClick={() => handleBulkAction("delete")}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Delete
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => handleBulkAction("print")}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Print
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GroupIcon />}
              onClick={(event) => handleBulkAction("manageGroups", event)}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Manage Groups
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GroupIcon />}
              onClick={(event) => handleBulkAction("collaborate", event)}
              disabled={selectedOrders.length === 0 || isCollaborateDisabled}
              sx={{ mr: 1 }}
            >
              Collaborate
            </Button>
            <CSVLink
              data={isGroupedView ? filteredGroups : filteredOrders}
              filename={isGroupedView ? "groups.csv" : "orders.csv"}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<ExportIcon />}
                disabled={paginatedItems.length === 0}
                sx={{ mr: 1 }}
              >
                Export CSV
              </Button>
            </CSVLink>
          </Box>

          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={handleSelectAllOrders}
                      checked={
                        selectedOrders.length === paginatedItems.length &&
                        paginatedItems.length > 0
                      }
                      indeterminate={
                        selectedOrders.length > 0 &&
                        selectedOrders.length < paginatedItems.length
                      }
                    />
                  </TableCell>
                  {isGroupedView ? (
                    <>
                      <TableCell />
                      <TableCell>Group Name</TableCell>
                      <TableCell>Order Count</TableCell>
                      <TableCell>Total Value</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Origin</TableCell>
                      <TableCell>Destination</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Level</TableCell>
                      <TableCell>Collaboration</TableCell>
                      <TableCell>Group</TableCell>
                      <TableCell>Actions</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={isGroupedView ? 8 : 9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedItems.map((item) =>
                    isGroupedView ? renderGroupRow(item) : renderOrderRow(item)
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Pagination
              count={Math.ceil(
                (isGroupedView ? filteredGroups : filteredOrders).length /
                  itemsPerPage
              )}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </CardContent>
      </Card>
      {/* Status Change Menu */}
      <Menu
        anchorEl={statusMenuAnchorEl}
        open={Boolean(statusMenuAnchorEl)}
        onClose={handleStatusMenuClose}
      >
        {[
          "pending",
          "processing",
          "shipped",
          "delivered",
          "cancelled",
          "out_for_delivery",
        ].map((status) => (
          <MenuItem
            key={status}
            onClick={() => handleStatusChange(activeOrderForStatusMenu, status)}
          >
            {
              status
                .replace(/_/g, " ") // Replace all underscores with spaces
                .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
            }
          </MenuItem>
        ))}
      </Menu>
      {/* Group Change Menu */}
      <Menu
        anchorEl={groupMenuAnchorEl}
        open={Boolean(groupMenuAnchorEl)}
        onClose={handleGroupMenuClose}
      >
        {/* Find the current group for the active order */}
        {(() => {
          const currentGroup = groups.find((group) =>
            group.ShippingOrders.some(
              (order) => order.shipping_order_id === activeOrderForGroupMenu
            )
          );

          return (
            <>
              <MenuItem
                onClick={() =>
                  currentGroup &&
                  handleRemoveOrderFromGroup(
                    currentGroup.group_id,
                    activeOrderForGroupMenu
                  )
                }
                disabled={!currentGroup}
                sx={{
                  opacity: currentGroup ? 1 : 0.5,
                  "&.Mui-disabled": {
                    color: "text.disabled",
                  },
                }}
              >
                Remove from Group
              </MenuItem>
              <Divider />
              {groups.map((group) => (
                <MenuItem
                  key={group.group_id}
                  onClick={() => handleChangeOrderGroup(group.group_id)}
                >
                  {group.name}
                </MenuItem>
              ))}
            </>
          );
        })()}
      </Menu>
      <MultiOrderPrintDialog
        open={isMultiPrintDialogOpen}
        onClose={() => setIsMultiPrintDialogOpen(false)}
        selectedOrders={selectedOrders.map((orderId) =>
          orders.find((order) => order.shipping_order_id === orderId)
        )}
      />
      <Menu
        anchorEl={groupManagementAnchorEl}
        open={Boolean(groupManagementAnchorEl)}
        onClose={handleGroupManagementClose}
      >
        <MenuItem onClick={handleCreateNewGroupWithOrders}>
          Create New Group
        </MenuItem>
        <MenuItem disabled>Add to Existing Group:</MenuItem>
        {groups.map((group) => (
          <MenuItem
            key={group.group_id}
            onClick={() => handleAddToExistingGroup(group.group_id)}
          >
            {group.name}
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleBulkAction("removeFromGroups")}>
          Remove from Group(s)
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={draftMenuAnchorEl}
        open={Boolean(draftMenuAnchorEl)}
        onClose={handleDraftMenuClose}
      >
        <MenuItem onClick={() => handleDraftAction("complete")}>
          Complete Draft
        </MenuItem>
        <MenuItem onClick={() => handleDraftAction("delete")}>
          Delete Draft
        </MenuItem>
      </Menu>
      <Dialog
        open={isBulkStatusDialogOpen}
        onClose={() => setIsBulkStatusDialogOpen(false)}
      >
        <DialogTitle>Update Status for Selected Orders</DialogTitle>
        <DialogContent>
          <List>
            {[
              "pending",
              "processing",
              "shipped",
              "out_for_delivery",
              "delivered",
              "cancelled",
            ].map((status) => (
              <ListItem
                button
                key={status}
                onClick={() => handleBulkStatusUpdate(status)}
              >
                <ListItemText
                // remove underscore from status and capitalize first letter
                >
                  {
                    status
                      .replace(/_/g, " ") // Replace all underscores with spaces
                      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                  }
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkStatusDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CollaborationComponent
        open={isCollaborationComponentOpen}
        onClose={(wasUpdated) => handleCloseCollaborationComponent(wasUpdated)}
        selectedOrders={selectedOrders.filter((orderId) => {
          const order = orders.find((o) => o.shipping_order_id === orderId);
          return (
            order && order.order_status === "pending" && !order.collaboration_id
          );
        })}
        businessId={businessId}
        onCollaborationUpdate={refreshOrderData}
      />

      <CollaborationDetailsDialog
        open={isCollaborationDetailsOpen}
        onClose={() => {
          setIsCollaborationDetailsOpen(false);
          setSelectedCollaboration(null);
        }}
        collaborations={selectedCollaboration?.allCollaborations}
        businessId={businessId}
        onActionComplete={async () => {
          await refreshOrderData();
          setIsCollaborationDetailsOpen(false);
          setSelectedCollaboration(null);
        }}
      />
    </>
  );
};

export default OrderList;
