import React, { useState, useEffect, useContext, useCallback } from "react";
import { Alert } from "@mui/material";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import DisplayEmail from "../../components/Form/LoginSecurity/DisplayEmail";
import DisplayPhoneNumber from "../../components/Form/LoginSecurity/DisplayPhoneNumber";
import ChangePassword from "../../components/Form/LoginSecurity/ChangePassword";
import TwoFactorAuth from "../../components/Form/LoginSecurity/TwoFactorAuth";
import { AuthContext } from "../../components/Auth/AuthContext";
import styles from "./LoginSecurity.module.css";

const LoginSecurity = () => {
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const showMessage = (type, content) => {
    setAlertMessage({ type, content });
    setTimeout(() => setAlertMessage(null), 5000);
  };

  return (
    <div className={styles["security-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["security-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h2 className={styles["title"]}>Login & Security</h2>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {alertMessage.content}
              </Alert>
            )}
            {user ? (
              <div className={styles["edit-form"]}>
                <div className={styles["form-box"]}>
                  <DisplayEmail showMessage={showMessage} />
                </div>
                <div className={styles["form-box"]}>
                  <DisplayPhoneNumber showMessage={showMessage} />
                </div>
                <div className={styles["form-box"]}>
                  <ChangePassword showMessage={showMessage} />
                </div>
                {/* <div className={styles["form-box"]}>
                  <TwoFactorAuth showMessage={showMessage} />
                </div> */}
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default LoginSecurity;
