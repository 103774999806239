import React, { useState, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  PictureAsPdf as PdfIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
} from "@mui/icons-material";

const PrintComponent = React.forwardRef(
  ({ order, printOptions, selectedItems }, ref) => {
    if (!order) {
      return <Typography>No order data available</Typography>;
    }

    const getPageStyle = (size) => {
      const sizes = {
        "2x2": { width: "2in", height: "2in" },
        "4x6": { width: "4in", height: "6in" },
        "4x4": { width: "4in", height: "4in" },
        "6x4": { width: "6in", height: "4in" },
        A4: { width: "210mm", height: "297mm" },
        Letter: { width: "8.5in", height: "11in" },
      };
      return {
        width: sizes[size]?.width || "4in",
        height: sizes[size]?.height || "6in",
      };
    };

    const LabelPage = ({ children, size }) => (
      <div
        className="label-page"
        style={{
          ...getPageStyle(size),
          margin: "0 auto",
          padding: "0.5in",
          pageBreakAfter: "always",
          pageBreakInside: "avoid",
          breakInside: "avoid",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "4px",
            height: "100%",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          {children}
        </div>
      </div>
    );

    const OrderLabel = () => (
      <LabelPage size={printOptions.orderLabel.size}>
        <Typography variant="h6" gutterBottom>
          Order Information
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography>
              <strong>Order ID:</strong> {order.shipping_order_id || "N/A"}
            </Typography>
            <Typography>
              <strong>Tracking:</strong> {order.tracking_number || "N/A"}
            </Typography>
            <Typography>
              <strong>From:</strong> {order.sender?.first_name}{" "}
              {order.sender?.last_name}
            </Typography>
            <Typography>
              <strong>To:</strong> {order.recipient?.first_name}{" "}
              {order.recipient?.last_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <QRCodeSVG value={order.shipping_order_id || ""} size={100} />
            </Box>
          </Grid>
        </Grid>
      </LabelPage>
    );

    const PackageLabel = ({ pkg, index }) => (
      <LabelPage size={printOptions.packageLabels.size}>
        <Typography variant="h6" gutterBottom>
          Package {index + 1}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography>
              <strong>Package ID:</strong> {pkg.package_id || "N/A"}
            </Typography>
            <Typography>
              <strong>Weight:</strong> {pkg.weight || "N/A"} lbs
            </Typography>
            <Typography>
              <strong>Dimensions:</strong> {pkg.dimensions?.length || "N/A"}x
              {pkg.dimensions?.width || "N/A"}x{pkg.dimensions?.height || "N/A"}{" "}
              cm
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <QRCodeSVG value={pkg.package_id || ""} size={80} />
            </Box>
          </Grid>
        </Grid>
      </LabelPage>
    );

    const SingleItemLabel = ({ item }) => (
      <LabelPage size={printOptions.itemQRs.size}>
        <Typography variant="h6" gutterBottom>
          Item Label
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <QRCodeSVG value={item.package_item_id || ""} size={120} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {item.name || "N/A"}
          </Typography>
          <Typography color="textSecondary">
            ID: {item.package_item_id}
          </Typography>
          {item.description && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {item.description}
            </Typography>
          )}
        </Box>
      </LabelPage>
    );

    const PrintContainer = ({ children }) => (
      <div
        ref={ref}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        {children}
      </div>
    );

    const labelsToRender = [];

    if (printOptions.orderLabel.checked) {
      labelsToRender.push(<OrderLabel key="order" />);
    }

    order.Packages?.forEach((pkg, index) => {
      if (printOptions.packageLabels.checked) {
        labelsToRender.push(
          <PackageLabel key={`pkg-${pkg.package_id}`} pkg={pkg} index={index} />
        );
      }

      if (printOptions.itemQRs.checked) {
        pkg.PackageItems?.forEach((item) => {
          if (selectedItems.includes(item.package_item_id)) {
            labelsToRender.push(
              <SingleItemLabel
                key={`item-${item.package_item_id}`}
                item={item}
              />
            );
          }
        });
      }
    });

    return <PrintContainer>{labelsToRender}</PrintContainer>;
  }
);

const PrintOptionsComponent = ({ order, onClose }) => {
  const [printOptions, setPrintOptions] = useState({
    orderLabel: { checked: true, size: "4x6" },
    packageLabels: { checked: true, size: "4x6" },
    itemQRs: { checked: true, size: "4x6" },
  });

  // Add this to your existing state
  const [pdfPreview, setPdfPreview] = useState({
    open: false,
    url: null,
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [error, setError] = useState(null);
  const printComponentRef = useRef();

  const handleOptionChange = (name) => {
    setPrintOptions((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked: !prev[name].checked },
    }));
  };

  const handleSizeChange = (event, name) => {
    setPrintOptions((prev) => ({
      ...prev,
      [name]: { ...prev[name], size: event.target.value },
    }));
  };

  const handleItemSelection = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const handleSelectAllItems = (packageItems) => {
    const itemIds = packageItems.map((item) => item.package_item_id);
    setSelectedItems((prev) => (prev.length === itemIds.length ? [] : itemIds));
  };

  const printContainerRef = useRef();

  const generatePDF = async () => {
    setIsGeneratingPdf(true);
    setError(null);

    // Create a temporary container for rendering
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    tempContainer.style.top = "0";
    document.body.appendChild(tempContainer);

    try {
      // Render the print component into the temporary container
      const labelElements =
        printComponentRef.current.getElementsByClassName("label-page");

      if (labelElements.length === 0) {
        throw new Error("No labels selected for printing");
      }

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
      });

      // Process each label
      for (let i = 0; i < labelElements.length; i++) {
        const element = labelElements[i].cloneNode(true);

        // Clear any transformations and ensure proper rendering
        element.style.transform = "none";
        element.style.position = "static";
        element.style.marginBottom = "20px";

        // Temporarily append to document for rendering
        tempContainer.appendChild(element);

        if (i > 0) {
          pdf.addPage();
        }

        // Create canvas
        const canvas = await html2canvas(element, {
          scale: 2,
          logging: false,
          useCORS: true,
          allowTaint: true,
          backgroundColor: "#FFFFFF",
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight,
        });

        // Convert to image and add to PDF
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Calculate dimensions to fit page while maintaining aspect ratio
        const ratio = Math.min(
          pdfWidth / imgProps.width,
          pdfHeight / imgProps.height
        );

        const width = imgProps.width * ratio;
        const height = imgProps.height * ratio;
        const x = (pdfWidth - width) / 2;
        const y = (pdfHeight - height) / 2;

        pdf.addImage(imgData, "PNG", x, y, width, height);

        // Clean up
        tempContainer.removeChild(element);
      }

      // Create blob URL for preview
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      setPdfPreview({ open: true, url });
    } catch (error) {
      console.error("Error generating PDF:", error);
      setError(error.message || "Error generating PDF. Please try again.");
    } finally {
      // Clean up temporary container
      document.body.removeChild(tempContainer);
      setIsGeneratingPdf(false);
    }
  };

  const handleClosePreview = () => {
    if (pdfPreview.url) {
      URL.revokeObjectURL(pdfPreview.url);
    }
    setPdfPreview({ open: false, url: null });
  };

  const handlePrint = () => {
    const printWindow = window.open(pdfPreview.url, "_blank");
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  const PDFPreviewDialog = () => (
    <Dialog
      open={pdfPreview.open}
      onClose={handleClosePreview}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        PDF Preview
        <IconButton
          onClick={handleClosePreview}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {pdfPreview.url && (
          <iframe
            src={pdfPreview.url}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="PDF Preview"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePreview}>Close</Button>
        <Button onClick={handlePrint} variant="contained" color="primary">
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getSizeSelector = (optionName) => (
    <FormControl fullWidth size="small" sx={{ mt: 1 }}>
      <InputLabel>Size</InputLabel>
      <Select
        value={printOptions[optionName].size}
        onChange={(e) => handleSizeChange(e, optionName)}
        label="Size"
      >
        <MenuItem value="2x2">2x2</MenuItem>
        <MenuItem value="4x6">4x6</MenuItem>
        <MenuItem value="4x4">4x4</MenuItem>
        <MenuItem value="6x4">6x4</MenuItem>
        <MenuItem value="A4">A4</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: "1200px", mx: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Print Options
      </Typography>
      <Divider sx={{ mb: 3 }} />

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Order Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.orderLabel.checked}
                      onChange={() => handleOptionChange("orderLabel")}
                    />
                  }
                  label="Order Label"
                />
                {getSizeSelector("orderLabel")}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Package Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.packageLabels.checked}
                      onChange={() => handleOptionChange("packageLabels")}
                    />
                  }
                  label="Package Labels"
                />
                {getSizeSelector("packageLabels")}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Item Labels</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.itemQRs.checked}
                      onChange={() => handleOptionChange("itemQRs")}
                    />
                  }
                  label="Item QR Codes"
                />
                {getSizeSelector("itemQRs")}

                {order.Packages?.map((pkg, pkgIndex) => (
                  <Box key={pkg.package_id} sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Package {pkgIndex + 1} Items
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pkg.PackageItems?.every((item) =>
                            selectedItems.includes(item.package_item_id)
                          )}
                          indeterminate={
                            pkg.PackageItems?.some((item) =>
                              selectedItems.includes(item.package_item_id)
                            ) &&
                            !pkg.PackageItems?.every((item) =>
                              selectedItems.includes(item.package_item_id)
                            )
                          }
                          onChange={() =>
                            handleSelectAllItems(pkg.PackageItems)
                          }
                        />
                      }
                      label="Select All"
                    />
                    <List dense>
                      {pkg.PackageItems?.map((item) => (
                        <ListItem key={item.package_item_id}>
                          <ListItemIcon>
                            <LabelIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.name}
                            secondary={`ID: ${item.package_item_id}`}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              checked={selectedItems.includes(
                                item.package_item_id
                              )}
                              onChange={() =>
                                handleItemSelection(item.package_item_id)
                              }
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Preview
          </Typography>
          <Box
            sx={{
              border: 1,
              borderColor: "grey.300",
              borderRadius: 1,
              p: 2,
              bgcolor: "grey.50",
              maxHeight: "60vh",
              overflow: "auto",
            }}
          >
            <Box sx={{ transform: "scale(0.5)", transformOrigin: "top left" }}>
              <PrintComponent
                ref={printComponentRef}
                order={order}
                printOptions={printOptions}
                selectedItems={selectedItems}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 1 }}>
        <Button variant="outlined" startIcon={<CloseIcon />} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={
            isGeneratingPdf ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <PdfIcon />
            )
          }
          onClick={generatePDF}
          disabled={isGeneratingPdf}
        >
          {isGeneratingPdf ? "Generating PDF..." : "Generate PDF"}
        </Button>
      </Box>

      <PDFPreviewDialog />

      {/* Hidden print component for PDF generation */}
      <div
        style={{
          position: "absolute",
          left: "-9999px",
          top: 0,
        }}
      >
        <PrintComponent
          ref={printComponentRef}
          order={order}
          printOptions={printOptions}
          selectedItems={selectedItems}
        />
      </div>
    </Paper>
  );
};

export default PrintOptionsComponent;
