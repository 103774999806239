import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  TextField,
  CircularProgress,
  Box,
  Modal,
  styled,
  Alert,
  Switch,
  FormControlLabel,
} from "@mui/material";
import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import CustomButton from "../../../components/Button/Button";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import styles from "./EditBusinessProfilePage.module.css";
import { resizeImage } from "browser-image-resizer";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const imageConfig = {
  quality: 0.8,
  maxWidth: 150,
  maxHeight: 150,
  autoRotate: true,
  debug: true,
};

const defaultProfilePicture = "/images/business-default.jpg"; // Default business profile picture path
const defaultBannerPicture = "/images/business-banner-default.jpg"; // Default business banner picture path

const EditBusinessProfilePage = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const navigate = useNavigate();
  const { getBusinessById, updateBusinessInLocalStorage } = useBusiness();
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [businessData, setBusinessData] = useState({
    business_name: "",
    business_email: "",
    business_phone: "",
    business_description: "",
    business_website: "",
    business_instagram: "",
    business_facebook: "",
    business_twitter: "",
    business_linkedin: "",
    business_youtube: "",
    business_is_physical: false,
    business_profile_picture: null,
    business_banner_picture: null,
  });
  const [originalData, setOriginalData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [previewImage, setPreviewImage] = useState(defaultProfilePicture);
  const [previewBanner, setPreviewBanner] = useState(defaultBannerPicture);
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const business = getBusinessById(businessId);
    if (business) {
      const businessInfo = {
        business_name: business.business_name || "",
        business_email: business.business_email || "",
        business_phone: business.business_phone || "",
        business_description: business.business_description || "",
        business_website: business.business_website || "",
        business_instagram: business.business_instagram || "",
        business_facebook: business.business_facebook || "",
        business_twitter: business.business_twitter || "",
        business_linkedin: business.business_linkedin || "",
        business_youtube: business.business_youtube || "",
        business_category_name: business.business_category_name || "",
        business_is_physical: business.business_is_physical || false,
        business_profile_picture: business.business_profile_picture || null,
        business_banner_picture: business.business_banner_picture || null,
      };
      setBusinessData(businessInfo);
      setOriginalData(businessInfo);
      setPreviewImage(
        businessInfo.business_profile_picture
          ? `data:image/jpeg;base64,${businessInfo.business_profile_picture}`
          : defaultProfilePicture
      );
      setPreviewBanner(
        businessInfo.business_banner_picture
          ? `data:image/jpeg;base64,${businessInfo.business_banner_picture}`
          : defaultBannerPicture
      );
    }
  }, [businessId, getBusinessById]);

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
  }, [sidebarActive]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessData((prevData) => ({ ...prevData, [name]: value }));
    setAlertMessage(null);

    const fieldError = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setBusinessData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handlePhoneChange = (value) => {
    setBusinessData((prevData) => ({ ...prevData, business_phone: value }));
    if (!matchIsValidTel(value)) {
      setPhoneError("Invalid phone number format");
    } else {
      setPhoneError("");
    }
  };

  const handleFileChange = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image")) {
        setAlertMessage({
          type: "error",
          message: "Only image files are allowed.",
        });
        return;
      }
      let processedFile = file;
      if (file.size > MAX_FILE_SIZE) {
        processedFile = await resizeImage(file, imageConfig);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === "profile") {
          setPreviewImage(reader.result);
          setBusinessData({
            ...businessData,
            business_profile_picture: processedFile,
          });
        } else {
          setPreviewBanner(reader.result);
          setBusinessData({
            ...businessData,
            business_banner_picture: processedFile,
          });
        }
      };
      reader.readAsDataURL(processedFile);
      setAlertMessage(null);
    }
  };

  const removeSelectedImage = (type) => {
    if (type === "profile") {
      setPreviewImage(defaultProfilePicture);
      setBusinessData({
        ...businessData,
        business_profile_picture: null,
      });
    } else {
      setPreviewBanner(defaultBannerPicture);
      setBusinessData({
        ...businessData,
        business_banner_picture: null,
      });
    }
  };

  const validateField = (name, value) => {
    if (!value.trim()) {
      return `${name.replace("business_", "").replace("_", " ")} is required`;
    }
    if (value.trim().length < 2) {
      return `${name
        .replace("business_", "")
        .replace("_", " ")} must be at least 2 characters long`;
    }
    if (name === "business_email" && !/\S+@\S+\.\S+/.test(value)) {
      return "Invalid email address";
    }
    return "";
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    ["business_name", "business_email"].forEach((field) => {
      const error = validateField(field, businessData[field]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });

    if (!matchIsValidTel(businessData.business_phone)) {
      newErrors.business_phone = "Invalid phone number format";
      isValid = false;
    }

    setErrors(newErrors);

    if (!isValid) {
      setAlertMessage({
        type: "error",
        message: "Please correct the errors in the form.",
      });
    }

    return isValid;
  };

  const isFormChanged = () => {
    return JSON.stringify(businessData) !== JSON.stringify(originalData);
  };

  const updateBusiness = async (formData) => {
    const response = await api.put(`/business/${businessId}`, formData);
    return response.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormChanged()) {
      setAlertMessage({
        type: "error",
        message: "No changes were made to update.",
      });
      return;
    }
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setAlertMessage(null);

    try {
      // Create a copy of businessData without the image fields
      const {
        business_profile_picture,
        business_banner_picture,
        ...businessInfo
      } = businessData;

      // Convert form data to a plain object
      const formData = {
        ...businessInfo,
        remove_profile_picture: !business_profile_picture ? "true" : "false",
        remove_banner_picture: !business_banner_picture ? "true" : "false",
      };

      // Add base64 images if they exist and are different from defaults
      if (previewImage && previewImage !== defaultProfilePicture) {
        formData.business_profile_picture = previewImage;
      }
      if (previewBanner && previewBanner !== defaultBannerPicture) {
        formData.business_banner_picture = previewBanner;
      }

      const updatedBusiness = await updateBusiness(formData);

      setOriginalData(updatedBusiness);
      setBusinessData(updatedBusiness);
      setPreviewImage(
        updatedBusiness.business_profile_picture
          ? `data:image/jpeg;base64,${updatedBusiness.business_profile_picture}`
          : defaultProfilePicture
      );
      setPreviewBanner(
        updatedBusiness.business_banner_picture
          ? `data:image/jpeg;base64,${updatedBusiness.business_banner_picture}`
          : defaultBannerPicture
      );

      updateBusinessInLocalStorage(updatedBusiness);
      setAlertMessage({
        type: "success",
        message: "Business profile updated successfully!",
      });
      navigate(`/business/${businessId}/profile`);
    } catch (error) {
      console.error("Error updating business profile:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({ type: "error", message: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["edit-business-profile-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessHeader />
        <div className={styles["main-content"]}>
          <BusinessSidebar
            onSidebarStateChange={handleSidebarStateChange}
            businessCategory={businessData.business_category_name}
          />
          <main
            className={`${styles["edit-profile-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Edit Business Profile</h1>
            <form onSubmit={handleSubmit} className={styles["edit-form"]}>
              {alertMessage && (
                <Alert
                  severity={alertMessage.type}
                  variant="outlined"
                  sx={{ mb: 2 }}
                >
                  {alertMessage.message}
                </Alert>
              )}
              <div className={styles["profile-picture"]}>
                <img src={previewImage} alt="Business Profile" />
                <label
                  htmlFor="business_profile_picture"
                  className={styles["upload-button"]}
                >
                  Change Business Profile Picture
                </label>
                <input
                  type="file"
                  id="business_profile_picture"
                  name="business_profile_picture"
                  onChange={(e) => handleFileChange(e, "profile")}
                  accept="image/*"
                />
                {previewImage !== defaultProfilePicture && (
                  <button
                    type="button"
                    onClick={() => removeSelectedImage("profile")}
                    className={styles["remove-button"]}
                  >
                    Remove
                  </button>
                )}
              </div>

              <div className={styles["banner-picture"]}>
                <img src={previewBanner} alt="Business Banner" />
                <label
                  htmlFor="business_banner_picture"
                  className={styles["upload-button"]}
                >
                  Change Business Banner Picture
                </label>
                <input
                  type="file"
                  id="business_banner_picture"
                  name="business_banner_picture"
                  onChange={(e) => handleFileChange(e, "banner")}
                  accept="image/*"
                />
                {previewBanner !== defaultBannerPicture && (
                  <button
                    type="button"
                    onClick={() => removeSelectedImage("banner")}
                    className={styles["remove-button"]}
                  >
                    Remove
                  </button>
                )}
              </div>

              <TextField
                name="business_name"
                label="Business Name"
                value={businessData.business_name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!errors.business_name}
                helperText={errors.business_name}
                required
              />
              <TextField
                name="business_email"
                label="Business Email"
                type="email"
                value={businessData.business_email}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!errors.business_email}
                helperText={errors.business_email}
                required
              />
              <MuiTelInput
                name="business_phone"
                label="Business Phone"
                value={businessData.business_phone}
                onChange={handlePhoneChange}
                fullWidth
                margin="normal"
                error={!!phoneError}
                helperText={phoneError}
                required
              />
              <TextField
                name="business_description"
                label="Business Description"
                value={businessData.business_description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <div className={styles.submitButton}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={isLoading || !isFormChanged()}
                  text={isLoading ? "Updating..." : "Update Business Profile"}
                />
              </div>
            </form>
          </main>
        </div>
        <DashboardFooter />
      </div>
      <Modal
        open={isLoading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box className={styles.loadingModal}>
          <CircularProgress />
          <p>Updating business profile...</p>
        </Box>
      </Modal>
    </div>
  );
};

export default EditBusinessProfilePage;
