import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  FaTachometerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaUserCircle,
  FaCalendarAlt,
  FaClipboardList,
  FaCog,
  FaBox,
  FaTruck,
  FaWarehouse,
} from "react-icons/fa";
import logo from "../../assets/logo-plain.svg";
import "./EmployeeSidebar.css";
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";

const EmployeeSidebar = ({
  onSidebarStateChange,
  employeeRole,
  businessType,
}) => {
  const location = useLocation();
  const { businessId, employeeId } = useParams();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(() => {
    const saved = localStorage.getItem("employeeSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem("employeeSidebarActive", JSON.stringify(isActive));
    onSidebarStateChange(isActive);
  }, [isActive, onSidebarStateChange]);

  const isLinkActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = async () => {
    await logout();
    navigate("/auth");
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const commonLinks = [
    {
      path: `/business/${businessId}/employee/${employeeId}/dashboard`,
      icon: <FaTachometerAlt />,
      text: "Dashboard",
    },
    {
      path: `/business/${businessId}/employee/${employeeId}/profile`,
      icon: <FaUserCircle />,
      text: "My Profile",
    },
    {
      path: `/business/${businessId}/employee/${employeeId}/schedule`,
      icon: <FaCalendarAlt />,
      text: "My Schedule",
    },
    {
      path: `/business/${businessId}/employee/${employeeId}/tasks`,
      icon: <FaClipboardList />,
      text: "My Tasks",
    },
    {
      path: `/business/${businessId}/employee/${employeeId}/shipments`,
      icon: <FaBox />,
      text: "Shipments",
    },
  ];

  const shippingLogisticsLinks = [
    {
      path: `/business/${businessId}/employee/${employeeId}/shipments`,
      icon: <FaBox />,
      text: "Shipments",
    },
    // {
    //   path: `/business/${businessId}/employee/${employeeId}/vehicles`,
    //   icon: <FaTruck />,
    //   text: "Vehicles",
    // },
    // {
    //   path: `/business/${businessId}/employee/${employeeId}/warehouse`,
    //   icon: <FaWarehouse />,
    //   text: "Warehouse",
    // },
  ];

  const renderLinks = (links) => {
    return links.map(({ path, icon, text }) => (
      <Link key={path} to={path} className={isLinkActive(path)}>
        {icon}
        <span>{isActive && text}</span>
      </Link>
    ));
  };

  return (
    <aside className={`employee-sidebar ${isActive ? "active" : ""}`}>
      <div className="sidebar-top">
        <div className="sidebar-logo">
          <img src={logo} alt="Your App Logo" className="logo" />
        </div>
        <button onClick={toggleSidebar} className="sidebar-toggle">
          {isActive ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      <nav>
        {renderLinks(commonLinks)}
        {businessType === "Shipping and Logistics" && (
          <>
            <div className="sidebar-divider"></div>
            {renderLinks(shippingLogisticsLinks)}
          </>
        )}
      </nav>
      <div className="sidebar-bottom">
        <Link
          to={`/business/${businessId}/employee/${employeeId}/settings`}
          className={isLinkActive(
            `/business/${businessId}/employee/${employeeId}/settings`
          )}
        >
          <FaCog />
          <span>{isActive && "Settings"}</span>
        </Link>
        <button onClick={handleLogout} className="sidebar-link">
          <FaSignOutAlt />
          <span>{isActive && "Log Out"}</span>
        </button>
      </div>
    </aside>
  );
};

export default EmployeeSidebar;
