import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import ParallaxBackground from "../Background/ParallaxBackground/ParallaxBackground";
import LoadingModal from "../Icon/LoadingModal";

const CompleteProfileRoute = ({ children }) => {
  const { user, isProfileComplete, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <>
        <ParallaxBackground />
        <LoadingModal open={true} />
      </>
    );
  }

  if (!user) {
    return <Navigate to="/auth" />;
  }

  if (isProfileComplete(user)) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default CompleteProfileRoute;
