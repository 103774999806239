import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import LoadingModal from "../Icon/LoadingModal";
import ParallaxBackground from "../Background/ParallaxBackground/ParallaxBackground";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const {
    user,
    loading: authLoading,
    initializationComplete,
    isAuthenticated,
    isProfileComplete,
    refreshAccessToken,
    logout,
    api,
  } = useContext(AuthContext);

  const [verificationState, setVerificationState] = useState({
    isVerifying: false,
    isValid: null,
    initialCheckDone: false,
  });

  useEffect(() => {
    let mounted = true;

    const verifySession = async () => {
      // Skip verification if we don't have a token
      const token = localStorage.getItem("accessToken");
      if (!token) {
        if (mounted) {
          setVerificationState((prev) => ({
            ...prev,
            isVerifying: false,
            isValid: false,
            initialCheckDone: true,
          }));
        }
        return;
      }

      // Skip verification if we're already authenticated
      if (isAuthenticated && verificationState.initialCheckDone) {
        return;
      }

      setVerificationState((prev) => ({ ...prev, isVerifying: true }));

      try {
        if (isAuthenticated) {
          await api.get("/auth/verify-token");
        } else {
          await refreshAccessToken();
        }

        if (mounted) {
          setVerificationState({
            isVerifying: false,
            isValid: true,
            initialCheckDone: true,
          });
        }
      } catch (error) {
        console.error("Session verification failed:", error);
        if (mounted) {
          setVerificationState({
            isVerifying: false,
            isValid: false,
            initialCheckDone: true,
          });
          logout(true);
        }
      }
    };

    // Only verify if initialization is complete and we haven't done initial check
    if (initializationComplete && !verificationState.initialCheckDone) {
      verifySession();
    }

    return () => {
      mounted = false;
    };
  }, [
    initializationComplete,
    isAuthenticated,
    refreshAccessToken,
    logout,
    api,
  ]);

  // Only show loading modal during initial auth check or app initialization
  const shouldShowLoading =
    (!initializationComplete || authLoading) &&
    !verificationState.initialCheckDone;

  if (shouldShowLoading) {
    return (
      <>
        <ParallaxBackground />
        <LoadingModal open={true} />
      </>
    );
  }

  // Handle authentication redirects
  if (!isAuthenticated || verificationState.isValid === false) {
    return <Navigate to="/auth" state={{ from: location.pathname }} replace />;
  }

  // Handle incomplete profile
  if (!isProfileComplete(user)) {
    return (
      <Navigate
        to="/complete-profile"
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  // Render children without any loading state for normal navigation
  return children;
};

export default PrivateRoute;
