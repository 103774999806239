import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import { CircularProgress, styled, Alert } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import styles from "./EmailVerification.module.css";

const StyledLink = styled("a")({
  color: "#3498DB",
  textDecoration: "none",
  fontWeight: "bold",
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#E74C3C",
  },
});

const EmailChangeVerification = () => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { api } = useContext(AuthContext);

  useEffect(() => {
    const verifyEmail = async (token) => {
      try {
        const response = await api.get(
          `/auth/confirm-email-change?token=${token}`
        );
        setAlertMessage({
          type: "success",
          message: response.data.message,
        });
        setTimeout(() => {
          navigate("/auth");
        }, 3000);
      } catch (error) {
        console.error("There was an error verifying email!", error);
        const errorMessage =
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message;
        setAlertMessage({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    };

    const params = new URLSearchParams(location.search);
    const tokenFromURL = params.get("token");
    if (tokenFromURL) {
      verifyEmail(tokenFromURL);
    } else {
      setAlertMessage({
        type: "error",
        message: "Verification token is missing",
      });
      setIsLoading(false);
    }
  }, [location, navigate]);

  return (
    <div className={styles.mainContent}>
      <Header />
      <ParallaxBackground />
      <main className={styles.container}>
        <div className={styles.card}>
          <h2>New Email Verification</h2>
          {isLoading ? (
            <CircularProgress />
          ) : (
            alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {alertMessage.message}
                {alertMessage.type === "success" && (
                  <>
                    <br />
                    <StyledLink href="/auth">
                      Redirecting to login...
                    </StyledLink>
                  </>
                )}
              </Alert>
            )
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default EmailChangeVerification;
