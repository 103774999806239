import React from "react";
import Navigation from "../Navigation/Navigation";
import "./Header.css";
import logo from "../../assets/logo-plain.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <a href="/">
          <img src={logo} alt="Your App Logo" className="logo" />
        </a>
      </div>
      <div className="navigation-container">
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
