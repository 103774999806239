import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
  Pagination,
  Box,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  History as HistoryIcon,
  MonetizationOn as MonetizationOnIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import CollaborationDetailsDialog from "./CollaborationDetailsDialog";

const CollaborationManagementScreen = ({ businessId, onRefresh }) => {
  const [collaborations, setCollaborations] = useState([]);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedCollaboration, setEditedCollaboration] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [collaborationHistory, setCollaborationHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollaborations, setFilteredCollaborations] = useState([]);
  const { api } = useContext(AuthContext);

  const fetchCollaborations = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/shipping-collaboration/collaborations?businessId=${businessId}`,
        {}
      );
      console.log("Collaborations:", response.data);
      setCollaborations(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching collaborations:", error);
      setError("Failed to fetch collaborations. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollaborations();
  }, []);

  useEffect(() => {
    filterCollaborations();
  }, [collaborations, filterStatus, searchQuery]);

  const filterCollaborations = () => {
    let filtered = [...collaborations];

    // Apply status filter
    if (filterStatus !== "all") {
      filtered = filtered.filter((collab) => collab.status === filterStatus);
    }

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (collab) =>
          collab.shipping_order_id.toLowerCase().includes(query) ||
          collab.CollaboratingBusiness?.business_name
            .toLowerCase()
            .includes(query) ||
          collab.collaboration_type.toLowerCase().includes(query)
      );
    }

    setFilteredCollaborations(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredCollaborations.slice(startIndex, endIndex);
  };

  const handleResponseClick = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setResponseDialogOpen(true);
  };

  const handleAcceptCollaboration = async (collaborationId) => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration/${collaborationId}/${businessId}/collaborating-business-status`,
        { status: "accepted" }
      );
      fetchCollaborations();
      onRefresh();
    } catch (error) {
      console.error("Error accepting collaboration:", error);
      setError("Failed to accept collaboration. Please try again.");
    }
  };

  const handleCompleteCollaboration = async (collaborationId) => {
    try {
      await api.post(
        `/shipping-collaboration/collaboration-request/${collaborationId}/${businessId}/action`,
        { action: "complete" }
      );
      fetchCollaborations();
      onRefresh();
    } catch (error) {
      console.error("Error completing collaboration:", error);
      setError("Failed to complete collaboration. Please try again.");
    }
  };

  const handleResponseSubmit = async () => {
    try {
      await api.post(
        `/shipping-collaboration/collaboration-response/${selectedCollaboration.collaboration_id}/${businessId}`,
        {
          status: responseStatus,
        }
      );
      setResponseDialogOpen(false);
      fetchCollaborations();
      onRefresh();
    } catch (error) {
      console.error("Error responding to collaboration:", error);
      setError("Failed to respond to collaboration. Please try again.");
    }
  };

  const handleStatusUpdate = async (collaborationId, newStatus) => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration-request/${collaborationId}/${businessId}/collaborating-business-status`,
        { status: newStatus }
      );
      fetchCollaborations();
      onRefresh();
    } catch (error) {
      console.error("Error updating collaboration status:", error);
      setError("Failed to update collaboration status. Please try again.");
    }
  };

  const handleViewHistory = async (collaboration) => {
    setCollaborationHistory(collaboration.collaboration_history);
    setSelectedCollaboration(collaboration);
    setHistoryDialogOpen(true);
  };

  const handleEditClick = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setEditedCollaboration({
      ...collaboration,
      additional_charges: collaboration.additional_charges || [],
    });
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration-request/${editedCollaboration.collaboration_id}/${businessId}/edit`,
        {
          agreed_compensation: editedCollaboration.agreed_compensation,
          additional_charges: editedCollaboration.additional_charges,
          special_instructions: editedCollaboration.special_instructions,
        }
      );
      setEditDialogOpen(false);
      fetchCollaborations();
      onRefresh();
    } catch (error) {
      console.error("Error editing collaboration:", error);
      setError("Failed to edit collaboration. Please try again.");
    }
  };

  const handleAddCharge = () => {
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: [
        ...editedCollaboration.additional_charges,
        { description: "", amount: "" },
      ],
    });
  };

  const handleRemoveCharge = (index) => {
    const updatedCharges = editedCollaboration.additional_charges.filter(
      (_, i) => i !== index
    );
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: updatedCharges,
    });
  };

  const handleChargeChange = (index, field, value) => {
    const updatedCharges = editedCollaboration.additional_charges.map(
      (charge, i) => (i === index ? { ...charge, [field]: value } : charge)
    );
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: updatedCharges,
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "info";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "cancelled":
        return "error";
      case "completed":
        return "success";
      default:
        return "default";
    }
  };

  const handleViewDetails = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setDetailsDialogOpen(true);
  };

  const getActionButtons = (collaboration) => {
    const isOriginator = collaboration.originating_business_id === businessId;
    const isCollaborator =
      collaboration.collaborating_business_id === businessId;

    return (
      <>
        <Tooltip title="View Details">
          <IconButton onClick={() => handleViewDetails(collaboration)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View History">
          <IconButton onClick={() => handleViewHistory(collaboration)}>
            <HistoryIcon />
          </IconButton>
        </Tooltip>
        {collaboration.status === "proposed" && isCollaborator && (
          <>
            <Tooltip title="Accept">
              <IconButton
                onClick={() =>
                  handleAcceptCollaboration(collaboration.collaboration_id)
                }
              >
                <CheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(collaboration)}>
                <MonetizationOnIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(collaboration.collaboration_id, "rejected")
                }
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
        {collaboration.status === "counter_offered" && isOriginator && (
          <>
            <Tooltip title="Accept Counter">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(collaboration.collaboration_id, "accepted")
                }
              >
                <CheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(collaboration)}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(
                    collaboration.collaboration_id,
                    "cancelled"
                  )
                }
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
        {collaboration.status === "accepted" &&
          (isOriginator || isCollaborator) && (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                handleCompleteCollaboration(collaboration.collaboration_id)
              }
            >
              Mark Completed
            </Button>
          )}
      </>
    );
  };

  if (loading) return <CircularProgress />;

  return (
    <div>
      {error && <Alert severity="error">{error}</Alert>}

      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5">Collaboration Management</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search order ID, collaborator..."
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Filter Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Filter Status"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="proposed">Proposed</MenuItem>
              <MenuItem value="counter_offered">Counter Offered</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Items per page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              label="Items per page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Collaborator</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Compensation</TableCell>
              <TableCell>Additional Charges</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : getPaginatedData().length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No collaborations found
                </TableCell>
              </TableRow>
            ) : (
              getPaginatedData().map((collaboration) => (
                <TableRow key={collaboration.collaboration_id}>
                  <TableCell>{collaboration.shipping_order_id}</TableCell>
                  <TableCell>
                    {collaboration.CollaboratingBusiness?.business_name}
                  </TableCell>
                  <TableCell>{collaboration.collaboration_type}</TableCell>
                  <TableCell>
                    <Chip
                      label={collaboration.status}
                      color={getStatusColor(collaboration.status)}
                    />
                  </TableCell>
                  <TableCell>
                    ${parseFloat(collaboration.agreed_compensation).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    $
                    {collaboration.additional_charges
                      ?.reduce(
                        (sum, charge) => sum + parseFloat(charge.amount),
                        0
                      )
                      .toFixed(2) || "0.00"}
                  </TableCell>
                  <TableCell>
                    {new Date(collaboration.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>{getActionButtons(collaboration)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Pagination
          count={Math.ceil(filteredCollaborations.length / itemsPerPage)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>

      {/* Response Dialog */}
      <Dialog
        open={responseDialogOpen}
        onClose={() => setResponseDialogOpen(false)}
      >
        <DialogTitle>Respond to Collaboration Request</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Response"
            value={responseStatus}
            onChange={(e) => setResponseStatus(e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="accepted">Accept</MenuItem>
            <MenuItem value="rejected">Reject</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResponseDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleResponseSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* History Dialog */}
      <Dialog
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Collaboration History</DialogTitle>
        <DialogContent>
          {collaborationHistory.length === 0 ? (
            <Typography variant="body1" align="center" sx={{ py: 2 }}>
              No history available
            </Typography>
          ) : (
            <Timeline>
              {collaborationHistory.map((entry, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color={getStatusColor(entry.action)} />
                    {index < collaborationHistory.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h6">
                      {entry.action.charAt(0).toUpperCase() +
                        entry.action.slice(1).replace("_", " ")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(entry.timestamp).toLocaleString()}
                    </Typography>
                    {entry.businessId && (
                      <Typography variant="body2">
                        By:{" "}
                        {entry.businessId === businessId
                          ? "You"
                          : entry.businessId ===
                            selectedCollaboration?.originating_business_id
                          ? selectedCollaboration?.OriginatingBusiness
                              ?.business_name
                          : selectedCollaboration?.CollaboratingBusiness
                              ?.business_name}
                      </Typography>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHistoryDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Collaboration Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Collaboration Request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Agreed Compensation"
                type="number"
                value={editedCollaboration?.agreed_compensation || ""}
                onChange={(e) =>
                  setEditedCollaboration({
                    ...editedCollaboration,
                    agreed_compensation: e.target.value,
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Special Instructions"
                multiline
                rows={4}
                value={editedCollaboration?.special_instructions || ""}
                onChange={(e) =>
                  setEditedCollaboration({
                    ...editedCollaboration,
                    special_instructions: e.target.value,
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Additional Charges</Typography>
              {editedCollaboration?.additional_charges.map((charge, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Description"
                      value={charge.description}
                      onChange={(e) =>
                        handleChargeChange(index, "description", e.target.value)
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Amount"
                      type="number"
                      value={charge.amount}
                      onChange={(e) =>
                        handleChargeChange(index, "amount", e.target.value)
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => handleRemoveCharge(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddCharge}
                variant="outlined"
                color="primary"
              >
                Add Charge
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Collaboration Details Dialog */}
      {selectedCollaboration && (
        <CollaborationDetailsDialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          collaborations={[selectedCollaboration]}
          businessId={businessId}
          onActionComplete={fetchCollaborations}
        />
      )}
    </div>
  );
};

export default CollaborationManagementScreen;
