import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const CustomerInfo = ({
  customer = {},
  onChange,
  title,
  formErrors = {},
  validateField,
}) => {
  const handleFieldChange = (field, value) => {
    onChange(field, value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="First Name"
          name="first_name"
          value={customer?.first_name || ""}
          onChange={(e) => handleFieldChange("first_name", e.target.value)}
          error={!!formErrors.first_name}
          helperText={formErrors.first_name}
          required
          onBlur={() => validateField?.("first_name", customer?.first_name)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Last Name"
          name="last_name"
          value={customer?.last_name || ""}
          onChange={(e) => handleFieldChange("last_name", e.target.value)}
          error={!!formErrors.last_name}
          helperText={formErrors.last_name}
          required
          onBlur={() => validateField?.("last_name", customer?.last_name)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={customer?.email || ""}
          onChange={(e) => handleFieldChange("email", e.target.value)}
          error={!!formErrors.email}
          helperText={formErrors.email}
          required
          onBlur={() => validateField?.("email", customer?.email)}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiTelInput
          fullWidth
          label="Phone"
          value={customer?.phone || ""}
          onChange={(value) => handleFieldChange("phone", value)}
          error={!!formErrors.phone}
          helperText={formErrors.phone}
          required
          onBlur={() => validateField?.("phone", customer?.phone)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address"
          name="address_line1"
          value={customer?.address_line1 || ""}
          onChange={(e) => handleFieldChange("address_line1", e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 2"
          name="address_line2"
          value={customer?.address_line2 || ""}
          onChange={(e) => handleFieldChange("address_line2", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="City"
          name="city"
          value={customer?.city || ""}
          onChange={(e) => handleFieldChange("city", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="State"
          name="state"
          value={customer?.state || ""}
          onChange={(e) => handleFieldChange("state", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Postal Code"
          name="postal_code"
          value={customer?.postal_code || ""}
          onChange={(e) => handleFieldChange("postal_code", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Country"
          name="country"
          value={customer?.country || ""}
          onChange={(e) => handleFieldChange("country", e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerInfo;
