import React, { useState, useContext } from "react";
import {
  TextField,
  Box,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Edit as EditIcon,
} from "@mui/icons-material";
import CustomButton from "../../Button/Button";
import { AuthContext } from "../../Auth/AuthContext";
import styles from "./SecurityComponents.css";

const ChangePassword = ({ showMessage }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { verifyPassword, user, updateUserContext } = useContext(AuthContext);
  const { api } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAlertMessage(null);

    if (newPassword !== confirmPassword) {
      setAlertMessage({ type: "error", message: "Passwords do not match" });
      showMessage("error", "Passwords do not match");
      setIsLoading(false);
      return;
    }

    if (newPassword.length < 5) {
      setAlertMessage({
        type: "error",
        message: "Password must be at least 5 characters long",
      });
      showMessage("error", "Password must be at least 5 characters long");
      setIsLoading(false);
      return;
    }

    try {
      const result = await verifyPassword(currentPassword);
      if (result.verified) {
        const response = await api.post("/auth/change-password", {
          currentPassword,
          newPassword,
        });
        setAlertMessage({ type: "success", message: response.data.message });
        showMessage("success", response.data.message);
        updateUserContext();
        setIsEditing(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setAlertMessage({
          type: "error",
          message: "Incorrect current password",
        });
        showMessage("error", "Incorrect current password");
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({ type: "error", message: errorMessage });
      showMessage("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setAlertMessage(null);
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Never";
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <Box className={styles["change-password"]}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <h3>Password</h3>
        {alertMessage && (
          <Alert severity={alertMessage.type} sx={{ ml: 2 }}>
            {alertMessage.message}
          </Alert>
        )}
      </Box>
      {!isEditing ? (
        <div className={styles["display-mode"]}>
          <p>
            Last password change: {formatDate(user.user_last_password_change)}
          </p>
          <CustomButton
            variant="contained"
            onClick={() => setIsEditing(true)}
            text={<EditIcon />}
            style={{ padding: "10px 20px" }}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={styles["edit-mode"]}>
          <TextField
            name="currentPassword"
            type={showCurrentPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("current")}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="newPassword"
            type={showNewPassword ? "text" : "password"}
            variant="outlined"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("new")}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText="Password must be at least 5 characters long"
          />
          <TextField
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("confirm")}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <CustomButton
              type="button"
              variant="contained"
              onClick={handleCancel}
              text="Cancel"
            />
            <CustomButton
              type="submit"
              variant="contained"
              disabled={
                isLoading ||
                !currentPassword ||
                !newPassword ||
                !confirmPassword ||
                newPassword.length < 5 ||
                newPassword !== confirmPassword
              }
              text={isLoading ? "Changing..." : "Change Password"}
            />
          </Box>
        </form>
      )}
    </Box>
  );
};

export default ChangePassword;
