import React from "react";
import { Modal, CircularProgress, Box } from "@mui/material";
import "./LoadingModal.css"; // Import the CSS file

const LoadingModal = ({ open }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal-title"
      className={open ? "fade-in" : "fade-out"}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        className="loading-modal-box"
      >
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default LoadingModal;
