import React, { useEffect } from "react";
import "./ParallaxBackground.css";

const ParallaxBackground = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const pageX = e.clientX - window.innerWidth / 2;
      const pageY = e.clientY - window.innerHeight / 2;
      document.querySelectorAll(".parallax-layer").forEach((layer) => {
        const speed = layer.getAttribute("data-speed");
        layer.style.transform = `translate(${pageX * speed}px, ${
          pageY * speed
        }px)`;
      });
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <section className="parallax">
      <div className="parallax-layer" data-speed="0.05" id="layer-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          poster="/images/background-image.jpg"
        >
          <source src="/images/background-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default ParallaxBackground;
