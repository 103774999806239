import React, { useState, useContext } from "react";
import { TextField, Box, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import CustomButton from "../../Button/Button";
import { AuthContext } from "../../Auth/AuthContext";
import styles from "./SecurityComponents.css";

const DisplayEmail = ({ showMessage }) => {
  const { user, updateUserContext } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState(user.user_email);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newEmail !== confirmEmail) {
      showMessage("error", "Emails do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/auth/request-email-change", {
        newEmail,
      });
      updateUserContext();
      showMessage("success", response.data.message);
      setIsEditing(false);
      setNewEmail("");
      setConfirmEmail("");
    } catch (error) {
      console.error("Error requesting email change:", error);
      showMessage(
        "error",
        error.response && error.response.data.error
          ? error.response.data.error
          : "An error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewEmail(user.user_email);
    setConfirmEmail("");
  };

  const renderEmailStatus = () => {
    if (user.user_is_email_verified) {
      return (
        <Alert icon={<CheckIcon />} severity="success" sx={{ mt: 2, mb: 2 }}>
          Email verified
        </Alert>
      );
    } else {
      return (
        <Alert icon={<CancelIcon />} severity="error" sx={{ mt: 2, mb: 2 }}>
          Email not verified
        </Alert>
      );
    }
  };

  const renderPendingEmailChange = () => {
    if (
      user.user_new_email &&
      user.user_new_email !== user.user_email &&
      new Date(user.user_last_email_verification_date_expired) > new Date()
    ) {
      const minutesRemaining = Math.ceil(
        (new Date(user.user_last_email_verification_date_expired) -
          new Date()) /
          (1000 * 60)
      );
      return (
        <Alert icon={<WarningIcon />} severity="warning" sx={{ mt: 2, mb: 2 }}>
          <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>
            Email change pending: {user.user_new_email}
          </p>
          <p style={{ margin: 0 }}>
            Change code expires in: {minutesRemaining} minutes
          </p>
        </Alert>
      );
    }
    return null;
  };

  return (
    <Box className={styles["display-email"]}>
      <h3>Email</h3>
      {!isEditing ? (
        <div className={styles["display-mode"]}>
          <p>{user.user_email}</p>
          <div className={styles["verification-status"]}>
            {renderEmailStatus()}
            {renderPendingEmailChange()}
          </div>
          <CustomButton
            variant="contained"
            onClick={() => setIsEditing(true)}
            text={<EditIcon />}
            style={{ padding: "10px 20px" }}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={styles["edit-mode"]}>
          <TextField
            name="newEmail"
            type="email"
            variant="outlined"
            placeholder="New Email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="confirmEmail"
            type="email"
            variant="outlined"
            placeholder="Confirm New Email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <CustomButton
              type="button"
              variant="contained"
              onClick={handleCancel}
              text="Cancel"
            />
            <CustomButton
              type="submit"
              variant="contained"
              disabled={
                isLoading ||
                newEmail === user.user_email ||
                newEmail !== confirmEmail
              }
              text={isLoading ? "Sending..." : "Change Email"}
            />
          </Box>
        </form>
      )}
    </Box>
  );
};

export default DisplayEmail;
