import { QRCodeSVG } from "qrcode.react";
import ReactDOMServer from "react-dom/server";

const pageStyles = {
  container: {
    width: "100%",
    baclbsroundColor: "white",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    borderBottom: "2px solid #000",
    paddingBottom: "10px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666",
    marginBottom: "5px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "15px",
  },
  th: {
    baclbsroundColor: "#f5f5f5",
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  section: {
    marginBottom: "20px",
  },
  label: {
    border: "1px solid #000",
    padding: "15px",
    marginBottom: "20px",
  },
  qrCode: {
    textAlign: "center",
    marginBottom: "10px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
};

const generateManifestPage = async (orders, format) => {
  const element = document.createElement("div");
  element.style.cssText = Object.entries(pageStyles.container)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");

  const header = document.createElement("div");
  header.style.cssText = Object.entries(pageStyles.header)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");
  header.innerHTML = `
    <div style="${Object.entries(pageStyles.title)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      Order Manifest
    </div>
    <div style="${Object.entries(pageStyles.subtitle)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      Generated: ${new Date().toLocaleString()}
    </div>
  `;
  element.appendChild(header);

  const table = document.createElement("table");
  table.style.cssText = Object.entries(pageStyles.table)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");

  const thead = document.createElement("thead");
  thead.innerHTML = `
    <tr>
      <th style="${Object.entries(pageStyles.th)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">Order ID</th>
      <th style="${Object.entries(pageStyles.th)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">Tracking</th>
      <th style="${Object.entries(pageStyles.th)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">Status</th>
      ${
        format === "detailed"
          ? `
        <th style="${Object.entries(pageStyles.th)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">Weight</th>
        <th style="${Object.entries(pageStyles.th)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">Cost</th>
      `
          : ""
      }
    </tr>
  `;
  table.appendChild(thead);

  const tbody = document.createElement("tbody");
  orders.forEach((order) => {
    const row = document.createElement("tr");
    row.innerHTML = `
      <td style="${Object.entries(pageStyles.td)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">${order.shipping_order_id}</td>
      <td style="${Object.entries(pageStyles.td)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">${order.tracking_number}</td>
      <td style="${Object.entries(pageStyles.td)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">${order.order_status}</td>
      ${
        format === "detailed"
          ? `
        <td style="${Object.entries(pageStyles.td)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">${order.total_weight} lbs</td>
        <td style="${Object.entries(pageStyles.td)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">$${order.total_cost}</td>
      `
          : ""
      }
    `;
    tbody.appendChild(row);
  });
  table.appendChild(tbody);
  element.appendChild(table);

  return element;
};

const generatePackingListPage = async (order, format) => {
  const element = document.createElement("div");
  element.style.cssText = Object.entries(pageStyles.container)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");

  element.innerHTML = `
    <div style="${Object.entries(pageStyles.header)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <div style="${Object.entries(pageStyles.title)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        Packing List
      </div>
      <div style="${Object.entries(pageStyles.flexContainer)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        <div>
          <strong>Order ID:</strong> ${order.shipping_order_id}<br>
          <strong>Tracking:</strong> ${order.tracking_number}<br>
          <strong>Status:</strong> ${order.order_status}
        </div>
        <div>
          <strong>From:</strong> ${order.sender.first_name} ${
    order.sender.last_name
  }<br>
          <strong>To:</strong> ${order.recipient.first_name} ${
    order.recipient.last_name
  }<br>
          <strong>Total Weight:</strong> ${order.total_weight} lbs
        </div>
      </div>
    </div>
  `;

  order.Packages?.forEach((plbs, packageIndex) => {
    element.innerHTML += `
      <div style="${Object.entries(pageStyles.section)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        <h3>Package ${packageIndex + 1}</h3>
        <table style="${Object.entries(pageStyles.table)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">
          <thead>
            <tr>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Item</th>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Quantity</th>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Weight</th>
              ${
                format === "detailed"
                  ? `
                <th style="${Object.entries(pageStyles.th)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">Description</th>
                <th style="${Object.entries(pageStyles.th)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">Value</th>
              `
                  : ""
              }
            </tr>
          </thead>
          <tbody>
            ${plbs.PackageItems?.map(
              (item) => `
              <tr>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.name}</td>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.quantity}</td>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.weight} lbs</td>
                ${
                  format === "detailed"
                    ? `
                  <td style="${Object.entries(pageStyles.td)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(";")}">${item.description || "N/A"}</td>
                  <td style="${Object.entries(pageStyles.td)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(";")}">$${item.value || "0.00"}</td>
                `
                    : ""
                }
              </tr>
            `
            ).join("")}
          </tbody>
        </table>
      </div>
    `;
  });

  return element;
};

const generateShippingLabelPage = async (order, size) => {
  const element = document.createElement("div");
  element.style.cssText = Object.entries({
    ...pageStyles.container,
    width: size === "4x6" ? "6in" : "4in",
    height: size === "4x6" ? "4in" : "4in",
  })
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");

  const qrCodeString = ReactDOMServer.renderToString(
    <QRCodeSVG
      value={order.tracking_number || ""}
      size={100}
      level="M"
      includeMargin={true}
    />
  );

  element.innerHTML = `
    <div style="${Object.entries(pageStyles.label)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <div style="${Object.entries(pageStyles.qrCode)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        ${qrCodeString}
      </div>
      
      <div style="${Object.entries(pageStyles.section)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        <strong>Ship To:</strong><br>
        ${order.recipient.first_name} ${order.recipient.last_name}<br>
        ${order.recipient.address_line1}<br>
        ${
          order.recipient.address_line2
            ? order.recipient.address_line2 + "<br>"
            : ""
        }
        ${order.recipient.city}, ${order.recipient.state} ${
    order.recipient.postal_code
  }<br>
        ${order.recipient.country}
      </div>
      
      <div style="${Object.entries(pageStyles.section)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        <strong>Order ID:</strong> ${order.shipping_order_id}<br>
        <strong>Tracking:</strong> ${order.tracking_number}<br>
        <strong>Weight:</strong> ${order.total_weight} lbs
      </div>
    </div>
  `;

  return element;
};

const generateCustomsPage = async (order) => {
  const element = document.createElement("div");
  element.style.cssText = Object.entries(pageStyles.container)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");

  element.innerHTML = `
    <div style="${Object.entries(pageStyles.header)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <div style="${Object.entries(pageStyles.title)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        Customs Declaration
      </div>
      <div style="${Object.entries(pageStyles.subtitle)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        Order ID: ${order.shipping_order_id}
      </div>
    </div>

    <div style="${Object.entries(pageStyles.section)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <strong>Shipper:</strong><br>
      ${order.sender.first_name} ${order.sender.last_name}<br>
      ${order.sender.address_line1}<br>
      ${order.sender.address_line2 ? order.sender.address_line2 + "<br>" : ""}
      ${order.sender.city}, ${order.sender.state} ${
    order.sender.postal_code
  }<br>
      ${order.sender.country}
    </div>

    <div style="${Object.entries(pageStyles.section)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <strong>Recipient:</strong><br>
      ${order.recipient.first_name} ${order.recipient.last_name}<br>
      ${order.recipient.address_line1}<br>
      ${
        order.recipient.address_line2
          ? order.recipient.address_line2 + "<br>"
          : ""
      }
      ${order.recipient.city}, ${order.recipient.state} ${
    order.recipient.postal_code
  }<br>
      ${order.recipient.country}
    </div>

    ${order.Packages?.map(
      (plbs, index) => `
      <div style="${Object.entries(pageStyles.section)
        .map(([key, value]) => `${key}: ${value}`)
        .join(";")}">
        <h3>Package ${index + 1}</h3>
        <table style="${Object.entries(pageStyles.table)
          .map(([key, value]) => `${key}: ${value}`)
          .join(";")}">
          <thead>
            <tr>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Description</th>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Quantity</th>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Weight</th>
              <th style="${Object.entries(pageStyles.th)
                .map(([key, value]) => `${key}: ${value}`)
                .join(";")}">Value</th>
            </tr>
          </thead>
          <tbody>
            ${plbs.PackageItems?.map(
              (item) => `
              <tr>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.description || item.name}</td>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.quantity}</td>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">${item.weight} lbs</td>
                <td style="${Object.entries(pageStyles.td)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(";")}">$${item.value || "0.00"}</td>
              </tr>
            `
            ).join("")}
          </tbody>
        </table>
      </div>
    `
    ).join("")}

    <div style="${Object.entries(pageStyles.section)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";")}">
      <strong>Total Declared Value:</strong> $${order.total_declared_value}<br>
      <strong>Total Weight:</strong> ${order.total_weight} lbs
    </div>
  `;

  return element;
};

export {
  generateManifestPage,
  generatePackingListPage,
  generateShippingLabelPage,
  generateCustomsPage,
};
