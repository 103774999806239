import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./BusinessProfilePage.module.css";
import { CircularProgress, Box, Skeleton } from "@mui/material";
import { get } from "lodash";

const defaultProfilePicture = "/images/default-business-profile.jpg";
const defaultBannerPicture = "/images/default-business-banner.jpg";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const ProfileSkeleton = () => (
  <div className={styles["profile-info"]}>
    <div className={styles["profile-picture"]}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        animation="wave"
      />
    </div>
    <div className={styles["banner-picture"]}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        animation="wave"
      />
    </div>
    <div className={styles["business-details"]}>
      <Skeleton variant="text" width="60%" height={40} animation="wave" />
      <Skeleton variant="text" width="40%" animation="wave" />
      <Skeleton variant="text" width="40%" animation="wave" />
      <Skeleton variant="text" width="30%" animation="wave" />
      <Skeleton variant="text" width="25%" animation="wave" />
    </div>
    <div className={styles["profile-grid"]}>
      {[1, 2, 3].map((item) => (
        <div key={item} className={styles["profile-card"]}>
          <Skeleton variant="text" width="50%" height={30} animation="wave" />
          <Skeleton variant="text" width="70%" animation="wave" />
        </div>
      ))}
    </div>
  </div>
);

const BusinessProfilePage = () => {
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [currentBusiness, setCurrentBusiness] = useState(
    getBusinessById(businessId)
  );
  // Use the custom hook for category management
  const businessCategory = useBusinessCategory(businessId);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
    console.log("BusinessProfilePage useEffect");
    console.log("businessId", businessId);
    console.log("currentBusiness", currentBusiness);
    console.log("businessCategory", businessCategory);
  }, [businessId, getBusinessById]);

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
  }, [sidebarActive]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  const profilePictureSrc = currentBusiness?.business_profile_picture
    ? `data:image/jpeg;base64,${currentBusiness.business_profile_picture}`
    : defaultProfilePicture;

  const bannerPictureSrc = currentBusiness?.business_banner_picture
    ? `data:image/jpeg;base64,${currentBusiness.business_banner_picture}`
    : defaultBannerPicture;

  return (
    <div className={styles["profile-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessHeader />
        <div className={styles["main-content"]}>
          {renderSidebar()}
          <main
            className={`${styles["profile-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Business Profile</h1>
            {!currentBusiness ? (
              <ProfileSkeleton />
            ) : (
              <>
                <div className={styles["profile-info"]}>
                  <div className={styles["profile-picture"]}>
                    <img src={profilePictureSrc} alt="Business Profile" />
                  </div>
                  <div className={styles["banner-picture"]}>
                    <img src={bannerPictureSrc} alt="Business Banner" />
                  </div>
                  <div className={styles["business-details"]}>
                    <h2>{currentBusiness.business_name}</h2>
                    <p>Email: {currentBusiness.business_email}</p>
                    <p>
                      Phone: {currentBusiness.business_phone || "Not provided"}
                    </p>
                    <p>Category: {currentBusiness.business_category_name}</p>
                    <p>Status: {currentBusiness.business_status}</p>
                  </div>
                </div>
                <div className={styles["profile-grid"]}>
                  <Link
                    to={`/business/${businessId}/edit`}
                    className={styles["profile-card"]}
                  >
                    <h3>Edit Business</h3>
                    <p>Update your business information</p>
                  </Link>
                  <Link
                    to={`/business/${businessId}/services`}
                    className={styles["profile-card"]}
                  >
                    <h3>Services</h3>
                    <p>Manage your service offerings</p>
                  </Link>
                  <Link
                    to={`/business/${businessId}/addresses`}
                    className={styles["profile-card"]}
                  >
                    <h3>Addresses</h3>
                    <p>Manage business addresses</p>
                  </Link>
                </div>
              </>
            )}
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default BusinessProfilePage;
