import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  IconButton,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Switch,
  FormControlLabel,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
  Tooltip,
  Toolbar,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Error as ErrorIcon,
  ReportProblem as ReportProblemIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const PackageDetails = ({ packages, onPackageDetailsChange }) => {
  const { api } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [upcErrors, setUpcErrors] = useState({});
  const [expandedItems, setExpandedItems] = useState([]);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reportSubmitted, setReportSubmitted] = useState({});

  // Add report dialog handlers
  const handleReportDialogOpen = (item) => {
    setSelectedItem(item);
    setReportDialogOpen(true);
  };

  useEffect(() => {
    console.log("packages", packages);
    console.log("errors", errors);
  }, [packages, errors]);

  const handleReportDialogClose = () => {
    setReportDialogOpen(false);
    setSelectedItem(null);
  };

  const handleReportSubmit = () => {
    setReportSubmitted((prev) => ({
      ...prev,
      [selectedItem.upc]: true,
    }));
    handleReportDialogClose();
  };

  const handleNumberInput = (value) => {
    // Parse the input as a float and round to 2 decimal places
    const parsed = parseFloat(parseFloat(value).toFixed(2));
    return isNaN(parsed) ? "" : parsed.toString();
  };

  const handlePackageChange = (pkgIndex, field, value) => {
    const updatedPackages = [...packages];
    if (field === "dimensions") {
      updatedPackages[pkgIndex] = {
        ...updatedPackages[pkgIndex],
        dimensions: {
          ...updatedPackages[pkgIndex].dimensions,
          ...Object.fromEntries(
            Object.entries(value).map(([key, val]) => [
              key,
              handleNumberInput(val),
            ])
          ),
        },
      };
    } else if (["weight", "declared_value"].includes(field)) {
      updatedPackages[pkgIndex] = {
        ...updatedPackages[pkgIndex],
        [field]: handleNumberInput(value),
      };
    } else {
      updatedPackages[pkgIndex] = {
        ...updatedPackages[pkgIndex],
        [field]: value,
      };
    }
    onPackageDetailsChange(updatedPackages);
  };

  const handleItemChange = (pkgIndex, itemIndex, field, value) => {
    const updatedPackages = [...packages];
    updatedPackages[pkgIndex] = {
      ...updatedPackages[pkgIndex],
      PackageItems: updatedPackages[pkgIndex].PackageItems.map((item, index) =>
        index === itemIndex
          ? {
              ...item,
              [field]: ["weight", "quantity"].includes(field)
                ? handleNumberInput(value)
                : value,
            }
          : item
      ),
    };
    onPackageDetailsChange(updatedPackages);
  };

  const addUPCItem = async (pkgIndex) => {
    const pkg = packages[pkgIndex];
    if (pkg.upcCode) {
      try {
        const response = await api.get(`/upc/${pkg.upcCode}`);
        const productData = response.data.product;

        const parseSpecs = (specsArray) => {
          return specsArray.reduce((acc, spec) => {
            let [key, value] = spec;
            key = key.toLowerCase().replace(/\s+/g, "_");
            acc[key] = value;
            return acc;
          }, {});
        };

        const existingItemIndex = pkg.PackageItems.findIndex(
          (item) => item.upc === productData.upc
        );

        const updatedPackages = [...packages];
        if (existingItemIndex > -1) {
          updatedPackages[pkgIndex].PackageItems[
            existingItemIndex
          ].quantity += 1;
        } else {
          updatedPackages[pkgIndex].PackageItems.push({
            item_id: productData.item_id,
            quantity: 1,
            name: productData.name,
            description: productData.description,
            specs: productData.specs ? parseSpecs(productData.specs) : {},
            weight: parseFloat(parseSpecs(productData.specs).weight) || "",
            value: productData.value || "",
            package_id: pkg.package_id,
            Item: productData,
          });

          setExpandedItems((prev) => [
            ...prev,
            `${pkgIndex}-${updatedPackages[pkgIndex].PackageItems.length - 1}`,
          ]);
        }

        updatedPackages[pkgIndex].upcCode = "";
        onPackageDetailsChange(updatedPackages);
        setUpcErrors((prevErrors) => ({
          ...prevErrors,
          [pkgIndex]: null,
        }));
      } catch (err) {
        setUpcErrors((prevErrors) => ({
          ...prevErrors,
          [pkgIndex]:
            err.response?.data?.error || "Failed to fetch product data.",
        }));
      }
    }
  };

  const addManualItem = (pkgIndex) => {
    const updatedPackages = [...packages];
    const newItemIndex = updatedPackages[pkgIndex].PackageItems.length;
    updatedPackages[pkgIndex].PackageItems.push({
      name: "",
      description: "",
      weight: "",
      quantity: 1,
    });
    setExpandedItems((prev) => [...prev, `${pkgIndex}-${newItemIndex}`]);
    onPackageDetailsChange(updatedPackages);
  };

  const handleAccordionChange = (panel) => {
    setExpandedItems((prev) => {
      if (prev.includes(panel)) {
        return prev.filter((item) => item !== panel);
      }
      return [...prev, panel];
    });
  };

  const removeItem = (pkgIndex, itemIndex) => {
    const updatedPackages = [...packages];
    updatedPackages[pkgIndex].PackageItems.splice(itemIndex, 1);
    onPackageDetailsChange(updatedPackages);
  };

  const addPackage = () => {
    onPackageDetailsChange([
      ...packages,
      {
        dimensions: {
          length: "",
          width: "",
          height: "",
        },
        weight: "",
        declared_value: "",
        fragile: false,
        hazardous: false,
        temperature_control: false,
        PackageItems: [],
      },
    ]);
  };

  const removePackage = (pkgIndex) => {
    const updatedPackages = [...packages];
    updatedPackages.splice(pkgIndex, 1);
    onPackageDetailsChange(updatedPackages);
  };

  const renderItemDetails = (item, pkgIndex, itemIndex) => (
    <AccordionDetails>
      <Grid container spacing={2}>
        {item.Item?.image_data && (
          <Grid item xs={12} sm={3}>
            <CardMedia
              component="img"
              height="140"
              image={`data:image/jpeg;base64,${item.Item.image_data}`}
              alt={item.name}
              sx={{ objectFit: "contain", mb: 2, borderRadius: 1 }}
            />
            {(item.upc || item.item_id) && (
              <Box display="flex" alignItems="center" gap={1}>
                <Tooltip title="Report Incorrect Image" placement="top">
                  <IconButton
                    onClick={() => handleReportDialogOpen(item)}
                    color="warning"
                    size="small"
                    // add a description to the icon button
                    aria-label="report item issue"
                  >
                    <ReportProblemIcon />
                  </IconButton>
                  {reportSubmitted[item.upc] && (
                    <Typography variant="caption" color="text.secondary">
                      Report submitted
                    </Typography>
                  )}
                </Tooltip>
              </Box>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Name"
            multiline
            rows={6}
            value={item.name}
            onChange={(e) =>
              handleItemChange(pkgIndex, itemIndex, "name", e.target.value)
            }
            required
            error={!!errors[`${pkgIndex}-${itemIndex}-name`]}
            helperText={errors[`${pkgIndex}-${itemIndex}-name`]}
            InputProps={{
              readOnly: !!(item.item_id || item.upc),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            height="100%"
            label="Description"
            value={item.description}
            onChange={(e) =>
              handleItemChange(
                pkgIndex,
                itemIndex,
                "description",
                e.target.value
              )
            }
            multiline
            rows={6}
            required
            error={!!errors[`${pkgIndex}-${itemIndex}-description`]}
            helperText={errors[`${pkgIndex}-${itemIndex}-description`]}
            InputProps={{
              readOnly: !!(item.item_id || item.upc),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Quantity"
            type="number"
            value={item.quantity}
            onChange={(e) =>
              handleItemChange(pkgIndex, itemIndex, "quantity", e.target.value)
            }
            required
            error={!!errors[`${pkgIndex}-${itemIndex}-quantity`]}
            helperText={errors[`${pkgIndex}-${itemIndex}-quantity`]}
            inputProps={{ min: 1 }}
            onKeyDown={(e) => {
              if (["-", "+", "e"].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>

        {(item.upc || item.item_id) && (
          <>
            {/* <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="UPC"
                value={item.upc || "N/A"}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Brand"
                value={item.brand || "N/A"}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Category"
                value={item.category || "N/A"}
                InputProps={{ readOnly: true }}
              />
            </Grid> */}
          </>
        )}

        <Grid item xs={12} sm={1}>
          <IconButton onClick={() => removeItem(pkgIndex, itemIndex)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </AccordionDetails>
  );

  // Update the item rendering in the main JSX
  const renderPackageItems = (pkg, pkgIndex) => (
    <Grid item xs={12}>
      {pkg.PackageItems?.map((item, itemIndex) => (
        <Accordion
          key={itemIndex}
          expanded={expandedItems.includes(`${pkgIndex}-${itemIndex}`)}
          onChange={() => handleAccordionChange(`${pkgIndex}-${itemIndex}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${pkgIndex}-${itemIndex}-content`}
            id={`panel${pkgIndex}-${itemIndex}-header`}
          >
            <Typography>
              {item.name || `Item ${itemIndex + 1}`}
              {(item.upc || item.item_id) && (
                <Chip
                  label="UPC Item"
                  size="small"
                  color="primary"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
            {(errors[`${pkgIndex}-${itemIndex}-name`] ||
              errors[`${pkgIndex}-${itemIndex}-description`] ||
              errors[`${pkgIndex}-${itemIndex}-quantity`]) && (
              <ErrorIcon color="error" style={{ marginLeft: "10px" }} />
            )}
          </AccordionSummary>
          {renderItemDetails(item, pkgIndex, itemIndex)}
        </Accordion>
      ))}
    </Grid>
  );

  const renderPackage = (pkg, pkgIndex) => (
    <Grid item xs={12} key={pkgIndex}>
      <Typography variant="h6">Package {pkgIndex + 1}</Typography>
      {packages.length > 1 && (
        <IconButton onClick={() => removePackage(pkgIndex)} color="error">
          <DeleteIcon />
        </IconButton>
      )}

      <Grid container spacing={2}>
        {/* UPC Entry Section */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Items</Typography>
        </Grid>
        {/* Package Items */}
        {renderPackageItems(pkg, pkgIndex)}
        <Grid item xs={12}>
          {upcErrors[pkgIndex] && (
            <Alert
              severity="error"
              onClose={() => {
                setUpcErrors((prevErrors) => ({
                  ...prevErrors,
                  [pkgIndex]: null,
                }));
              }}
            >
              {upcErrors[pkgIndex]}
            </Alert>
          )}
        </Grid>

        {/* UPC and Manual Item Controls */}
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            label="UPC Code"
            value={pkg.upcCode || ""}
            helperText="Enter a UPC code to fetch product details"
            onChange={(e) =>
              handlePackageChange(pkgIndex, "upcCode", e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() => addUPCItem(pkgIndex)}
                    variant="contained"
                    color="primary"
                    disabled={!pkg.upcCode}
                  >
                    Add UPC Item
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            onClick={() => addManualItem(pkgIndex)}
            variant="outlined"
            startIcon={<AddIcon />}
            fullWidth
            sx={{ height: "70%" }}
          >
            Add Manual Item
          </Button>
        </Grid>

        {/* Package Details Section */}
        <Grid item xs={12}>
          <Divider style={{ margin: "20px 0" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Package Details</Typography>
        </Grid>

        {/* Dimensions */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Length (in)"
            type="number"
            value={pkg.dimensions?.length || ""}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "dimensions", {
                length: e.target.value,
              })
            }
            required
            error={!!errors[`package${pkgIndex}Length`]}
            helperText={errors[`package${pkgIndex}Length`]}
            inputProps={{ min: 0, step: "0.01" }}
            onKeyDown={(e) => {
              if (["-", "+", "e"].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Width (in)"
            type="number"
            value={pkg.dimensions?.width || ""}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "dimensions", {
                width: e.target.value,
              })
            }
            required
            error={!!errors[`package${pkgIndex}Width`]}
            helperText={errors[`package${pkgIndex}Width`]}
            inputProps={{ min: 0, step: "0.01" }}
            onKeyDown={(e) => {
              if (["-", "+", "e"].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Height (in)"
            type="number"
            value={pkg.dimensions?.height || ""}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "dimensions", {
                height: e.target.value,
              })
            }
            required
            error={!!errors[`package${pkgIndex}Height`]}
            helperText={errors[`package${pkgIndex}Height`]}
            inputProps={{ min: 0, step: "0.01" }}
            onKeyDown={(e) => {
              if (["-", "+", "e"].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>

        {/* Weight and Value */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Total Weight (kg)"
            type="number"
            value={pkg.weight || ""}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "weight", e.target.value)
            }
            required
            inputProps={{ min: 0, step: "0.01" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Declared Value"
            type="number"
            value={pkg.declared_value || ""}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "declared_value", e.target.value)
            }
            required
            error={!!errors[`package${pkgIndex}DeclaredValue`]}
            helperText={errors[`package${pkgIndex}DeclaredValue`]}
            inputProps={{ min: 0, step: "0.01" }}
            onKeyDown={(e) => {
              if (["-", "+", "e"].includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>

        {/* Additional Information */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Additional Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={pkg.fragile || false}
                onChange={(e) =>
                  handlePackageChange(pkgIndex, "fragile", e.target.checked)
                }
                name="fragile"
              />
            }
            label="Fragile"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={pkg.hazardous || false}
                onChange={(e) =>
                  handlePackageChange(pkgIndex, "hazardous", e.target.checked)
                }
                name="hazardous"
              />
            }
            label="Hazardous"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={pkg.temperature_control || false}
                onChange={(e) =>
                  handlePackageChange(
                    pkgIndex,
                    "temperature_control",
                    e.target.checked
                  )
                }
                name="temperature_control"
              />
            }
            label="Temperature Controlled"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  // Then update the return statement to use this function
  return (
    <>
      <Grid container spacing={3}>
        {packages.map((pkg, pkgIndex) => renderPackage(pkg, pkgIndex))}
        <Grid item xs={12}>
          <Button onClick={addPackage} variant="outlined">
            Add Another Package
          </Button>
        </Grid>
        {Object.keys(errors).length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              Please correct the errors in the package and item details before
              proceeding.
            </Alert>
          </Grid>
        )}
      </Grid>

      <Dialog open={reportDialogOpen} onClose={handleReportDialogClose}>
        <DialogTitle>Report Incorrect or Missing Image</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Issue Description"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReportDialogClose}>Cancel</Button>
          <Button onClick={handleReportSubmit} color="primary">
            Submit Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PackageDetails;
