import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const SendReceiptDialog = ({ order, isOpen, onClose }) => {
  const { api } = useContext(AuthContext);
  const [email, setEmail] = useState(order.sender.email || "");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSendReceipt = async () => {
    setIsLoading(true);
    try {
      await api.post(
        `/shipping-orders/send-receipt/${order.shipping_order_id}`,
        { email }
      );
      setSnackbar({
        open: true,
        message: "Receipt sent successfully",
        severity: "success",
      });
      onClose();
    } catch (error) {
      console.error("Error sending receipt:", error);
      setSnackbar({
        open: true,
        message: "Failed to send receipt",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>Send Receipt</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSendReceipt} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendReceiptDialog;
