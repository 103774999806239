import React, { useState } from "react";
import {
  Paper,
  ToggleButton,
  Typography,
  Divider,
  Tooltip,
  Box,
  IconButton,
  Collapse,
  Slider,
} from "@mui/material";
import {
  History as HistoryIcon,
  Place as MapPinIcon,
  Route as RouteIcon,
  LocalShipping as PackageIcon,
  Person as UserIcon,
  Settings as SettingsIcon,
  Layers as LayersIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  justifyContent: "flex-start",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  width: "100%",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ControlSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));

export default function MapControls({
  // Map display controls
  showHistoryPaths,
  setShowHistoryPaths,
  showOrders,
  setShowOrders,
  showCouriers,
  setShowCouriers,
  showActiveOnly,
  setShowActiveOnly,
  // Map appearance settings
  pathOpacity,
  setPathOpacity,
  mapType,
  setMapType,
  // Data settings
  refreshInterval,
  setRefreshInterval,
  // Optional clustering
  showCluster,
  setShowCluster,
  // Optional callback for when settings change
  onSettingsChange,
}) {
  const [expanded, setExpanded] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const mapTypes = [
    { value: "roadmap", label: "Road Map" },
    { value: "satellite", label: "Satellite" },
    { value: "hybrid", label: "Hybrid" },
    { value: "terrain", label: "Terrain" },
  ];

  const handleSettingChange = (settingName, value) => {
    // Call the appropriate setter
    switch (settingName) {
      case "historyPaths":
        setShowHistoryPaths(value);
        break;
      case "orders":
        setShowOrders(value);
        break;
      case "couriers":
        setShowCouriers(value);
        break;
      case "activeOnly":
        setShowActiveOnly(value);
        break;
      case "pathOpacity":
        setPathOpacity(value);
        break;
      case "mapType":
        setMapType(value);
        break;
      case "refreshInterval":
        setRefreshInterval(value);
        break;
      case "cluster":
        setShowCluster(value);
        break;
    }

    // Notify parent of changes if callback provided
    if (onSettingsChange) {
      onSettingsChange(settingName, value);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: 16,
        right: 16,
        width: 280,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        backdropFilter: "blur(10px)",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <LayersIcon fontSize="small" />
          Map Controls
        </Typography>
        <Box>
          <Tooltip title="Settings">
            <IconButton
              size="small"
              onClick={() => setShowSettings(!showSettings)}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={expanded ? "Collapse" : "Expand"}>
            <IconButton size="small" onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Divider />

      <Collapse in={expanded}>
        <ControlSection>
          <StyledToggleButton
            value="historyPaths"
            selected={showHistoryPaths}
            onChange={() =>
              handleSettingChange("historyPaths", !showHistoryPaths)
            }
            size="small"
          >
            <HistoryIcon fontSize="small" />
            <Typography variant="body2">Location History</Typography>
          </StyledToggleButton>

          <StyledToggleButton
            value="orders"
            selected={showOrders}
            onChange={() => handleSettingChange("orders", !showOrders)}
            size="small"
          >
            <PackageIcon fontSize="small" />
            <Typography variant="body2">Orders</Typography>
          </StyledToggleButton>

          <StyledToggleButton
            value="couriers"
            selected={showCouriers}
            onChange={() => handleSettingChange("couriers", !showCouriers)}
            size="small"
          >
            <UserIcon fontSize="small" />
            <Typography variant="body2">Couriers</Typography>
          </StyledToggleButton>

          {/* <StyledToggleButton
            value="activeOnly"
            selected={showActiveOnly}
            onChange={() => handleSettingChange("activeOnly", !showActiveOnly)}
            size="small"
          >
            <RouteIcon fontSize="small" />
            <Typography variant="body2">Active Only</Typography>
          </StyledToggleButton> */}

          {setShowCluster && (
            <StyledToggleButton
              value="cluster"
              selected={showCluster}
              onChange={() => handleSettingChange("cluster", !showCluster)}
              size="small"
            >
              <MapPinIcon fontSize="small" />
              <Typography variant="body2">Cluster Markers</Typography>
            </StyledToggleButton>
          )}
        </ControlSection>

        <Collapse in={showSettings}>
          <Divider />
          <ControlSection>
            <Typography variant="subtitle2" gutterBottom>
              Settings
            </Typography>

            <Box>
              <Typography variant="caption" gutterBottom>
                Path Opacity
              </Typography>
              <Slider
                value={pathOpacity}
                onChange={(_, value) =>
                  handleSettingChange("pathOpacity", value)
                }
                min={0.1}
                max={1}
                step={0.1}
                marks
                size="small"
                valueLabelDisplay="auto"
              />
            </Box>

            {/* <Box>
              <Typography variant="caption" gutterBottom>
                Refresh Interval (seconds)
              </Typography>
              <Slider
                value={refreshInterval}
                onChange={(_, value) =>
                  handleSettingChange("refreshInterval", value)
                }
                min={5}
                max={60}
                step={5}
                marks
                size="small"
                valueLabelDisplay="auto"
              />
            </Box> */}

            <Box>
              <Typography variant="caption" gutterBottom>
                Map Type
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {mapTypes.map((type) => (
                  <ToggleButton
                    key={type.value}
                    value={type.value}
                    selected={mapType === type.value}
                    onChange={() => handleSettingChange("mapType", type.value)}
                    size="small"
                    sx={{ flex: "1 0 45%" }}
                  >
                    <Typography variant="caption">{type.label}</Typography>
                  </ToggleButton>
                ))}
              </Box>
            </Box>
          </ControlSection>
        </Collapse>
      </Collapse>
    </Paper>
  );
}
