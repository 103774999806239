import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { AuthContext } from "./AuthContext";

const BusinessContext = createContext();

const MAX_STORAGE_ITEMS = 10;
const MAX_IMAGE_SIZE = 1024 * 1024; // 1MB

const cleanupStorage = () => {
  try {
    const businessKeys = Object.keys(localStorage).filter(
      (key) => key.startsWith("business_") || key === "businesses"
    );

    if (businessKeys.length > MAX_STORAGE_ITEMS) {
      const keysToRemove = businessKeys.slice(
        0,
        businessKeys.length - MAX_STORAGE_ITEMS
      );
      keysToRemove.forEach((key) => localStorage.removeItem(key));
    }
  } catch (error) {
    console.error("Error cleaning up storage:", error);
  }
};

const compressImageData = (business) => {
  const compressedBusiness = { ...business };

  if (
    business.business_profile_picture &&
    business.business_profile_picture.length > MAX_IMAGE_SIZE
  ) {
    compressedBusiness.business_profile_picture = null;
  }

  if (
    business.business_banner_picture &&
    business.business_banner_picture.length > MAX_IMAGE_SIZE
  ) {
    compressedBusiness.business_banner_picture = null;
  }

  return compressedBusiness;
};

const safeSetItem = (key, value) => {
  try {
    cleanupStorage();

    const compressedValue = key.startsWith("business_")
      ? JSON.stringify(compressImageData(JSON.parse(value)))
      : value;

    try {
      localStorage.setItem(key, compressedValue);
    } catch (quotaError) {
      if (quotaError.name === "QuotaExceededError") {
        Object.keys(localStorage)
          .filter((k) => k.startsWith("business_"))
          .forEach((k) => localStorage.removeItem(k));

        localStorage.setItem(key, compressedValue);
      } else {
        throw quotaError;
      }
    }
  } catch (error) {
    console.warn(`Failed to set localStorage item: ${key}`, error);
  }
};

export const BusinessProvider = ({ children }) => {
  const { api, isAuthenticated } = useContext(AuthContext);
  const [businesses, setBusinesses] = useState(() => {
    try {
      const storedBusinesses = localStorage.getItem("businesses");
      return storedBusinesses ? JSON.parse(storedBusinesses) : [];
    } catch (error) {
      console.error("Error parsing stored businesses:", error);
      return [];
    }
  });

  const [selectedBusinessId, setSelectedBusinessId] = useState(() => {
    return localStorage.getItem("selectedBusinessId") || null;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      clearBusinessData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBusinesses();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    try {
      safeSetItem("businesses", JSON.stringify(businesses));
    } catch (error) {
      console.error("Error storing businesses:", error);
    }
  }, [businesses]);

  useEffect(() => {
    try {
      if (selectedBusinessId) {
        localStorage.setItem("selectedBusinessId", selectedBusinessId);
      } else {
        localStorage.removeItem("selectedBusinessId");
      }
    } catch (error) {
      console.error("Error managing selected business:", error);
    }
  }, [selectedBusinessId]);

  const fetchBusinesses = useCallback(
    async (forceRefresh = false) => {
      if (!isAuthenticated) {
        console.log("User not authenticated. Skipping business fetch.");
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const response = await api.get("/business/get-all-businesses");

        if (response.data && Array.isArray(response.data.businesses)) {
          const compressedBusinesses =
            response.data.businesses.map(compressImageData);
          setBusinesses(compressedBusinesses);

          compressedBusinesses.slice(-MAX_STORAGE_ITEMS).forEach((business) => {
            safeSetItem(
              `business_${business.business_id}`,
              JSON.stringify(business)
            );
          });

          if (
            selectedBusinessId &&
            !compressedBusinesses.some(
              (business) => business.business_id === selectedBusinessId
            )
          ) {
            setSelectedBusinessId(null);
          }
        }
      } catch (error) {
        console.error("Error fetching businesses:", error);
        if (!forceRefresh) {
          try {
            const cachedBusinesses = localStorage.getItem("businesses");
            if (cachedBusinesses) {
              setBusinesses(JSON.parse(cachedBusinesses));
            }
          } catch (parseError) {
            console.error("Error parsing cached businesses:", parseError);
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [api, isAuthenticated, selectedBusinessId]
  );

  const selectBusiness = useCallback((businessId) => {
    const business = getBusinessById(businessId);
    if (business) {
      setSelectedBusinessId(businessId);
      return business;
    }
    return null;
  }, []);

  const getBusinessById = useCallback(
    (businessId) => {
      const businessFromState = businesses.find(
        (business) => business.business_id === businessId
      );
      if (businessFromState) return businessFromState;

      try {
        const cachedBusiness = localStorage.getItem(`business_${businessId}`);
        return cachedBusiness ? JSON.parse(cachedBusiness) : null;
      } catch (error) {
        console.error("Error retrieving business from cache:", error);
        return null;
      }
    },
    [businesses]
  );

  const updateBusinessInLocalStorage = useCallback(
    (updatedBusiness) => {
      try {
        if (!updatedBusiness?.business_id) {
          throw new Error("Invalid business data");
        }

        const updatedBusinesses = businesses.map((business) =>
          business.business_id === updatedBusiness.business_id
            ? { ...business, ...updatedBusiness }
            : business
        );

        setBusinesses(updatedBusinesses);

        safeSetItem(
          `business_${updatedBusiness.business_id}`,
          JSON.stringify(updatedBusiness)
        );
        safeSetItem("businesses", JSON.stringify(updatedBusinesses));

        return true;
      } catch (error) {
        console.error("Error updating business:", error);
        return false;
      }
    },
    [businesses]
  );

  const clearBusinessData = useCallback(() => {
    try {
      setBusinesses([]);
      setSelectedBusinessId(null);
      setError(null);

      localStorage.removeItem("businesses");
      localStorage.removeItem("selectedBusinessId");

      businesses.forEach((business) => {
        localStorage.removeItem(`business_${business.business_id}`);
      });
    } catch (error) {
      console.error("Error clearing business data:", error);
    }
  }, [businesses]);

  const updateCurrentBusinessContext = useCallback(async () => {
    if (!isAuthenticated || !selectedBusinessId) {
      return null;
    }

    try {
      const response = await api.get("/business/get-all-businesses");

      if (response.data && Array.isArray(response.data.businesses)) {
        const currentBusiness = response.data.businesses.find(
          (business) => business.business_id === selectedBusinessId
        );

        if (currentBusiness) {
          updateBusinessInLocalStorage(currentBusiness);
          return currentBusiness;
        }
      }
      return null;
    } catch (error) {
      console.error("Error updating business context:", error);
      return null;
    }
  }, [api, isAuthenticated, selectedBusinessId, updateBusinessInLocalStorage]);

  return (
    <BusinessContext.Provider
      value={{
        businesses,
        selectedBusinessId,
        loading,
        error,
        selectBusiness,
        getBusinessById,
        fetchBusinesses,
        updateBusinessInLocalStorage,
        clearBusinessData,
        updateCurrentBusinessContext,
        selectedBusiness: selectedBusinessId
          ? businesses.find((b) => b.business_id === selectedBusinessId)
          : null,
        hasBusiness: businesses.length > 0,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusiness = () => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error("useBusiness must be used within a BusinessProvider");
  }
  return context;
};

export default BusinessContext;
