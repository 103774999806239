import React, { useState, useCallback, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Typography, Skeleton, Button } from "@mui/material";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import BusinessActionItems from "./BusinessActionItems";
import BusinessDashboardOverview from "../../../components/Actions/BusinessDashboardOverview";
import "./BusinessDashboardPage.css";

const BusinessDashboardPage = () => {
  const { api } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  const [actionItems, setActionItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        setLoading(true);

        // Fetch business data
        const business = getBusinessById(businessId);
        setCurrentBusiness(business);

        // Fetch action items
        const response = await api.get(
          `/business-actions/${businessId}/actions`
        );

        if (response.data.success) {
          setActionItems(response.data.data);
        }

        // Save business data to localStorage
        localStorage.setItem(
          `business_${businessId}`,
          JSON.stringify(business)
        );
      } catch (err) {
        console.error("Error fetching business data:", err);
        setError(err.response?.data?.message || err.message);
      } finally {
        setLoading(false);
      }
    };

    if (businessId) {
      fetchBusinessData();
    }
  }, [businessId, getBusinessById]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  const renderDashboardContent = () => {
    if (loading) {
      return (
        <div className="dashboard-loading">
          <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" height={400} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-container">
          <Typography color="error" variant="h6">
            {error}
          </Typography>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      );
    }

    return (
      <>
        <BusinessDashboardOverview
          business={currentBusiness}
          actionItems={actionItems}
        />
        <BusinessActionItems
          businessId={businessId}
          actionItems={actionItems}
          business={currentBusiness}
        />
      </>
    );
  };

  return (
    <div className="business-dashboard-page">
      <ParallaxBackground />
      <BusinessHeader business={currentBusiness} />
      <div className="dashboard-main-content">
        <BusinessSidebar
          onSidebarStateChange={handleSidebarStateChange}
          businessCategory={currentBusiness?.business_category_name}
          businessId={businessId}
        />
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            {renderDashboardContent()}
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default BusinessDashboardPage;
