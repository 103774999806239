import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const AssetsContext = createContext();

export const useAssets = () => {
  const context = useContext(AssetsContext);
  if (context === undefined) {
    throw new Error("useAssets must be used within an AssetsProvider");
  }
  return context;
};

export const AssetsProvider = ({ children }) => {
  const [couriers, setCouriers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupsError, setGroupsError] = useState(null);
  const { api } = useContext(AuthContext);

  const fetchCouriers = useCallback(async (businessId) => {
    setLoading(true);
    try {
      const response = await api.get("/employees", {
        params: {
          business_id: businessId,
          role: "Courier",
        },
      });
      setCouriers(response.data);
    } catch (error) {
      console.error("Error fetching couriers:", error);
      setError("Failed to fetch couriers. Please try again.");
    }
    setLoading(false);
  }, []);

  const addCourier = useCallback(async (courierData, businessId) => {
    try {
      const payload = {
        ...courierData,
        employee_role: "Courier",
        employee_status: "pending",
        employee_is_active: false,
      };
      const response = await api.post(`/employees/${businessId}`, payload);
      setCouriers((prev) => [...prev, response.data.employee]);
      return response.data;
    } catch (error) {
      console.error("Error adding courier:", error);
      throw error;
    }
  }, []);

  const updateCourier = useCallback(
    async (courierId, courierData, businessId) => {
      try {
        const payload = {
          ...courierData,
          employee_role: "Courier",
        };
        const response = await api.put(
          `/employees/${businessId}/${courierId}`,
          payload
        );
        setCouriers((prev) =>
          prev.map((courier) =>
            courier.employee_id === courierId ? response.data : courier
          )
        );
        return response.data;
      } catch (error) {
        console.error("Error updating courier:", error);
        throw error;
      }
    },
    []
  );

  const deleteCourier = useCallback(async (courierId, businessId) => {
    try {
      await api.delete(`/employees/${businessId}/${courierId}`, {});
      setCouriers((prev) =>
        prev.filter((courier) => courier.employee_id !== courierId)
      );
    } catch (error) {
      console.error("Error deleting courier:", error);
      throw error;
    }
  }, []);

  const fetchVehicles = useCallback(async (businessId) => {
    setLoading(true);
    try {
      const response = await api.get(`/vehicles/get-all/${businessId}`, {});
      setVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setError("Failed to fetch vehicles. Please try again.");
    }
    setLoading(false);
  }, []);

  const addVehicle = useCallback(async (vehicleData, businessId) => {
    try {
      const formData = new FormData();
      for (const key in vehicleData) {
        formData.append(key, vehicleData[key]);
      }
      formData.append("business_id", businessId);

      const response = await api.post(`/vehicles/${businessId}`, formData);
      setVehicles((prev) => [...prev, response.data]);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle:", error);
      throw error;
    }
  }, []);

  const updateVehicle = useCallback(
    async (vehicleId, vehicleData, businessId) => {
      try {
        const formData = new FormData();
        for (const key in vehicleData) {
          formData.append(key, vehicleData[key]);
        }
        formData.append("business_id", businessId);

        const response = await api.put(
          `/vehicles/${businessId}/${vehicleId}`,
          formData
        );
        setVehicles((prev) =>
          prev.map((vehicle) =>
            vehicle.vehicle_id === vehicleId ? response.data : vehicle
          )
        );
        return response.data;
      } catch (error) {
        console.error("Error updating vehicle:", error);
        throw error;
      }
    },
    []
  );

  const deleteVehicle = useCallback(async (vehicleId, businessId) => {
    try {
      await api.delete(`/vehicles/${businessId}/${vehicleId}`, {});
      setVehicles((prev) =>
        prev.filter((vehicle) => vehicle.vehicle_id !== vehicleId)
      );
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      throw error;
    }
  }, []);

  const createVehicleAssignment = useCallback(
    async (businessId, assignmentData) => {
      try {
        console.log("assignmentData", assignmentData);
        console.log("businessId", businessId);
        const response = await api.post(
          `/vehicles/${businessId}/${assignmentData.vehicle_id}/assignments`,
          assignmentData
        );
        // updat the vehicle with the new vehicle assignment entry, the assignment returns the full vehicle object
        setVehicles((prev) =>
          prev.map((vehicle) =>
            vehicle.vehicle_id === response.data.vehicle_id
              ? response.data
              : vehicle
          )
        );

        return response.data;
      } catch (error) {
        console.error("Error creating vehicle assignment:", error);
        throw error;
      }
    },
    []
  );

  const unassignVehicle = useCallback(async (businessId, vehicle_id) => {
    try {
      await api.delete(`/vehicles/${businessId}/${vehicle_id}/unassign`, {});
      setVehicles((prev) =>
        prev.map((vehicle) =>
          vehicle.vehicle_id === vehicle_id
            ? {
                ...vehicle,
                ShippingVehicleAssignments: [],
              }
            : vehicle
        )
      );
    } catch (error) {
      console.error("Error unassigning vehicle:", error);
      throw error;
    }
  }, []);

  const fetchOrders = useCallback(async (businessId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/shipping-orders", {
        params: { business_id: businessId },
      });
      setOrders(response.data);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError("Failed to fetch orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchLocations = useCallback(async (businessId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/assets/locations/${businessId}`, {});
      setLocations(response.data);
    } catch (err) {
      console.error("Error fetching locations:", err);
      setError("Failed to fetch locations. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  const addLocation = useCallback(async (locationData, businessId) => {
    try {
      const response = await api.post(
        `/assets/locations/${businessId}`,
        locationData
      );
      const newLocation = response.data;
      setLocations((prevLocations) => [...prevLocations, newLocation]);
      return newLocation;
    } catch (err) {
      console.error("Error adding location:", err);
      throw new Error(
        "Failed to add location: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const updateLocation = useCallback(
    async (locationId, updatedData, businessId) => {
      try {
        const response = await api.put(
          `/assets/locations/${businessId}/${locationId}`,
          updatedData
        );
        const updatedLocation = response.data;
        setLocations((prevLocations) =>
          prevLocations.map((location) =>
            location.location_id === updatedLocation.location_id
              ? updatedLocation
              : location
          )
        );
        return updatedLocation;
      } catch (err) {
        console.error("Error updating location:", err);
        throw new Error(
          "Failed to update location: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    []
  );

  const deleteLocation = useCallback(async (locationId, businessId) => {
    try {
      await api.delete(`/assets/locations/${businessId}/${locationId}`, {});
      setLocations((prevLocations) =>
        prevLocations.filter((location) => location.location_id !== locationId)
      );
    } catch (err) {
      console.error("Error deleting location:", err);
      throw new Error(
        "Failed to delete location: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const assignOrderToCourier = useCallback(async (orderId, employeeId) => {
    try {
      const response = await api.post(`/assets/assign-order`, {
        order_id: orderId,
        employee_id: employeeId,
      });
      const updatedOrder = response.data.order;
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipping_order_id === updatedOrder.shipping_order_id
            ? updatedOrder
            : order
        )
      );
      return updatedOrder;
    } catch (err) {
      console.error("Error assigning order to courier:", err);
      throw new Error(
        "Failed to assign order: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const assignOrder = useCallback(async (orderId, assignmentData) => {
    try {
      const response = await api.post(`/assets/assign-order`, {
        order_id: orderId,
        ...assignmentData,
      });
      const updatedOrder = response.data.order;
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipping_order_id === updatedOrder.shipping_order_id
            ? updatedOrder
            : order
        )
      );
      return updatedOrder;
    } catch (err) {
      console.error("Error assigning order:", err);
      throw new Error(
        "Failed to assign order: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const createGroup = useCallback(async (groupData, businessId) => {
    try {
      const response = await api.post("/shipping-groups", {
        ...groupData,
        business_id: businessId,
      });
      const newGroup = response.data;
      // Ensure the new group has all necessary properties
      const completeNewGroup = {
        ...newGroup,
        ShippingOrders: [],
        total_value: 0,
        createdAt: new Date().toISOString(),
        status: "active",
      };
      setGroups((prevGroups) => [...prevGroups, completeNewGroup]);
      return completeNewGroup;
    } catch (err) {
      console.error("Error creating group:", err);
      throw new Error(
        "Failed to create group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const updateGroup = useCallback(async (groupId, updatedData, businessId) => {
    try {
      const response = await api.put(`/shipping-groups/${groupId}`, {
        ...updatedData,
        business_id: businessId,
      });
      const updatedGroup = response.data;
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.group_id === updatedGroup.group_id ? updatedGroup : group
        )
      );
      return updatedGroup;
    } catch (err) {
      console.error("Error updating group:", err);
      throw new Error(
        "Failed to update group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const deleteGroup = useCallback(async (groupId, businessId) => {
    try {
      await api.delete(`/shipping-groups/${groupId}`, {
        params: { business_id: businessId },
      });
      setGroups((prevGroups) =>
        prevGroups.filter((group) => group.group_id !== groupId)
      );
    } catch (err) {
      console.error("Error deleting group:", err);
      throw new Error(
        "Failed to delete group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const addOrderToGroup = useCallback(async (groupId, orderId, businessId) => {
    try {
      const response = await api.post(`/shipping-groups/${groupId}/orders`, {
        order_id: orderId,
        business_id: businessId,
      });
      const updatedGroup = response.data;
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.group_id === updatedGroup.group_id ? updatedGroup : group
        )
      );
      return updatedGroup;
    } catch (err) {
      console.error("Error adding order to group:", err);
      throw new Error(
        "Failed to add order to group: " +
          (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const removeOrderFromGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const response = await api.delete(
          `/shipping-groups/${groupId}/orders/${orderId}`,
          {
            params: { business_id: businessId },
          }
        );
        const updatedGroup = response.data;
        setGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === updatedGroup.group_id ? updatedGroup : group
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error removing order from group:", err);
        throw new Error(
          "Failed to remove order from group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    []
  );

  const fetchGroups = useCallback(async (businessId) => {
    setGroupsLoading(true);
    setGroupsError(null);
    try {
      const response = await api.get("/shipping-groups", {
        params: { business_id: businessId },
      });
      setGroups(response.data);
    } catch (err) {
      console.error("Error fetching groups:", err);
      setGroupsError("Failed to fetch groups. Please try again later.");
    } finally {
      setGroupsLoading(false);
    }
  }, []);

  const value = {
    couriers,
    vehicles,
    locations,
    orders,
    groups,
    loading,
    error,
    fetchCouriers,
    addCourier,
    updateCourier,
    deleteCourier,
    fetchVehicles,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    createVehicleAssignment,
    unassignVehicle,
    fetchLocations,
    addLocation,
    updateLocation,
    deleteLocation,
    fetchOrders,
    fetchGroups,
    assignOrderToCourier,
  };

  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};

export default AssetsContext;
