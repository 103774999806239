import React from "react";
import "./DashboardFooter.css";

const DashboardFooter = () => {
  return (
    <footer className="dashboard-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Get to Know Us</h4>
          <ul>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/careers">Careers</a>
            </li>
            <li>
              <a href="/press">Press Releases</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Connect with Us</h4>
          <ul>
            <li>
              <a href="/facebook">Facebook</a>
            </li>
            <li>
              <a href="/twitter">Twitter</a>
            </li>
            <li>
              <a href="/instagram">Instagram</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Make Money with Us</h4>
          <ul>
            <li>
              <a href="/sell">Sell on Our Platform</a>
            </li>
            <li>
              <a href="/affiliate">Become an Affiliate</a>
            </li>
            <li>
              <a href="/advertise">Advertise Your Products</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Cexpress. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default DashboardFooter;
