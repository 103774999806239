import React, { useState, useCallback, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import UserProfilePage from "../../pages/profile/UserProfilePage";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  InputBase,
  Button,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Chip,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  Search,
  ChevronRight,
  LocalOffer,
  Analytics,
  LocalShipping,
  Language,
  Business,
} from "@mui/icons-material";
import "./UserDashboardPage.css";
import ShippingServiceCard from "./ShippingServiceCard";

const UserDashboardPage = () => {
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  return (
    <Box className="user-dashboard-page">
      <ParallaxBackground />
      <DashboardHeader />
      <Box className="dashboard-main-content">
        <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
        <Box className="dashboard-container">
          <Box
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            <Routes>
              <Route path="/" element={<DashboardOverview />} />
              <Route path="profile" element={<UserProfilePage />} />
              <Route path="search-results" element={<SearchResultsPage />} />
            </Routes>
          </Box>
        </Box>
      </Box>
      <DashboardFooter />
    </Box>
  );
};

const DashboardOverview = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("all");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const { api } = useContext(AuthContext);

  const showAlert = (message, severity = "error") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.get("/search/all", {});

      console.log("Fetched data:", response.data);

      setProducts(response.data.products || []);
      setServices(response.data.services || []);
      showAlert("Data loaded successfully", "success");
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Failed to load data";
      setError(errorMessage);
      showAlert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      showAlert("Please enter a search query");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await api.get("/search/search", {
        params: {
          query: searchQuery,
          type: searchType,
        },
      });

      setProducts(response.data.products || []);
      setServices(response.data.services || []);

      const resultCount =
        (response.data.products?.length || 0) +
        (response.data.services?.length || 0);

      if (resultCount === 0) {
        showAlert("No results found", "info");
      } else {
        showAlert(`Found ${resultCount} results`, "success");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        err.response?.data?.errors?.[0]?.msg ||
        "Search failed";
      setError(errorMessage);
      showAlert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header */}
      <Box textAlign="center" mb={6}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ color: "#2c3e50" }}
        >
          Welcome to Your Dashboard
        </Typography>
        <Typography variant="h6" sx={{ color: "#7f8c8d" }}>
          Discover our shipping solutions tailored for your business
        </Typography>
      </Box>

      {/* Search Form */}
      <Paper
        component="form"
        onSubmit={handleSearch}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: 800,
          mx: "auto",
          mb: 6,
          borderRadius: 2,
        }}
        elevation={3}
      >
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <Search />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search for products and services..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          style={{
            margin: "0 10px",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <option value="all">All</option>
          <option value="product">Products</option>
          <option value="service">Services</option>
        </select>
        <Button variant="contained" type="submit" sx={{ px: 3, m: "8px" }}>
          Search
        </Button>
      </Paper>

      {/* Loading and Error States */}
      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}

      {error && !loading && (
        <Alert severity="error" sx={{ mb: 4 }} onClose={() => setError(null)}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}

      {/* Tabs and Content */}
      {!loading && !error && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              centered
            >
              <Tab label={`Products (${products.length})`} />
              <Tab label={`Services (${services.length})`} />
            </Tabs>
          </Box>

          {/* Products Grid */}
          {tabValue === 0 && (
            <Grid container spacing={3}>
              {products.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.product_id}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" component="h3" gutterBottom>
                        {product.product_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {product.product_description}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: "auto",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#3498db" }}
                        >
                          ${product.price}
                        </Typography>
                        <Chip
                          label={product.product_category}
                          size="small"
                          sx={{
                            backgroundColor: "#ecf0f1",
                            color: "#7f8c8d",
                          }}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {/* Services Grid */}
          {tabValue === 1 && (
            <Grid container spacing={3}>
              {services.map((service) => (
                <Grid item xs={12} sm={6} md={4} key={service.service_id}>
                  {service.service_category === "Shipping" ? (
                    <ShippingServiceCard service={service} />
                  ) : (
                    // Regular service card for non-shipping services
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="h3" gutterBottom>
                          {service.service_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 2 }}
                        >
                          {service.service_description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: "auto",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#3498db" }}
                          >
                            ${service.service_price}
                          </Typography>
                          <Chip
                            label={service.service_category}
                            size="small"
                            sx={{
                              backgroundColor: "#ecf0f1",
                              color: "#7f8c8d",
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      {/* Snackbar Alert */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const SearchResultsPage = () => (
  <Container>
    <Typography variant="h4" component="h1" gutterBottom>
      Search Results
    </Typography>
    {/* Implement search results display here */}
  </Container>
);

export default UserDashboardPage;
