import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  Outlet,
} from "react-router-dom";
import { AuthProvider } from "./components/Auth/AuthContext";
import { BusinessProvider } from "./components/Auth/BusinessContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/auth/LoginPage";
import ContactPage from "./pages/contact/ContactPage";
import AboutPage from "./pages/about/AboutPage";
import UserDashboardPage from "./pages/dashboard/UserDashboardPage";
import UserProfilePage from "./pages/profile/UserProfilePage";
import EmailVerification from "./pages/verification/EmailVerification";
import BusinessEmailVerification from "./pages/verification/BusinessEmailVerification";
import EmailChangeVerification from "./pages/verification/EmailChangeVerification";
import CompleteProfilePopup from "./pages/profile/CompleteProfilePopup";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import PasswordProtectedRoute from "./components/Auth/PasswordProtectedRoute";
import CompleteProfileRoute from "./components/Auth/CompleteProfileRoute";
import EditProfilePage from "./pages/profile/EditProfilePage";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PasswordVerificationPage from "./pages/security/PasswordVerificationPage";
import LoginSecurity from "./pages/security/LoginSecurity";
import PaymentsPage from "./pages/payments/PaymentsPage";
import BusinessPaymentsPage from "./pages/business/payments/BusinessPaymentsPage";
import AddressesPage from "./pages/addresses/AddressesPage";
import OrdersPage from "./pages/orders/OrdersPage";
import BusinessesPage from "./pages/business/BusinessesPage";
import BusinessRegistrationPage from "./pages/business/BusinessRegistrationPage";
import BusinessDashboardPage from "./pages/business/dashboard/BusinessDashboardPage";
import ShipmentsPage from "./pages/business/shipping/ShipmentsPage/ShipmentsPage";
import BusinessProfilePage from "./pages/business/profile/BusinessProfilePage";
import BusinessServicesPage from "./pages/business/service/BusinessServicePage";
import EditBusinessProfilePage from "./pages/business/profile/EditBusinessProfilePage";
import BusinessCustomersPage from "./pages/business/customers/BusinessCustomersPage";
import BusinessAddressesPage from "./pages/business/address/BusinessAddressesPage";
import EmployeeManagement from "./pages/business/employees/EmployeeManagement";
import EmployeeOnboarding from "./pages/business/employees/EmployeeOnboarding";
import EmployeeDashboardPage from "./pages/employee/dashboard/EmployeeDashboardPage";
import EmployeeShipmentsPage from "./pages/employee/shipping/EmployeeShipmentsPage";
import Custom404Page from "./pages/util/Custom404Page";
import AssetsPage from "./pages/business/shipping/AssetsPage/AssetsPage";
import LocationTrackingPage from "./pages/business/shipping/TrackingPage/LocationTrackingPage";
import { LocationTrackingProvider } from "./pages/business/shipping/TrackingPage/LocationTrackingContext";
import { AssetsProvider } from "./pages/business/shipping/AssetsPage/AssetsContext";
import { OrdersProvider } from "./pages/business/shipping/ShipmentsPage/OrdersContext";
import { EmployeeProvider } from "./components/Auth/EmployeeContext";
import "./global.css";
import "./App.css";
import ServicesPage from "./pages/services/ServicesPage";

const stripePromise = loadStripe(
  "pk_live_51NnQZ6DZzcpBER6kAXXlwm7DPy4YfJPr4dq7L2O4bQU9Wma39a35H3KJApSF0Uode4ocVmkpgrL3WPNvbDE4cXWo00NcSCvsxN"
);

// Root component with providers
const Root = () => {
  return (
    <AuthProvider>
      <BusinessProvider>
        <div className="app">
          <Outlet />
          <ScrollRestoration />
        </div>
      </BusinessProvider>
    </AuthProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "home", element: <HomePage /> },
      { path: "about", element: <AboutPage /> },
      { path: "services", element: <ServicesPage /> },
      { path: "contact", element: <ContactPage /> },
      { path: "confirm-email-change", element: <EmailChangeVerification /> },
      {
        path: "auth",
        element: (
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        ),
      },
      {
        path: "dashboard/*",
        element: (
          <PrivateRoute>
            <UserDashboardPage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <UserProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile/edit",
        element: (
          <PrivateRoute>
            <EditProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: "verify",
        element: (
          <PublicRoute>
            <EmailVerification />
          </PublicRoute>
        ),
      },
      { path: "verify-business", element: <BusinessEmailVerification /> },
      {
        path: "complete-profile",
        element: (
          <CompleteProfileRoute>
            <CompleteProfilePopup />
          </CompleteProfileRoute>
        ),
      },
      {
        path: "reset-password",
        element: (
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        ),
      },
      {
        path: "verify-password",
        element: (
          <PrivateRoute>
            <PasswordVerificationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile/security",
        element: (
          <PrivateRoute>
            <PasswordProtectedRoute>
              <LoginSecurity />
            </PasswordProtectedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "profile/payments",
        element: (
          <PrivateRoute>
            <Elements stripe={stripePromise}>
              <PaymentsPage />
            </Elements>
          </PrivateRoute>
        ),
      },
      {
        path: "profile/addresses",
        element: (
          <PrivateRoute>
            <AddressesPage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile/orders",
        element: (
          <PrivateRoute>
            <OrdersPage />
          </PrivateRoute>
        ),
      },
      {
        path: "profile/businesses",
        element: (
          <PrivateRoute>
            <BusinessesPage />
          </PrivateRoute>
        ),
      },
      {
        path: "register-business",
        element: (
          <PrivateRoute>
            <BusinessRegistrationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/dashboard",
        element: (
          <PrivateRoute>
            <BusinessDashboardPage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/profile",
        element: (
          <PrivateRoute>
            <BusinessProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/services",
        element: (
          <PrivateRoute>
            <BusinessServicesPage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/edit",
        element: (
          <PrivateRoute>
            <EditBusinessProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/addresses",
        element: (
          <PrivateRoute>
            <BusinessAddressesPage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/customers",
        element: (
          <PrivateRoute>
            <BusinessCustomersPage />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/employees",
        element: (
          <PrivateRoute>
            <EmployeeManagement />
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/shipments/*",
        element: (
          <PrivateRoute>
            <OrdersProvider>
              <ShipmentsPage />
            </OrdersProvider>
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/assets/*",
        element: (
          <PrivateRoute>
            <OrdersProvider>
              <AssetsProvider>
                <AssetsPage />
              </AssetsProvider>
            </OrdersProvider>
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/finances",
        element: (
          <PrivateRoute>
            <Elements stripe={stripePromise}>
              <BusinessPaymentsPage />
            </Elements>
          </PrivateRoute>
        ),
      },
      {
        path: "employee-onboarding/:token",
        element: <EmployeeOnboarding />,
      },
      {
        path: "business/:businessId/employee/:employeeId/dashboard",
        element: (
          <PrivateRoute>
            <EmployeeProvider>
              <EmployeeDashboardPage />
            </EmployeeProvider>
          </PrivateRoute>
        ),
      },
      {
        path: "business/:businessId/employee/:employeeId/shipments/*",
        element: (
          <PrivateRoute>
            <OrdersProvider>
              <EmployeeProvider>
                <EmployeeShipmentsPage />
              </EmployeeProvider>
            </OrdersProvider>
          </PrivateRoute>
        ),
      },
      {
        path: "/business/:businessId/location-tracking/*",
        element: (
          <PrivateRoute>
            <LocationTrackingProvider>
              <LocationTrackingPage />
            </LocationTrackingProvider>
          </PrivateRoute>
        ),
      },
      { path: "*", element: <Custom404Page /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
