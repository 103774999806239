import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page" id="wrapper">
      <Header />
      <main className="about-content">
        <ParallaxBackground />
        <h1 className="about-title">About Us</h1>
        <div className="about-grid">
          <div className="about-card mission">
            <h2>Our Mission</h2>
            <p>
              Empowering innovation through cutting-edge technology and
              exceptional service.
            </p>
          </div>
          <div className="about-card vision">
            <h2>Our Vision</h2>
            <p>
              To be the catalyst for positive change in the digital landscape,
              driving progress and creativity.
            </p>
          </div>
          <div className="about-card values">
            <h2>Our Values</h2>
            <ul>
              <li>Innovation</li>
              <li>Integrity</li>
              <li>Collaboration</li>
              <li>Excellence</li>
            </ul>
          </div>
          <div className="about-card team">
            <h2>Our Team</h2>
            <p>
              A diverse group of passionate experts dedicated to pushing
              boundaries and delivering exceptional results.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutPage;
