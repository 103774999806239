import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Badge,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Skeleton,
} from "@mui/material";
import {
  Warning,
  ErrorOutline,
  CheckCircleOutline,
  LocalShipping,
  Refresh,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import "./BusinessActionItems.css";

const ActionItemsSkeleton = () => (
  <div className="action-items-skeleton">
    <Skeleton variant="rectangular" height={48} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={120} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={160} />
      </Grid>
    </Grid>
  </div>
);

const BusinessActionItems = ({ businessId, actionItems, business }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [urgentActions, setUrgentActions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { api } = useContext(AuthContext);

  useEffect(() => {
    const fetchUrgentActions = async () => {
      if (!expanded) return;

      setLoading(true);
      try {
        const response = await api.get(
          `/business-actions/${businessId}/urgent-actions`,
          {}
        );

        if (response.data.success) {
          setUrgentActions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching urgent actions:", error);
        setError(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUrgentActions();
  }, [expanded, businessId]);

  const handleCreateShippingTier = () => {
    navigate(`/business/${businessId}/services`);
  };

  const handleViewPendingOrders = () => {
    navigate(`/business/${businessId}/orders?status=pending`);
  };

  if (!actionItems) {
    return (
      <Card className="business-action-items">
        <CardContent>
          <ActionItemsSkeleton />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="business-action-items">
      <CardContent>
        <div className="action-items-header">
          <Typography variant="h6" component="h2">
            Action Items
          </Typography>
          <Badge
            badgeContent={actionItems.action_items_count}
            color="error"
            max={99}
          >
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Badge>
        </div>

        <Collapse in={expanded}>
          {loading ? (
            <ActionItemsSkeleton />
          ) : (
            <Grid container spacing={2}>
              {/* Missing Shipping Tier Warning */}
              {actionItems.needs_shipping_tier && (
                <Grid item xs={12}>
                  <Card variant="outlined" className="warning-card">
                    <CardContent>
                      <Typography color="error" gutterBottom>
                        <Warning /> No Shipping Tiers Configured
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        You need to set up shipping tiers to start accepting
                        orders.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateShippingTier}
                        sx={{ mt: 1 }}
                      >
                        Create Shipping Tier
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* Pending Orders */}
              {actionItems.pending_orders_count > 0 && (
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography color="primary" gutterBottom>
                        <LocalShipping /> Pending Orders
                      </Typography>
                      <Typography variant="body2">
                        You have {actionItems.pending_orders_count} orders
                        pending action.
                      </Typography>
                      <List>
                        {actionItems.pending_orders.map((order) => (
                          <ListItem key={order.shipping_order_id}>
                            <ListItemIcon>
                              <ErrorOutline color="warning" />
                            </ListItemIcon>
                            <ListItemText
                              primary={`Order #${order.tracking_number}`}
                              secondary={`Created: ${new Date(
                                order.createdAt
                              ).toLocaleDateString()}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleViewPendingOrders}
                      >
                        View All Pending Orders
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* Recent Updates */}
              {actionItems.recent_updates?.length > 0 && (
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography color="textPrimary" gutterBottom>
                        <Refresh /> Recent Updates
                      </Typography>
                      <List>
                        {actionItems.recent_updates.map((update) => (
                          <ListItem key={update.shipping_order_id}>
                            <ListItemIcon>
                              <CheckCircleOutline color="success" />
                            </ListItemIcon>
                            <ListItemText
                              primary={`Order #${update.tracking_number}`}
                              secondary={`Status: ${
                                update.order_status
                              } - ${new Date(
                                update.updatedAt
                              ).toLocaleString()}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {error && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default BusinessActionItems;
