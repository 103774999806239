import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaUser,
  FaTachometerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaEdit,
  FaLock,
  FaCreditCard,
  FaMapMarkerAlt,
  FaShoppingCart,
  FaBriefcase,
} from "react-icons/fa";
import logo from "../../assets/logo-plain.svg";
import "./DashboardSidebar.css";
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";

const DashboardSidebar = ({ onSidebarStateChange }) => {
  const location = useLocation();
  const { logout } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(isActive));
    onSidebarStateChange(isActive);
  }, [isActive, onSidebarStateChange]);

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ? "active" : "";
  };

  const handleLogout = async () => {
    await logout();
    navigate("/auth");
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <aside className={`dashboard-sidebar ${isActive ? "active" : ""}`}>
      <div className="sidebar-top">
        <div className="sidebar-logo">
          <img src={logo} alt="Your App Logo" className="logo" />
        </div>
        <button onClick={toggleSidebar} className="sidebar-toggle">
          {isActive ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      <nav>
        <Link to="/dashboard" className={isLinkActive("/dashboard")}>
          <FaTachometerAlt />
          <span>{isActive && "Dashboard"}</span>
        </Link>
        <Link
          to="/profile"
          className={`${isLinkActive("/profile")} parent-link`}
        >
          <FaUser />
          <span>{isActive && "Profile"}</span>
        </Link>
        <div className="sidebar-submenu">
          <Link to="/profile/edit" className={isLinkActive("/profile/edit")}>
            <FaEdit />
            <span>{isActive && "Edit Profile"}</span>
          </Link>
          <Link
            to="/profile/security"
            className={isLinkActive("/profile/security")}
          >
            <FaLock />
            <span>{isActive && "Login & Security"}</span>
          </Link>
          <Link
            to="/profile/payments"
            className={isLinkActive("/profile/payments")}
          >
            <FaCreditCard />
            <span>{isActive && "Payments"}</span>
          </Link>
          <Link
            to="/profile/addresses"
            className={isLinkActive("/profile/addresses")}
          >
            <FaMapMarkerAlt />
            <span>{isActive && "Addresses"}</span>
          </Link>
          <Link
            to="/profile/orders"
            className={isLinkActive("/profile/orders")}
          >
            <FaShoppingCart />
            <span>{isActive && "Orders"}</span>
          </Link>
          <Link
            to="/profile/businesses"
            className={isLinkActive("/profile/businesses")}
          >
            <FaBriefcase />
            <span>{isActive && "Business Account"}</span>
          </Link>
        </div>
      </nav>
      <div className="sidebar-bottom">
        <button onClick={handleLogout} className="sidebar-link">
          <FaSignOutAlt />
          <span>{isActive && "Log Out"}</span>
        </button>
      </div>
    </aside>
  );
};

export default DashboardSidebar;
