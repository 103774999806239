import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  TextField,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Pagination } from "@mui/material";

import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import styles from "./OrdersPage.module.css";

const OrdersPage = () => {
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // New state for items per page
  const { api } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
    fetchOrders();
  }, [sidebarActive, currentPage, filterOption, searchQuery, itemsPerPage]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/orders", {
        params: {
          page: currentPage,
          search: searchQuery,
          filter: filterOption,
          itemsPerPage, // Send items per page to the backend
        },
      });
      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch orders. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const renderOrderCard = (order) => (
    <Card key={order.order_id} className={styles["order-card"]}>
      <CardContent>
        <Typography variant="h6">Order ID: {order.order_id}</Typography>
        <Typography>Date: {order.date}</Typography>
        <Typography>Total: ${order.total.toFixed(2)}</Typography>
        <Typography>Contents: {order.contents}</Typography>
        <div className={styles["card-actions"]}>
          <IconButton
            aria-label="edit"
            size="small"
            color="midnightblue"
            className={styles["icon-button"]}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            className={styles["icon-button"]}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className={styles["orders-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["orders-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Manage Orders</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            <div className={styles["filter-search"]}>
              <FormControl className={styles["filter-control"]}>
                <InputLabel>Filter By</InputLabel>
                <Select
                  value={filterOption}
                  onChange={handleFilterChange}
                  label="Filter By"
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="date">Date</MenuItem>
                  <MenuItem value="total">Total</MenuItem>
                  <MenuItem value="contents">Contents</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="search"
                label="Search Orders"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                className={styles["search-field"]}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
              <FormControl className={styles["items-per-page"]}>
                <InputLabel>Items Per Page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items Per Page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </div>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div className={styles["orders-grid"]}>
                {orders.map((order) => renderOrderCard(order))}
              </div>
            )}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              className={styles.pagination}
            />
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default OrdersPage;
