import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

const CourierList = ({
  couriers,
  businessId,
  onCourierAdded,
  onCourierUpdated,
  onCourierDeleted,
}) => {
  const [open, setOpen] = useState(false);
  const [editingCourier, setEditingCourier] = useState(null);
  const [formData, setFormData] = useState({
    employee_first_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_phone: "",
    employee_hire_date: "",
    employment_status: "",
    employee_notes: "",
    employee_role: "Courier",
  });
  const [formErrors, setFormErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // filter out employees whose employee_role is not courier
  const courierEmployees = useMemo(() => {
    return couriers.filter((courier) => courier.employee_role === "Courier");
  }, [couriers]);

  const handleOpen = (courier = null) => {
    if (courier) {
      setEditingCourier(courier);
      setFormData(courier);
    } else {
      setEditingCourier(null);
      setFormData({
        employee_first_name: "",
        employee_last_name: "",
        employee_email: "",
        employee_phone: "",
        employee_hire_date: "",
        employment_status: "",
        employee_notes: "",
        employee_role: "Courier",
      });
    }
    setOpen(true);
    setAlertMessage(null);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCourier(null);
    setFormErrors({});
    setAlertMessage(null);
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.employee_first_name)
      errors.employee_first_name = "First name is required";
    if (!formData.employee_last_name)
      errors.employee_last_name = "Last name is required";
    if (!formData.employee_email) errors.employee_email = "Email is required";
    if (!formData.employee_phone) errors.employee_phone = "Phone is required";
    if (!formData.employee_hire_date)
      errors.employee_hire_date = "Hire date is required";
    if (!formData.employment_status)
      errors.employment_status = "Employment status is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        if (editingCourier) {
          await onCourierUpdated(editingCourier.employee_id, formData);
          setAlertMessage({
            type: "success",
            message: "Courier updated successfully.",
          });
        } else {
          await onCourierAdded(formData);
          setAlertMessage({
            type: "success",
            message: "Courier added successfully.",
          });
        }
        handleClose();
      } catch (error) {
        console.error("Error saving courier:", error);

        let errorMessage = "Failed to save courier. Please try again.";
        const newFormErrors = { ...formErrors };

        if (error.response && error.response.data) {
          if (error.response.data.msg) {
            errorMessage = error.response.data.msg;
          }

          if (error.response.data.employee_email) {
            newFormErrors.employee_email = "Email already exists";
          }

          if (error.response.data.employee_phone) {
            newFormErrors.employee_phone = "Phone already exists";
          }
        }

        setAlertMessage({
          type: "error",
          message: errorMessage,
        });

        setFormErrors(newFormErrors);
      }
      setIsLoading(false);
    }
  };

  const handleDelete = async (employeeId) => {
    setIsLoading(true);
    try {
      await onCourierDeleted(employeeId);
      setAlertMessage({
        type: "success",
        message: "Courier deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting courier:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete courier. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const sortedCouriers = useMemo(() => {
    return [...courierEmployees].sort((a, b) => {
      const nameA = a?.employee_first_name || "";
      const nameB = b?.employee_first_name || "";
      return nameA.localeCompare(nameB);
    });
  }, [courierEmployees]);

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
          sx={{ mb: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpen()}
        style={{ marginBottom: "1rem" }}
      >
        Add Courier
      </Button>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Hire Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCouriers.length > 0 ? (
                sortedCouriers.map((courier) => (
                  <TableRow key={courier.employee_id}>
                    <TableCell>{`${courier.employee_first_name} ${courier.employee_last_name}`}</TableCell>
                    <TableCell>{courier.employee_email}</TableCell>
                    <TableCell>{courier.employee_phone}</TableCell>
                    <TableCell>{courier.employee_hire_date}</TableCell>
                    <TableCell>{courier.employment_status}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(courier)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(courier.employee_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center">No couriers found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editingCourier ? "Edit Courier" : "Add Courier"}
        </DialogTitle>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            onClose={() => setAlertMessage(null)}
            sx={{ mb: 2, mr: 2, ml: 2 }}
          >
            {alertMessage.message}
          </Alert>
        )}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            fullWidth
            required
            value={formData.employee_first_name}
            onChange={(e) =>
              handleChange("employee_first_name", e.target.value)
            }
            error={!!formErrors.employee_first_name}
            helperText={formErrors.employee_first_name}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            required
            value={formData.employee_last_name}
            onChange={(e) => handleChange("employee_last_name", e.target.value)}
            error={!!formErrors.employee_last_name}
            helperText={formErrors.employee_last_name}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            required
            value={formData.employee_email}
            onChange={(e) => handleChange("employee_email", e.target.value)}
            error={!!formErrors.employee_email}
            helperText={formErrors.employee_email}
          />
          <MuiTelInput
            margin="dense"
            label="Phone"
            fullWidth
            value={formData.employee_phone}
            onChange={(value) => handleChange("employee_phone", value)}
            error={!!formErrors.employee_phone}
            helperText={formErrors.employee_phone}
          />
          <TextField
            margin="dense"
            label="Hire Date"
            type="date"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={formData.employee_hire_date}
            onChange={(e) => handleChange("employee_hire_date", e.target.value)}
            error={!!formErrors.employee_hire_date}
            helperText={formErrors.employee_hire_date}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Employment Status</InputLabel>
            <Select
              value={formData.employment_status}
              onChange={(e) =>
                handleChange("employment_status", e.target.value)
              }
              error={!!formErrors.employment_status}
            >
              <MenuItem value="full-time">Full Time</MenuItem>
              <MenuItem value="part-time">Part Time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={4}
            value={formData.employee_notes}
            onChange={(e) => handleChange("employee_notes", e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            dusabled={isLoading}
          >
            Save
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CourierList;
