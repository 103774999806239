import { Grid, Card, CardContent, CardHeader, Typography } from "@mui/material";

const DashboardOverview = ({ orders }) => {
  const totalOrders = orders.length;
  const pendingOrders = orders.filter(
    (order) => order.order_status === "pending"
  ).length;
  const completedOrders = orders.filter(
    (order) => order.order_status === "completed"
  ).length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardHeader title="Total Orders" />
          <CardContent>
            <Typography variant="h4">{totalOrders}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardHeader title="Pending Orders" />
          <CardContent>
            <Typography variant="h4">{pendingOrders}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardHeader title="Completed Orders" />
          <CardContent>
            <Typography variant="h4">{completedOrders}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardOverview;
