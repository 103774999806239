import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthContext";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  Chip,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  CheckCircle,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CribSharp,
} from "@mui/icons-material";
import AddHomeIcon from "@mui/icons-material/AddHome";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./BusinessAddressesPage.module.css";

const libraries = ["places"];

const BusinessAddressesPage = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const { getBusinessById, fetchBusinesses, updateCurrentBusinessContext } =
    useBusiness();
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [addressAlert, setAddressAlert] = useState(null);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [originalAddressData, setOriginalAddressData] = useState({});
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    console.log("businessId", businessId);
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    console.log("savedBusiness", savedBusiness);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
  }, [sidebarActive]);

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
      console.log("business", business);
    }
    console.log("currentBusiness", currentBusiness);
    console.log("addresses", addresses);
  }, [businessId, getBusinessById]);

  // Simplified fetch function that only gets addresses

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/business/${businessId}/addresses`);
      setAddresses(response.data);
    } catch (error) {
      console.error("Error fetching business addresses:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch addresses. Please try again.",
      });
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch addresses on component mount
  useEffect(() => {
    if (businessId) {
      fetchAddresses();
    }
  }, [businessId]);

  // Simplified save function
  const handleSave = async (addressId) => {
    const addressToSave =
      addressId === "new"
        ? newAddress
        : addresses.find((addr) => addr.business_address_id === addressId);

    if (!validateForm(addressToSave)) {
      console.log("Validation errors:", errors);
      return;
    }

    const openHours = addressToSave.BusinessOperatingHours.filter(
      (day) => !day.business_address_is_closed
    );

    const dataToSend = {
      ...addressToSave,
      BusinessOperatingHours: openHours,
    };

    setIsLoading(true);
    try {
      if (addressId === "new") {
        await api.post(`/business/${businessId}/addresses`, dataToSend);
        setNewAddress(null);
      } else {
        await api.put(
          `/business/${businessId}/addresses/${addressId}`,
          dataToSend
        );
      }

      setAlertMessage({
        type: "success",
        message: `Address ${
          addressId === "new" ? "added" : "updated"
        } successfully!`,
      });

      await fetchAddresses();
      setEditingAddressId(null);
    } catch (error) {
      console.error("Error saving address:", error);
      setAlertMessage({
        type: "error",
        message: `Failed to ${
          addressId === "new" ? "add" : "update"
        } address. Please try again.`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Simplified delete function

  const handleDelete = async (addressId) => {
    if (!addressId) {
      console.error("No address ID provided for deletion");
      setAlertMessage({
        type: "error",
        message: "Unable to delete address. Address ID is missing.",
      });
      return;
    }

    setIsLoading(true);
    try {
      await api.delete(`/${businessId}/addresses/${addressId}`);

      setAlertMessage({
        type: "success",
        message: "Address deleted successfully!",
      });

      await fetchAddresses();
    } catch (error) {
      console.error("Error deleting address:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete address. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // no need to immediately fetch addresses on page load instead set them from the stored context
    if (currentBusiness && currentBusiness.Addresses) {
      setAddresses(currentBusiness.Addresses);
    }
    console.log("addresses", addresses);
  }, []);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("sidebarActive", JSON.stringify(isActive));
  }, []);

  const handleAddNewAddress = () => {
    setNewAddress({
      business_address_name: "",
      business_address_type: "",
      business_address_street_address_1: "",
      business_address_street_address_2: "",
      business_address_city: "",
      business_address_state_province: "",
      business_address_postal_code: "",
      business_address_country: "",
      business_address_phone: "",
      BusinessOperatingHours: [
        {
          business_address_day_of_week: "Monday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Tuesday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Wednesday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Thursday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Friday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Saturday",
          business_address_is_closed: true,
        },
        {
          business_address_day_of_week: "Sunday",
          business_address_is_closed: true,
        },
      ],
    });
    setEditingAddressId("new");
  };

  const handleChange = (e, addressId) => {
    const { name, value } = e.target;
    if (addressId === "new") {
      setNewAddress((prev) => ({ ...prev, [name]: value }));
    } else {
      setAddresses((prevAddresses) =>
        prevAddresses.map((addr) =>
          addr.business_address_id === addressId
            ? { ...addr, [name]: value }
            : addr
        )
      );
    }
    // Clear the error for the changed field
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddressSelect = async (selectedAddress, addressId) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((comp) =>
          comp.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const newAddressData = {
        business_address_street_address_1: selectedAddress,
        business_address_city: getAddressComponent("locality"),
        business_address_state_province: getAddressComponent(
          "administrative_area_level_1"
        ),
        business_address_postal_code: getAddressComponent("postal_code"),
        business_address_country: getAddressComponent("country"),
        business_address_latitude: latLng.lat,
        business_address_longitude: latLng.lng,
      };

      if (addressId === "new") {
        setNewAddress((prev) => ({ ...prev, ...newAddressData }));
      } else {
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr.business_address_id === addressId
              ? { ...addr, ...newAddressData }
              : addr
          )
        );
      }
    } catch (error) {
      console.error("Error selecting address:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to process the selected address. Please try again.",
      });
    }
  };

  const validateForm = (address) => {
    const newErrors = {};
    if (!address.business_address_name) {
      newErrors.business_address_name = "Address name is required";
    }
    if (!address.business_address_type) {
      newErrors.business_address_type = "Address type is required";
    }
    if (!address.business_address_street_address_1) {
      newErrors.business_address_street_address_1 =
        "Street address is required";
    }
    if (!address.business_address_city) {
      newErrors.business_address_city = "City is required";
    }
    if (!address.business_address_state_province) {
      newErrors.business_address_state_province = "State/Province is required";
    }
    if (!address.business_address_postal_code) {
      newErrors.business_address_postal_code = "Postal code is required";
    }
    if (!address.business_address_country) {
      newErrors.business_address_country = "Country is required";
    }
    if (!address.business_address_phone) {
      newErrors.business_address_phone = "Phone number is required";
    } else if (!matchIsValidTel(address.business_address_phone)) {
      newErrors.business_address_phone = "Invalid phone number format";
    }

    if (
      address.BusinessOperatingHours &&
      Array.isArray(address.BusinessOperatingHours)
    ) {
      address.BusinessOperatingHours.forEach((hours) => {
        const day = hours.business_address_day_of_week;
        if (!hours.business_address_is_closed) {
          if (
            !hours.business_address_open_time &&
            !hours.business_address_close_time
          ) {
            newErrors[
              `BusinessOperatingHours_${day}`
            ] = `Please set both opening and closing times for ${day}`;
          } else if (!hours.business_address_open_time) {
            newErrors[
              `BusinessOperatingHours_${day}`
            ] = `Please set opening time for ${day}`;
          } else if (!hours.business_address_close_time) {
            newErrors[
              `BusinessOperatingHours_${day}`
            ] = `Please set closing time for ${day}`;
          }
        }
      });
    } else {
      newErrors.BusinessOperatingHours = "Operating hours are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEdit = (address) => {
    setEditingAddressId(address.business_address_id);
    setOriginalAddressData((prev) => ({
      ...prev,
      [address.business_address_id]: { ...address },
    }));
  };

  const handleCancelEdit = (addressId) => {
    if (addressId === "new") {
      // If canceling a new address, remove it entirely
      setNewAddress(null);
    } else {
      // If canceling an edit on an existing address, revert changes
      setAddresses((prevAddresses) =>
        prevAddresses.map((addr) =>
          addr.business_address_id === addressId
            ? { ...addr, ...originalAddressData[addressId] }
            : addr
        )
      );
    }
    // Clear editing state
    setEditingAddressId(null);
    // Clear all errors
    setErrors({});
    // Clear any address-specific alert
    setAddressAlert(null);
  };

  const renderAddressCard = (address) => {
    const isEditing =
      address.business_address_id === editingAddressId ||
      address.business_address_id === "new";

    const parseTime = (timeString) => {
      if (!timeString) return null;
      const [hours, minutes] = timeString.split(":");
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      return date;
    };

    const formatTime = (timeString) => {
      if (!timeString) return "Closed";
      const date = parseTime(timeString);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const handleTimeChange = (day, type, value) => {
      const newHours = address.BusinessOperatingHours || [];
      const existingDayIndex = newHours.findIndex(
        (hour) => hour.business_address_day_of_week === day
      );

      if (existingDayIndex > -1) {
        newHours[existingDayIndex] = {
          ...newHours[existingDayIndex],
          [`business_address_${type}_time`]: value.toTimeString().split(" ")[0],
        };
      } else {
        newHours.push({
          business_address_day_of_week: day,
          [`business_address_${type}_time`]: value.toTimeString().split(" ")[0],
          business_address_is_closed: false,
        });
      }

      handleChange(
        {
          target: {
            name: "BusinessOperatingHours",
            value: newHours,
          },
        },
        address.business_address_id
      );
    };

    const handleDayToggle = (day, checked) => {
      const newHours = address.BusinessOperatingHours || [];
      const existingDayIndex = newHours.findIndex(
        (hour) => hour.business_address_day_of_week === day
      );

      if (checked) {
        if (existingDayIndex === -1) {
          newHours.push({
            business_address_day_of_week: day,
            business_address_open_time: null,
            business_address_close_time: null,
            business_address_is_closed: false,
          });
        } else {
          newHours[existingDayIndex].business_address_is_closed = false;
        }
      } else {
        if (existingDayIndex > -1) {
          newHours[existingDayIndex].business_address_is_closed = true;
        }
      }

      handleChange(
        {
          target: {
            name: "BusinessOperatingHours",
            value: newHours,
          },
        },
        address.business_address_id
      );
    };

    return (
      <Card className={styles["business-address-card"]}>
        <CardContent
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
          }}
        >
          {isEditing ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSave(address.business_address_id);
              }}
            >
              <FormControl
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_type}
              >
                <InputLabel htmlFor="business_address_type">
                  Address Type
                </InputLabel>
                <Select
                  name="business_address_type"
                  value={address.business_address_type}
                  onChange={(e) => handleChange(e, address.business_address_id)}
                >
                  <MenuItem value="primary">Primary</MenuItem>
                  <MenuItem value="billing">Billing</MenuItem>
                  <MenuItem value="shipping">Shipping</MenuItem>
                  <MenuItem value="warehouse">Warehouse</MenuItem>
                </Select>
                {errors.business_address_type && (
                  <Typography variant="caption" color="error">
                    {errors.business_address_type}
                  </Typography>
                )}
              </FormControl>
              <PlacesAutocomplete
                value={address.business_address_street_address_1}
                onChange={(value) =>
                  handleChange(
                    {
                      target: {
                        name: "business_address_street_address_1",
                        value,
                      },
                    },
                    address.business_address_id
                  )
                }
                onSelect={(selectedAddress) =>
                  handleAddressSelect(
                    selectedAddress,
                    address.business_address_id
                  )
                }
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextField
                      {...getInputProps({
                        name: "business_address_street_address_1",
                        label: "Street Address 1",
                        fullWidth: true,
                        margin: "dense",
                        required: true,
                        error: !!errors.business_address_street_address_1,
                        helperText: errors.business_address_street_address_1,
                      })}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "0.8rem",
                          height: "30px",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.8rem",
                          padding: "8px",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.75rem",
                          top: "-6px",
                        },
                        "& .MuiInputLabel-shrink": { top: "0" },
                        "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                      }}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className={styles["autocomplete-dropdown-container"]}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? styles["suggestion-item--active"]
                            : styles["suggestion-item"];
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                              key={index}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
              <TextField
                name="business_address_street_address_2"
                label="Street Address 2"
                value={address.business_address_street_address_2}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="business_address_city"
                label="City"
                value={address.business_address_city}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_city}
                helperText={errors.business_address_city}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="business_address_state_province"
                label="State/Province"
                value={address.business_address_state_province}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_state_province}
                helperText={errors.business_address_state_province}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="business_address_postal_code"
                label="Postal Code"
                value={address.business_address_postal_code}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_postal_code}
                helperText={errors.business_address_postal_code}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="business_address_country"
                label="Country"
                value={address.business_address_country}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_country}
                helperText={errors.business_address_country}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <MuiTelInput
                name="business_address_phone"
                label="Phone Number"
                value={address.business_address_phone}
                onChange={(value) =>
                  handleChange(
                    { target: { name: "business_address_phone", value } },
                    address.business_address_id
                  )
                }
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_phone}
                helperText={errors.business_address_phone}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />
              <TextField
                name="business_address_name"
                label="Address Name"
                value={address.business_address_name}
                onChange={(e) => handleChange(e, address.business_address_id)}
                fullWidth
                margin="dense"
                required
                error={!!errors.business_address_name}
                helperText={errors.business_address_name}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "0.8rem",
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "8px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                    top: "-6px",
                  },
                  "& .MuiInputLabel-shrink": { top: "0" },
                  "& .MuiFormHelperText-root": { fontSize: "0.75rem" },
                }}
              />

              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Hours of Operation
              </Typography>
              <Alert severity="info" style={{ marginBottom: "10px" }}>
                Please set the hours of operation for each day of the week.
              </Alert>

              <div className={styles["hours-of-operation-grid"]}>
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day) => {
                  const dayHours = address.BusinessOperatingHours?.find(
                    (hour) => hour.business_address_day_of_week === day
                  ) || { business_address_is_closed: true };

                  return (
                    <div key={day} className={styles["hours-row"]}>
                      <div className={styles["day-column"]}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!dayHours.business_address_is_closed}
                              onChange={(event) =>
                                handleDayToggle(
                                  day,
                                  event.target.checked,
                                  address.business_address_id
                                )
                              }
                              name={day}
                            />
                          }
                          label={day}
                          className={styles["day-checkbox"]}
                        />
                      </div>
                      <div className={styles["time-column"]}>
                        {!dayHours.business_address_is_closed && (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className={styles["time-pickers"]}>
                              <DesktopTimePicker
                                label="Opening Time"
                                value={parseTime(
                                  dayHours.business_address_open_time
                                )}
                                onChange={(value) =>
                                  handleTimeChange(
                                    day,
                                    "open",
                                    value,
                                    address.business_address_id
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    error={
                                      !!errors[`BusinessOperatingHours_${day}`]
                                    }
                                  />
                                )}
                              />
                              <DesktopTimePicker
                                label="Closing Time"
                                value={parseTime(
                                  dayHours.business_address_close_time
                                )}
                                onChange={(value) =>
                                  handleTimeChange(
                                    day,
                                    "close",
                                    value,
                                    address.business_address_id
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    error={
                                      !!errors[`BusinessOperatingHours_${day}`]
                                    }
                                  />
                                )}
                              />
                            </div>
                          </LocalizationProvider>
                        )}
                      </div>
                      {errors[`BusinessOperatingHours_${day}`] && (
                        <Typography
                          variant="caption"
                          color="error"
                          className={styles["time-picker-error"]}
                        >
                          {errors[`BusinessOperatingHours_${day}`]}
                        </Typography>
                      )}
                    </div>
                  );
                })}
              </div>
              {errors.BusinessOperatingHours && (
                <Typography variant="caption" color="error">
                  {errors.BusinessOperatingHours}
                </Typography>
              )}
              <div className={styles["card-actions"]}>
                <IconButton
                  aria-label="save"
                  type="submit"
                  size="small"
                  variant="contained"
                  className={styles["icon-button"]}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  aria-label="cancel"
                  type="reset"
                  onClick={() => {
                    handleCancelEdit(address.business_address_id);
                  }}
                  margin="dense"
                  size="small"
                  className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </form>
          ) : (
            <div className={styles["address-details"]}>
              <Typography variant="h6" component="h3">
                {address.business_address_name}
              </Typography>
              <Chip
                label={address.business_address_type}
                color="primary"
                size="small"
                style={{ marginBottom: "10px" }}
              />
              <Typography>
                {address.business_address_street_address_1}
              </Typography>
              {address.business_address_street_address_2 && (
                <Typography>
                  {address.business_address_street_address_2}
                </Typography>
              )}
              <Typography>{`${address.business_address_city}, ${address.business_address_state_province} ${address.business_address_postal_code}`}</Typography>
              <Typography>{address.business_address_country}</Typography>
              <Typography>{`Phone: ${address.business_address_phone}`}</Typography>
              {address.BusinessOperatingHours && (
                <div style={{ marginTop: "10px" }}>
                  <Typography variant="subtitle2">
                    Hours of Operation:
                  </Typography>
                  {address.BusinessOperatingHours.map((hours) => (
                    <Typography
                      key={hours.business_address_day_of_week}
                      variant="body2"
                    >
                      {`${hours.business_address_day_of_week}: ${
                        hours.business_address_is_closed
                          ? "Closed"
                          : `${formatTime(
                              hours.business_address_open_time
                            )} - ${formatTime(
                              hours.business_address_close_time
                            )}`
                      }`}
                    </Typography>
                  ))}
                </div>
              )}

              <div className={styles["card-actions"]}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit(address)}
                  size="small"
                  color="midnightblue"
                  className={`${styles["icon-button"]} ${styles["edit-button"]}`}
                  sx={{
                    color: "var(--midnight-blue)",
                    borderColor: "var(--midnight-blue)",
                    "&:hover": {
                      color: "var(--soft-blue)",
                      borderColor: "var(--soft-blue)",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(address.business_address_id)}
                  size="small"
                  className={`${styles["icon-button"]} ${styles["delete-button"]}`}
                  sx={{
                    color: "var(--midnight-blue)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={styles["business-addresses-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessHeader />
        <div className={styles["main-content"]}>
          <BusinessSidebar
            onSidebarStateChange={handleSidebarStateChange}
            businessCategory={currentBusiness?.business_category_name}
          />
          <main
            className={`${styles["business-addresses-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Manage Business Addresses</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            {isLoading ? (
              <div className={styles["loading-container"]}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles["addresses-grid"]}>
                <Card
                  key="add-new-address"
                  className={`${styles["business-address-card"]} ${styles["add-card"]}`}
                  onClick={handleAddNewAddress}
                >
                  <CardContent>
                    <AddHomeIcon
                      style={{ fontSize: "4rem", marginBottom: "1rem" }}
                    />
                    <Typography variant="h6" component="h3">
                      Add New Address
                    </Typography>
                  </CardContent>
                </Card>
                {newAddress &&
                  renderAddressCard({
                    ...newAddress,
                    business_address_id: "new",
                  })}
                {addresses.map((address) => (
                  <div key={address.business_address_id}>
                    {renderAddressCard(address)}
                  </div>
                ))}
              </div>
            )}
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default BusinessAddressesPage;
