import React, { useState, useContext } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Box,
  ButtonGroup,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CompareArrows as CompareArrowsIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const CollaborationActions = ({
  collaboration,
  businessId,
  orderStatus,
  onActionComplete,
  disabled = false,
  allCollaborations = [],
  onStartAgain,
}) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { api } = useContext(AuthContext);

  const isOriginatingBusiness =
    businessId === collaboration.originating_business_id;
  const isCollaboratingBusiness =
    businessId === collaboration.collaborating_business_id;

  const handleAction = async (action) => {
    setActionType(action);
    setConfirmDialog(true);
  };

  const handleClose = () => {
    setConfirmDialog(false);
    setActionType(null);
    setReason("");
    setError(null);
  };

  const handleStartAgain = () => {
    if (onStartAgain) {
      onStartAgain(collaboration);
    }
  };

  const executeAction = async () => {
    setLoading(true);
    setError(null);

    try {
      let response;

      if (actionType === "delete") {
        response = await api.delete(
          `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${businessId}`
        );
      } else if (isOriginatingBusiness) {
        if (actionType === "cancel") {
          response = await api.put(
            `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${businessId}/originating-business-status`,
            {
              status: "cancelled",
              reason,
            }
          );
        } else {
          response = await api.put(
            `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${businessId}/originating-business-status`,
            {
              status: actionType,
              reason,
            }
          );
        }
      } else if (isCollaboratingBusiness) {
        if (actionType === "counter_offer" || actionType === "edit") {
          if (onActionComplete) {
            onActionComplete({ action: actionType, collaboration });
          }
          handleClose();
          return;
        }

        response = await api.put(
          `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${businessId}/collaborating-business-status`,
          {
            status: actionType,
            reason,
          }
        );
      }

      handleClose();
      if (onActionComplete) {
        onActionComplete(response.data);
      }
    } catch (err) {
      console.error("Error executing action:", err);
      setError(
        err.response?.data?.msg || "Failed to execute action. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const renderActionButtons = () => {
    const buttons = [];

    if (isOriginatingBusiness) {
      // Check if collaboration is the latest attempt and active
      if (
        collaboration.status === "rejected" ||
        collaboration.status === "cancelled"
      ) {
        // Check if there are any active collaborations for this order
        const hasActiveCollaboration = allCollaborations?.some(
          (collab) =>
            collab.shipping_order_id === collaboration.shipping_order_id &&
            ["proposed", "accepted", "counter_offered", "completed"].includes(
              collab.status
            ) &&
            new Date(collab.createdAt) > new Date(collaboration.createdAt)
        );

        if (!hasActiveCollaboration) {
          if (collaboration.status === "rejected") {
            buttons
              .push
              //   <Button
              //     key="delete"
              //     startIcon={<DeleteIcon />}
              //     variant="outlined"
              //     color="error"
              //     onClick={() => handleAction("delete")}
              //     disabled={disabled}
              //   >
              //     Delete
              //   </Button>
              ();
          }

          buttons.push(
            <Button
              key="start-again"
              startIcon={<CompareArrowsIcon />}
              variant="contained"
              color="primary"
              onClick={handleStartAgain}
              disabled={disabled}
            >
              Start Again
            </Button>
          );
        } else {
          // Show info that there's an active collaboration
          buttons.push(
            <Button
              key="start-again"
              startIcon={<CompareArrowsIcon />}
              variant="contained"
              color="primary"
              disabled={true}
              title="Cannot start new collaboration while there is an active one"
              sx={{ cursor: "not-allowed" }}
            >
              Active Collaboration Exists
            </Button>
          );
        }
      } else {
        switch (collaboration.status) {
          case "counter_offered":
            buttons.push(
              <ButtonGroup variant="outlined" key="counter-actions">
                <Button
                  startIcon={<CheckCircleIcon />}
                  color="success"
                  onClick={() => handleAction("accepted")}
                  disabled={disabled}
                >
                  Confirm
                </Button>
                <Button
                  startIcon={<CancelIcon />}
                  color="error"
                  onClick={() => handleAction("rejected")}
                  disabled={disabled}
                >
                  Reject
                </Button>
              </ButtonGroup>
            );
            break;

          default:
            if (
              !["accepted", "rejected", "cancelled", "completed"].includes(
                collaboration.status
              )
            ) {
              buttons.push(
                <Button
                  key="cancel"
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  color="error"
                  onClick={() => handleAction("cancel")}
                  disabled={disabled}
                >
                  Cancel
                </Button>
              );
            }
            break;
        }
      }
    } else if (isCollaboratingBusiness) {
      if (collaboration.status === "proposed") {
        buttons.push(
          <ButtonGroup variant="outlined" key="collab-actions">
            <Button
              startIcon={<CheckCircleIcon />}
              color="success"
              onClick={() => handleAction("accepted")}
              disabled={disabled}
            >
              Confirm
            </Button>
            <Button
              startIcon={<CancelIcon />}
              color="error"
              onClick={() => handleAction("rejected")}
              disabled={disabled}
            >
              Reject
            </Button>
            <Button
              startIcon={<CompareArrowsIcon />}
              color="primary"
              onClick={() => handleAction("counter_offer")}
              disabled={disabled}
            >
              Counter
            </Button>
            <Button
              startIcon={<EditIcon />}
              color="info"
              onClick={() => handleAction("edit")}
              disabled={disabled}
            >
              Edit
            </Button>
          </ButtonGroup>
        );
      }
    }

    return buttons;
  };
  const getActionTitle = (action) => {
    switch (action) {
      case "delete":
        return "Delete Rejected Collaboration";
      case "cancel":
        return "Cancel Collaboration";
      case "accepted":
        return "Confirm Collaboration";
      case "rejected":
        return "Reject Collaboration";
      case "counter_offer":
        return "Counter Offer";
      case "edit":
        return "Edit Collaboration";
      default:
        return (
          action?.replace("_", " ").charAt(0).toUpperCase() + action?.slice(1)
        );
    }
  };

  if (!isOriginatingBusiness && !isCollaboratingBusiness) {
    return null;
  }

  return (
    <>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}>
        {renderActionButtons()}
      </Box>

      <Dialog
        open={confirmDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{getActionTitle(actionType)}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Typography variant="body1" gutterBottom>
            Are you sure you want to {actionType?.replace("_", " ")} this
            collaboration?
          </Typography>
          <TextField
            fullWidth
            label="Reason (Optional)"
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={executeAction}
            variant="contained"
            color={
              ["rejected", "cancel", "delete"].includes(actionType)
                ? "error"
                : "primary"
            }
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollaborationActions;
