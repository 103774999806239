import React, { useState, useCallback } from "react";
import EmployeeHeader from "../../../components/Header/DashboardHeader";
import EmployeeSidebar from "../../../components/Sidebar/EmployeeSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useEmployee } from "../../../components/Auth/EmployeeContext";
import {
  Typography,
  CircularProgress,
  Skeleton,
  Box,
  Alert,
} from "@mui/material";
import EmployeeActionItems from "./EmployeeActionItems";
import EmployeeDashboardOverview from "./EmployeeDashboardOverview";
import "./EmployeeDashboardPage.css";

const LoadingSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Skeleton variant="rectangular" width="100%" height={118} />
    <Skeleton variant="text" sx={{ marginTop: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </Box>
);

const EmployeeDashboardPage = () => {
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("employeeSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const { employeeData, loading, error, businessId } = useEmployee();

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("employeeSidebarActive", JSON.stringify(isActive));
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (!employeeData || !businessId) {
      return (
        <Alert severity="warning">
          No employee data or business ID available.
        </Alert>
      );
    }

    return (
      <>
        <EmployeeDashboardOverview employeeData={employeeData} />
        <EmployeeActionItems
          employeeData={employeeData}
          businessId={businessId}
        />
      </>
    );
  };

  const employee_role = employeeData?.employee_role || "Unknown";
  const business_type =
    employeeData?.EmployeeBusiness?.business_category_name || "Unknown";

  return (
    <div className="employee-dashboard-page">
      <ParallaxBackground />
      <EmployeeHeader />
      <div className="dashboard-main-content">
        <EmployeeSidebar
          onSidebarStateChange={handleSidebarStateChange}
          employeeRole={employee_role}
          businessType={business_type}
        />
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            {renderContent()}
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default EmployeeDashboardPage;
