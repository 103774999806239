import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { useBusiness } from "../Auth/BusinessContext";
import { Typography, Button, Select, MenuItem, Box } from "@mui/material";

const BusinessDashboardHeader = () => {
  const { user, logout } = useContext(AuthContext);
  const { businesses, selectedBusinessId, selectBusiness } = useBusiness();
  const navigate = useNavigate();
  const { businessId } = useParams();

  useEffect(() => {
    if (businessId && businesses.length > 0) {
      selectBusiness(businessId);
    }
  }, [businessId, businesses, selectBusiness]);

  const handleBusinessChange = (event) => {
    const newBusinessId = event.target.value;
    selectBusiness(newBusinessId);
    navigate(`/business/${newBusinessId}/dashboard`);
  };

  return (
    <header className="dashboard-header">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          padding: "10px 20px",
        }}
      >
        {businesses.length > 0 && (
          <Select
            value={selectedBusinessId || ""}
            onChange={handleBusinessChange}
            displayEmpty
            sx={{
              minWidth: 200,
              mr: 2,
              backgroundColor: "#C8E6F9",
              color: "black",
            }}
          >
            <MenuItem value="" disabled>
              Select a business
            </MenuItem>
            {businesses.map((business) => (
              <MenuItem key={business.business_id} value={business.business_id}>
                {business.business_name}
              </MenuItem>
            ))}
          </Select>
        )}
        <Typography variant="subtitle1" sx={{ mr: 2 }}>
          Welcome, {user ? user.user_first_name : "User"}!
        </Typography>
        <Link to="/profile" className="profile-link">
          User Profile
        </Link>
        <button className="logout-button" onClick={logout}>
          Logout
        </button>
      </Box>
    </header>
  );
};

export default BusinessDashboardHeader;
