import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import styles from "./UserProfilePage.module.css";

const defaultProfilePicture = "/images/background-image.jpg";

const UserProfilePage = () => {
  const { user } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
    console.log("user", user);
  }, [sidebarActive]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const profilePictureSrc = user?.user_profile_picture
    ? `data:image/jpeg;base64,${user.user_profile_picture}`
    : defaultProfilePicture;

  return (
    <div className={styles["profile-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <DashboardHeader />
        <div className={styles["main-content"]}>
          <DashboardSidebar onSidebarStateChange={handleSidebarStateChange} />
          <main
            className={`${styles["profile-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>User Profile</h1>
            {user ? (
              <div className={styles["profile-info"]}>
                <div className={styles["profile-picture"]}>
                  <img src={profilePictureSrc} alt="Profile" />
                </div>
                <div className={styles["user-details"]}>
                  <h2>{`${user.user_first_name} ${user.user_last_name}`}</h2>
                  <p>Email: {user.user_email}</p>
                  <p>Phone: {user.user_phone || "Not provided"}</p>
                  <p>
                    Member since:{" "}
                    {new Date(user.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}
            <div className={styles["profile-grid"]}>
              <Link to="/profile/edit" className={styles["profile-card"]}>
                <h3>Edit Profile</h3>
                <p>Update your information</p>
              </Link>
              <Link to="/profile/security" className={styles["profile-card"]}>
                <h3>Login & Security</h3>
                <p>Manage security settings</p>
              </Link>
              <Link to="/profile/payments" className={styles["profile-card"]}>
                <h3>Payments</h3>
                <p>Manage payment methods</p>
              </Link>
              <Link to="/profile/addresses" className={styles["profile-card"]}>
                <h3>Addresses</h3>
                <p>Manage your addresses</p>
              </Link>
              <Link to="/profile/orders" className={styles["profile-card"]}>
                <h3>Orders</h3>
                <p>View your order history</p>
              </Link>
              <Link to="/profile/businesses" className={styles["profile-card"]}>
                <h3>Business Account</h3>
                <p>Manage your business profile</p>
              </Link>
            </div>
          </main>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default UserProfilePage;
