import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Box,
  Typography,
  Switch,
  Grid,
  Chip,
  IconButton,
  Autocomplete,
  CircularProgress,
  Alert,
  Checkbox,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  FormHelperText,
  Badge,
} from "@mui/material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useBusiness } from "../../../../components/Auth/BusinessContext";

const OrderAssignment = ({
  orders,
  groups,
  couriers,
  vehicles,
  locations,
  onAddOrderToGroup,
  onRemoveOrderFromGroup,
  loading,
}) => {
  const [locationMap, setLocationMap] = useState({});
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [addresses, setAddresses] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loadingSegments, setLoadingSegments] = useState(true);
  const [filters, setFilters] = useState({
    status: "",
    serviceLevel: "",
    orderCount: "",
    isAssigned: "",
    employee: "",
    orderType: "all", // Add this new filter
  });
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [routeSegments, setRouteSegments] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [error, setError] = useState(null);
  const [incompleteFormError, setIncompleteFormError] = useState(false);
  const [collaboratingOrders, setCollaboratingOrders] = useState([]);
  const [loadingCollaborations, setLoadingCollaborations] = useState(false);
  const { api } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOrderClick = (orderId) => {
    navigate(`/business/${businessId}/shipments`, {
      state: { activeTab: 0, searchQuery: orderId },
    });
  };

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/business/${businessId}/addresses`);
      setAddresses(response.data);
    } catch (error) {
      console.error("Error fetching business addresses:", error);
      setError("Failed to fetch business addresses");
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCollaboratingOrders = async () => {
    try {
      setLoadingCollaborations(true);
      const response = await api.get(
        `/shipping-collaboration/collaborations?businessId=${businessId}&status=accepted`
      );

      // filter out all orders that are cancelled or rejected

      response.data = response.data.filter(
        (collaboration) => collaboration.status === "accepted"
      );

      const collaborations = response.data;
      const orderIds = collaborations.map((collab) => collab.shipping_order_id);

      // Fetch full order details for each collaborating order
      const orderDetails = await Promise.all(
        orderIds.map(async (orderId) => {
          const response = await api.get(`/shipping-orders/${orderId}`, {});
          return {
            ...response.data,
            isCollaborating: true,
            collaboration: collaborations.find(
              (c) => c.shipping_order_id === orderId
            ),
          };
        })
      );

      setCollaboratingOrders(orderDetails);
    } catch (error) {
      console.error("Error fetching collaborating orders:", error);
      setError("Failed to fetch collaborating orders");
    } finally {
      setLoadingCollaborations(false);
    }
  };

  useEffect(() => {
    fetchCollaboratingOrders();
  }, [businessId]);

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
  }, [businessId, getBusinessById]);

  useEffect(() => {
    if (businessId) {
      fetchAddresses();
    }
  }, [businessId]);

  const filteredItems = useMemo(() => {
    let items = showGroups ? groups || [] : orders || [];

    // Handle collaborating orders first
    if (!showGroups) {
      if (filters.orderType === "collaborating") {
        items = collaboratingOrders;
      } else if (filters.orderType === "all") {
        items = [...items, ...collaboratingOrders];
      }
    }

    // Apply status filter
    if (filters.status) {
      items = items.filter((item) => {
        if (showGroups) {
          return item.status === filters.status;
        } else {
          // Handle order status for both regular and collaborating orders
          const orderStatus = item.order_status
            ? item.order_status.toLowerCase()
            : "";
          return orderStatus === filters.status.toLowerCase();
        }
      });
    }

    // Filter by service level if in order view
    if (!showGroups && filters.serviceLevel) {
      items = items.filter((order) => {
        const serviceLevelMatch =
          order.ShippingTier?.service_level === filters.serviceLevel;
        return serviceLevelMatch;
      });
    }

    // Filter by order count if in group view
    if (showGroups && filters.orderCount) {
      items = items.filter((group) => {
        const orderCount = group.ShippingOrders?.length || 0;
        switch (filters.orderCount) {
          case "low":
            return orderCount < 5;
          case "medium":
            return orderCount >= 5 && orderCount < 10;
          case "high":
            return orderCount >= 10;
          default:
            return true;
        }
      });
    }

    // Filter by assignment status
    if (filters.isAssigned !== "") {
      items = items.filter((item) => {
        const hasSegments = (item.RouteSegments || []).length > 0;
        return filters.isAssigned === "assigned" ? hasSegments : !hasSegments;
      });
    }

    // Filter by employee
    if (filters.employee) {
      items = items.filter((item) => {
        return (item.RouteSegments || []).some(
          (segment) => segment.courier_id === filters.employee
        );
      });
    }

    // Apply search query
    if (searchQuery) {
      items = items.filter((item) => {
        const searchFields = showGroups
          ? [item.name, item.group_id, String(item.ShippingOrders?.length || 0)]
          : [
              item.shipping_order_id,
              item.order_status,
              item.sender?.first_name,
              item.sender?.last_name,
              item.recipient?.first_name,
              item.recipient?.last_name,
              item.isCollaborating ? "collaborating" : "own",
              item.tracking_number,
            ];

        return searchFields.some(
          (field) =>
            field &&
            field.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    return items;
  }, [
    showGroups,
    groups,
    orders,
    collaboratingOrders,
    searchQuery,
    filters,
    filters.status,
    filters.serviceLevel,
    filters.orderCount,
    filters.isAssigned,
    filters.employee,
    filters.orderType,
  ]);
  useEffect(() => {
    if (openAssignmentDialog) {
      const timer = setTimeout(() => setIsDraggable(true), 100);
      return () => clearTimeout(timer);
    } else {
      setIsDraggable(false);
    }
  }, [openAssignmentDialog]);
  useEffect(() => {
    const newLocationMap = {};
    locations.forEach((location) => {
      newLocationMap[location.location_id] = {
        type: "shipping_location",
        name: location.name,
      };
    });
    addresses.forEach((address) => {
      newLocationMap[address.business_address_id] = {
        type: "business_address",
        name: address.business_address_name,
      };
    });
    setLocationMap(newLocationMap);
  }, [locations, addresses]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredItems, currentPage, itemsPerPage]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event) => {
    console.log("Filter change:", event.target.name, event.target.value); // Add this for debugging
    const { name, value } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    // Reset to first page when filter changes
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(
      event.target.checked ? filteredItems.map((item) => item) : []
    );
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedItem) =>
          selectedItem[showGroups ? "group_id" : "shipping_order_id"] ===
          item[showGroups ? "group_id" : "shipping_order_id"]
      );
      if (isSelected) {
        return prevSelected.filter(
          (selectedItem) =>
            selectedItem[showGroups ? "group_id" : "shipping_order_id"] !==
            item[showGroups ? "group_id" : "shipping_order_id"]
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const validateSegment = (segment, index, totalSegments) => {
    const errors = {};
    if (!segment.type) errors.type = "Type is required";
    if (segment.type === "courier" && !segment.courier_id)
      errors.courier_id = "Courier is required";
    if (!segment.start_location_id)
      errors.start_location_id = "Start location is required";
    if (index < totalSegments - 1) {
      if (!segment.end_location_id)
        errors.end_location_id = "End location is required";
    }
    if (!segment.estimated_duration)
      errors.estimated_duration = "Estimated duration is required";
    return errors;
  };

  const validateForm = () => {
    if (routeSegments.length === 0) {
      setIncompleteFormError(true);
      return false;
    }
    setIncompleteFormError(false);
    const segmentErrors = routeSegments.map((segment, index) =>
      validateSegment(segment, index, routeSegments.length)
    );
    const hasErrors = segmentErrors.some(
      (errors) => Object.keys(errors).length > 0
    );
    setFormErrors(segmentErrors);
    return !hasErrors;
  };

  const updateSegmentsWithFinalDestination = (segments) => {
    return segments.map((segment, index) => {
      if (index === segments.length - 1) {
        return {
          ...segment,
          end_location_id: "",
          end_location_type: null,
          is_last_segment: true,
        };
      }
      return {
        ...segment,
        is_last_segment: false,
      };
    });
  };

  const handleSegmentChange = (index, field, value) => {
    let updatedSegments = [...routeSegments];

    if (field === "start_location_id" || field === "end_location_id") {
      const locationType = value ? locationMap[value]?.type : null;
      updatedSegments[index][field] = value;
      updatedSegments[index][`${field.split("_")[0]}_location_type`] =
        locationType;

      // If changing the end location, update the next segment's start location
      if (field === "end_location_id" && index < updatedSegments.length - 1) {
        updatedSegments[index + 1].start_location_id = value;
        updatedSegments[index + 1].start_location_type = locationType;
      }
    } else {
      updatedSegments[index][field] = value;
    }

    // Apply final destination logic
    updatedSegments = updateSegmentsWithFinalDestination(updatedSegments);

    setRouteSegments(updatedSegments);

    const updatedErrors = [...formErrors];
    if (updatedErrors[index]) {
      delete updatedErrors[index][field];
    }
    setFormErrors(updatedErrors);
  };

  const handleAddSegment = () => {
    const newSegment = {
      type: "courier",
      courier_id: "",
      start_location_id: "",
      start_location_type: "",
      end_location_id: "",
      end_location_type: "",
      estimated_duration: "",
    };

    const updatedSegments = updateSegmentsWithFinalDestination([
      ...routeSegments,
      newSegment,
    ]);
    setRouteSegments(updatedSegments);
    setFormErrors([...formErrors, {}]);
  };

  const handleRemoveSegment = (index) => {
    const updatedSegments = updateSegmentsWithFinalDestination(
      routeSegments.filter((_, i) => i !== index)
    );
    setRouteSegments(updatedSegments);
    setFormErrors(formErrors.filter((_, i) => i !== index));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(routeSegments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update start and end locations based on new order
    for (let i = 0; i < items.length; i++) {
      if (i > 0) {
        items[i].start_location_type = items[i - 1].end_location_type;
        items[i].start_location_id = items[i - 1].end_location_id;
      }
    }

    const updatedSegments = updateSegmentsWithFinalDestination(items);
    setRouteSegments(updatedSegments);

    // Reorder form errors as well
    const errorItems = Array.from(formErrors);
    const [reorderedError] = errorItems.splice(result.source.index, 1);
    errorItems.splice(result.destination.index, 0, reorderedError);
    setFormErrors(errorItems);
  };

  const renderFilters = () => (
    <>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" component="div">
            {showGroups ? "Group Assignment" : "Order Assignment"}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body2" color="textSecondary" mr={1}>
              Group View
            </Typography>
            <Switch
              checked={showGroups}
              onChange={() => setShowGroups(!showGroups)}
              inputProps={{ "aria-label": "toggle group view" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={showGroups ? "Search Groups" : "Search Orders"}
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by ID, name, status..."
          />
        </Grid>

        {/* Order Type Filter - Only show for orders, not groups */}
        {!showGroups && (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Order Type</InputLabel>
              <Select
                name="orderType"
                value={filters.orderType}
                onChange={handleFilterChange}
                label="Order Type"
              >
                <MenuItem value="all">All Orders</MenuItem>
                <MenuItem value="own">Own Orders</MenuItem>
                <MenuItem value="collaborating">Collaborating Orders</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Status Filter */}
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {/* Remove the Fragment and render MenuItems directly */}
              {showGroups
                ? [
                    <MenuItem key="active" value="active">
                      Active
                    </MenuItem>,
                    <MenuItem key="inactive" value="inactive">
                      Inactive
                    </MenuItem>,
                  ]
                : [
                    <MenuItem key="pending" value="pending">
                      Pending
                    </MenuItem>,
                    <MenuItem key="processing" value="processing">
                      Processing
                    </MenuItem>,
                    <MenuItem key="shipped" value="shipped">
                      Shipped
                    </MenuItem>,
                    <MenuItem key="delivered" value="delivered">
                      Delivered
                    </MenuItem>,
                    <MenuItem key="cancelled" value="cancelled">
                      Cancelled
                    </MenuItem>,
                  ]}
            </Select>
          </FormControl>
        </Grid>

        {/* Assignment Status Filter */}
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Assignment Status</InputLabel>
            <Select
              name="isAssigned"
              value={filters.isAssigned}
              onChange={handleFilterChange}
              label="Assignment Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="assigned">Assigned</MenuItem>
              <MenuItem value="unassigned">Unassigned</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Employee Filter */}
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Employee</InputLabel>
            <Select
              name="employee"
              value={filters.employee}
              onChange={handleFilterChange}
              label="Employee"
            >
              <MenuItem value="">All</MenuItem>
              {couriers.map((courier) => (
                <MenuItem key={courier.employee_id} value={courier.employee_id}>
                  {courier.employee_first_name} {courier.employee_last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Conditional Filters based on view type */}
        {showGroups ? (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Order Count</InputLabel>
              <Select
                name="orderCount"
                value={filters.orderCount}
                onChange={handleFilterChange}
                label="Order Count"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="low">Low (0-4)</MenuItem>
                <MenuItem value="medium">Medium (5-9)</MenuItem>
                <MenuItem value="high">High (10+)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Service Level</InputLabel>
              <Select
                name="serviceLevel"
                value={filters.serviceLevel}
                onChange={handleFilterChange}
                label="Service Level"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Standard">Standard</MenuItem>
                <MenuItem value="Express">Express</MenuItem>
                <MenuItem value="Priority">Priority</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Items per page selector */}
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Items per page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Active Filters Display */}
      {Object.values(filters).some((value) => value !== "") && (
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Active Filters:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.entries(filters).map(([key, value]) => {
              if (!value) return null;
              return (
                <Chip
                  key={key}
                  label={`${key
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}: ${value}`}
                  onDelete={() =>
                    handleFilterChange({
                      target: { name: key, value: "" },
                    })
                  }
                  size="small"
                />
              );
            })}
            <Chip
              label="Clear All"
              onClick={() => {
                setFilters({
                  status: "",
                  serviceLevel: "",
                  orderCount: "",
                  isAssigned: "",
                  employee: "",
                  orderType: "all",
                });
              }}
              size="small"
              color="secondary"
            />
          </Box>
        </Box>
      )}
    </>
  );

  const createRouteSegments = async (orderId, segments) => {
    try {
      const response = await api.post(`/route-segments/${orderId}`, {
        segments,
      });
      return response.data;
    } catch (error) {
      console.error("Error creating route segments:", error);
      throw error;
    }
  };

  const handleOpenAssignmentDialog = (item) => {
    setCurrentAssignment(item);
    setOpenAssignmentDialog(true);
    setFormErrors([]);
    setRouteSegments(item.RouteSegments || []);
    setIsEditing((item.RouteSegments || []).length > 0);
  };

  const handleCloseAssignmentDialog = () => {
    setOpenAssignmentDialog(false);
    setCurrentAssignment(null);
    setRouteSegments([]);
    setFormErrors([]);
    setIsEditing(false);
  };

  const handleAssignOrder = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.error("Form validation failed");
      return;
    }

    try {
      const segments = routeSegments.map((segment, index) => ({
        type: segment.type,
        courier_id: segment.courier_id,
        start_location_type: segment.start_location_type,
        start_location_id: segment.start_location_id,
        end_location_type:
          index === routeSegments.length - 1 ? null : segment.end_location_type,
        end_location_id:
          index === routeSegments.length - 1 ? null : segment.end_location_id,
        is_last_segment: index === routeSegments.length - 1,
        estimated_duration: parseFloat(segment.estimated_duration),
      }));

      if (showGroups) {
        for (const order of currentAssignment.ShippingOrders) {
          await createRouteSegments(order.shipping_order_id, segments);
        }
      } else {
        await createRouteSegments(
          currentAssignment.shipping_order_id,
          segments
        );
      }

      // Update the order in the local state
      const updatedOrders = orders.map((order) => {
        if (order.shipping_order_id === currentAssignment.shipping_order_id) {
          return { ...order, RouteSegments: segments };
        }
        return order;
      });
      // Assuming you have a function to update the orders in the parent component
      // updateOrders(updatedOrders);

      handleCloseAssignmentDialog();
    } catch (error) {
      console.error("Failed to assign order:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const AssignmentRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const isItemSelected = selectedItems.some(
      (selectedItem) =>
        selectedItem[showGroups ? "group_id" : "shipping_order_id"] ===
        item[showGroups ? "group_id" : "shipping_order_id"]
    );

    const isAssigned = (item.RouteSegments || []).length > 0;

    const assignedEmployees = item.RouteSegments?.map(
      (segment) => segment.courier_id
    );
    return (
      <>
        <TableRow
          sx={{
            backgroundColor: isAssigned
              ? "rgba(76, 175, 80, 0.1)"
              : item.isCollaborating
              ? "rgba(33, 150, 243, 0.1)"
              : "inherit",
            "&:hover": {
              backgroundColor: isAssigned
                ? "rgba(76, 175, 80, 0.2)"
                : item.isCollaborating
                ? "rgba(33, 150, 243, 0.2)"
                : "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              onChange={() => handleSelectItem(item)}
            />
          </TableCell>
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {showGroups ? (
              item.name
            ) : (
              <>
                <Button
                  onClick={() => handleOrderClick(item.shipping_order_id)}
                >
                  {item.shipping_order_id}
                </Button>
                {item.isCollaborating && (
                  <Chip
                    label="Collaborating"
                    size="small"
                    color="primary"
                    sx={{ ml: 1 }}
                  />
                )}
                {isAssigned && (
                  <Chip
                    label="Assigned"
                    size="small"
                    color="success"
                    sx={{ ml: 1 }}
                  />
                )}
              </>
            )}
          </TableCell>
          <TableCell>
            {showGroups ? item.ShippingOrders?.length : item.order_status}
          </TableCell>
          <TableCell>
            {showGroups
              ? `$${parseFloat(item.total_value || 0).toFixed(2)}`
              : `$${parseFloat(item.total_cost || 0).toFixed(2)}`}
          </TableCell>
          <TableCell>
            {assignedEmployees.map((employeeId) => {
              const employee = couriers.find(
                (c) => c.employee_id === employeeId
              );
              return employee ? (
                <Chip
                  key={employeeId}
                  label={`${employee.employee_first_name} ${employee.employee_last_name}`}
                  size="small"
                  sx={{ mr: 1, mb: 1 }}
                />
              ) : null;
            })}
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              color={isAssigned ? "secondary" : "primary"}
              onClick={() => handleOpenAssignmentDialog(item)}
              disabled={
                // Disable the button if the order is cancelled or delivered
                item.order_status === "cancelled" ||
                item.order_status === "delivered"
              }
            >
              {isAssigned ? "Edit" : "Assign"}
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {showGroups ? "Group Details" : "Order Details"}
                </Typography>
                {showGroups ? (
                  <Table size="small" aria-label="group orders">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Total Cost</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.ShippingOrders?.map((order) => (
                        <TableRow key={order.shipping_order_id}>
                          <TableCell>
                            <Button
                              onClick={() =>
                                handleOrderClick(order.shipping_order_id)
                              }
                            >
                              {order.shipping_order_id}
                            </Button>
                          </TableCell>
                          <TableCell>{order.order_status}</TableCell>
                          <TableCell>
                            ${parseFloat(order.total_cost || 0).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleOpenAssignmentDialog(order)}
                            >
                              Assign
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        Sender: {item.sender?.first_name}{" "}
                        {item.sender?.last_name}
                      </Typography>
                      <Typography>Sender City: {item.sender?.city}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        Recipient: {item.recipient?.first_name}{" "}
                        {item.recipient?.last_name}
                      </Typography>
                      <Typography>
                        Recipient City: {item.recipient?.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        Service Level: {item.ShippingTier?.service_level}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        Package Weight: {item.package_details?.weight}{" "}
                        {item.package_details?.weight_unit}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Tracking Number:{" "}
                        {item.tracking_number || "Not available"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper elevation={3} style={{ padding: "1rem" }}>
        {renderFilters()}
        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedItems.length === filteredItems.length}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < filteredItems.length
                    }
                    onChange={handleSelectAllItems}
                  />
                </TableCell>
                <TableCell />
                <TableCell>{showGroups ? "Group Name" : "Order ID"}</TableCell>
                <TableCell>{showGroups ? "Orders Count" : "Status"}</TableCell>
                <TableCell>
                  {showGroups ? "Total Value" : "Total Cost"}
                </TableCell>
                <TableCell>Assigned Employees</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedItems.map((item) => (
                <AssignmentRow
                  key={showGroups ? item.group_id : item.shipping_order_id}
                  item={item}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>

        <Dialog
          open={openAssignmentDialog}
          onClose={handleCloseAssignmentDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            {isEditing ? (
              <Badge color="primary">Edit Assignment:</Badge>
            ) : (
              "Create New Assignment:"
            )}{" "}
            {showGroups
              ? `Group ${currentAssignment?.name}`
              : `Order ${currentAssignment?.shipping_order_id}`}
          </DialogTitle>
          {incompleteFormError && (
            <Alert severity="error" style={{ margin: "1rem" }}>
              Please add at least one route segment
            </Alert>
          )}
          <form onSubmit={handleAssignOrder}>
            <DialogContent>
              <Card variant="outlined" style={{ marginBottom: "1rem" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Assignment Instructions
                  </Typography>
                  <Typography variant="body2">
                    1. Select the start location where the{" "}
                    {showGroups ? "group" : "package"} was received.
                  </Typography>
                  <Typography variant="body2">
                    2. Add route segments to break down the journey into
                    manageable parts.
                  </Typography>
                  <Typography variant="body2">
                    3. For each segment, choose the type (courier or
                    third-party), assign resources, and set locations.
                  </Typography>
                  <Typography variant="body2">
                    4. Drag and drop segments to reorder them as needed.
                  </Typography>
                  <Typography variant="body2">
                    5. Ensure all segments are properly connected to form a
                    complete route.
                  </Typography>
                </CardContent>
              </Card>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "1rem" }}
              >
                Route Segments
              </Typography>
              <Paper elevation={3} style={{ padding: "1rem" }}>
                {isDraggable && (
                  <Droppable droppableId="segments">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {routeSegments.map((segment, index) => (
                          <Draggable
                            key={`segment-${index}`}
                            draggableId={`segment-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mb={2}
                                sx={{
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  padding: "10px",
                                  backgroundColor: "#f5f5f5",
                                }}
                              >
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={1}>
                                    <DragIndicatorIcon />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <FormControl
                                      fullWidth
                                      error={!!formErrors[index]?.type}
                                    >
                                      <InputLabel>Type</InputLabel>
                                      <Select
                                        value={segment.type}
                                        onChange={(e) =>
                                          handleSegmentChange(
                                            index,
                                            "type",
                                            e.target.value
                                          )
                                        }
                                        required
                                      >
                                        <MenuItem value="courier">
                                          Courier
                                        </MenuItem>
                                        <MenuItem value="third-party">
                                          Third-party Transit
                                        </MenuItem>
                                      </Select>
                                      {formErrors[index]?.type && (
                                        <FormHelperText>
                                          {formErrors[index].type}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  {segment.type === "courier" && (
                                    <Grid item xs={2}>
                                      <FormControl
                                        fullWidth
                                        error={!!formErrors[index]?.courier_id}
                                      >
                                        <InputLabel>Courier</InputLabel>
                                        <Select
                                          value={segment.courier_id}
                                          onChange={(e) =>
                                            handleSegmentChange(
                                              index,
                                              "courier_id",
                                              e.target.value
                                            )
                                          }
                                          required
                                        >
                                          {couriers.map((courier) => (
                                            <MenuItem
                                              key={courier.employee_id}
                                              value={courier.employee_id}
                                            >
                                              {courier.employee_first_name}{" "}
                                              {courier.employee_last_name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {formErrors[index]?.courier_id && (
                                          <FormHelperText>
                                            {formErrors[index].courier_id}
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                  )}
                                  <Grid item xs={2}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        !!formErrors[index]?.start_location_id
                                      }
                                    >
                                      <InputLabel>Start Location</InputLabel>
                                      <Select
                                        value={segment.start_location_id}
                                        onChange={(e) =>
                                          handleSegmentChange(
                                            index,
                                            "start_location_id",
                                            e.target.value
                                          )
                                        }
                                        required
                                      >
                                        {Object.entries(locationMap).map(
                                          ([id, location]) => (
                                            <MenuItem key={id} value={id}>
                                              {location.name} ({location.type})
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {formErrors[index]?.start_location_id && (
                                        <FormHelperText>
                                          {formErrors[index].start_location_id}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        !!formErrors[index]?.end_location_id
                                      }
                                    >
                                      <InputLabel>End Location</InputLabel>
                                      <Select
                                        value={segment.end_location_id || ""}
                                        onChange={(e) =>
                                          handleSegmentChange(
                                            index,
                                            "end_location_id",
                                            e.target.value
                                          )
                                        }
                                        required={!segment.is_last_segment}
                                        disabled={segment.is_last_segment}
                                      >
                                        {Object.entries(locationMap).map(
                                          ([id, location]) => (
                                            <MenuItem key={id} value={id}>
                                              {location.name} ({location.type})
                                            </MenuItem>
                                          )
                                        )}
                                        {segment.is_last_segment && (
                                          <MenuItem value="">
                                            Final Destination
                                          </MenuItem>
                                        )}
                                      </Select>
                                      {formErrors[index]?.end_location_id && (
                                        <FormHelperText>
                                          {formErrors[index].end_location_id}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField
                                      fullWidth
                                      label="Est. Duration (hrs)"
                                      type="number"
                                      value={segment.estimated_duration}
                                      onChange={(e) =>
                                        handleSegmentChange(
                                          index,
                                          "estimated_duration",
                                          e.target.value
                                        )
                                      }
                                      required
                                      error={
                                        !!formErrors[index]?.estimated_duration
                                      }
                                      helperText={
                                        formErrors[index]?.estimated_duration
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      onClick={() => handleRemoveSegment(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </Paper>
              <Button startIcon={<AddIcon />} onClick={handleAddSegment}>
                Add Segment
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAssignmentDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {isEditing ? "Update" : "Create"} Assignment
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Paper>
    </DragDropContext>
  );
};

export default OrderAssignment;
