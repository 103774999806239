import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import {
  Button,
  Typography,
  Alert,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

const BulkImport = ({ setOrders }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { api } = useContext(AuthContext);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await api.post("/orders/bulk-import", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setOrders((prevOrders) => [...prevOrders, ...response.data]);
        setFile(null);
        setSuccess(true);
        setError(null);
      } catch (err) {
        setError("Failed to import orders. Please try again.");
        console.error("Error importing orders:", err);
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Bulk Import Orders" />
      {/* add a pretty coming soon text and icon */}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Coming Soon!
        </Typography>
        <Typography>
          This feature is not yet available. Please check back later.
        </Typography>
      </CardContent>

      {/* <CardContent>
        <form onSubmit={handleSubmit}>
          <input type="file" accept=".csv,.xlsx" onChange={handleFileChange} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!file}
            sx={{ mt: 2 }}
          >
            Import Orders
          </Button>
          {file && (
            <Typography sx={{ mt: 1 }}>Selected file: {file.name}</Typography>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Orders imported successfully!
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
      </CardContent> */}
    </Card>
  );
};

export default BulkImport;
