import React, { createContext, useContext, useState, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const LocationTrackingContext = createContext(null);

export function useLocationTracking() {
  const context = useContext(LocationTrackingContext);
  if (!context) {
    throw new Error(
      "useLocationTracking must be used within LocationTrackingProvider"
    );
  }
  return context;
}

export function LocationTrackingProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [orders, setOrders] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationHistories, setLocationHistories] = useState({});
  const { api } = useContext(AuthContext);

  const handleApiError = useCallback((err) => {
    console.error("API Error:", err);
    const errorMessage = err.response?.data?.message || err.message;
    setError(errorMessage);
    setSnackbar({
      open: true,
      message: errorMessage,
      severity: "error",
    });
  }, []);

  const fetchLocationData = useCallback(
    async (businessId) => {
      if (!businessId) {
        setError("Business ID is required");
        return;
      }

      setLoading(true);
      try {
        const accessToken = localStorage.getItem("accessToken");

        // Fetch orders with their current locations
        const ordersResponse = await api.get(
          `/shipping-orders/location-tracking/${businessId}/orders/current`
        );

        // Fetch active couriers with their current locations
        const couriersResponse = await api.get(
          `/couriers/location-tracking/${businessId}/couriers`
        );

        console.log("Orders:", ordersResponse.data);

        setOrders(ordersResponse.data);
        setCouriers(couriersResponse.data);
        setError(null);
      } catch (err) {
        handleApiError(err);
      } finally {
        setLoading(false);
      }
    },
    [handleApiError]
  );

  const fetchLocationHistory = useCallback(
    async (businessId, itemId, type) => {
      try {
        console.log("Fetching location history for", type, itemId);
        const accessToken = localStorage.getItem("accessToken");
        const endpoint =
          type === "courier"
            ? `/couriers/location-tracking/${businessId}/history/courier/${itemId}`
            : `/shipping-orders/location-tracking/${businessId}/order/${itemId}/history`;

        const response = await api.get(endpoint, {});

        console.log("Location history response:", response.data);

        // Format history data consistently
        // check if history is not empty
        if (!response.data.locations || response.data.locations.length === 0) {
          setSnackbar({
            open: true,
            message: "No location history found",
            severity: "info",
          });
          return null;
        }
        const formattedHistory = {
          itemId,
          type,
          locations: response.data.locations.map((loc) => ({
            latitude: Number(loc.latitude),
            longitude: Number(loc.longitude),
            recorded_at: loc.recorded_at,
            accuracy: loc.accuracy ? Number(loc.accuracy) : null,
            speed: loc.speed ? Number(loc.speed) : null,
            heading: loc.heading ? Number(loc.heading) : null,
            altitude: loc.altitude ? Number(loc.altitude) : null,
          })),
        };

        setLocationHistories((prev) => ({
          ...prev,
          [itemId]: formattedHistory,
        }));

        console.log("Location history:", formattedHistory);

        return formattedHistory;
      } catch (err) {
        handleApiError(err);
        return null;
      }
    },
    [handleApiError]
  );

  const fetchLocationDataQuiet = useCallback(
    async (businessId) => {
      if (!businessId) {
        setError("Business ID is required");
        return;
      }

      try {
        const accessToken = localStorage.getItem("accessToken");

        // Fetch orders with their current locations
        const ordersResponse = await api.get(
          `/shipping-orders/location-tracking/${businessId}/orders/current`
        );

        // Fetch active couriers with their current locations
        const couriersResponse = await api.get(
          `/couriers/location-tracking/${businessId}/couriers`
        );

        console.log("Orders:", ordersResponse.data);

        setOrders(ordersResponse.data);
        setCouriers(couriersResponse.data);
        setError(null);
      } catch (err) {
        handleApiError(err);
      }
    },
    [handleApiError]
  );

  //   const startRealtimeUpdates = useCallback(
  //     (businessId) => {
  //       if (!businessId) return () => {};

  //       const interval = setInterval(() => {
  //         fetchLocationDataQuiet(businessId);
  //       }, 30000); // Update every 30 seconds

  //       return () => clearInterval(interval);
  //     },
  //     [fetchLocationDataQuiet]
  //   );

  return (
    <LocationTrackingContext.Provider
      value={{
        orders,
        couriers,
        loading,
        error,
        locationHistories,
        fetchLocationData,
        fetchLocationHistory,
        // startRealtimeUpdates,
        fetchLocationDataQuiet,
      }}
    >
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </LocationTrackingContext.Provider>
  );
}
