import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
  Alert,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import "./ContactForm.css";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ContactForm = () => {
  const { api } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    setIsError(false);

    try {
      const response = await api.post("/contact/email", formData);
      setMessage(response.data.message);
      setFormData({ name: "", email: "", message: "" }); // Clear the form on success
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors.map((err) => err.msg).join(", ")
          : error.message;
      setMessage(errorMessage);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" className="contact-form" onSubmit={handleSubmit}>
      {message && (
        <Alert
          severity={isError ? "error" : "success"}
          variant="outlined"
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
      <TextField
        variant="outlined"
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        variant="outlined"
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        variant="outlined"
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <CustomButton
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Send Message"}
      </CustomButton>
    </Box>
  );
};

export default ContactForm;
