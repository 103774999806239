import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import _, { set } from "lodash";

const OrdersContext = createContext();

export const useOrders = () => {
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error("useOrders must be used within an OrdersProvider");
  }
  return context;
};

export const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupsError, setGroupsError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(true);
  const [customersError, setCustomersError] = useState(null);
  const { api } = useContext(AuthContext);
  const fetchOrders = useCallback(
    async (businessId, refresh = false) => {
      if (!loaded || refresh) {
        setLoading(true);
        setError(null);
        try {
          const response = await api.get("/shipping-orders", {
            params: { business_id: businessId },
          });
          const backendOrders = response.data;
          const draftOrders = JSON.parse(
            localStorage.getItem("incompleteOrders") || "[]"
          );
          setOrders([...draftOrders, ...backendOrders]);
          setLoaded(true);
        } catch (err) {
          console.error("Error fetching orders:", err);
          setError("Failed to fetch orders. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    },
    [loaded]
  );

  const fetchSingleOrder = useCallback(async (orderId) => {
    try {
      const response = await api.get(`/shipping-orders/${orderId}`, {});
      return response.data;
    } catch (err) {
      console.error("Error fetching single order:", err);
      throw new Error("Failed to fetch order details");
    }
  }, []);

  // fetch customers
  const fetchCustomers = useCallback(async (businessId) => {
    setCustomersLoading(true);
    setCustomersError(null);
    try {
      const response = await api.get("/customers", {
        params: {
          business_id: businessId,
        },
      });
      setCustomers(response.data.customers);
    } catch (err) {
      console.error("Error fetching customers:", err);
      setCustomersError("Failed to fetch customers. Please try again later.");
    } finally {
      setCustomersLoading(false);
    }
  }, []);

  const updateOrder = useCallback(async (orderId, updatedData, businessId) => {
    try {
      console.log("Updating order with data: ", updatedData);
      console.log("Order ID: ", orderId);
      console.log("Business ID: ", businessId);
      console.log("Access Token: ", localStorage.getItem("accessToken"));

      const response = await api.put(`/shipping-orders/${orderId}`, {
        ...updatedData,
        business_id: businessId,
      });

      const updatedOrderData = response.data;
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipping_order_id === updatedOrderData.shipping_order_id
            ? { ...order, ...updatedOrderData }
            : order
        )
      );
      return updatedOrderData;
    } catch (err) {
      console.error("Error updating order:", err);
      if (err.response) {
        console.error("Response data:", err.response.data);
        console.error("Response status:", err.response.status);
        console.error("Response headers:", err.response.headers);
      }
      throw new Error(
        "Failed to update order: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const addOrder = useCallback((newOrder) => {
    setOrders((prevOrders) => [...prevOrders, newOrder]);
  }, []);

  const deleteOrder = useCallback((orderId) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.shipping_order_id !== orderId)
    );
  }, []);

  const deleteOrderFromLocalStorage = useCallback((orderId) => {
    const draftOrders = JSON.parse(
      localStorage.getItem("incompleteOrders") || "[]"
    );
    localStorage.setItem(
      "incompleteOrders",
      JSON.stringify(
        draftOrders.filter((order) => order.shipping_order_id !== orderId)
      )
    );
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.shipping_order_id !== orderId)
    );
  }, []);

  // save customer info

  const saveCustomerInfo = useCallback(
    async (customerId, customerData, businessId) => {
      try {
        console.log("customerData: ", customerData);
        console.log("customerId: ", customerId);
        console.log("businessId: ", businessId);
        const response = await api.put(`/customers/update`, {
          business_id: businessId,
          customer_id: customerId,
          ...customerData,
        });
        const updatedCustomer = response.data.customer;

        // Update the customer in the orders state if necessary
        setOrders((prevOrders) =>
          prevOrders.map((order) => {
            if (order.sender.customer_id === customerId) {
              return { ...order, sender: updatedCustomer };
            }
            if (order.recipient.customer_id === customerId) {
              return { ...order, recipient: updatedCustomer };
            }
            return order;
          })
        );
        return updatedCustomer;
      } catch (err) {
        console.error("Error updating customer information:", err);
        throw new Error("Failed to update customer information");
      }
    },
    []
  );

  // updatePackages

  const updatePackages = useCallback(async (orderId, packages) => {
    try {
      const response = await api.put(`/shipping-orders/${orderId}/packages`, {
        packages,
      });
      return response.data;
    } catch (err) {
      console.error("Error updating packages:", err);
      throw new Error("Failed to update packages");
    }
  }, []);

  const updateOrderStatus = useCallback(async (orderId, newStatus) => {
    try {
      console.log("Updating order status to: ", newStatus);
      console.log("Order ID: ", orderId);

      const response = await api.put(`/shipping-orders/${orderId}/status`, {
        status: newStatus,
      });

      const updatedOrder = response.data;

      setOrders((prevOrders) => {
        // Create a deep copy of the previous orders
        const newOrders = [...prevOrders];

        // Find the order to update
        const orderToUpdate = newOrders.find(
          (order) => order.shipping_order_id === orderId
        );

        if (!orderToUpdate) {
          console.warn(`Order with ID ${orderId} not found in current state`);
          return prevOrders;
        }

        // Update the order with all the new data from the response
        Object.assign(orderToUpdate, {
          ...orderToUpdate,
          ...updatedOrder,
          order_status: newStatus,
        });

        return newOrders;
      });

      setUpdateError(null);
      return updatedOrder;
    } catch (err) {
      const errorMessage = err.response?.data?.msg || err.message;
      console.error("Error updating order status:", errorMessage);
      setUpdateError(`Failed to update order status: ${errorMessage}`);
      throw new Error(`Failed to update order status: ${errorMessage}`);
    }
  }, []);

  const fetchGroups = useCallback(async (businessId) => {
    setGroupsLoading(true);
    setGroupsError(null);
    try {
      const response = await api.get("/shipping-groups", {
        params: { business_id: businessId },
      });
      setGroups(response.data);
    } catch (err) {
      console.error("Error fetching groups:", err);
      setGroupsError("Failed to fetch groups. Please try again later.");
    } finally {
      setGroupsLoading(false);
    }
  }, []);

  const createGroup = useCallback(async (groupData, businessId) => {
    try {
      const response = await api.post("/shipping-groups", {
        ...groupData,
        business_id: businessId,
      });
      const newGroup = response.data;
      // Ensure the new group has all necessary properties
      const completeNewGroup = {
        ...newGroup,
        ShippingOrders: [],
        total_value: 0,
        createdAt: new Date().toISOString(),
        status: "active",
      };
      setGroups((prevGroups) => [...prevGroups, completeNewGroup]);
      return completeNewGroup;
    } catch (err) {
      console.error("Error creating group:", err);
      throw new Error(
        "Failed to create group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const updateGroup = useCallback(async (groupId, updatedData, businessId) => {
    try {
      const response = await api.put(`/shipping-groups/${groupId}`, {
        ...updatedData,
        business_id: businessId,
      });
      const updatedGroup = response.data;
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.group_id === updatedGroup.group_id
            ? { ...group, ...updatedGroup }
            : group
        )
      );
      return updatedGroup;
    } catch (err) {
      console.error("Error updating group:", err);
      throw new Error(
        "Failed to update group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const deleteGroup = useCallback(async (groupId, businessId) => {
    try {
      await api.delete(`/shipping-groups/${groupId}`, {
        params: { business_id: businessId },
      });
      setGroups((prevGroups) =>
        prevGroups.filter((group) => group.group_id !== groupId)
      );
    } catch (err) {
      console.error("Error deleting group:", err);
      throw new Error(
        "Failed to delete group: " + (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const addOrderToGroup = useCallback(async (groupId, orderId, businessId) => {
    try {
      const response = await api.post(`/shipping-groups/${groupId}/orders`, {
        order_id: orderId,
        business_id: businessId,
      });
      const updatedGroup = response.data;
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.group_id === updatedGroup.group_id ? updatedGroup : group
        )
      );
      return updatedGroup;
    } catch (err) {
      console.error("Error adding order to group:", err);
      throw new Error(
        "Failed to add order to group: " +
          (err.response?.data?.msg || err.message)
      );
    }
  }, []);

  const removeOrderFromGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const response = await api.delete(
          `/shipping-groups/${groupId}/orders/${orderId}`,
          {
            params: { business_id: businessId },
          }
        );
        const updatedGroup = response.data;
        setGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === updatedGroup.group_id ? updatedGroup : group
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error removing order from group:", err);
        throw new Error(
          "Failed to remove order from group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    []
  );

  const value = {
    orders,
    setOrders,
    groups,
    groupsLoading,
    groupsError,
    loading,
    error,
    customers,
    customersLoading,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchSingleOrder,
    updateOrder,
    addOrder,
    deleteOrder,
    deleteOrderFromLocalStorage,
    saveCustomerInfo,
    updatePackages,
    updateOrderStatus,
    // New group-related functions
    fetchGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    addOrderToGroup,
    removeOrderFromGroup,
  };

  return (
    <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
  );
};
