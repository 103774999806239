import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const CollaborationComponent = ({
  open,
  onClose,
  selectedOrders,
  businessId,
  onCollaborationUpdate,
}) => {
  const { api } = useContext(AuthContext);
  const [collaborators, setCollaborators] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [collaborationDetails, setCollaborationDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [collaborationResults, setCollaborationResults] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setError(null);
    if (open && selectedOrders?.length > 0) {
      fetchCollaborators();
      setSelectedOrderIds(selectedOrders);
      setHasChanges(false); // Reset changes flag when opening
    }
    // Reset states when dialog closes
    if (!open) {
      setSelectedCollaborator(null);
      setSelectedTier(null);
      setCollaborationDetails("");
      setCollaborationResults(null);
      setError(null);
      setHasChanges(false);
    }
  }, [open, businessId, selectedOrders]);

  const fetchCollaborators = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/shipping-collaboration/search-collaborators?businessId=${businessId}`,
        {
          params: {
            selectedOrders,
          },
        }
      );
      setCollaborators(response.data);
    } catch (err) {
      console.error("Error fetching collaborators:", err);
      setError("Failed to fetch collaborators. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setError(null);
      setCollaborationResults(null);

      const collaborationData = {
        business_id: businessId,
        shipping_order_ids: selectedOrderIds,
        collaborating_business_id: selectedCollaborator.business_id,
        shipping_tier_id: selectedTier.tier_id,
        collaboration_type: "full_handling",
        collaboration_details: { notes: collaborationDetails },
        additional_charges: [],
        special_instructions: collaborationDetails,
      };

      // Send collaboration request
      const response = await api.post(
        "/shipping-collaboration/collaboration-request",
        collaborationData
      );

      setCollaborationResults(response.data);
      setHasChanges(true); // Set changes flag on successful submission

      // Call the parent's update callback
      if (onCollaborationUpdate) {
        await onCollaborationUpdate();
      }

      // Show success message for a moment before closing
      setTimeout(() => {
        onClose(true); // Pass true to indicate successful update
        // Reset states
        setSelectedCollaborator(null);
        setSelectedTier(null);
        setCollaborationDetails("");
        setSelectedOrderIds([]);
        setCollaborationResults(null);
      }, 1500);
    } catch (err) {
      console.error("Error sending collaboration request:", err);
      setError("Failed to send collaboration request. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleOrderSelection = (orderId) => {
    setHasChanges(true); // Set changes flag when orders are modified
    setSelectedOrderIds((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleClose = () => {
    if (!submitting) {
      onClose(hasChanges); // Pass hasChanges flag to indicate if updates occurred
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Set Up Collaboration</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {collaborationResults && (
            <Grid item xs={12}>
              <Alert severity="success">
                Successfully created {collaborationResults.successful.length}{" "}
                collaborations.
                {collaborationResults.failed.length > 0 && (
                  <>
                    {" "}
                    {collaborationResults.failed.length} collaborations failed.
                  </>
                )}
              </Alert>
            </Grid>
          )}
          {loading ? (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : selectedOrders.length === 0 ? (
            <Grid item xs={12}>
              <Alert severity="error">
                No orders selected. Please select at least one order to
                collaborate.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Select Orders for Collaboration
                </Typography>
                <List>
                  {selectedOrders.map((orderId) => (
                    <ListItem key={orderId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOrderIds.includes(orderId)}
                            onChange={() => handleOrderSelection(orderId)}
                          />
                        }
                        label={`Order ID: ${orderId}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Select a Collaborator</Typography>
              </Grid>
              {collaborators.map((collaborator) => (
                <Grid item xs={12} key={collaborator.business_id}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              collaborator.business_profile_picture ||
                              "/path/to/default/image.jpg"
                            }
                            alt={collaborator.business_name}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h6">
                            {collaborator.business_name}
                          </Typography>
                          <Typography variant="body2">
                            Email: {collaborator.business_email}
                          </Typography>
                          <Typography variant="body2">
                            Phone: {collaborator.business_phone}
                          </Typography>
                          <Typography variant="body2">
                            Address:{" "}
                            {
                              collaborator.BusinessAddresses[0]
                                ?.business_address_city
                            }
                            ,{" "}
                            {
                              collaborator.BusinessAddresses[0]
                                ?.business_address_country
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="subtitle1" mt={2}>
                        Matching Orders and Tiers:
                      </Typography>
                      {collaborator.orderMatches
                        .filter((match) =>
                          selectedOrderIds.includes(match.order_id)
                        )
                        .map((match) => (
                          <Accordion key={match.order_id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>
                                Order ID: {match.order_id}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List>
                                {match.matchingTiers.map((tier) => (
                                  <ListItem
                                    key={tier.tier_id}
                                    button
                                    selected={
                                      selectedTier &&
                                      selectedTier.tier_id === tier.tier_id
                                    }
                                    onClick={() => {
                                      setSelectedCollaborator(collaborator);
                                      setSelectedTier(tier);
                                      setHasChanges(true); // Set changes when tier is selected
                                    }}
                                  >
                                    <ListItemText
                                      primary={tier.tier_name}
                                      secondary={
                                        <>
                                          <Typography variant="body2">
                                            Service Level: {tier.service_level}
                                          </Typography>
                                          <Typography variant="body2">
                                            Estimated Delivery:{" "}
                                            {tier.estimated_delivery_time.min}-
                                            {tier.estimated_delivery_time.max}{" "}
                                            {tier.estimated_delivery_time.unit}
                                          </Typography>
                                          <Typography variant="body2">
                                            Price: $
                                            {tier.calculatedPrice.toFixed(2)}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {selectedCollaborator && selectedTier && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Collaboration Details"
                    multiline
                    rows={4}
                    value={collaborationDetails}
                    onChange={(e) => {
                      setCollaborationDetails(e.target.value);
                      setHasChanges(true); // Set changes when details are modified
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={
            !selectedCollaborator ||
            !selectedTier ||
            selectedOrderIds.length === 0 ||
            loading ||
            submitting
          }
          variant="contained"
        >
          {submitting ? (
            <>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              Sending Request...
            </>
          ) : (
            "Send Collaboration Request"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollaborationComponent;
