import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  TextField,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Box,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
  Add as AddIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import { Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import BusinessDashboardHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./BusinessCustomersPage.module.css";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const BusinessCustomersPage = () => {
  const { api } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [orderBy, setOrderBy] = useState("first_name");
  const [order, setOrder] = useState("asc");
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [newCustomerAlertMessage, setNewCustomerAlertMessage] = useState(null);
  const [existingCustomerAlertMessage, setExistingCustomerAlertMessage] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editCustomer, setEditCustomer] = useState(null);
  const [deleteCustomer, setDeleteCustomer] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { businessId } = useParams();
  const [isAddCustomerDialogOpen, setIsAddCustomerDialogOpen] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });
  const [newCustomer, setNewCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [filters, setFilters] = useState({
    state: "",
    city: "",
  });

  const { getBusinessById, fetchBusinesses, updateCurrentBusinessContext } =
    useBusiness();

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
    fetchCustomers();
  }, [sidebarActive, currentPage, filterOption, searchQuery, itemsPerPage]);

  // Use the custom hook for category management
  const businessCategory = useBusinessCategory(businessId);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/customers", {
        params: {
          business_id: businessId,
        },
      });
      console.log("Response data:", response.data);
      setCustomers(response.data.customers);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch customers. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const validateFields = (customer) => {
    const errors = {};
    if (!customer.first_name.trim())
      errors.first_name = "First Name is required";
    if (!customer.last_name.trim()) errors.last_name = "Last Name is required";
    if (!customer.email.trim()) errors.email = "Email is required";
    if (
      customer.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customer.email)
    ) {
      errors.email = "Email is not valid";
    }
    return errors;
  };

  const handleSaveCustomer = async () => {
    setExistingCustomerAlertMessage(null);
    const errors = validateFields(editCustomer);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.put("/customers/update", {
        business_id: businessId,
        customer_id: editCustomer.customer_id,
        ...editCustomer,
      });
      setAlertMessage({
        type: "success",
        message: "Customer updated successfully.",
      });
      fetchCustomers();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setExistingCustomerAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });

        if (error.response.data.msg.includes("Email")) {
          setFormErrors({ email: "Email already exists" });
        }
        if (error.response.data.msg.includes("Phone")) {
          setFormErrors({ phone: "Phone already exists" });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to update customer. Please try again.",
        });
      }
    }
  };

  const handleSaveNewCustomer = async () => {
    const errors = validateFields(newCustomer);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      console.log("Adding new customer:", newCustomer);
      await api.post("/customers/add", {
        business_id: businessId,
        ...newCustomer,
      });
      setAlertMessage({
        type: "success",
        message: "Customer added successfully.",
      });
      fetchCustomers();
      handleCloseAddCustomerDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setNewCustomerAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });

        if (error.response.data.msg.includes("Email")) {
          setFormErrors({ email: "Email already exists" });
        }
        if (error.response.data.msg.includes("Phone")) {
          setFormErrors({ phone: "Phone already exists" });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to add customer. Please try again.",
        });
      }
    }
  };

  const handleFieldChange = (field, value, setCustomer) => {
    setCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
    setCurrentPage(1);
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      const matchesSearch = Object.values(customer).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );
      const matchesState = !filters.state || customer.state === filters.state;
      const matchesCity = !filters.city || customer.city === filters.city;
      return matchesSearch && matchesState && matchesCity;
    });
  }, [customers, searchQuery, filters]);

  const sortedCustomers = useMemo(() => {
    return [...filteredCustomers].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredCustomers, orderBy, order]);

  const paginatedCustomers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedCustomers.slice(start, start + itemsPerPage);
  }, [sortedCustomers, currentPage, itemsPerPage]);

  const uniqueStates = useMemo(() => {
    return [...new Set(customers.map((c) => c.state))].filter(Boolean);
  }, [customers]);

  const uniqueCities = useMemo(() => {
    return [...new Set(customers.map((c) => c.city))].filter(Boolean);
  }, [customers]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleEditCustomer = (customer) => {
    setEditCustomer(customer);
    setIsDialogOpen(true);
  };

  const handleDeleteCustomer = (customer) => {
    setDeleteCustomer(customer);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setExistingCustomerAlertMessage(null);
    setNewCustomerAlertMessage(null);
    setIsDialogOpen(false);
    setEditCustomer(null);
    setDeleteCustomer(null);
  };

  const handleAddCustomer = () => {
    setIsAddCustomerDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    console.log("Deleting customer:", deleteCustomer);
    try {
      await api.delete("/customers/delete", {
        params: {
          business_id: businessId,
          customer_id: deleteCustomer.customer_id,
        },
      });
      setAlertMessage({
        type: "success",
        message: "Customer deleted successfully.",
      });
      fetchCustomers();
    } catch (error) {
      console.error("Error deleting customer:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete customer. Please try again.",
      });
    }
    handleCloseDialog();
  };

  const handleCloseAddCustomerDialog = () => {
    setNewCustomerAlertMessage(null);
    setIsAddCustomerDialogOpen(false);
    setNewCustomer({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    });
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {[
          { id: "first_name", label: "First Name" },
          { id: "last_name", label: "Last Name" },
          { id: "email", label: "Email" },
          { id: "phone", label: "Phone" },
          { id: "address_line1", label: "Address" },
          { id: "city", label: "City" },
          { id: "state", label: "State" },
          { id: "postal_code", label: "Postal Code" },
        ].map((column) => (
          <TableCell key={column.id}>
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={() => handleRequestSort(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {paginatedCustomers.map((customer) => (
        <TableRow key={customer.customer_id}>
          <TableCell>{customer.first_name}</TableCell>
          <TableCell>{customer.last_name}</TableCell>
          <TableCell>{customer.email}</TableCell>
          <TableCell>{customer.phone}</TableCell>
          <TableCell>{customer.address_line1}</TableCell>
          <TableCell>{customer.city}</TableCell>
          <TableCell>{customer.state}</TableCell>
          <TableCell>{customer.postal_code}</TableCell>
          <TableCell>
            <Tooltip title="Edit" className={styles["icon-button"]}>
              <IconButton onClick={() => handleEditCustomer(customer)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete"
              className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
            >
              <IconButton onClick={() => handleDeleteCustomer(customer)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <div className={styles["customers-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessDashboardHeader />
        <div className={styles["main-content"]}>
          {renderSidebar()}
          <main
            className={`${styles["customers-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Manage Customers</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            <Button
              variant="outlined"
              startIcon={<PersonAddIcon />}
              onClick={handleAddCustomer}
              className={styles["add-customer-IconButton"]}
              sx={{
                display: "block",
                mt: 4,
                mb: 4,
                color: "midnightblue",
                borderColor: "midnightblue",
                "&:hover": {
                  color: "var(--soft-blue)",
                  borderColor: "var(--soft-blue)",
                },
                cursor: "pointer",
              }}
            >
              Add Customer
            </Button>
            <div className={styles["filter-search"]}>
              <TextField
                name="search"
                label="Search Customers"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e)}
                className={styles["search-field"]}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
              <FormControl className={styles["filter-control"]}>
                <InputLabel>State</InputLabel>
                <Select
                  value={filters.state}
                  onChange={(e) => handleFilterChange("state", e.target.value)}
                  label="State"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles["filter-control"]}>
                <InputLabel>City</InputLabel>
                <Select
                  value={filters.city}
                  onChange={(e) => handleFilterChange("city", e.target.value)}
                  label="City"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueCities.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles["items-per-page"]}>
                <InputLabel>Items Per Page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items Per Page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </div>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  mt: 2,
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                }}
              >
                <Table>
                  {renderTableHeader()}
                  {renderTableBody()}
                </Table>
              </TableContainer>
            )}
            <Pagination
              count={Math.ceil(sortedCustomers.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              className={styles.pagination}
            />
          </main>
        </div>
        <DashboardFooter />
      </div>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        {editCustomer && (
          <>
            <DialogTitle>Edit Customer</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={editCustomer.first_name}
                error={!!formErrors.first_name}
                helperText={formErrors.first_name}
                onChange={(e) =>
                  handleFieldChange(
                    "first_name",
                    e.target.value,
                    setEditCustomer
                  )
                }
              />
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={editCustomer.last_name}
                error={!!formErrors.last_name}
                helperText={formErrors.last_name}
                onChange={(e) =>
                  handleFieldChange(
                    "last_name",
                    e.target.value,
                    setEditCustomer
                  )
                }
              />
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                required
                value={editCustomer.email}
                error={!!formErrors.email}
                helperText={formErrors.email}
                onChange={(e) =>
                  handleFieldChange("email", e.target.value, setEditCustomer)
                }
              />
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={editCustomer.phone}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                onChange={(value) =>
                  handleFieldChange("phone", value, setEditCustomer)
                }
              />
              <TextField
                margin="dense"
                label="Street Address 1"
                fullWidth
                value={editCustomer.address_line1}
                onChange={(e) =>
                  handleFieldChange(
                    "address_line1",
                    e.target.value,
                    setEditCustomer
                  )
                }
              />
              <TextField
                margin="dense"
                label="Street Address 2"
                fullWidth
                value={editCustomer.address_line2}
                onChange={(e) =>
                  handleFieldChange(
                    "address_line2",
                    e.target.value,
                    setEditCustomer
                  )
                }
              />
              <TextField
                margin="dense"
                label="City"
                fullWidth
                value={editCustomer.city}
                onChange={(e) =>
                  handleFieldChange("city", e.target.value, setEditCustomer)
                }
              />
              <TextField
                margin="dense"
                label="State/Province"
                fullWidth
                value={editCustomer.state}
                onChange={(e) =>
                  handleFieldChange("state", e.target.value, setEditCustomer)
                }
              />
              <TextField
                margin="dense"
                label="Postal Code"
                fullWidth
                value={editCustomer.postal_code}
                onChange={(e) =>
                  handleFieldChange(
                    "postal_code",
                    e.target.value,
                    setEditCustomer
                  )
                }
              />
              <TextField
                margin="dense"
                label="Country"
                fullWidth
                value={editCustomer.country}
                onChange={(e) =>
                  handleFieldChange("country", e.target.value, setEditCustomer)
                }
              />
              {existingCustomerAlertMessage && (
                <Alert
                  severity={existingCustomerAlertMessage.type}
                  variant="outlined"
                  sx={{ mb: 2, mt: 2 }}
                  onClose={() => setExistingCustomerAlertMessage(null)}
                >
                  {existingCustomerAlertMessage.message}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <IconButton
                aria-label="save"
                type="submit"
                size="small"
                variant="contained"
                className={styles["icon-button"]}
                onClick={handleSaveCustomer}
                sx={{ padding: "8px" }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                aria-label="cancel"
                type="reset"
                onClick={handleCloseDialog}
                startIcon={<CancelIcon />}
                margin="dense"
                size="small"
                className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                sx={{ padding: "8px" }}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </>
        )}
        {deleteCustomer && (
          <>
            <DialogTitle>Delete Customer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete {deleteCustomer.first_name}{" "}
                {deleteCustomer.last_name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton
                aria-label="save"
                type="submit"
                size="small"
                variant="contained"
                className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                onClick={handleConfirmDelete}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="cancel"
                type="reset"
                onClick={handleCloseDialog}
                margin="dense"
                size="small"
                className={styles["icon-button"]}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={isAddCustomerDialogOpen}
        onClose={handleCloseAddCustomerDialog}
      >
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={newCustomer.first_name}
                error={!!formErrors.first_name}
                helperText={formErrors.first_name}
                onChange={(e) =>
                  handleFieldChange(
                    "first_name",
                    e.target.value,
                    setNewCustomer
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={newCustomer.last_name}
                error={!!formErrors.last_name}
                helperText={formErrors.last_name}
                onChange={(e) =>
                  handleFieldChange("last_name", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                required
                value={newCustomer.email}
                error={!!formErrors.email}
                helperText={formErrors.email}
                onChange={(e) =>
                  handleFieldChange("email", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={newCustomer.phone}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                onChange={(value) =>
                  handleFieldChange("phone", value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Street Address 1"
                fullWidth
                value={newCustomer.address_line1}
                onChange={(e) =>
                  handleFieldChange(
                    "address_line1",
                    e.target.value,
                    setNewCustomer
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Street Address 2"
                fullWidth
                value={newCustomer.address_line2}
                onChange={(e) =>
                  handleFieldChange(
                    "address_line2",
                    e.target.value,
                    setNewCustomer
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="City"
                fullWidth
                value={newCustomer.city}
                onChange={(e) =>
                  handleFieldChange("city", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="State/Province"
                fullWidth
                value={newCustomer.state}
                onChange={(e) =>
                  handleFieldChange("state", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Postal Code"
                fullWidth
                value={newCustomer.postal_code}
                onChange={(e) =>
                  handleFieldChange(
                    "postal_code",
                    e.target.value,
                    setNewCustomer
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Country"
                fullWidth
                value={newCustomer.country}
                onChange={(e) =>
                  handleFieldChange("country", e.target.value, setNewCustomer)
                }
              />
            </Grid>

            {newCustomerAlertMessage && (
              <Grid item xs={12} sm={12}>
                <Alert
                  severity={newCustomerAlertMessage.type}
                  variant="outlined"
                  sx={{ mb: 2, mt: 2 }}
                  onClose={() => setAlertMessage(null)}
                >
                  {newCustomerAlertMessage.message}
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={handleSaveNewCustomer}
            aria-label="save"
            type="submit"
            size="small"
            variant="contained"
            className={styles["icon-button"]}
            sx={{ padding: "8px" }}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            aria-label="cancel"
            type="reset"
            onClick={handleCloseAddCustomerDialog}
            margin="dense"
            size="small"
            className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
            sx={{ padding: "8px" }}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BusinessCustomersPage;
