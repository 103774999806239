import React from "react";
import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 10px", // Adjusted margin for spacing
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const CustomButton = ({ text, onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {text}
    </StyledButton>
  );
};

export default CustomButton;
