import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  TextField,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
  Add as AddIcon,
  PersonAdd as PersonAddIcon,
  Replay as ReplayIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import { Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import BusinessDashboardHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./EmployeeManagement.module.css";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const EmployeeManagement = () => {
  const { api } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [orderBy, setOrderBy] = useState("employee_first_name");
  const [order, setOrder] = useState("asc");
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [newEmployeeAlertMessage, setNewEmployeeAlertMessage] = useState(null);
  const [existingEmployeeAlertMessage, setExistingEmployeeAlertMessage] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editEmployee, setEditEmployee] = useState(null);
  const [deleteEmployee, setDeleteEmployee] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { businessId } = useParams();
  const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });
  const [newEmployee, setNewEmployee] = useState({
    employee_first_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_phone: "",
    employee_role: "",
    employee_hire_date: "",
    employment_status: "",
    employee_notes: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [filters, setFilters] = useState({
    role: "",
    status: "",
  });

  const EMPLOYEE_ROLES = [
    "owner",
    "manager",
    "supervisor",
    "courier",
    "cashier",
    "sales representative",
    "customer service",
    "administrator",
    "accountant",
  ];

  const { getBusinessById, fetchBusinesses, updateCurrentBusinessContext } =
    useBusiness();

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
    fetchEmployees();
  }, [sidebarActive, currentPage, filterOption, searchQuery, itemsPerPage]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  const businessCategory = useBusinessCategory(businessId);

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/employees", {
        params: {
          business_id: businessId,
        },
      });
      console.log("Fetched employees:", response.data);
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch employees. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const validateFields = (employee) => {
    const errors = {};
    if (!employee.employee_first_name.trim())
      errors.employee_first_name = "First Name is required";
    if (!employee.employee_last_name.trim())
      errors.employee_last_name = "Last Name is required";
    if (!employee.employee_email.trim())
      errors.employee_email = "Email is required";
    if (
      employee.employee_email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(employee.employee_email)
    ) {
      errors.employee_email = "Email is not valid";
    }
    if (!employee.employee_role.trim())
      errors.employee_role = "Role is required";
    if (!employee.employee_hire_date)
      errors.employee_hire_date = "Hire date is required";
    if (!employee.employment_status)
      errors.employment_status = "Employment status is required";
    return errors;
  };

  const handleSaveEmployee = async () => {
    setExistingEmployeeAlertMessage(null);
    const errors = validateFields(editEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.put(
        `/employees/${businessId}/${editEmployee.employee_id}`,
        editEmployee
      );
      setAlertMessage({
        type: "success",
        message: "Employee updated successfully.",
      });
      fetchEmployees();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setExistingEmployeeAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
        if (error.response.data.msg.includes("Email")) {
          setFormErrors({ employee_email: "Email already exists" });
        }
        if (error.response.data.msg.includes("Phone")) {
          setFormErrors({ employee_phone: "Phone already exists" });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to update employee. Please try again.",
        });
      }
    }
  };

  const handleSaveNewEmployee = async () => {
    const errors = validateFields(newEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.post(`/employees/${businessId}`, newEmployee, {});
      setAlertMessage({
        type: "success",
        message: "Employee added successfully.",
      });
      fetchEmployees();
      handleCloseAddEmployeeDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setNewEmployeeAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
        if (error.response.data.msg.includes("Email")) {
          setFormErrors({ employee_email: "Email already exists" });
        }
        if (error.response.data.msg.includes("Phone")) {
          setFormErrors({ employee_phone: "Phone already exists" });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to add employee. Please try again.",
        });
      }
    }
  };

  const handleFieldChange = (field, value, setEmployee) => {
    setEmployee((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [field]: value,
      };
    });

    setFormErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
    setCurrentPage(1);
  };

  const filteredEmployees = useMemo(() => {
    return employees.filter((employee) => {
      const matchesSearch = Object.values(employee).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );
      const matchesRole =
        !filters.role || employee.employee_role === filters.role;
      const matchesStatus =
        !filters.status || employee.employment_status === filters.status;
      return matchesSearch && matchesRole && matchesStatus;
    });
  }, [employees, searchQuery, filters]);

  const sortedEmployees = useMemo(() => {
    return [...filteredEmployees].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredEmployees, orderBy, order]);

  const paginatedEmployees = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedEmployees.slice(start, start + itemsPerPage);
  }, [sortedEmployees, currentPage, itemsPerPage]);

  const uniqueRoles = useMemo(() => {
    return [...new Set(employees.map((e) => e.employee_role))].filter(Boolean);
  }, [employees]);

  const uniqueStatuses = useMemo(() => {
    return [...new Set(employees.map((e) => e.employment_status))].filter(
      Boolean
    );
  }, [employees]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleResendInvitation = async (employee) => {
    try {
      await api.post(`/employees/${businessId}/resend-invitation`, {
        employeeId: employee.employee_id,
      });
      setAlertMessage({
        type: "success",
        message: "Invitation resent successfully.",
      });
    } catch (error) {
      console.error("Error resending invitation:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to resend invitation. Please try again.",
      });
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleEditEmployee = (employee) => {
    setEditEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleDeleteEmployee = (employee) => {
    setDeleteEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setExistingEmployeeAlertMessage(null);
    setNewEmployeeAlertMessage(null);
    setIsDialogOpen(false);
    setEditEmployee(null);
    setDeleteEmployee(null);
  };

  const handleAddEmployee = () => {
    setIsAddEmployeeDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(
        `/employees/${businessId}/${deleteEmployee.employee_id}`
      );
      setAlertMessage({
        type: "success",
        message: "Employee deleted successfully.",
      });
      fetchEmployees();
    } catch (error) {
      console.error("Error deleting employee:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete employee. Please try again.",
      });
    }
    handleCloseDialog();
  };

  const handleCloseAddEmployeeDialog = () => {
    setNewEmployeeAlertMessage(null);
    setIsAddEmployeeDialogOpen(false);
    setNewEmployee({
      employee_first_name: "",
      employee_last_name: "",
      employee_email: "",
      employee_phone: "",
      employee_role: "",
      employee_hire_date: "",
      employment_status: "",
      employee_notes: "",
    });
  };

  const renderRoleField = (employee, setEmployee, isEditMode = false) => {
    const isOwner = isEditMode && employee?.employee_role === "owner";

    return (
      <FormControl fullWidth margin="dense">
        <InputLabel>Role</InputLabel>
        <Select
          value={employee?.employee_role || ""}
          onChange={(e) =>
            handleFieldChange("employee_role", e.target.value, setEmployee)
          }
          error={!!formErrors.employee_role}
          disabled={isOwner}
          required
        >
          {EMPLOYEE_ROLES.map((role) => (
            <MenuItem
              key={role}
              value={role}
              sx={{ textTransform: "capitalize" }}
            >
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </MenuItem>
          ))}
        </Select>
        {formErrors.employee_role && (
          <FormHelperText error>{formErrors.employee_role}</FormHelperText>
        )}
      </FormControl>
    );
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {[
          { id: "employee_first_name", label: "First Name" },
          { id: "employee_last_name", label: "Last Name" },
          { id: "employee_email", label: "Email" },
          { id: "employee_phone", label: "Phone" },
          { id: "employee_role", label: "Role" },
          { id: "employee_hire_date", label: "Hire Date" },
          { id: "employment_status", label: "Status" },
          { id: "employee_status", label: "Employee Status" },
        ].map((column) => (
          <TableCell key={column.id}>
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={() => handleRequestSort(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {paginatedEmployees.map((employee) => (
        <TableRow key={employee.employee_id}>
          <TableCell>{employee.employee_first_name}</TableCell>
          <TableCell>{employee.employee_last_name}</TableCell>
          <TableCell>{employee.employee_email}</TableCell>
          <TableCell>{employee.employee_phone}</TableCell>
          <TableCell>{employee.employee_role}</TableCell>
          <TableCell>{employee.employee_hire_date}</TableCell>
          <TableCell>{employee.employment_status}</TableCell>
          <TableCell>{employee.employee_status}</TableCell>
          <TableCell>
            {employee.employee_status === "pending" && (
              <Tooltip
                title="Resend Invitation"
                className={styles["icon-button"]}
              >
                <IconButton onClick={() => handleResendInvitation(employee)}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Edit" className={styles["icon-button"]}>
              <IconButton onClick={() => handleEditEmployee(employee)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete"
              className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
            >
              <IconButton
                onClick={() => handleDeleteEmployee(employee)}
                disabled={
                  employee.employee_status === "pending" ||
                  employee.employee_role === "owner"
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const renderEditEmployeeDialog = () => (
    <Dialog open={isDialogOpen && editEmployee} onClose={handleCloseDialog}>
      <DialogTitle>Edit Employee</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="First Name"
          fullWidth
          required
          value={editEmployee?.employee_first_name || ""}
          error={!!formErrors.employee_first_name}
          helperText={formErrors.employee_first_name}
          onChange={(e) =>
            handleFieldChange(
              "employee_first_name",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <TextField
          margin="dense"
          label="Last Name"
          fullWidth
          required
          value={editEmployee?.employee_last_name || ""}
          error={!!formErrors.employee_last_name}
          helperText={formErrors.employee_last_name}
          onChange={(e) =>
            handleFieldChange(
              "employee_last_name",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          required
          value={editEmployee?.employee_email || ""}
          error={!!formErrors.employee_email}
          helperText={formErrors.employee_email}
          onChange={(e) =>
            handleFieldChange("employee_email", e.target.value, setEditEmployee)
          }
        />
        <MuiTelInput
          margin="dense"
          label="Phone"
          fullWidth
          value={editEmployee?.employee_phone || ""}
          error={!!formErrors.employee_phone}
          helperText={formErrors.employee_phone}
          onChange={(value) =>
            handleFieldChange("employee_phone", value, setEditEmployee)
          }
        />
        {renderRoleField(editEmployee, setEditEmployee, true)}
        <TextField
          margin="dense"
          label="Hire Date"
          type="date"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          value={editEmployee?.employee_hire_date || ""}
          error={!!formErrors.employee_hire_date}
          helperText={formErrors.employee_hire_date}
          onChange={(e) =>
            handleFieldChange(
              "employee_hire_date",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Employment Status</InputLabel>
          <Select
            value={editEmployee?.employment_status || ""}
            onChange={(e) =>
              handleFieldChange(
                "employment_status",
                e.target.value,
                setEditEmployee
              )
            }
            error={!!formErrors.employment_status}
          >
            <MenuItem value="full-time">Full Time</MenuItem>
            <MenuItem value="part-time">Part Time</MenuItem>
            <MenuItem value="contract">Contract</MenuItem>
            <MenuItem value="intern">Intern</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Notes"
          fullWidth
          multiline
          rows={4}
          value={editEmployee?.employee_notes || ""}
          onChange={(e) =>
            handleFieldChange("employee_notes", e.target.value, setEditEmployee)
          }
        />
        {existingEmployeeAlertMessage && (
          <Alert
            severity={existingEmployeeAlertMessage.type}
            variant="outlined"
            sx={{ mb: 2, mt: 2 }}
            onClose={() => setExistingEmployeeAlertMessage(null)}
          >
            {existingEmployeeAlertMessage.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <IconButton
          aria-label="save"
          onClick={handleSaveEmployee}
          className={styles["icon-button"]}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          aria-label="cancel"
          onClick={handleCloseDialog}
          className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
        >
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );

  const renderAddEmployeeDialog = () => (
    <Dialog
      open={isAddEmployeeDialogOpen}
      onClose={handleCloseAddEmployeeDialog}
    >
      <DialogTitle>Add New Employee</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              margin="dense"
              label="First Name"
              fullWidth
              required
              value={newEmployee.employee_first_name}
              error={!!formErrors.employee_first_name}
              helperText={formErrors.employee_first_name}
              onChange={(e) =>
                handleFieldChange(
                  "employee_first_name",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Last Name"
              fullWidth
              required
              value={newEmployee.employee_last_name}
              error={!!formErrors.employee_last_name}
              helperText={formErrors.employee_last_name}
              onChange={(e) =>
                handleFieldChange(
                  "employee_last_name",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              required
              value={newEmployee.employee_email}
              error={!!formErrors.employee_email}
              helperText={formErrors.employee_email}
              onChange={(e) =>
                handleFieldChange(
                  "employee_email",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              margin="dense"
              label="Phone"
              fullWidth
              value={newEmployee.employee_phone}
              error={!!formErrors.employee_phone}
              helperText={formErrors.employee_phone}
              onChange={(value) =>
                handleFieldChange("employee_phone", value, setNewEmployee)
              }
            />
          </Grid>
          <Grid item xs={12}>
            {renderRoleField(newEmployee, setNewEmployee)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Hire Date"
              type="date"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newEmployee.employee_hire_date}
              error={!!formErrors.employee_hire_date}
              helperText={formErrors.employee_hire_date}
              onChange={(e) =>
                handleFieldChange(
                  "employee_hire_date",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Employment Status</InputLabel>
              <Select
                value={newEmployee.employment_status}
                onChange={(e) =>
                  handleFieldChange(
                    "employment_status",
                    e.target.value,
                    setNewEmployee
                  )
                }
                error={!!formErrors.employment_status}
              >
                <MenuItem value="full-time">Full Time</MenuItem>
                <MenuItem value="part-time">Part Time</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                <MenuItem value="intern">Intern</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Notes"
              fullWidth
              multiline
              rows={4}
              value={newEmployee.employee_notes}
              onChange={(e) =>
                handleFieldChange(
                  "employee_notes",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
        </Grid>
        {newEmployeeAlertMessage && (
          <Alert
            severity={newEmployeeAlertMessage.type}
            variant="outlined"
            sx={{ mb: 2, mt: 2 }}
            onClose={() => setNewEmployeeAlertMessage(null)}
          >
            {newEmployeeAlertMessage.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <IconButton
          onClick={handleSaveNewEmployee}
          aria-label="save"
          className={styles["icon-button"]}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          aria-label="cancel"
          onClick={handleCloseAddEmployeeDialog}
          className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
        >
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className={styles["employees-page"]}>
      <ParallaxBackground />
      <div className={styles["content-wrapper"]}>
        <BusinessDashboardHeader />
        <div className={styles["main-content"]}>
          {renderSidebar()}
          <main
            className={`${styles["employees-content"]} ${
              sidebarActive ? styles["sidebar-active"] : ""
            }`}
          >
            <h1 className={styles.title}>Manage Employees</h1>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            <Button
              variant="outlined"
              startIcon={<PersonAddIcon />}
              onClick={handleAddEmployee}
              className={styles["add-employee-IconButton"]}
              sx={{
                display: "block",
                mt: 4,
                mb: 4,
                color: "midnightblue",
                borderColor: "midnightblue",
                "&:hover": {
                  color: "var(--soft-blue)",
                  borderColor: "var(--soft-blue)",
                },
                cursor: "pointer",
              }}
            >
              Add Employee
            </Button>
            <div className={styles["filter-search"]}>
              <TextField
                name="search"
                label="Search Employees"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                className={styles["search-field"]}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
              <FormControl className={styles["filter-control"]}>
                <InputLabel>Role</InputLabel>
                <Select
                  value={filters.role}
                  onChange={(e) => handleFilterChange("role", e.target.value)}
                  label="Role"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles["filter-control"]}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status}
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">All</MenuItem>
                  {uniqueStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles["items-per-page"]}>
                <InputLabel>Items Per Page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items Per Page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </div>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  mt: 2,
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                }}
              >
                <Table>
                  {renderTableHeader()}
                  {renderTableBody()}
                </Table>
              </TableContainer>
            )}
            <Pagination
              count={Math.ceil(sortedEmployees.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              className={styles.pagination}
            />
          </main>
        </div>
        <DashboardFooter />
      </div>

      {/* Delete Employee Dialog */}
      <Dialog open={isDialogOpen && deleteEmployee} onClose={handleCloseDialog}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {deleteEmployee?.employee_first_name}{" "}
            {deleteEmployee?.employee_last_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="delete"
            onClick={handleConfirmDelete}
            className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="cancel"
            onClick={handleCloseDialog}
            className={styles["icon-button"]}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/* Render Edit Employee Dialog */}
      {renderEditEmployeeDialog()}

      {/* Render Add Employee Dialog */}
      {renderAddEmployeeDialog()}
    </div>
  );
};

export default EmployeeManagement;
