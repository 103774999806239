import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import ContactForm from "../../components/Form/ContactForm/ContactForm";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contact-page" id="wrapper">
      <Header />
      <main className="contact-content">
        <ParallaxBackground />
        <div className="form-content">
          <h1>Contact Us</h1>
          <ContactForm />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
