import React from "react";
import { Typography, Button, Grid } from "@mui/material";
// import "./EmployeeActionItems.css";

const EmployeeActionItems = ({ businessId, employeeInfo }) => {
  return (
    <div className="employee-action-items">
      <Typography variant="h5" gutterBottom>
        Quick Actions
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary">
            View Schedule
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary">
            Request Time Off
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="info">
            Submit Timesheet
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success">
            View Pay Stub
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeActionItems;
