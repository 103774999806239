import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Typography, Button, Box, Skeleton } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import BusinessHeader from "../../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../../components/Background/ParallaxBackground/ParallaxBackground";
import LocationTrackingView from "./LocationTrackingView";
import { useLocationTracking } from "./LocationTrackingContext";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const LocationTrackingContent = () => {
  const { businessId } = useParams();
  const [refreshing, setRefreshing] = useState(false);
  const { fetchLocationData } = useLocationTracking();

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchLocationData(businessId);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "auto", padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4">Location Tracking</Typography>
        <Button
          variant="contained"
          startIcon={
            refreshing ? (
              <RefreshIcon className="animate-spin" />
            ) : (
              <RefreshIcon />
            )
          }
          onClick={handleRefresh}
          disabled={refreshing}
        >
          {refreshing ? "Refreshing..." : "Refresh Data"}
        </Button>
      </Box>

      <LocationTrackingView />
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Skeleton variant="rectangular" width="100%" height={118} />
    <Skeleton variant="text" sx={{ marginTop: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </Box>
);

const LocationTrackingPage = () => {
  const [sidebarActive, setSidebarActive] = useState(() => {
    return JSON.parse(localStorage.getItem("businessSidebarActive") || "true");
  });

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [currentBusiness] = useState(() => getBusinessById(businessId));
  const location = useLocation();
  const businessCategory = useBusinessCategory(businessId);

  // Memoize the business data lookup
  const businessData = useMemo(
    () => getBusinessById(businessId),
    [businessId, getBusinessById]
  );

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  return (
    <div className="business-dashboard-page">
      <ParallaxBackground />
      <BusinessHeader />
      <div className="dashboard-main-content">
        {renderSidebar()}
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames="page"
                timeout={300}
              >
                <Routes>
                  <Route path="/" element={<LocationTrackingContent />} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default LocationTrackingPage;
