import React, { useEffect } from "react";
import { Typography, Card, CardContent, Grid } from "@mui/material";
// import "./EmployeeDashboardOverview.css";

const EmployeeDashboardOverview = ({ employeeData }) => {
  useEffect(() => {
    console.log("Employee data in overview:", employeeData);
  }, [employeeData]);

  if (!employeeData) {
    return <Typography>No employee data available.</Typography>;
  }

  const { employee_first_name, employee_last_name, employee_role } =
    employeeData;

  return (
    <div className="employee-dashboard-overview">
      <Typography variant="h4" gutterBottom>
        Welcome, {employee_first_name || "Employee"}!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {employee_role || "Role not specified"}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Upcoming Shifts</Typography>
              {/* Add logic to display upcoming shifts */}
              <Typography variant="body2">No upcoming shifts</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Pending Tasks</Typography>
              {/* Add logic to display pending tasks */}
              <Typography variant="body2">No pending tasks</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Recent Announcements</Typography>
              {/* Add logic to display recent announcements */}
              <Typography variant="body2">No recent announcements</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeDashboardOverview;
