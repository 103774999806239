import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Typography, Grid, Box, Divider } from "@mui/material";

const PrintComponent = React.forwardRef(({ order, printOptions }, ref) => {
  if (!order) {
    return <Typography>No order data available</Typography>;
  }

  const getPageStyle = (size) => {
    const sizes = {
      "2x2": { width: "2in", height: "2in" },
      "4x6": { width: "4in", height: "6in" },
      "4x4": { width: "4in", height: "4in" },
      "6x4": { width: "6in", height: "4in" },
      A4: { width: "210mm", height: "297mm" },
      Letter: { width: "8.5in", height: "11in" },
      Legal: { width: "8.5in", height: "14in" },
    };
    return {
      width: sizes[size].width,
      height: sizes[size].height,
      padding: "0.5in",
      margin: "0 auto",
      pageBreakAfter: "always",
    };
  };

  const Section = ({ title, children, size }) => (
    <Box style={getPageStyle(size)}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider style={{ marginBottom: "12px" }} />
      {children}
    </Box>
  );

  const OrderLabel = () => (
    <Section title="Order Information" size={printOptions.orderLabel.size}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography>
            <strong>Order ID:</strong> {order.shipping_order_id || "N/A"}
          </Typography>
          <Typography>
            <strong>Tracking Number:</strong> {order.tracking_number || "N/A"}
          </Typography>
          <Typography>
            <strong>From:</strong>{" "}
            {order.sender?.first_name + " " + order.sender?.last_name || "N/A"}
          </Typography>
          <Typography>
            <strong>To:</strong>{" "}
            {order.recipient?.first_name + " " + order.recipient?.last_name ||
              "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <QRCodeSVG value={order.shipping_order_id || ""} size={100} />
        </Grid>
      </Grid>
    </Section>
  );

  const TrackingQRCode = () => (
    <Section title="Tracking QR Code" size={printOptions.trackingQR.size}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <QRCodeSVG value={order.tracking_number || ""} size={150} />
        <Typography style={{ marginTop: "12px" }}>
          Scan to track: {order.tracking_number || "N/A"}
        </Typography>
      </Box>
    </Section>
  );

  const PackageLabel = ({ pkg, index }) => (
    <Section
      title={`Package ${index + 1}`}
      size={printOptions.packageLabels.size}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography>
            <strong>Package ID:</strong> {pkg.package_id || "N/A"}
          </Typography>
          <Typography>
            <strong>Weight:</strong> {pkg.weight || "N/A"} lbs
          </Typography>
          <Typography>
            <strong>Dimensions:</strong> {pkg.dimensions?.length || "N/A"}x
            {pkg.dimensions?.width || "N/A"}x{pkg.dimensions?.height || "N/A"}{" "}
            cm
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <QRCodeSVG value={pkg.package_id || ""} size={80} />
        </Grid>
      </Grid>
    </Section>
  );

  const ItemQRCodes = ({ pkg, index }) => (
    <Section
      title={`Package ${index + 1} Items`}
      size={printOptions.itemQRs.size}
    >
      <Grid container spacing={2}>
        {pkg.PackageItems &&
          pkg.PackageItems.map((item) => (
            <Grid item xs={3} key={item.package_item_id || item.id}>
              <Box textAlign="center">
                <QRCodeSVG value={item.package_item_id || ""} size={60} />
                <Typography variant="caption">{item.name || "N/A"}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Section>
  );

  const Receipt = () => (
    <Section title="Order Receipt" size={printOptions.receipt.size}>
      <Typography>
        <strong>Order Tracking Number:</strong> {order.tracking_number || "N/A"}
      </Typography>
      <Typography>
        <strong>Date:</strong>{" "}
        {order.createdAt ? new Date(order.createdAt).toLocaleString() : "N/A"}
      </Typography>
      <Typography>
        <strong>Sender:</strong>{" "}
        {order.sender?.first_name + " " + order.sender?.last_name || "N/A"}
      </Typography>
      <Typography>
        <strong>Recipient:</strong>{" "}
        {order.recipient?.first_name + " " + order.recipient?.last_name ||
          "N/A"}
      </Typography>
      <Typography>
        <strong>Total Packages:</strong> {order.Packages?.length || 0}
      </Typography>
      <Typography>
        <strong>Shipping Cost:</strong> ${order.shipping_cost || "N/A"}
      </Typography>
      <Typography>
        <strong>Insurance Cost:</strong> ${order.insurance_cost || "N/A"}
      </Typography>
      <Typography>
        <strong>Total Cost:</strong> ${order.total_cost || "N/A"}
      </Typography>
    </Section>
  );

  return (
    <div ref={ref}>
      {printOptions.orderLabel.checked && <OrderLabel />}
      {printOptions.trackingQR.checked && <TrackingQRCode />}
      {order.Packages &&
        order.Packages.map((pkg, index) => (
          <React.Fragment key={pkg.package_id || index}>
            {printOptions.packageLabels.checked && (
              <PackageLabel pkg={pkg} index={index} />
            )}
            {printOptions.packageQRs.checked && (
              <PackageLabel pkg={pkg} index={index} />
            )}
            {printOptions.itemQRs.checked && (
              <ItemQRCodes pkg={pkg} index={index} />
            )}
          </React.Fragment>
        ))}
      {printOptions.receipt.checked && <Receipt />}
    </div>
  );
});

export default PrintComponent;
