import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  useParams,
  useLocation,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Paper,
  Alert,
  Button,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BusinessHeader from "../../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import { OrdersProvider, useOrders } from "./OrdersContext";
import DashboardOverview from "./DashboardOverview";
import QuickAddOrder from "./QuickAddOrder";
import BulkImport from "./BulkImport";
import OrderList from "./OrderList";
import OrderEditPage from "./OrderEditPage";
import CollaborationManagementScreen from "./CollaborationManagementScreen";
import "./ShipmentsPage.css";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const ShipmentsContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const {
    orders,
    groups,
    loading,
    error,
    customers,
    customersLoading,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    updateOrder,
    addOrder,
    deleteOrder,
    deleteOrderFromLocalStorage,
  } = useOrders();
  const [currentBusiness, setCurrentBusiness] = useState(() =>
    getBusinessById(businessId)
  );
  const [completingOrder, setCompletingOrder] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const location = useLocation();
  const prevLocationRef = useRef(null);
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [filteredGroups, setFilteredGroups] = useState([]);

  // Use URL search params as a fallback
  const searchParams = new URLSearchParams(location.search);
  const highlightedOrderId =
    location.state?.highlightedOrderId ||
    searchParams.get("highlightedOrderId");

  useEffect(() => {
    // restore scroll position when navigating back
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.activeTab !== undefined) {
        setActiveTab(location.state.activeTab);
      }
      if (location.state.searchQuery) {
        setSearchQuery(location.state.searchQuery);
      }
      // Clear the state after using it
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, []);

  useEffect(() => {
    if (businessId) {
      fetchGroups(businessId);
      console.log("Fetching groups for business:", businessId);
      console.log("Groups are: ", groups);
    }
  }, [businessId, fetchGroups]);

  useEffect(() => {
    if (groups) {
      setFilteredGroups(groups);
    }
  }, [groups]);

  const handleOrderClick = useCallback(
    (orderId) => {
      navigate(`/business/${businessId}/shipments/${orderId}`);
    },
    [businessId, navigate]
  );

  // Memoize the orders data
  const memoizedOrders = useMemo(() => {
    if (prevLocationRef.current !== location.pathname) {
      fetchOrders(businessId);
    }
    prevLocationRef.current = location.pathname;
    return orders;
  }, [businessId, fetchOrders, location.pathname, orders]);

  // Fetch customers on mount
  useEffect(() => {
    fetchCustomers(businessId);
  }, [businessId, fetchCustomers]);

  useEffect(() => {
    console.log("Customers are: ", customers);
  }, [customers]);

  const handleCompleteOrder = useCallback((order) => {
    setCompletingOrder(order);
    setActiveTab(1);
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const handleRefresh = useCallback(() => {
    setRefreshCount((prevCount) => prevCount + 1);
    fetchOrders(businessId, true);
    fetchCustomers(businessId);
    fetchGroups(businessId);
  }, [businessId, fetchOrders, fetchCustomers, fetchGroups]);

  if (loading || customersLoading) {
    return <LoadingSkeleton />;
  }

  if (error || customersError) {
    return <Alert severity="error">{error || customersError}</Alert>;
  }

  return (
    <Box ref={contentRef} style={{ height: "100%", overflowY: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Shipments Management
      </Typography>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
        sx={{ mb: 2 }}
      >
        Refresh Data
      </Button>
      <DashboardOverview orders={memoizedOrders} />
      <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Order List" />
          <Tab label="Quick Add Order" />
          <Tab label="Bulk Import" />
          <Tab label="Collaboration Management" /> {/* New tab */}
        </Tabs>
        <div className="tab-content">
          {activeTab === 0 && (
            <OrderList
              onOrderUpdate={updateOrder}
              onCompleteOrder={handleCompleteOrder}
              onDeleteDraftOrder={deleteOrderFromLocalStorage}
              initialSearchQuery={searchQuery}
            />
          )}
          {activeTab === 1 && (
            <QuickAddOrder
              existingCustomers={customers}
              onOrderCreate={addOrder}
              initialOrder={completingOrder}
            />
          )}
          {activeTab === 2 && (
            <BulkImport
              onOrdersImport={(importedOrders) =>
                importedOrders.forEach(addOrder)
              }
            />
          )}
          {activeTab === 3 && (
            <CollaborationManagementScreen
              businessId={businessId}
              orders={orders}
              onRefresh={handleRefresh}
            />
          )}
        </div>
      </Paper>
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Skeleton variant="rectangular" width="100%" height={118} />
    <Skeleton variant="text" sx={{ marginTop: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </Box>
);

const ShipmentsPage = () => {
  const { businessId } = useParams();
  const location = useLocation();
  const { getBusinessById } = useBusiness();

  const [sidebarActive, setSidebarActive] = useState(() => {
    return JSON.parse(localStorage.getItem("businessSidebarActive") || "true");
  });

  // Use the custom hook for category management
  const businessCategory = useBusinessCategory(businessId);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  // Memoize the business data lookup
  const businessData = useMemo(
    () => getBusinessById(businessId),
    [businessId, getBusinessById]
  );

  // Memoize the sidebar render to prevent unnecessary re-renders
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  // Show minimum loading state if we don't have essential data
  if (!businessId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="business-dashboard-page">
      <ParallaxBackground />
      <BusinessHeader />
      <div className="dashboard-main-content">
        {renderSidebar()}
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            <OrdersProvider>
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  classNames="page"
                  timeout={300}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        businessData ? (
                          <ShipmentsContent />
                        ) : (
                          <LoadingSkeleton />
                        )
                      }
                    />
                    <Route
                      path="/:orderId"
                      element={
                        businessData ? <OrderEditPage /> : <LoadingSkeleton />
                      }
                    />
                  </Routes>
                </CSSTransition>
              </TransitionGroup>
            </OrdersProvider>
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default ShipmentsPage;
