import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  styled,
  Alert,
  TextField,
  Button,
  Box,
} from "@mui/material";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import styles from "./EmployeeOnboarding.module.css";
import { AuthContext } from "../../../components/Auth/AuthContext";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessage = styled("div")({
  color: "red",
  backgroundColor: "#ffe6e6",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid red",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
});

const EmployeeOnboarding = () => {
  const { api } = useContext(AuthContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [userData, setUserData] = useState({
    user_email: "",
    user_password: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { isProfileComplete, user, loading } = useContext(AuthContext);

  useEffect(() => {
    const fetchEmployeeData = async (token) => {
      try {
        const response = await api.get(`/employees/onboarding/${token}`);
        setEmployee(response.data.employee);
      } catch (error) {
        console.error("Error fetching employee data:", error);
        setAlertMessage({
          type: "error",
          message: "Invalid or expired onboarding link.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    const token = location.pathname.split("/").pop();
    if (token) {
      fetchEmployeeData(token);
    } else {
      setAlertMessage({
        type: "error",
        message: "Onboarding token is missing.",
      });
      setIsLoading(false);
    }
  }, [location]);

  const handleUserDataChange = (field, value) => {
    setUserData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = location.pathname.split("/").pop();
      console.log("Submitting onboarding data:", userData);
      const response = await api.post(
        `/employees/onboarding/${token}`,
        userData
      );

      setAlertMessage({
        type: "success",
        message: response.data.msg,
      });

      if (response.data.user) {
        // User account found, log in the user
        localStorage.setItem("accessToken", response.data.token);
        setTimeout(() => navigate("/dashboard"), 3000);
      } else {
        // User account not found, complete profile
        setTimeout(() => navigate("/complete-profile"), 3000);
      }
    } catch (error) {
      console.error("Error during onboarding:", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: "An error occurred during onboarding. Please try again.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <ParallaxBackground />
        <CircularProgress />
      </>
    );
  }

  if (user && isProfileComplete(user)) {
    return navigate("/dashboard");
  }

  return (
    <div className={styles.mainContent}>
      <ParallaxBackground />
      <main className={styles.container}>
        <Box component="div" className={styles.card}>
          <h2>Employee Onboarding</h2>
          {isLoading ? (
            <CircularProgress />
          ) : employee ? (
            <>
              <p>
                Welcome to {employee.business_name}! You have been invited to
                join as a {employee.employee_role}.
              </p>
              <p>
                Please create an account or link your existing account to
                complete the onboarding process.
              </p>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  type="email"
                  value={userData.user_email}
                  onChange={(e) =>
                    handleUserDataChange("user_email", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Password"
                  type="password"
                  value={userData.user_password}
                  onChange={(e) =>
                    handleUserDataChange("user_password", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                  required
                />
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Complete Onboarding"
                  )}
                </CustomButton>
              </form>
            </>
          ) : null}
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              variant="outlined"
              style={{ marginTop: "20px" }}
            >
              {alertMessage.message}
            </Alert>
          )}
        </Box>
      </main>
    </div>
  );
};

export default EmployeeOnboarding;
