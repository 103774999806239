import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const OrderNotesComponent = ({ order, isOpen, onClose }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { api } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen && order.shipping_order_id) {
      fetchNotes();
    }
  }, [isOpen, order.shipping_order_id]);

  const fetchNotes = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/order-notes/${order.shipping_order_id}/notes`,
        {}
      );
      setNotes(response.data);
    } catch (err) {
      setError("Failed to fetch notes. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (newNote.trim()) {
      setIsLoading(true);
      try {
        const response = await api.post(
          `/order-notes/${order.shipping_order_id}/notes`,
          {
            content: newNote.trim(),
          }
        );
        // Ensure the response includes the user information
        const newNoteWithUser = {
          ...response.data,
          User: response.data.User || {
            user_first_name: response.data.user_first_name,
            user_last_name: response.data.user_last_name,
          },
        };
        setNotes([newNoteWithUser, ...notes]);
        setNewNote("");
        setError(null);
      } catch (err) {
        setError("Failed to add note. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEditNote = (noteId) => {
    setEditingNoteId(noteId);
    const noteToEdit = notes.find((note) => note.note_id === noteId);
    setNewNote(noteToEdit.content);
  };

  const handleSaveEdit = async (noteId) => {
    setIsLoading(true);
    try {
      const response = await api.put(`/order-notes/${noteId}`, {
        content: newNote.trim(),
      });
      // Ensure the response includes the user information
      const updatedNote = {
        ...response.data,
        User: response.data.User || {
          user_first_name: response.data.user_first_name,
          user_last_name: response.data.user_last_name,
        },
      };
      setNotes(
        notes.map((note) => (note.note_id === noteId ? updatedNote : note))
      );
      setEditingNoteId(null);
      setNewNote("");
      setError(null);
    } catch (err) {
      setError("Failed to update note. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingNoteId(null);
    setNewNote("");
  };

  const handleDeleteNote = async (noteId) => {
    setIsLoading(true);
    try {
      await api.delete(`/order-notes/delete/${noteId}`, {});
      setNotes(notes.filter((note) => note.note_id !== noteId));
      setError(null);
    } catch (err) {
      console.error(err);
      setError(
        err.response?.data?.msg || "Failed to delete note: " + err.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Order Notes</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <List>
              {notes.map((note) => (
                <ListItem key={note.note_id}>
                  {editingNoteId === note.note_id ? (
                    <TextField
                      fullWidth
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      autoFocus
                    />
                  ) : (
                    <ListItemText
                      primary={note.content}
                      secondary={
                        <>
                          {new Date(note.createdAt).toLocaleString()}
                          <br />
                          By:{" "}
                          {note.User?.user_first_name ||
                            note.user_first_name}{" "}
                          {note.User?.user_last_name || note.user_last_name}
                        </>
                      }
                    />
                  )}
                  <ListItemSecondaryAction>
                    {editingNoteId === note.note_id ? (
                      <>
                        <IconButton
                          edge="end"
                          onClick={() => handleSaveEdit(note.note_id)}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={handleCancelEdit}>
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          edge="end"
                          onClick={() => handleEditNote(note.note_id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          onClick={() => handleDeleteNote(note.note_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Typography variant="subtitle1" gutterBottom>
              Add New Note
            </Typography>
            <TextField
              fullWidth
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Type your note here"
              variant="outlined"
              multiline
              rows={3}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAddNote}
          startIcon={<AddIcon />}
          color="primary"
          disabled={isLoading}
        >
          Add Note
        </Button>
        <Button onClick={onClose} color="secondary" disabled={isLoading}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderNotesComponent;
