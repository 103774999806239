import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Box,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import WarningIcon from "@mui/icons-material/Warning";
import CustomButton from "../../Button/Button";
import { AuthContext } from "../../Auth/AuthContext";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import styles from "./SecurityComponents.css";

const DisplayPhoneNumber = ({ showMessage }) => {
  const { user, updateUserContext } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState(
    user.user_new_phone || user.user_phone || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { api } = useContext(AuthContext);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const isPendingVerification = () => {
    return (
      !!user.user_new_phone &&
      new Date(user.user_last_phone_verification_date_expired) > new Date()
    );
  };

  const [countdown, setCountdown] = useState(() => {
    if (isPendingVerification()) {
      const expirationDate = new Date(
        user.user_last_phone_verification_date_expired
      );
      const now = new Date();
      return Math.max(0, Math.floor((expirationDate - now) / 1000));
    }
    return 0;
  });

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handlePhoneChange = (value) => {
    setNewPhoneNumber(value);
    if (!matchIsValidTel(value)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleVerificationCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setVerificationCode(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!matchIsValidTel(newPhoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }
    setIsLoading(true);

    try {
      const response = await api.post("/auth/request-phone-verification", {
        phoneNumber: newPhoneNumber,
      });
      showMessage("success", response.data.message);
      setCountdown(600);
      updateUserContext(); // Update the user context after successful request
    } catch (error) {
      console.error("Error requesting phone number change:", error);
      showMessage("error", error.response?.data?.error || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePhoneNumber = async () => {
    setIsLoading(true);
    try {
      const response = await api.post("/auth/remove-phone-number", {});
      showMessage("success", "Phone number removed successfully");
      updateUserContext();
      setNewPhoneNumber("");
      setIsEditing(false);
    } catch (error) {
      console.error("Error removing phone number:", error);
      showMessage("error", error.response?.data?.error || "An error occurred");
    } finally {
      setIsLoading(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setIsVerifying(true);

    try {
      const response = await api.post("/auth/verify-phone", {
        verificationCode: verificationCode,
      });
      updateUserContext();
      showMessage("success", response.data.message);
      setVerificationCode("");
      setIsEditing(false);
    } catch (error) {
      console.error("Error verifying phone number:", error);
      showMessage("error", error.response?.data?.error || "An error occurred");
    } finally {
      setIsVerifying(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewPhoneNumber(user.user_phone || "");
    setPhoneError("");
  };

  const renderPhoneStatus = () => {
    if (user.user_is_phone_verified) {
      return (
        <Alert icon={<CheckIcon />} severity="success" sx={{ mt: 2, mb: 2 }}>
          Phone number verified
        </Alert>
      );
    } else {
      return (
        <Alert icon={<CancelIcon />} severity="error" sx={{ mt: 2, mb: 2 }}>
          Phone number not verified
        </Alert>
      );
    }
  };

  const renderPendingPhoneChange = () => {
    if (isPendingVerification()) {
      const minutesRemaining = Math.ceil(countdown / 60);
      return (
        <Alert icon={<WarningIcon />} severity="warning" sx={{ mt: 2, mb: 2 }}>
          <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>
            Phone number change pending: {user.user_new_phone}
          </p>
          <p style={{ margin: 0 }}>
            Verification code expires in: {minutesRemaining} minutes
          </p>
        </Alert>
      );
    }
    return null;
  };

  return (
    <Box className={styles["display-phone"]}>
      <h3>Phone Number</h3>
      <div className={styles["display-mode"]}>
        <p>{user.user_phone ? user.user_phone : "No Phone Number Provided"}</p>
        <div className={styles["verification-status"]}>
          {renderPhoneStatus()}
          {renderPendingPhoneChange()}
        </div>
        {!isEditing && (
          <Box>
            <CustomButton
              variant="contained"
              onClick={() => setIsEditing(true)}
              text={<EditIcon />}
              style={{ padding: "10px 20px", marginRight: "10px" }}
            />
            {user.user_phone && (
              <CustomButton
                variant="contained"
                onClick={handleOpenConfirmDialog}
                text={<DeleteIcon />}
                style={{ padding: "10px 20px" }}
              />
            )}
          </Box>
        )}
      </div>
      {isEditing && (
        <form onSubmit={handleSubmit} className={styles["edit-mode"]}>
          <MuiTelInput
            value={newPhoneNumber}
            onChange={handlePhoneChange}
            fullWidth
            margin="normal"
            error={!!phoneError}
            helperText={phoneError}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <CustomButton
              type="button"
              variant="contained"
              onClick={handleCancel}
              text="Cancel"
            />
            <CustomButton
              type="submit"
              variant="contained"
              disabled={
                isLoading ||
                newPhoneNumber === user.user_phone ||
                !!phoneError ||
                !matchIsValidTel(newPhoneNumber)
              }
              text={
                isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Send Verification Code"
                )
              }
            />
          </Box>
        </form>
      )}
      {isPendingVerification() && (
        <form onSubmit={handleVerify} className={styles["verify-mode"]}>
          <TextField
            name="verificationCode"
            type="text"
            variant="outlined"
            placeholder="Enter 6-digit code"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            inputProps={{
              maxLength: 6,
              pattern: "[0-9]*",
              inputMode: "numeric",
            }}
            fullWidth
            margin="normal"
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <CustomButton
              type="submit"
              variant="contained"
              disabled={isVerifying || verificationCode.length !== 6}
              text={
                isVerifying ? (
                  <CircularProgress size={24} />
                ) : (
                  "Verify Phone Number"
                )
              }
            />
            <p>
              Time remaining: {Math.floor(countdown / 60)}:
              {countdown % 60 < 10 ? "0" : ""}
              {countdown % 60}
            </p>
          </Box>
          {countdown === 0 && (
            <CustomButton
              type="button"
              variant="outlined"
              onClick={handleSubmit}
              text="Resend Code"
              style={{ marginTop: "10px" }}
            />
          )}
        </form>
      )}
      {openConfirmDialog && (
        <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Phone Number Removal"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove your phone number? This action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseConfirmDialog} text="Cancel" />
            <CustomButton
              onClick={handleRemovePhoneNumber}
              text="Confirm"
              autoFocus
            />
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default DisplayPhoneNumber;
