import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import "./SignUpForm.css";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessage = styled("div")({
  color: "red",
  backgroundColor: "#ffe6e6",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid red",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
});

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { api } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return false;
    }
    if (formData.password.length < 5) {
      setErrorMessage("Password must be at least 5 characters long!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await api.post("/auth/register", {
        user_name: formData.email.split("@")[0],
        user_email: formData.email,
        user_password: formData.password,
      });
      setIsEmailSent(true);
    } catch (error) {
      console.error("There was an error registering!", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" className="signup-form" onSubmit={handleSubmit}>
      {isEmailSent ? (
        <div>
          <h2>Check your email</h2>
          <p>
            We have sent a confirmation link to your email. Please check your
            inbox and follow the instructions to complete your registration.
          </p>
        </div>
      ) : (
        <>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <TextField
            name="email"
            variant="outlined"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            variant="outlined"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="confirmPassword"
            variant="outlined"
            type="password"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <CustomButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Sign Up"}
          </CustomButton>
        </>
      )}
    </Box>
  );
};

export default SignUpForm;
