import React, { useState, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Divider,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  IconButton,
  Alert,
} from "@mui/material";
import {
  PictureAsPdf as PdfIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  generateManifestPage,
  generatePackingListPage,
  generateShippingLabelPage,
  generateCustomsPage,
} from "./PrintGenerators";

const MultiOrderPrintDialog = ({ open, onClose, selectedOrders }) => {
  const [printOptions, setPrintOptions] = useState({
    orderManifest: { checked: true, format: "detailed" },
    packingList: { checked: true, format: "compact" },
    shippingLabels: { checked: true, size: "4x6" },
    customsDocuments: { checked: false },
  });

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [error, setError] = useState(null);
  const [pdfPreview, setPdfPreview] = useState({
    open: false,
    url: null,
  });

  const printComponentRef = useRef();

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;
    setPrintOptions((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked },
    }));
  };

  const handleFormatChange = (event, name) => {
    setPrintOptions((prev) => ({
      ...prev,
      [name]: { ...prev[name], format: event.target.value },
    }));
  };

  const handleSizeChange = (event, name) => {
    setPrintOptions((prev) => ({
      ...prev,
      [name]: { ...prev[name], size: event.target.value },
    }));
  };

  const generatePDF = async () => {
    setIsGeneratingPdf(true);
    setError(null);

    // Create a temporary container for rendering
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.left = "-9999px";
    tempContainer.style.top = "0";
    document.body.appendChild(tempContainer);

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
      });

      let currentPage = 0;

      // Generate Order Manifest if selected
      if (printOptions.orderManifest.checked) {
        const manifestElement = await generateManifestPage(
          selectedOrders,
          printOptions.orderManifest.format
        );
        tempContainer.appendChild(manifestElement);
        await addElementToPdf(manifestElement, pdf, currentPage > 0);
        tempContainer.removeChild(manifestElement);
        currentPage++;
      }

      // Generate Packing List if selected
      if (printOptions.packingList.checked) {
        for (const order of selectedOrders) {
          const packingListElement = await generatePackingListPage(
            order,
            printOptions.packingList.format
          );
          tempContainer.appendChild(packingListElement);
          await addElementToPdf(packingListElement, pdf, currentPage > 0);
          tempContainer.removeChild(packingListElement);
          currentPage++;
        }
      }

      // Generate Shipping Labels if selected
      if (printOptions.shippingLabels.checked) {
        for (const order of selectedOrders) {
          const labelElement = await generateShippingLabelPage(
            order,
            printOptions.shippingLabels.size
          );
          tempContainer.appendChild(labelElement);
          await addElementToPdf(labelElement, pdf, currentPage > 0);
          tempContainer.removeChild(labelElement);
          currentPage++;
        }
      }

      // Generate Customs Documents if selected
      if (printOptions.customsDocuments.checked) {
        for (const order of selectedOrders) {
          const customsElement = await generateCustomsPage(order);
          tempContainer.appendChild(customsElement);
          await addElementToPdf(customsElement, pdf, currentPage > 0);
          tempContainer.removeChild(customsElement);
          currentPage++;
        }
      }

      // Create blob URL for preview
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      setPdfPreview({ open: true, url });
    } catch (error) {
      console.error("Error generating PDF:", error);
      setError(error.message || "Error generating PDF. Please try again.");
    } finally {
      document.body.removeChild(tempContainer);
      setIsGeneratingPdf(false);
    }
  };

  const addElementToPdf = async (element, pdf, addPage) => {
    if (addPage) {
      pdf.addPage();
    }

    const canvas = await html2canvas(element, {
      scale: 2,
      logging: false,
      useCORS: true,
      allowTaint: true,
      backgroundColor: "#FFFFFF",
    });

    const imgData = canvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const ratio = Math.min(
      pdfWidth / imgProps.width,
      pdfHeight / imgProps.height
    );

    const width = imgProps.width * ratio;
    const height = imgProps.height * ratio;
    const x = (pdfWidth - width) / 2;
    const y = (pdfHeight - height) / 2;

    pdf.addImage(imgData, "PNG", x, y, width, height);
  };

  const handleClosePreview = () => {
    if (pdfPreview.url) {
      URL.revokeObjectURL(pdfPreview.url);
    }
    setPdfPreview({ open: false, url: null });
  };

  const handlePrint = () => {
    const printWindow = window.open(pdfPreview.url, "_blank");
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  const PDFPreviewDialog = () => (
    <Dialog
      open={pdfPreview.open}
      onClose={handleClosePreview}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        PDF Preview
        <IconButton
          onClick={handleClosePreview}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {pdfPreview.url && (
          <iframe
            src={pdfPreview.url}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="PDF Preview"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePreview}>Close</Button>
        <Button onClick={handlePrint} variant="contained" color="primary">
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );

  const printOptionItems = [
    {
      name: "orderManifest",
      label: "Order Manifest",
      formats: ["detailed", "summary", "compact"],
    },
    {
      name: "packingList",
      label: "Packing List",
      formats: ["detailed", "compact"],
    },
    {
      name: "shippingLabels",
      label: "Shipping Labels",
      sizes: ["4x6", "4x4", "6x4"],
    },
    {
      name: "customsDocuments",
      label: "Customs Documents",
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Print Options for Multiple Orders</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {printOptionItems.map((option) => (
              <Accordion key={option.name} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{option.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={printOptions[option.name].checked}
                        onChange={handleOptionChange}
                        name={option.name}
                      />
                    }
                    label={option.label}
                  />
                  {option.formats && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Format</InputLabel>
                      <Select
                        value={printOptions[option.name].format}
                        onChange={(e) => handleFormatChange(e, option.name)}
                        label="Format"
                      >
                        {option.formats.map((format) => (
                          <MenuItem key={format} value={format}>
                            {format.charAt(0).toUpperCase() + format.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {option.sizes && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Size</InputLabel>
                      <Select
                        value={printOptions[option.name].size}
                        onChange={(e) => handleSizeChange(e, option.name)}
                        label="Size"
                      >
                        {option.sizes.map((size) => (
                          <MenuItem key={size} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Preview
            </Typography>
            <Box
              maxHeight="50vh"
              overflow="auto"
              border={1}
              borderColor="grey.300"
              p={2}
              bgcolor="grey.100"
            >
              {/* Add preview content here */}
              <Typography>
                {selectedOrders.length} orders selected for printing
              </Typography>
              {/* Add more preview details as needed */}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<CloseIcon />}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            isGeneratingPdf ? <CircularProgress size={20} /> : <PdfIcon />
          }
          onClick={generatePDF}
          disabled={isGeneratingPdf}
        >
          {isGeneratingPdf ? "Generating PDF..." : "Generate PDF"}
        </Button>
      </DialogActions>

      <PDFPreviewDialog />
    </Dialog>
  );
};

export default MultiOrderPrintDialog;
