import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Update as UpdateIcon,
  Print as PrintIcon,
} from "@mui/icons-material";
import MultiOrderPrintDialog from "../../pages/business/shipping/ShipmentsPage/MultiOrderPrintDialog";
const GroupActionMenu = ({
  group,
  onEdit,
  onDelete,
  onUpdateGroupStatus,
  onUpdateOrdersStatus,
  onPrint,
  orders,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [editedName, setEditedName] = useState(group.name);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditDialogOpen(true);
    handleClose();
  };

  const handleSaveEdit = () => {
    onEdit(group.group_id, editedName);
    setIsEditDialogOpen(false);
  };

  const handleDelete = () => {
    onDelete(group.group_id);
    handleClose();
  };

  const handleStatusMenuOpen = (event) => {
    setStatusAnchorEl(event.currentTarget);
    setIsStatusMenuOpen(true);
    handleClose();
  };

  const handleStatusMenuClose = () => {
    setIsStatusMenuOpen(false);
    setStatusAnchorEl(null);
  };

  const handleUpdateGroupStatus = (status) => {
    onUpdateGroupStatus(group.group_id, status);
    handleStatusMenuClose();
  };

  const handleUpdateOrdersStatus = (status) => {
    onUpdateOrdersStatus(group.group_id, status);
    handleStatusMenuClose();
  };

  const handlePrint = () => {
    setIsPrintDialogOpen(true);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={handleStatusMenuOpen}>
          <ListItemIcon>
            <UpdateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Set Status" />
        </MenuItem>
        <MenuItem onClick={handlePrint}>
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Print" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={statusAnchorEl}
        open={isStatusMenuOpen}
        onClose={handleStatusMenuClose}
      >
        <MenuItem onClick={() => handleUpdateGroupStatus("active")}>
          Set Group Active
        </MenuItem>
        <MenuItem onClick={() => handleUpdateGroupStatus("inactive")}>
          Set Group Inactive
        </MenuItem>
        <MenuItem onClick={() => handleUpdateOrdersStatus("pending")}>
          Set Orders Pending
        </MenuItem>
        <MenuItem onClick={() => handleUpdateOrdersStatus("processing")}>
          Set Orders Processing
        </MenuItem>
        <MenuItem onClick={() => handleUpdateOrdersStatus("shipped")}>
          Set Orders Shipped
        </MenuItem>
        <MenuItem onClick={() => handleUpdateOrdersStatus("delivered")}>
          Set Orders Delivered
        </MenuItem>
        <MenuItem onClick={() => handleUpdateOrdersStatus("cancelled")}>
          Set Orders Cancelled
        </MenuItem>
      </Menu>
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <DialogTitle>Edit Group Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveEdit}>Save</Button>
        </DialogActions>
      </Dialog>

      <MultiOrderPrintDialog
        open={isPrintDialogOpen}
        onClose={() => setIsPrintDialogOpen(false)}
        selectedOrders={group.ShippingOrders.map((orderInfo) =>
          orders.find(
            (order) => order.shipping_order_id === orderInfo.shipping_order_id
          )
        ).filter(Boolean)}
      />
    </>
  );
};

export default GroupActionMenu;
