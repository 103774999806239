import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useParams, useLocation, Routes, Route } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BusinessHeader from "../../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import { AssetsProvider, useAssets } from "./AssetsContext";
import AssetsDashboardOverview from "./AssetsDashboardOverview";
import AssetsContent from "./AssetsContent";
import "./AssetsPage.css";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

// Custom hook for business category management
const useBusinessCategory = (businessId) => {
  const { getBusinessById } = useBusiness();
  const [categoryName, setCategoryName] = useState(() => {
    try {
      const categoriesCache = JSON.parse(
        localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
      );
      return categoriesCache[businessId] || null;
    } catch (error) {
      console.error("Error reading category cache:", error);
      return null;
    }
  });

  useEffect(() => {
    const updateCategory = () => {
      const business = getBusinessById(businessId);
      if (business?.business_category_name) {
        setCategoryName(business.business_category_name);

        // Update cache
        try {
          const categoriesCache = JSON.parse(
            localStorage.getItem(BUSINESS_CATEGORY_CACHE_KEY) || "{}"
          );
          categoriesCache[businessId] = business.business_category_name;
          localStorage.setItem(
            BUSINESS_CATEGORY_CACHE_KEY,
            JSON.stringify(categoriesCache)
          );
        } catch (error) {
          console.error("Error updating category cache:", error);
        }
      }
    };

    updateCategory();

    // Set up event listener for business updates
    window.addEventListener("business-updated", updateCategory);
    return () => window.removeEventListener("business-updated", updateCategory);
  }, [businessId, getBusinessById]);

  return categoryName;
};

const AssetsPage = () => {
  const { businessId } = useParams();
  const location = useLocation();
  const { getBusinessById } = useBusiness();

  const [sidebarActive, setSidebarActive] = useState(() => {
    return JSON.parse(localStorage.getItem("businessSidebarActive") || "true");
  });

  // Use the custom hook for category management
  const businessCategory = useBusinessCategory(businessId);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  // Use memo to prevent unnecessary re-renders of heavy business data
  const businessData = useMemo(
    () => getBusinessById(businessId),
    [businessId, getBusinessById]
  );

  // Render sidebar immediately with cached category
  const renderSidebar = useCallback(
    () => (
      <BusinessSidebar
        onSidebarStateChange={handleSidebarStateChange}
        businessCategory={businessCategory}
        isLoading={!businessCategory}
      />
    ),
    [handleSidebarStateChange, businessCategory]
  );

  // Show minimum loading state if we don't have essential data
  if (!businessId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="business-dashboard-page">
      <ParallaxBackground />
      <BusinessHeader />
      <div className="dashboard-main-content">
        {renderSidebar()}
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              sidebarActive ? "sidebar-active" : ""
            }`}
          >
            <AssetsProvider>
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  classNames="page"
                  timeout={300}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        businessData ? (
                          <AssetsContent />
                        ) : (
                          <Box p={3}>
                            <Skeleton variant="rectangular" height={200} />
                            <Skeleton
                              variant="rectangular"
                              height={400}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                        )
                      }
                    />
                    {/* Add more routes as needed */}
                  </Routes>
                </CSSTransition>
              </TransitionGroup>
            </AssetsProvider>
          </main>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default AssetsPage;
