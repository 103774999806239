import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import {
  Person as PersonIcon,
  DirectionsCar as CarIcon,
  LocationOn as LocationIcon,
  LocalShipping as ShippingIcon,
} from "@mui/icons-material";
import { useAssets } from "./AssetsContext";

const StatCard = ({ title, value, icon, color }) => (
  <Card>
    <CardContent>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: color, mr: 2 }}>{icon}</Avatar>
        <Box>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="h4" component="div">
            {value}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const AssetsDashboardOverview = () => {
  const { couriers, vehicles, locations, orders, loading, error } = useAssets();

  // filter out employees with employee_role not Courier
  const activeEmployees =
    couriers?.filter((emp) => emp.employee_role === "Courier").length ?? 0;
  const activeVehicles =
    vehicles?.filter((vehicle) => vehicle.status === "Active").length ?? 0;
  const totalLocations = locations?.length ?? 0;
  const pendingOrders =
    orders?.filter((order) => order.order_status === "Pending").length ?? 0;

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ flexGrow: 1, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 3, bgcolor: "#ffebee" }}>
          <Typography variant="h6" color="error">
            Error loading assets data
          </Typography>
        </Paper>
      </Box>
    );
  }

  // const activeEmployees =
  //   couriers?.filter((emp) => emp.employee_status === "active").length ?? 0;
  // const activeVehicles =
  //   vehicles?.filter((vehicle) => vehicle.status === "Active").length ?? 0;
  // const totalLocations = locations?.length ?? 0;
  // const pendingOrders =
  //   orders?.filter((order) => order.order_status === "pending").length ?? 0;

  return (
    <Box sx={{ flexGrow: 1, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom component="div">
          Assets Overview
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Active Employees"
              value={activeEmployees}
              icon={<PersonIcon />}
              color="#1976d2"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Active Vehicles"
              value={activeVehicles}
              icon={<CarIcon />}
              color="#388e3c"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Locations"
              value={totalLocations}
              icon={<LocationIcon />}
              color="#f57c00"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Pending Orders"
              value={pendingOrders}
              icon={<ShippingIcon />}
              color="#d32f2f"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AssetsDashboardOverview;
