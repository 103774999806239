import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import "./LoginForm.css";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessage = styled("div")({
  color: "red",
  backgroundColor: "#ffe6e6",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid red",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      await login(email, password, rememberMe);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" className="login-form" onSubmit={handleSubmit}>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <TextField
        variant="outlined"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        variant="outlined"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
        }
        label="Remember Me"
      />
      <CustomButton type="submit" variant="contained" fullWidth>
        {isLoading ? <CircularProgress size={24} /> : "Log In"}
      </CustomButton>
    </Box>
  );
};

export default LoginForm;
