import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Grid,
  CircularProgress,
  Alert,
  Typography,
  FormHelperText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

const libraries = ["places"];

const LocationList = ({
  locations,
  onAddLocation,
  onUpdateLocation,
  onDeleteLocation,
}) => {
  const [open, setOpen] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);
  const [formData, setFormData] = useState({
    location_type: "",
    name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    contact_phone: "",
    latitude: "",
    longitude: "",
  });
  const [mapCenter, setMapCenter] = useState({ lat: 40.7128, lng: -74.006 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleOpen = useCallback((location = null) => {
    if (location) {
      setEditingLocation(location);
      setFormData(location);
      setMapCenter({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      });
      setMarkerPosition({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      });
    } else {
      setEditingLocation(null);
      setFormData({
        location_type: "",
        name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state_province: "",
        postal_code: "",
        country: "",
        contact_phone: "",
        latitude: "",
        longitude: "",
      });
      setMarkerPosition(null);
    }
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setEditingLocation(null);
    setErrors({});
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.location_type) newErrors.location_type = "Type is required";
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.address_line1)
      newErrors.address_line1 = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state_province)
      newErrors.state_province = "State/Province is required";
    if (!formData.postal_code)
      newErrors.postal_code = "Postal code is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.contact_phone) {
      newErrors.contact_phone = "Phone number is required";
    } else if (!matchIsValidTel(formData.contact_phone)) {
      newErrors.contact_phone = "Invalid phone number format";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!validateForm()) return;
      setIsLoading(true);

      const locationData = {
        ...formData,
        latitude: markerPosition ? markerPosition.lat.toString() : "",
        longitude: markerPosition ? markerPosition.lng.toString() : "",
      };

      try {
        if (editingLocation) {
          await onUpdateLocation(editingLocation.location_id, locationData);
          setAlertMessage({
            type: "success",
            message: "Location updated successfully",
          });
        } else {
          await onAddLocation(locationData);
          setAlertMessage({
            type: "success",
            message: "Location added successfully",
          });
        }
        handleClose();
      } catch (error) {
        setAlertMessage({
          type: "error",
          message:
            error.message ||
            `Failed to ${editingLocation ? "update" : "add"} location`,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      formData,
      markerPosition,
      editingLocation,
      onUpdateLocation,
      onAddLocation,
      handleClose,
    ]
  );

  const handleDelete = useCallback(
    async (locationId) => {
      if (window.confirm("Are you sure you want to delete this location?")) {
        setIsLoading(true);
        try {
          await onDeleteLocation(locationId);
          setAlertMessage({
            type: "success",
            message: "Location deleted successfully",
          });
        } catch (error) {
          setAlertMessage({
            type: "error",
            message: error.message || "Failed to delete location",
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [onDeleteLocation]
  );

  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;

          const getAddressComponent = (types) => {
            return (
              addressComponents.find((component) =>
                types.some((type) => component.types.includes(type))
              )?.long_name || ""
            );
          };

          setFormData((prev) => ({
            ...prev,
            address_line1: results[0].formatted_address,
            city: getAddressComponent(["locality"]),
            state_province: getAddressComponent([
              "administrative_area_level_1",
            ]),
            postal_code: getAddressComponent(["postal_code"]),
            country: getAddressComponent(["country"]),
            latitude: lat.toString(),
            longitude: lng.toString(),
          }));
        } else {
          setAlertMessage({
            type: "error",
            message: "No results found for the selected location.",
          });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Geocoder failed due to: " + status,
        });
      }
    });
  }, []);

  const handlePlaceSelect = useCallback(async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setMapCenter(latLng);
      setMarkerPosition(latLng);

      const addressComponents = results[0].address_components;

      const getAddressComponent = (types) => {
        return (
          addressComponents.find((component) =>
            types.some((type) => component.types.includes(type))
          )?.long_name || ""
        );
      };

      setFormData((prev) => ({
        ...prev,
        address_line1: address,
        city: getAddressComponent(["locality"]),
        state_province: getAddressComponent(["administrative_area_level_1"]),
        postal_code: getAddressComponent(["postal_code"]),
        country: getAddressComponent(["country"]),
        latitude: latLng.lat.toString(),
        longitude: latLng.lng.toString(),
      }));
    } catch (error) {
      console.error("Error selecting place:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to process the selected address. Please try again.",
      });
    }
  }, []);

  if (loadError) {
    return <Alert severity="error">Error loading maps</Alert>;
  }

  if (!isLoaded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpen()}
        style={{ marginBottom: "1rem" }}
      >
        Add Location
      </Button>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
          sx={{ mb: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State/Province</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((location) => (
                <TableRow key={location.location_id}>
                  <TableCell>{location.location_type}</TableCell>
                  <TableCell>{location.name}</TableCell>
                  <TableCell>{location.address_line1}</TableCell>
                  <TableCell>{location.city}</TableCell>
                  <TableCell>{location.state_province}</TableCell>
                  <TableCell>{location.country}</TableCell>
                  <TableCell>{location.contact_phone}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(location)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(location.location_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingLocation ? "Edit Location" : "Add Location"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.location_type}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="location_type"
                    value={formData.location_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="Warehouse">Warehouse</MenuItem>
                    <MenuItem value="DropOffPoint">Drop Off Point</MenuItem>
                    <MenuItem value="PickUpPoint">Pick Up Point</MenuItem>
                    <MenuItem value="Store">Store</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {errors.location_type && (
                    <FormHelperText>{errors.location_type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <PlacesAutocomplete
                  value={formData.address_line1}
                  onChange={(value) =>
                    handleChange({ target: { name: "address_line1", value } })
                  }
                  onSelect={handlePlaceSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        {...getInputProps({
                          name: "address_line1",
                          label: "Address",
                          fullWidth: true,
                          error: !!errors.address_line1,
                          helperText: errors.address_line1,
                        })}
                      />
                      <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.placeId}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address_line2"
                  label="Address Line 2"
                  fullWidth
                  value={formData.address_line2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="city"
                  label="City"
                  fullWidth
                  value={formData.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="state_province"
                  label="State/Province"
                  fullWidth
                  value={formData.state_province}
                  onChange={handleChange}
                  error={!!errors.state_province}
                  helperText={errors.state_province}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="postal_code"
                  label="Postal Code"
                  fullWidth
                  value={formData.postal_code}
                  onChange={handleChange}
                  error={!!errors.postal_code}
                  helperText={errors.postal_code}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="country"
                  label="Country"
                  fullWidth
                  value={formData.country}
                  onChange={handleChange}
                  error={!!errors.country}
                  helperText={errors.country}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiTelInput
                  name="contact_phone"
                  label="Contact Phone"
                  value={formData.contact_phone}
                  onChange={(value) =>
                    handleChange({ target: { name: "contact_phone", value } })
                  }
                  fullWidth
                  error={!!errors.contact_phone}
                  helperText={errors.contact_phone}
                />
              </Grid>
              <Grid item xs={12}>
                <Box component="div" sx={{ width: "100%", height: "300px" }}>
                  <GoogleMap
                    center={mapCenter}
                    zoom={14}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    onClick={handleMapClick}
                  >
                    {markerPosition && <Marker position={markerPosition} />}
                  </GoogleMap>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {editingLocation ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default LocationList;
